$highlight-color: var(--cor-menu-label);
$sidebar-color: var(--cor-menu-label);
$sidebar-bg-color: var(--cor-menu-principal);
$submenu-bg-color: var(--cor-menu-secundaria);
$submenu-bg-color-collapsed: var(--cor-menu-secundaria);
$icon-bg-color: transparent;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.infinite-scroll-component__outerdiv {
	height: calc(100vh - 205px);
}

.btn-primary,
.btn-primary:disabled {
	background-color: var(--cor-principal);
	border-color: var(--cor-principal);

	&:hover,
	&:focus {
		background-color: var(--cor-secundaria);
		border-color: var(--cor-principal);
	}
}

.btn-outline-primary,
.btn-outline-primary:disabled {
	color: var(--cor-principal);
	border-color: var(--cor-principal);

	&:hover,
	&:focus {
		background-color: var(--cor-secundaria);
		border-color: var(--cor-principal);
		color: white;
	}
}

.btn-secondary {
	&:hover {
		color: #ffffff;
	}
}

.fc-scrollgrid.fc-scrollgrid-liquid {
	background-color: #ffffff;
}

body {
	margin: 0px;
	font-family: "Montserrat", sans-serif;
	font-size: 80%;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--cor-label);
	}
}

.form-label {
	margin-bottom: 0;
}

.btn-close {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: 0.25em;
	color: #000;
	background: transparent url("../assets/close.svg") 50% / 1em auto no-repeat;
	border: 0;
	border-radius: 0.25rem;
	opacity: 0.5;
}

.container-login {
	.background {
		background: url("../assets/bg-login.jpg") 0 0 no-repeat;
		background-size: cover;
		height: 100vh;
		text-indent: -9999px;
	}

	h2 {
		font-size: 1.4rem;
		font-weight: 500;
		margin-bottom: 30px;
		text-align: left;
	}
}

.container-geral {
	padding-top: 68px;
	padding-left: 0;
	padding-right: 0;
	font-size: var(--default-font-size);
}

.top-bar {
	align-items: center;
	box-shadow: 0 1px 15px 1px rgb(52 40 104 / 8%);
	background-color: #fff !important;
	height: 65px;
	padding-bottom: 11px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;

	.nav-link-top {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		&::after {
			display: none;
		}
	}

	.icon-menu {
		cursor: pointer;
		color: #aea9c3;
		font-size: 1.5em;
	}

	button {
		background-color: transparent;
		border: 0;
		color: var(--cor-principal);
		outline: none;
		box-shadow: none;

		&.btn-primary.dropdown-toggle:active:focus,
		&.btn-primary.dropdown-toggle:focus,
		&.btn-primary.dropdown-toggle:active,
		&.btn-primary.dropdown-toggle:hover {
			background-color: transparent;
			box-shadow: none;
			color: var(--cor-principal);
			border: 0;
			outline: none;
		}
	}

	.filter-autocomplete {
		position: relative;

		.dropdown-menu {
			transform: translate(0, -3px) !important;
			width: 100%;
		}

		#dropdown-toggle-top {
			opacity: 0;
			position: absolute;
			top: -9999px;
			width: 100%;
		}

		.list-tags {
			position: absolute;

			.badge {
				color: #fff;
				cursor: pointer;
				font-weight: normal;
				font-size: 11px;
				margin-right: 5px;
				padding: 3px 7px;
			}
		}

		input:focus {
			outline: none;
			box-shadow: none;
		}

		.ico-search {
			cursor: pointer;
			min-height: 25px;
			max-width: 25px;
			justify-content: center;
		}

		.list-tags {
			display: flex;
			justify-content: center;
			margin-top: 5px;
		}
	}
}

.sidebar-nav {
	background-color: var(--cor-menu-principal);
	color: var(--cor-menu-label);
	height: calc(100vh - 68px);
	overflow-y: auto;
	padding: 5px 0;
	z-index: 8;

	.pro-item-content i {
		margin-right: 10px;
		width: 20px;
	}
}

.bg-content-geral {
	background-color: #f2f3f8;
	padding: 0;

	.content-geral {
		height: calc(100vh - 68px);
		overflow-y: auto;
		padding: 30px;
		width: 100%;
		padding-bottom: 0;
		// padding-top: 12px;
		padding-top: 0;

		header {
			align-items: center;
			// margin-bottom: 30px;
			display: flex;
			justify-content: space-between;

			.breadcrumb {
				background-color: transparent;
				margin: 0;
				padding: 0;
			}
		}

		h3 {
			font-size: 21px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		.list-home {
			flex-wrap: wrap;

			.item-list-home {
				align-items: center;
				border: 0;
				border-radius: 10px;
				color: var(--cor-principal);
				cursor: pointer;
				display: flex;
				font-size: 16px;
				margin-bottom: 10px;
				margin-right: 20px;
				padding: 30px;
				min-width: 265px;

				&:hover {
					background: var(--cor-principal);
					color: #fff;
					transition: all 0.5s ease;
				}

				i {
					font-size: 2em;
					margin-right: 15px;
					text-align: center;
					width: 50px;
				}
			}
		}
	}
}

.thStyles {
	border-bottom: 2px solid #ddd;
	padding: 15px;
	background: #ededed;
	color: #666;
	text-align: center;
	font-family: 'Arial, "Helvetica Neue", Helvetica, sans-serif';
}

.tdStyles {
	border-bottom: 1px solid #ddd;
	padding: 10px;
	color: #666;
	text-align: center;
	font-family: 'Arial, "Helvetica Neue", Helvetica, sans-serif';
	width: 150px;
}
thead tr th {
	background: white !important;
	position: sticky;
	top: 0;
	z-index: 99;
}

table tr {
	white-space: nowrap;
}

.btn:disabled {
	cursor: not-allowed !important;
}

legend {
	width: auto;
	text-align: left;
	border: none;
	margin-bottom: 0;
	padding-left: 10px;
	padding-right: 10px;
	font-size: var(--default-font-size);
	font-weight: bold;
	float: unset !important;
}

legend > i.glyphicon-th-list {
	margin-right: 15px;
}

fieldset {
	margin-top: 0px;
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 6px;
	margin-bottom: 10px;
	background-color: #fff;
}

fieldset {
	border: 1px solid var(--cor-principal);
	// padding-top: 5px;
}

.table-sort-button {
	color: #ccccdd;

	&:hover {
		cursor: pointer;
	}

	&.active {
		color: #000;
	}

	&.disabled {
		&:hover {
			cursor: not-allowed;
		}
	}
}

.header-middle-buttons {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;

	@media screen and (max-width: 960px) {
		transform: unset;
		top: unset;
		left: unset;
		display: flex;
		position: relative;
	}
}

.rct-title {
	font-weight: lighter;
}

// .table-home-style {
//   border: 1px solid #000000;

//   .fixed-col {
//     position: sticky;
//     left: 0;
//     top: auto;
//     z-index: 1;
//   }

//   .table-body-cell {
//     background: white;

//     td {
//       padding: 5px 2px;
//       border: 1px solid #000000;
//       height: 4rem;
//     }

//     .status-cell {
//       width: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//   }
// }

.table-container-home {
	width: 100%;
	display: flex;
}

.table-fixed-item {
	position: sticky;
	left: 0;
}

.table-header-cell {
	background: #2c304d;

	th {
		color: #fff;
		font-weight: bold;
		padding: 5px 2px;
		background: #2c304d !important;
	}

	.number-cell {
		text-align: center;
		width: 30px;
		min-width: 20px;
	}
}

.table-dashboard-header {
	th:first-child {
		position: sticky;
		left: 0;
		z-index: 5;
		min-width: 30px;
		width: 30px;
		max-width: 30px;
	}

	th:nth-child(2) {
		position: sticky;
		left: 30px;
		z-index: 5;
		min-width: 95px;
		width: 95px;
		max-width: 95px;
		white-space: nowrap;
	}

	th:nth-child(3) {
		position: sticky;
		left: 95px;
		min-width: 200px;
		width: 200px;
		max-width: 200px;
		white-space: nowrap;
		z-index: 5;
	}
}

.table-dashboard-row {
	td:first-child {
		position: sticky;
		left: 0;
		z-index: 00;
		min-width: 30px;
		width: 30px;
		max-width: 30px;
	}

	td:nth-child(2) {
		position: sticky;
		left: 30px;
		min-width: 95px;
		width: 95px;
		max-width: 95px;
		text-wrap: wrap !important;
		z-index: 0;
	}

	td:nth-child(3) {
		position: sticky;
		left: 95px;
		min-width: 200px;
		width: 200px;
		max-width: 250px;
		text-wrap: wrap !important;
		z-index: 0;
	}
}
.selectedStatus {
	background-color: #2b304d !important;
}

.form-group input:not(:placeholder-shown) + label,
.form-group textarea:not(:placeholder-shown) + label,
.form-group .form-select:not(:placeholder-shown) + label {
	top: 10px !important;
	font-size: var(--default-font-size);
}

.modal-90w {
	width: 90vw;
	/* Define a largura da modal como 90% da largura da tela */
	max-width: none;
	/* Permite que a modal ocupe toda a largura definida */
}

.list-group-item:first-child {
	padding-top: 10px !important;
}

.list-group-item:last-child {
	padding-bottom: 10px !important;
}

.overlay {
    position: absolute;
    top: 3%;
    bottom: 99px;
    width: 50%;
    height: 90%;
    background-color: transparent;
    z-index: 999;
    left: 44%;
}

.target-div {
  position: relative;
  /* Estilos adicionais conforme necessário */
}