/* ----------------------------------

Name: style.css
Version: 1.3

-------------------------------------

Table of contents
        
    01. Google font
    02. Reset
    03. Typography
    04. Background color
    05. Custom
    06. Button
    07. Border
    08. Aside style
    09. Video
    10. Background image
    11. Icon
    12. Magnific popup
    13. Header
    14. Page title
    15. Swiper carousel
    16. Slider and parallax typography
    17. Portfolio
    18. Elements
    19. Blog
    20. Footer
    21. Home page
    22. Others

*/

/* ===================================
    Google font
====================================== */

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

/* ===================================
    Reset
====================================== */

/* body{ font-family: 'montserrat', sans-serif; -webkit-font-smoothing: antialiased;  font-smoothing: antialiased; font-size:14px; color:#6f6f6f; font-weight: 400;line-height: 24px;} */
body {
  font-family: 'montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 14px;
  color: #6f6f6f;
  font-weight: 400;
}
body,
html {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
}
a,
a:active,
a:focus {
  color: #6f6f6f;
  text-decoration: none;
}
a:hover,
a:active {
  color: #ff214f;
  text-decoration: none;
}
a:focus,
a:active,
button:focus,
button:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
}
img {
  max-width: 100%;
  height: auto;
  padding-left: 0px;
  background-size: cover;
  background-position: bottom right;
}
video {
  background-size: cover;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
input,
textarea,
select {
  border: 1px solid #d1d1d1;
  font-size: 14px;
  padding: 8px 15px;
  width: 100%;
  margin: 0 0 20px 0;
  max-width: 100%;
  resize: none;
}
input[type='submit'] {
  width: auto;
}
input[type='button'],
input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
textarea,
input[type='submit'] {
  -webkit-appearance: none;
  outline: none;
}
input:focus,
textarea:focus {
  border-color: #585858;
  outline: none;
}
input[type='button']:focus {
  outline: none;
}
select::-ms-expand {
  display: none;
}
iframe {
  border: 0;
}
p {
  margin: 0 0 25px;
}
b,
strong {
  font-weight: 600;
}
.last-paragraph-no-margin p:last-of-type {
  margin-bottom: 0;
}
.alt-font strong {
  font-weight: 700;
}
ul,
ol,
dl {
  list-style-position: outside;
  margin-bottom: 25px;
}
* {
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}
/* *{transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .2s; -ms-transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s;} */
*:hover {
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}
::selection {
  color: #000;
  background: #dbdbdb;
}
::-moz-selection {
  color: #000;
  background: #dbdbdb;
}
::-webkit-input-placeholder {
  color: #6f6f6f;
  text-overflow: ellipsis;
}
::-moz-placeholder {
  color: #6f6f6f;
  text-overflow: ellipsis;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #6f6f6f;
  text-overflow: ellipsis;
  opacity: 1;
}

/* ===================================
    Typography
====================================== */

/* font family */
.alt-font {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
.main-font {
  font-family: 'montserrat', sans-serif;
}

/* heading */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 25px;
  padding: 0;
  letter-spacing: 0;
  font-weight: 400;
}
h1 {
  font-size: 70px;
  line-height: 70px;
}
h2 {
  font-size: 55px;
  line-height: 60px;
}
h3 {
  font-size: 48px;
  line-height: 54px;
}
h4 {
  font-size: 40px;
  line-height: 46px;
}
h5 {
  font-size: 32px;
  line-height: 40px;
}
h6 {
  font-size: 25px;
  line-height: 30px;
}

/* text size */
.text-extra-small {
  font-size: 11px;
  line-height: 14px;
}
.text-small {
  font-size: 12px;
  line-height: 20px;
}
.text-medium {
  font-size: 16px;
  line-height: 23px;
}
.text-large {
  font-size: 18px;
  line-height: 26px;
}
.text-extra-large {
  font-size: 20px;
  line-height: 26px;
}
.title-large {
  font-size: 100px;
  line-height: 95px;
}
.title-extra-large {
  font-size: 130px;
  line-height: 120px;
}

/* text color */
.text-white {
  color: #fff;
}
.text-black {
  color: #000;
}
.text-extra-dark-gray {
  color: #d84449;
}
.text-dark-gray {
  color: #626262;
}
.text-extra-medium-gray {
  color: #757575;
}
.text-medium-gray {
  color: #939393;
}
.text-extra-light-gray {
  color: #b7b7b7;
}
.text-light-gray {
  color: #d6d5d5;
}
.text-very-light-gray {
  color: #ededed;
}
.text-deep-pink {
  color: #ff214f;
}

/* dropcap */
.first-letter {
  float: left;
  font-size: 50px;
  line-height: auto;
  margin: 0 20px 0 0;
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
}
.first-letter-big {
  float: left;
  font-size: 110px;
  line-height: 110px;
  margin: 0 20px 0 0;
  padding: 0 8px;
  text-align: center;
  font-weight: 600;
  position: relative;
}
.first-letter-big:before {
  position: absolute;
  border-bottom: 1px solid;
  content: '';
  display: block;
  width: 100%;
  top: 55%;
  left: 0;
}
.first-letter-block {
  font-size: 30px;
  height: 55px;
  line-height: 22px;
  padding: 15px 0;
  width: 55px;
  font-weight: 500;
  margin-top: 5px;
}
.first-letter-block-round {
  border-radius: 6px;
  border: 1px solid;
}
.first-letter-round {
  border-radius: 50%;
  font-size: 35px;
  padding: 15px;
  width: 65px;
  height: 65px;
  line-height: 32px;
}

/* blockquote */
blockquote {
  padding: 20px 30px;
}
blockquote p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 17px !important;
  font-weight: 300;
}
blockquote {
  border-left: 2px solid;
  padding: 15px 40px;
  margin: 45px 0;
}
blockquote footer {
  color: #939393;
}
.blog-image blockquote {
  padding: 60px;
  border: 0;
  margin: 0;
}
.blog-image blockquote h6:before {
  content: '\e67f';
  font-family: 'themify';
  font-size: 40px;
  top: 5px;
  position: relative;
  margin-right: 12px;
  color: #ff214f;
}

/* text link hover color */
a.text-link-white,
a.text-link-white i {
  color: #ffffff;
}
a.text-link-white:hover,
a.text-link-white:hover i,
a.text-link-white:focus,
a.text-link-white:focus i {
  color: #ff214f;
}
a.text-link-black,
a.text-link-black i {
  color: #000000;
}
a.text-link-dark-gray {
  color: #939393;
}
a.text-link-dark-gray:hover,
.text-link-dark-gray:focus {
  color: #232323;
}
a.text-link-extra-dark-gray {
  color: #232323;
}
a.text-link-extra-dark-gray:hover,
a.text-link-extra-dark-gray:focus {
  color: #000000;
}
a.text-link-deep-pink,
a.text-link-deep-pink i {
  color: #ff214f;
}
a.text-link-deep-pink:hover,
a.text-link-deep-pink:hover i,
a.text-link-deep-pink:focus,
a.text-link-deep-pink:focus i {
  color: #fff;
}

/* hover color */
a.text-white-hover:hover,
a.text-white-hover:focus {
  color: #fff !important;
}
a.text-black-hover:hover,
a.text-black-hover:focus {
  color: #000 !important;
}
a.text-deep-pink-hover:hover,
a.text-deep-pink-hover:focus {
  color: #ff214f !important;
}
a.text-extra-dark-gray-hover:hover {
  color: #232323 !important;
}
a.text-dark-gray-hover:hover {
  color: #626262 !important;
}
a.text-extra-medium-gray-hover:hover {
  color: #757575 !important;
}
a.text-medium-gray-hover:hover {
  color: #939393 !important;
}
a.text-extra-light-gray-hover:hover {
  color: #b7b7b7 !important;
}
a.text-light-gray-hover:hover {
  color: #d6d5d5 !important;
}
a.text-very-light-gray-hover:hover {
  color: #ededed !important;
}

/* letter spacing */
.no-letter-spacing {
  letter-spacing: 0px;
}
.letter-spacing-1 {
  letter-spacing: 1px;
}
.letter-spacing-2 {
  letter-spacing: 2px;
}
.letter-spacing-3 {
  letter-spacing: 3px;
}
.letter-spacing-4 {
  letter-spacing: 4px;
}
.letter-spacing-5 {
  letter-spacing: 5px;
}
.letter-spacing-6 {
  letter-spacing: 6px;
}
.letter-spacing-7 {
  letter-spacing: 7px;
}
.letter-spacing-8 {
  letter-spacing: 8px;
}
.letter-spacing-9 {
  letter-spacing: 9px;
}
.letter-spacing-10 {
  letter-spacing: 10px;
}
.letter-spacing-minus-1 {
  letter-spacing: -1px;
}
.letter-spacing-minus-2 {
  letter-spacing: -2px;
}
.letter-spacing-minus-3 {
  letter-spacing: -3px;
}
.letter-spacing-minus-4 {
  letter-spacing: -4px;
}
.letter-spacing-minus-5 {
  letter-spacing: -5px;
}
.letter-spacing-minus-6 {
  letter-spacing: -6px;
}
.letter-spacing-minus-7 {
  letter-spacing: -7px;
}
.letter-spacing-minus-8 {
  letter-spacing: -8px;
}
.letter-spacing-minus-9 {
  letter-spacing: -9px;
}
.letter-spacing-minus-10 {
  letter-spacing: -10px;
}

/* font weight */
.font-weight-100 {
  font-weight: 100;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-900 {
  font-weight: 900;
}

/* text property */
.text-transform-unset {
  text-transform: unset;
}
.text-decoration-underline {
  text-decoration: underline;
}
.text-decoration-line-through {
  text-decoration: line-through;
}
.line-height-unset {
  line-height: unset;
}
.line-height-normal {
  line-height: normal;
}
.line-height-none {
  line-height: 0;
}
.word-wrap {
  word-wrap: break-word;
}
.text-nowrap {
  white-space: nowrap;
}
.text-transform-none {
  text-transform: none;
}
.title-line-through {
  padding: 0 15px;
}
.text-middle-line {
  position: relative;
  white-space: nowrap;
}
.text-outside-line {
  position: relative;
}
.text-outside-line::before {
  border-top: 1px solid;
  content: '';
  height: 0;
  left: auto;
  position: absolute;
  right: -60px;
  top: 50%;
  width: 40px;
  opacity: 0.4;
}
.text-outside-line::after {
  border-top: 1px solid;
  content: '';
  height: 0;
  left: auto;
  position: absolute;
  left: -60px;
  top: 50%;
  width: 40px;
  opacity: 0.4;
}
.text-leftside-line {
  position: relative;
  padding-left: 35px;
}
.text-leftside-line:before {
  border-top: 1px solid;
  content: '';
  height: 0;
  left: auto;
  position: absolute;
  left: 0;
  top: 45%;
  width: 25px;
  opacity: 0.7;
}
.text-outside-line-full {
  position: relative;
  display: inline-block;
}
.text-outside-line-full::before {
  content: '';
  position: absolute;
  top: 50%;
  height: 1px;
  width: 99%;
  display: block;
  border-bottom: 1px solid;
  right: 100%;
  margin-right: 25px;
  opacity: 0.15;
}
.text-outside-line-full::after {
  content: '';
  position: absolute;
  top: 50%;
  height: 1px;
  width: 99%;
  display: block;
  border-bottom: 1px solid;
  left: 100%;
  margin-left: 25px;
  opacity: 0.15;
}
.text-middle-line:before {
  border-bottom: 1px solid;
  position: absolute;
  content: '';
  width: 100%;
  top: 53%;
  opacity: 0.35;
}
.text-bottom-line {
  width: 1px;
  border-top: 30px solid;
  margin-left: auto;
  margin-right: auto;
}
.text-middle-line-deep-pink:before {
  border-bottom: 1px solid #ff214f;
  position: absolute;
  content: '';
  width: 100%;
  margin-top: 5px;
  margin-left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.text-decoration-line-through-black,
.text-decoration-line-through-deep-pink {
  position: relative;
}
.text-decoration-line-through-deep-pink:before {
  background: #ff214f;
}
.text-decoration-line-through-black:before {
  background: #000;
}
.text-decoration-line-through-black::before,
.text-decoration-line-through-deep-pink:before {
  content: '';
  height: 1px;
  left: -10px;
  position: absolute;
  top: 53%;
  width: calc(100% + 20px);
  -webkit-transform: translateY(-53%);
  -moz-transform: translateY(-53%);
  -ms-transform: translateY(-53%);
  -o-transform: translateY(-53%);
  transform: translateY(-53%);
}

/* line height */
.line-height-10 {
  line-height: 10px;
}
.line-height-13 {
  line-height: 13px;
}
.line-height-18 {
  line-height: 18px;
}
.line-height-20 {
  line-height: 20px;
}
.line-height-24 {
  line-height: 24px;
}
.line-height-22 {
  line-height: 22px;
}
.line-height-26 {
  line-height: 26px;
}
.line-height-28 {
  line-height: 28px;
}
.line-height-30 {
  line-height: 30px;
}
.line-height-35 {
  line-height: 35px;
}
.line-height-40 {
  line-height: 40px;
}
.line-height-45 {
  line-height: 45px;
}
.line-height-50 {
  line-height: 50px;
}
.line-height-55 {
  line-height: 55px;
}
.line-height-60 {
  line-height: 60px;
}
.line-height-65 {
  line-height: 65px;
}
.line-height-70 {
  line-height: 70px;
}
.line-height-75 {
  line-height: 75px;
}
.line-height-80 {
  line-height: 80px;
}
.line-height-85 {
  line-height: 85px;
}
.line-height-90 {
  line-height: 90px;
}
.line-height-95 {
  line-height: 95px;
}
.line-height-100 {
  line-height: 100px;
}
.line-height-110 {
  line-height: 110px;
}
.line-height-120 {
  line-height: 120px;
}

/* ===================================
    Background color
====================================== */

.bg-transparent {
  background-color: transparent;
}
.bg-white {
  background-color: #fff;
}
.bg-black {
  background-color: #000;
}
.bg-extra-dark-gray {
  background-color: #1c1c1c;
}
.bg-dark-gray {
  background-color: #757575;
}
.bg-extra-medium-gray {
  background-color: #939393;
}
.bg-medium-gray {
  background-color: #dbdbdb;
}
.bg-extra-light-gray {
  background-color: #e0e0e0;
}
.bg-medium-light-gray {
  background-color: #ededed;
}
.bg-light-gray {
  background-color: #f7f7f7;
}
.bg-very-light-gray {
  background-color: #fafafa;
}
.bg-deep-pink {
  background-color: #660099;
}
.bg-transparent-white {
  background-color: rgba(255, 255, 255, 0.3);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 37%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(37%, rgba(255, 255, 255, 0)),
    color-stop(96%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 37%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 37%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 37%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 37%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
}
.bg-transparent-black {
  background-color: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 37%,
    rgba(0, 0, 0, 1) 96%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(37%, rgba(0, 0, 0, 0)),
    color-stop(96%, rgba(0, 0, 0, 1)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 37%,
    rgba(0, 0, 0, 1) 96%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 37%,
    rgba(0, 0, 0, 1) 96%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 37%,
    rgba(0, 0, 0, 1) 96%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 37%,
    rgba(0, 0, 0, 1) 96%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
}
.bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.85);
}
.bg-black-opacity {
  background-color: rgba(0, 0, 0, 0.85);
}
.bg-black-opacity-light {
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-deep-pink-opacity {
  background-color: #660099de;
}
.bg-charcoal-gray {
  background-color: #0e0f10;
}

/* ===================================
    Custom
====================================== */

.center-col {
  float: none;
  margin-left: auto;
  margin-right: auto;
}
section {
  padding: 130px 0;
  overflow: hidden;
}
section.big-section {
  padding: 20px 0;
}
section.extra-big-section {
  padding: 200px 0;
}
section.half-section {
  padding: 80px 0;
}
.overlap-section {
  margin-top: -14%;
  position: relative;
}
.col-2-nth .col-md-6:nth-child(2n + 1) {
  clear: left;
}
.col-2-nth .col-sm-6:nth-child(2n + 1) {
  clear: left;
}
.col-3-nth .col-md-4:nth-child(3n + 1) {
  clear: left;
}
.col-3-nth .col-sm-4:nth-child(3n + 1) {
  clear: left;
}
.col-4-nth .col-md-3:nth-child(4n + 1) {
  clear: left;
}
.col-4-nth .col-sm-3:nth-child(4n + 1) {
  clear: left;
}

/* input */
.input-border-bottom {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #484848;
  padding: 10px 0;
  margin-bottom: 30px;
  font-size: 14px;
}
.input-border-bottom:focus {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #929292;
}
.input-border-bottom::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 14px;
}
.input-border-bottom::-moz-placeholder {
  font-weight: 300;
  font-size: 14px;
}
.input-border-bottom:-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
}

.extra-big-input,
.extra-big-textarea,
.extra-big-select select {
  padding: 18px 25px;
  font-size: 14px;
  line-height: 24px;
  height: 62px;
}
.big-input,
.big-textarea,
.big-select select {
  padding: 18px 25px;
  font-size: 14px;
  border-radius: 0;
}

.medium-input,
.medium-textarea,
.medium-select select {
  padding: 12px 20px;
  font-size: 14px;
  line-height: normal;
  border-radius: 0;
}
.small-input,
.small-textarea {
  padding: 12px 15px;
  font-size: 11px;
  line-height: normal;
}
.small-select select {
  padding: 15px 15px;
  line-height: normal;
}
.medium-input-light,
.medium-textarea-light,
.medium-select-light select {
  padding: 12px 25px;
  font-size: 12px;
  line-height: normal;
}

.extra-small-input,
.extra-small-textarea,
.extra-small-select select {
  padding: 9px 17px;
  font-size: 12px;
  line-height: normal;
}

.select-style {
  width: 100%;
  overflow: hidden;
  background: url('../images/select-arrow.png') no-repeat 97% 50%;
  border: 1px solid #d1d1d1;
  margin-bottom: 20px;
}
.select-style select {
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
.select-style select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.input-group input,
.input-group textarea,
.input-group select {
  margin: 0;
  border-radius: 4px 0 0 4px;
  border-color: #fff;
  padding: 19px 25px;
}
.input-group-btn .btn {
  border-radius: 0 4px 4px 0;
}
.input-group-btn .btn.btn-large {
  line-height: 2px;
  height: 62px;
  padding: 15px 25px !important;
}
.input-group-404 input {
  height: 62px;
}

.input-group .required-error {
  border: 1px solid #ee2323 !important;
}
.input-group .required-error,
.input-group .required-error + .input-group-btn button,
.input-group .required-error + .input-group-btn a.btn {
  border: 1px solid #ee2323 !important;
}

.input-border-white .input-border-bottom {
  border-bottom: 1px solid #ffffff;
  color: #fff;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
}
.input-border-white .input-border-bottom:focus {
  background: transparent;
  border-bottom: 1px solid #ffffff;
  color: #fff;
}
.input-border-white .input-border-bottom::-webkit-input-placeholder {
  color: #fff;
}
.input-border-white .input-border-bottom::-moz-placeholder {
  color: #fff;
}
.input-border-white .input-border-bottom:-ms-input-placeholder {
  color: #fff;
}

.btn .caret {
  border-top: 4px solid;
}
input.input-bg {
  background-color: #f7f7f7;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-bottom: 15px;
}
input.input-bg:focus,
textarea.input-bg:focus {
  border: 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
textarea.input-bg {
  background-color: #f7f7f7;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-bottom: 15px;
  min-height: 130px;
}
.input-bg::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
}
.input-bg::-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
}
.input-bg:-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
}
.required-error {
  border: 1px solid #ee2323 !important;
}
.input-border-bottom.required-error {
  border-width: 0 !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #ee2323 !important;
}
#success-subscribe-newsletter {
  clear: both;
  margin: 0 15px 15px;
  text-align: center;
  border: 1px solid #1a940a;
  color: #1a940a;
  padding: 2px 0;
  display: none;
  font-size: 11px;
}
#success-subscribe-newsletter2 {
  clear: both;
  margin: 0 15px 15px;
  text-align: center;
  border: 1px solid #1a940a;
  color: #1a940a;
  padding: 2px 0;
  display: none;
  font-size: 11px;
}

#success-contact-form {
  clear: both;
  margin: 0 15px 15px;
  text-align: center;
  border: 1px solid #1a940a;
  color: #1a940a;
  padding: 2px 0;
  display: none;
  font-size: 11px;
}
#success-contact-form-2 {
  clear: both;
  margin: 0 15px 15px;
  text-align: center;
  border: 1px solid #1a940a;
  color: #1a940a;
  padding: 2px 0;
  display: none;
  font-size: 11px;
}
#success-contact-form-3 {
  clear: both;
  margin: 0 15px 15px;
  text-align: center;
  border: 1px solid #1a940a;
  color: #1a940a;
  padding: 2px 0;
  display: none;
  font-size: 11px;
}
#success-project-contact-form {
  clear: both;
  margin: 0 15px 15px;
  text-align: center;
  border: 1px solid #1a940a;
  color: #1a940a;
  padding: 2px 0;
  display: none;
  font-size: 11px;
}
#success-project-contact-form-4 {
  clear: both;
  margin: 0 15px 15px;
  text-align: center;
  border: 1px solid #1a940a;
  color: #1a940a;
  padding: 2px 0;
  display: none;
  font-size: 11px;
}

/* separator */
.separator-line-verticle-extra-small {
  width: 1px;
  height: 8px;
}
.separator-line-verticle-small-thick {
  width: 17px;
  height: 3px;
}
.separator-line-verticle-small {
  width: 1px;
  height: 13px;
}
.separator-line-verticle-small-thick2 {
  width: 64px;
  height: 7px;
}
.separator-line-verticle-large {
  width: 1px;
  height: 20px;
}
.separator-line-verticle-extra-large {
  width: 1px;
  height: 30px;
}
.separator-line-verticle-medium-thick-full {
  width: 8px;
  height: 40px;
}
.separator-line-verticle-large-thick {
  width: 109px;
  height: 11px;
}
.separator-line-horrizontal-medium-light {
  width: 36px;
  height: 3px;
}
.separator-line-horrizontal-medium-light2 {
  width: 40%;
  height: 1px;
}
.separator-line-horrizontal-medium-light3 {
  width: 18%;
  height: 1px;
}
.separator-line-horrizontal-medium-thick {
  width: 50px;
  height: 5px;
}
.separator-line-horrizontal-full {
  width: 100%;
  height: 1px;
}

/* divider */
.divider-full {
  width: 100%;
  height: 1px;
  display: inline-block;
}
.new-demo {
  background: #ff214f;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  margin-left: 8px;
  padding: 2px 8px;
}

/* opacity */
.opacity-very-light {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  top: 0;
  left: 0;
}
.opacity-light {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  top: 0;
  left: 0;
}
.opacity-extra-medium {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
}
.opacity-medium {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.75;
  top: 0;
  left: 0;
}
.opacity-full {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  top: 0;
  left: 0;
}
.opacity-full-dark {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  top: 0;
  left: 0;
}

.opacity1 {
  opacity: 0.1;
}
.opacity2 {
  opacity: 0.2;
}
.opacity3 {
  opacity: 0.3;
}
.opacity4 {
  opacity: 0.4;
}
.opacity5 {
  opacity: 0.5;
}
.opacity6 {
  opacity: 0.6;
}
.opacity7 {
  opacity: 0.7;
}
.opacity8 {
  opacity: 0.8;
}
.opacity9 {
  opacity: 0.9;
}

/* box-shadow */
.btn-shadow {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
.box-shadow-light {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.box-shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.box-shadow-dark {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}
.box-shadow-large {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}

/* z-index */
.z-index-1111 {
  z-index: 1111;
}
.z-index-111 {
  z-index: 111;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}
.z-index-0 {
  z-index: 0;
}
.z-index-minus2 {
  z-index: -2;
}

/* verticle align */
.vertical-align-middle {
  vertical-align: middle;
}
.vertical-align-top {
  vertical-align: top;
}
.vertical-align-bottom {
  vertical-align: bottom;
}
.vertical-middle {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.clear-both {
  clear: both;
}
.line-break {
  display: block;
}
.no-transition *,
.swiper-container *,
.mfp-container *,
.skillbar-bar-main *,
.portfolio-grid *,
.parallax,
.rev-slider *,
.header-searchbar *,
.header-social-icon * {
  transition-timing-function: none;
  -moz-transition-timing-function: none;
  -webkit-transition-timing-function: none;
  -o-transition-timing-function: none;
  -ms-transition-timing-function: none;
  transition-duration: 0s;
  -moz-transition-duration: 0s;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
  -ms-transition-duration: 0s;
}
.absolute-middle-center {
  left: 50%;
  top: 50%;
  position: absolute;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
}
.absolute-bottom-center {
  left: 50%;
  top: 80%;
  position: absolute;
  -ms-transform: translateX(-50%) translateY(-80%);
  -moz-transform: translateX(-50%) translateY(-80%);
  -webkit-transform: translateX(-50%) translateY(-80%);
  transform: translateX(-50%) translateY(-80%);
  -o-transform: translateX(-50%) translateY(-80%);
}

/* ===================================
    Button
====================================== */

.btn {
  display: inline-block;
  border: 2px solid transparent;
  letter-spacing: 0.5px;
  line-height: inherit;
  border-radius: 0;
  text-transform: uppercase;
  width: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

/* button size */
.btn.btn-very-small {
  font-size: 9px;
  padding: 1px 17px;
  line-height: 22px;
}
.btn.btn-small {
  font-size: 11px;
  padding: 4px 24px;
}
.btn.btn-medium {
  font-size: 12px;
  padding: 6px 25px 5px;
}
.btn.btn-large {
  font-size: 13px;
  padding: 9px 34px;
  line-height: 25px;
}
.btn.btn-extra-large {
  font-size: 15px;
  padding: 12px 40px 13px;
  line-height: 25px;
}
.btn-dual .btn {
  margin: 0 10px;
}
.btn i {
  margin-left: 6px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

/* button background */
.btn.btn-white {
  background: #ffffff;
  border-color: #ffffff;
  color: #232323;
}
.btn.btn-white:hover,
.btn.btn-white:focus {
  background: transparent;
  color: #fff;
}
.btn.btn-black {
  background: #000000;
  border-color: #000000;
  color: #fff;
}
.btn.btn-black:hover,
.btn.btn-black:focus {
  background: transparent;
  color: #000;
}
.btn.btn-dark-gray {
  background: #232323;
  border-color: #232323;
  color: #fff;
}
.btn.btn-dark-gray:hover,
.btn.btn-dark-gray:focus {
  background: transparent;
  color: #232323;
}
.btn.btn-light-gray {
  background: #dbdbdb;
  border-color: #dbdbdb;
  color: #232323;
}
.btn.btn-light-gray:hover,
.btn.btn-light-gray:focus {
  background: transparent;
  border-color: #dbdbdb;
  color: #dbdbdb;
}
.btn.btn-deep-pink {
  background: #ff214f;
  border-color: #ff214f;
  color: #ffffff;
}
.btn.btn-deep-pink:hover,
.btn.btn-deep-pink:focus {
  background: transparent;
  border-color: #ff214f;
  color: #ff214f;
}

/* button transparent */
.btn.btn-transparent-white {
  background: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.btn.btn-transparent-white:hover,
.btn.btn-transparent-white:focus {
  background: #ffffff;
  border-color: #ffffff;
  color: #232323;
}
.btn.btn-transparent-black {
  background: transparent;
  border-color: #000000;
  color: #000000;
}
.btn.btn-transparent-black:hover,
.btn.btn-transparent-black:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.btn.btn-transparent-dark-gray {
  background: transparent;
  border-color: #232323;
  color: #232323;
}
.btn.btn-transparent-dark-gray:hover,
.btn.btn-transparent-dark-gray:focus {
  background: #232323;
  border-color: #232323;
  color: #ffffff;
}
.btn.btn-transparent-light-gray {
  background: transparent;
  border-color: #dbdbdb;
  color: #dbdbdb;
}
.btn.btn-transparent-light-gray:hover,
.btn.btn-transparent-light-gray:focus {
  background: #dbdbdb;
  border-color: #dbdbdb;
  color: #232323;
}
.btn.btn-transparent-deep-pink {
  background: transparent;
  border-color: #ff214f;
  color: #ff214f;
}
.btn.btn-transparent-deep-pink:hover,
.btn.btn-transparent-deep-pink:focus {
  background: #ff214f;
  border-color: #ff214f;
  color: #fff;
}

/* button rounded */
.btn.btn-rounded {
  border-radius: 50px;
}
.btn.btn-rounded.btn-very-small {
  padding: 2px 23px 1px;
}
.btn.btn-rounded.btn-small {
  padding: 5px 29px;
}
.btn.btn-rounded.btn-medium {
  padding: 6px 32px;
}
.btn.btn-rounded.btn-large {
  padding: 9px 38px;
}
.btn.btn-rounded.btn-extra-large {
  padding: 12px 45px 13px;
}

/* image button */
.image-button {
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  padding: 26px;
  font-family: 'montserrat', sans-serif;
  font-size: 16px;
}
.image-button:hover {
  background: rgba(0, 0, 0, 0.5);
}

.tag-cloud a {
  font-size: 10px;
  padding: 3px 8px;
  border: 1px solid #d9d9d9;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-transform: uppercase;
  color: #232323;
  line-height: 18px;
}
.tag-cloud a:hover {
  background: #232323;
  color: #fff !important;
  border: 1px solid #232323;
}

/* dropdown style 1 */
.dropdown-style-1 .btn {
  color: #fff;
  font-size: 11px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  font-weight: normal;
}
.dropdown-style-1 .btn:hover,
.custom-dropdown btn:focus {
  color: #ff214f;
}
.dropdown-style-1 .dropdown-menu {
  margin-top: 2px;
  min-width: 130px;
  border-radius: 0;
  border: none;
  z-index: 444;
  right: 0;
  left: auto;
}
.dropdown-style-1 .dropdown-menu > li > a {
  padding: 6px 15px;
  font-size: 12px;
}
.dropdown-style-1 .dropdown-menu > li > a:hover,
.dropdown-style-1 .dropdown-menu > li > a:focus {
  background: #ededed;
}

/* ===================================
    Border
====================================== */

.border-all {
  border: 1px solid #ededed;
}

/* boder width */
.border-width-1 {
  border-width: 1px;
}
.border-width-2 {
  border-width: 2px;
}
.border-width-3 {
  border-width: 3px;
}
.border-width-4 {
  border-width: 4px;
}
.border-width-5 {
  border-width: 5px;
}
.border-width-6 {
  border-width: 6px;
}
.border-width-7 {
  border-width: 7px;
}
.border-width-8 {
  border-width: 8px;
}
.border-width-9 {
  border-width: 9px;
}
.border-width-10 {
  border-width: 10px;
}
.border-width-20 {
  border-width: 20px;
}

/* boder align */
.border-top {
  border-top: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.border-left {
  border-left: 1px solid;
}
.border-right {
  border-right: 1px solid;
}
.border-lr {
  border-left: 1px solid;
  border-right: 1px solid;
}
.border-tb {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

/* border color */
.border-color-white {
  border-color: #fff;
}
.border-color-black {
  border-color: #000;
}
.border-color-extra-dark-gray {
  border-color: #232323;
}
.border-color-medium-dark-gray {
  border-color: #363636;
}
.border-color-dark-gray {
  border-color: #939393;
}
.border-color-extra-medium-gray {
  border-color: #dbdbdb;
}
.border-color-medium-gray {
  border-color: #e4e4e4;
}
.border-color-extra-light-gray {
  border-color: #ededed;
}
.border-color-light-gray {
  border-color: #f5f5f5;
}
.border-color-light-pink {
  border-color: #862237;
}
.border-color-deep-pink {
  border-color: #ff214f;
}
.border-color-transparent-pink {
  border-color: rgba(255, 33, 79, 0.45);
}

/* boder style */
.border-dotted {
  border-style: dotted !important;
}
.border-dashed {
  border-style: dashed !important;
}
.border-solid {
  border-style: solid !important;
}
.border-double {
  border-style: double !important;
}
.border-groove {
  border-style: groove !important;
}
.border-ridge {
  border-style: ridge !important;
}
.border-inset {
  border-style: inset !important;
}
.border-outset {
  border-style: outset !important;
}
.border-none {
  border-style: none !important;
}
.border-hidden {
  border-style: hidden !important;
}
.border-transperent {
  border-color: transparent !important;
}

.no-border-top {
  border-top: 0 !important;
}
.no-border-bottom {
  border-bottom: 0 !important;
}
.no-border-right {
  border-right: 0 !important;
}
.no-border-left {
  border-left: 0 !important;
}

/* border for overline icon box */
.overline-icon-box {
  border-top-width: 4px;
}

/* border color for overline icon box */
.overline-icon-box.overline-white {
  border-top-color: #fff;
}
.overline-icon-box.overline-black {
  border-top-color: #000;
}
.overline-icon-box.overline-extra-dark-gray {
  border-top-color: #232323;
}
.overline-icon-box.overline-medium-dark-gray {
  border-top-color: #363636;
}
.overline-icon-box.overline-dark-gray {
  border-top-color: #939393;
}
.overline-icon-box.overline-extra-light-gray {
  border-top-color: #dbdbdb;
}
.overline-icon-box.overline-medium-gray {
  border-top-color: #e4e4e4;
}
.overline-icon-box.overline-light-gray {
  border-top-color: #f5f5f5;
}
.overline-icon-box.overline-light-pink {
  border-top-color: #862237;
}
.overline-icon-box.overline-deep-pink {
  border-top-color: #ff214f;
}
.overline-icon-box.overline-transparent-pink {
  border-top-color: rgba(255, 33, 79, 0.45);
}

/* transparent border */
.border-black-light {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.border-white-light {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

/* border radius */
.border-radius-1 {
  border-radius: 1px;
}
.border-radius-2 {
  border-radius: 2px;
}
.border-radius-3 {
  border-radius: 3px;
}
.border-radius-4 {
  border-radius: 4px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-6 {
  border-radius: 6px;
}
.border-radius-7 {
  border-radius: 7px;
}
.border-radius-8 {
  border-radius: 8px;
}
.border-radius-9 {
  border-radius: 9px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-50 {
  border-radius: 50%;
}
.border-radius-100 {
  border-radius: 100%;
}
.border-radius-none {
  border-radius: 0;
}

/* ===================================
    Aside style
====================================== */

.aside-title {
  position: relative;
  overflow: hidden;
}
.aside-title span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.aside-title:after {
  content: '';
  display: inline-block;
  width: 100%;
  background: #ff214f;
  height: 1px;
  top: 50%;
  position: absolute;
}
.right-sidebar {
  padding-right: 45px;
}
.left-sidebar {
  padding-left: 45px;
}

/* ===================================
    Video
====================================== */

.video-wrapper {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.html-video {
  background-color: #000;
  height: auto;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: auto;
  z-index: -3;
}
.video-wrapper iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: -5px !important;
  width: 100%;
}
.video-background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  background: rgba(67, 67, 67, 0.5);
}

/* ===================================
    Background image
====================================== */

.parallax {
  position: relative;
  background-size: cover;
  overflow: hidden;
  background-attachment: fixed;
}
.fix-background {
  position: relative;
  background-size: cover;
  animation-duration: 0s;
  animation-fill-mode: none;
  -webkit-animation-duration: 0s;
  -webkit-animation-fill-mode: none;
  -moz-animation-duration: 0s;
  -moz-animation-fill-mode: none;
  -ms-animation-duration: 0s;
  -ms-animation-fill-mode: none;
  -o-animation-fill-mode: none;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.cover-background {
  position: relative !important;
  background-size: cover !important;
  overflow: hidden !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.background-size-inherit {
  background-size: inherit;
}
.background-attachment-inherit {
  background-attachment: inherit;
}
.background-position-left {
  background-position: left center;
}
.background-position-left-bottom {
  background-position: left bottom;
}
.background-position-right {
  background-position: right center;
}
.background-position-top {
  background-position: center top !important;
}
.up-down-ani {
  display: table;
  position: relative;
  -webkit-animation-name: up-down-animation;
  -webkit-animation-duration: 0.7s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0.7s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -moz-animation-name: up-down-animation;
  -moz-animation-duration: 0.7s;
  -moz-animation-timing-function: linear;
  -moz-animation-delay: 0.7s;
  -moz-animation-iteration-count: infinite;
  -ms-animation-name: up-down-animation;
  -ms-animation-duration: 0.7s;
  -ms-animation-timing-function: linear;
  -ms-animation-delay: 0.7s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: alternate;
  -o-animation-direction: alternate;
  -o-animation-name: up-down-animation;
  -o-animation-duration: 0.7s;
  -o-animation-timing-function: linear;
  -o-animation-delay: 0.7s;
  -o-animation-iteration-count: infinite;
  -o-animation-direction: alternate;
  animation-direction: alternate;
  animation-name: up-down-animation;
  animation-duration: 0.7s;
  animation-timing-function: linear;
  animation-delay: 0.7s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  margin: 0 auto;
}
.background-position-x-50 {
  background-position-x: 50% !important;
}

/*==============================
    Margin
================================*/

.margin-half-all {
  margin: 0.5%;
}
.margin-one-all {
  margin: 1%;
}
.margin-one-half-all {
  margin: 1.5%;
}
.margin-two-all {
  margin: 2%;
}
.margin-two-half-all {
  margin: 2.5%;
}
.margin-three-all {
  margin: 3%;
}
.margin-three-half-all {
  margin: 3.5%;
}
.margin-four-all {
  margin: 4%;
}
.margin-four-half-all {
  margin: 4.5%;
}
.margin-five-all {
  margin: 5%;
}
.margin-five-half-all {
  margin: 5.5%;
}
.margin-six-all {
  margin: 6%;
}
.margin-six-half-all {
  margin: 6.5%;
}
.margin-seven-all {
  margin: 7%;
}
.margin-seven-half-all {
  margin: 7.5%;
}
.margin-eight-all {
  margin: 8%;
}
.margin-eight-half-all {
  margin: 8.5%;
}
.margin-nine-all {
  margin: 9%;
}
.margin-nine-half-all {
  margin: 9.5%;
}
.margin-ten-all {
  margin: 10%;
}
.margin-ten-half-all {
  margin: 10.5%;
}
.margin-eleven-all {
  margin: 11%;
}
.margin-eleven-half-all {
  margin: 11.5%;
}
.margin-twelve-all {
  margin: 12%;
}
.margin-twelve-half-all {
  margin: 12.5%;
}
.margin-thirteen-all {
  margin: 13%;
}
.margin-thirteen-half-all {
  margin: 13.5%;
}
.margin-fourteen-all {
  margin: 14%;
}
.margin-fourteen-half-all {
  margin: 14.5%;
}
.margin-fifteen-all {
  margin: 15%;
}
.margin-fifteen-half-all {
  margin: 15.5%;
}
.margin-sixteen-all {
  margin: 16%;
}
.margin-sixteen-half-all {
  margin: 16.5%;
}
.margin-seventeen-all {
  margin: 17%;
}
.margin-seventeen-half-all {
  margin: 17.5%;
}
.margin-eighteen-all {
  margin: 18%;
}
.margin-eighteen-half-all {
  margin: 18.5%;
}
.margin-nineteen-all {
  margin: 19%;
}
.margin-nineteen-half-all {
  margin: 19.5%;
}
.margin-twenty-all {
  margin: 20%;
}
.margin-twenty-half-all {
  margin: 20.5%;
}
.margin-twenty-one-all {
  margin: 21%;
}
.margin-twenty-one-half-all {
  margin: 21.5%;
}
.margin-twenty-two-all {
  margin: 22%;
}
.margin-twenty-two-half-all {
  margin: 22.5%;
}
.margin-twenty-three-all {
  margin: 23%;
}
.margin-twenty-three-half-all {
  margin: 23.5%;
}
.margin-twenty-four-all {
  margin: 24%;
}
.margin-twenty-four-half-all {
  margin: 24.5%;
}
.margin-twenty-five-all {
  margin: 25%;
}
.margin-5px-all {
  margin: 5px;
}
.margin-10px-all {
  margin: 10px;
}
.margin-15px-all {
  margin: 15px;
}
.margin-20px-all {
  margin: 20px;
}
.margin-25px-all {
  margin: 25px;
}
.margin-30px-all {
  margin: 30px;
}
.margin-35px-all {
  margin: 35px;
}
.margin-40px-all {
  margin: 40px;
}
.margin-45px-all {
  margin: 45px;
}
.margin-50px-all {
  margin: 50px;
}
.margin-55px-all {
  margin: 55px;
}
.margin-60px-all {
  margin: 60px;
}
.margin-65px-all {
  margin: 65px;
}
.margin-70px-all {
  margin: 70px;
}
.margin-75px-all {
  margin: 75px;
}
.margin-80px-all {
  margin: 80px;
}
.margin-85px-all {
  margin: 85px;
}
.margin-90px-all {
  margin: 90px;
}
.margin-95px-all {
  margin: 95px;
}
.margin-100px-all {
  margin: 100px;
}

.no-margin {
  margin: 0 !important;
}
.no-margin-lr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-margin-tb {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-margin-left {
  margin-left: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}
.margin-lr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.margin-auto {
  margin: 0 auto !important;
}

/* margin top */
.margin-one-top {
  margin-top: 1%;
}
.margin-one-half-top {
  margin-top: 1.5%;
}
.margin-two-top {
  margin-top: 2%;
}
.margin-two-half-top {
  margin-top: 2.5%;
}
.margin-three-top {
  margin-top: 0%;
}
.margin-three-half-top {
  margin-top: 3.5%;
}
.margin-four-top {
  margin-top: 4%;
}
.margin-four-half-top {
  margin-top: 4.5%;
}
.margin-five-top {
  margin-top: 5%;
}
.margin-five-half-top {
  margin-top: 5.5%;
}
.margin-six-top {
  margin-top: 6%;
}
.margin-six-half-top {
  margin-top: 6.5%;
}
.margin-seven-top {
  margin-top: 7%;
}
.margin-seven-half-top {
  margin-top: 7.5%;
}
.margin-eight-top {
  margin-top: 8%;
}
.margin-eight-half-top {
  margin-top: 8.5%;
}
.margin-nine-top {
  margin-top: 9%;
}
.margin-nine-half-top {
  margin-top: 9.5%;
}
.margin-ten-top {
  margin-top: 10%;
}
.margin-ten-half-top {
  margin-top: 10.5%;
}
.margin-eleven-top {
  margin-top: 11%;
}
.margin-eleven-half-top {
  margin-top: 11.5%;
}
.margin-twelve-top {
  margin-top: 12%;
}
.margin-twelve-half-top {
  margin-top: 12.5%;
}
.margin-thirteen-top {
  margin-top: 13%;
}
.margin-thirteen-half-top {
  margin-top: 13.5%;
}
.margin-fourteen-top {
  margin-top: 14%;
}
.margin-fourteen-half-top {
  margin-top: 14.5%;
}
.margin-fifteen-top {
  margin-top: 15%;
}
.margin-fifteen-half-top {
  margin-top: 15.5%;
}
.margin-sixteen-top {
  margin-top: 16%;
}
.margin-sixteen-half-top {
  margin-top: 16.5%;
}
.margin-seventeen-top {
  margin-top: 17%;
}
.margin-seventeen-half-top {
  margin-top: 17.5%;
}
.margin-eighteen-top {
  margin-top: 18%;
}
.margin-eighteen-half-top {
  margin-top: 18.5%;
}
.margin-nineteen-top {
  margin-top: 19%;
}
.margin-nineteen-half-top {
  margin-top: 19.5%;
}
.margin-twenty-top {
  margin-top: 20%;
}
.margin-twenty-half-top {
  margin-top: 20.5%;
}
.margin-twenty-one-top {
  margin-top: 21%;
}
.margin-twenty-one-half-top {
  margin-top: 21.5%;
}
.margin-twenty-two-top {
  margin-top: 22%;
}
.margin-twenty-two-half-top {
  margin-top: 22.5%;
}
.margin-twenty-three-top {
  margin-top: 23%;
}
.margin-twenty-three-half-top {
  margin-top: 23.5%;
}
.margin-twenty-four-top {
  margin-top: 24%;
}
.margin-twenty-four-half-top {
  margin-top: 24.5%;
}
.margin-twenty-five-top {
  margin-top: 25%;
}
.margin-5px-top {
  margin-top: 5px;
}
.margin-10px-top {
  margin-top: 10px;
}
.margin-15px-top {
  margin-top: 15px;
}
.margin-20px-top {
  margin-top: 20px;
}
.margin-25px-top {
  margin-top: 25px;
}
.margin-30px-top {
  margin-top: 30px;
}
.margin-35px-top {
  margin-top: 35px;
}
.margin-40px-top {
  margin-top: 40px;
}
.margin-45px-top {
  margin-top: 45px;
}
.margin-50px-top {
  margin-top: 50px;
}
.margin-55px-top {
  margin-top: 55px;
}
.margin-60px-top {
  margin-top: 60px;
}
.margin-65px-top {
  margin-top: 65px;
}
.margin-70px-top {
  margin-top: 70px;
}
.margin-75px-top {
  margin-top: 75px;
}
.margin-80px-top {
  margin-top: 80px;
}
.margin-90px-top {
  margin-top: 90px;
}
.margin-100px-top {
  margin-top: 100px;
}

/* margin bottom */
.margin-one-bottom {
  margin-bottom: 1%;
}
.margin-one-half-bottom {
  margin-bottom: 1.5%;
}
.margin-two-bottom {
  margin-bottom: 2%;
}
.margin-two-half-bottom {
  margin-bottom: 2.5%;
}
.margin-three-bottom {
  margin-bottom: 3%;
}
.margin-three-half-bottom {
  margin-bottom: 3.5%;
}
.margin-four-bottom {
  margin-bottom: 4%;
}
.margin-four-half-bottom {
  margin-bottom: 4.5%;
}
.margin-five-bottom {
  margin-bottom: 5%;
}
.margin-five-half-bottom {
  margin-bottom: 5.5%;
}
.margin-six-bottom {
  margin-bottom: 6%;
}
.margin-six-half-bottom {
  margin-bottom: 6.5%;
}
.margin-seven-bottom {
  margin-bottom: 7%;
}
.margin-seven-half-bottom {
  margin-bottom: 7.5%;
}
.margin-eight-bottom {
  margin-bottom: 8%;
}
.margin-eight-half-bottom {
  margin-bottom: 8.5%;
}
.margin-nine-bottom {
  margin-bottom: 9%;
}
.margin-nine-half-bottom {
  margin-bottom: 9.5%;
}
.margin-ten-bottom {
  margin-bottom: 10%;
}
.margin-ten-half-bottom {
  margin-bottom: 10.5%;
}
.margin-eleven-bottom {
  margin-bottom: 11%;
}
.margin-eleven-half-bottom {
  margin-bottom: 11.5%;
}
.margin-twelve-bottom {
  margin-bottom: 12%;
}
.margin-twelve-half-bottom {
  margin-bottom: 12.5%;
}
.margin-thirteen-bottom {
  margin-bottom: 13%;
}
.margin-thirteen-half-bottom {
  margin-bottom: 13.5%;
}
.margin-fourteen-bottom {
  margin-bottom: 14%;
}
.margin-fourteen-half-bottom {
  margin-bottom: 14.5%;
}
.margin-fifteen-bottom {
  margin-bottom: 15%;
}
.margin-fifteen-half-bottom {
  margin-bottom: 15.5%;
}
.margin-sixteen-bottom {
  margin-bottom: 16%;
}
.margin-sixteen-half-bottom {
  margin-bottom: 16.5%;
}
.margin-seventeen-bottom {
  margin-bottom: 17%;
}
.margin-seventeen-half-bottom {
  margin-bottom: 17.5%;
}
.margin-eighteen-bottom {
  margin-bottom: 18%;
}
.margin-eighteen-half-bottom {
  margin-bottom: 18.5%;
}
.margin-nineteen-bottom {
  margin-bottom: 19%;
}
.margin-nineteen-half-bottom {
  margin-bottom: 19.5%;
}
.margin-twenty-bottom {
  margin-bottom: 20%;
}
.margin-twenty-half-bottom {
  margin-bottom: 20.5%;
}
.margin-twenty-one-bottom {
  margin-bottom: 21%;
}
.margin-twenty-one-half-bottom {
  margin-bottom: 21.5%;
}
.margin-twenty-two-bottom {
  margin-bottom: 22%;
}
.margin-twenty-two-half-bottom {
  margin-bottom: 22.5%;
}
.margin-twenty-three-bottom {
  margin-bottom: 23%;
}
.margin-twenty-three-half-bottom {
  margin-bottom: 23.5%;
}
.margin-twenty-four-bottom {
  margin-bottom: 24%;
}
.margin-twenty-four-half-bottom {
  margin-bottom: 24.5%;
}
.margin-twenty-five-bottom {
  margin-bottom: 25%;
}
.margin-5px-bottom {
  margin-bottom: 5px;
}
.margin-10px-bottom {
  margin-bottom: 10px;
}
.margin-15px-bottom {
  margin-bottom: 15px;
}
.margin-20px-bottom {
  margin-bottom: 20px;
}
.margin-25px-bottom {
  margin-bottom: 25px;
}
.margin-30px-bottom {
  margin-bottom: 30px;
}
.margin-35px-bottom {
  margin-bottom: 35px;
}
.margin-40px-bottom {
  margin-bottom: 40px;
}
.margin-45px-bottom {
  margin-bottom: 45px;
}
.margin-50px-bottom {
  margin-bottom: 50px;
}
.margin-55px-bottom {
  margin-bottom: 55px;
}
.margin-60px-bottom {
  margin-bottom: 60px;
}
.margin-65px-bottom {
  margin-bottom: 65px;
}
.margin-70px-bottom {
  margin-bottom: 70px;
}
.margin-75px-bottom {
  margin-bottom: 75px;
}
.margin-80px-bottom {
  margin-bottom: 80px;
}
.margin-85px-bottom {
  margin-bottom: 85px;
}
.margin-90px-bottom {
  margin-bottom: 90px;
}
.margin-95px-bottom {
  margin-bottom: 95px;
}
.margin-100px-bottom {
  margin-bottom: 100px;
}

/* margin right */
.margin-one-right {
  margin-right: 1%;
}
.margin-one-half-right {
  margin-right: 1.5%;
}
.margin-two-right {
  margin-right: 2%;
}
.margin-two-half-right {
  margin-right: 2.5%;
}
.margin-three-right {
  margin-right: 3%;
}
.margin-three-half-right {
  margin-right: 3.5%;
}
.margin-four-right {
  margin-right: 4%;
}
.margin-four-half-right {
  margin-right: 4.5%;
}
.margin-five-right {
  margin-right: 5%;
}
.margin-five-half-right {
  margin-right: 5.5%;
}
.margin-six-right {
  margin-right: 6%;
}
.margin-six-half-right {
  margin-right: 6.5%;
}
.margin-seven-right {
  margin-right: 7%;
}
.margin-seven-half-right {
  margin-right: 7.5%;
}
.margin-eight-right {
  margin-right: 8%;
}
.margin-eight-half-right {
  margin-right: 8.5%;
}
.margin-nine-right {
  margin-right: 9%;
}
.margin-nine-half-right {
  margin-right: 9.5%;
}
.margin-ten-right {
  margin-right: 10%;
}
.margin-ten-half-right {
  margin-right: 10.5%;
}
.margin-eleven-right {
  margin-right: 11%;
}
.margin-eleven-half-right {
  margin-right: 11.5%;
}
.margin-twelve-right {
  margin-right: 12%;
}
.margin-twelve-half-right {
  margin-right: 12.5%;
}
.margin-thirteen-right {
  margin-right: 13%;
}
.margin-thirteen-half-right {
  margin-right: 13.5%;
}
.margin-fourteen-right {
  margin-right: 14%;
}
.margin-fourteen-half-right {
  margin-right: 14.5%;
}
.margin-fifteen-right {
  margin-right: 15%;
}
.margin-fifteen-half-right {
  margin-right: 15.5%;
}
.margin-sixteen-right {
  margin-right: 16%;
}
.margin-sixteen-half-right {
  margin-right: 16.5%;
}
.margin-seventeen-right {
  margin-right: 17%;
}
.margin-seventeen-half-right {
  margin-right: 17.5%;
}
.margin-eighteen-right {
  margin-right: 18%;
}
.margin-eighteen-half-right {
  margin-right: 18.5%;
}
.margin-nineteen-right {
  margin-right: 19%;
}
.margin-nineteen-half-right {
  margin-right: 19.5%;
}
.margin-twenty-right {
  margin-right: 20%;
}
.margin-twenty-half-right {
  margin-right: 20.5%;
}
.margin-twenty-right {
  margin-right: 21%;
}
.margin-twenty-one-half-right {
  margin-right: 21.5%;
}
.margin-twenty-two-right {
  margin-right: 22%;
}
.margin-twenty-two-half-right {
  margin-right: 22.5%;
}
.margin-twenty-three-right {
  margin-right: 23%;
}
.margin-twenty-three-half-right {
  margin-right: 23.5%;
}
.margin-twenty-four-right {
  margin-right: 24%;
}
.margin-twenty-four-half-right {
  margin-right: 24.5%;
}
.margin-twenty-five-right {
  margin-right: 25%;
}
.margin-5px-right {
  margin-right: 5px;
}
.margin-10px-right {
  margin-right: 10px;
}
.margin-15px-right {
  margin-right: 15px;
}
.margin-20px-right {
  margin-right: 20px;
}
.margin-25px-right {
  margin-right: 25px;
}
.margin-30px-right {
  margin-right: 30px;
}
.margin-35px-right {
  margin-right: 35px;
}
.margin-40px-right {
  margin-right: 40px;
}
.margin-45px-right {
  margin-right: 45px;
}
.margin-50px-right {
  margin-right: 50px;
}
.margin-55px-right {
  margin-right: 55px;
}
.margin-60px-right {
  margin-right: 60px;
}
.margin-65px-right {
  margin-right: 65px;
}
.margin-70px-right {
  margin-right: 70px;
}
.margin-75px-right {
  margin-right: 75px;
}
.margin-80px-right {
  margin-right: 80px;
}
.margin-85px-right {
  margin-right: 85px;
}
.margin-90px-right {
  margin-right: 90px;
}
.margin-95px-right {
  margin-right: 95px;
}
.margin-100px-right {
  margin-right: 100px;
}

/* margin left */
.margin-one-left {
  margin-left: 1%;
}
.margin-one-half-left {
  margin-left: 1.5%;
}
.margin-two-left {
  margin-left: 2%;
}
.margin-two-half-left {
  margin-left: 2.5%;
}
.margin-three-left {
  margin-left: 3%;
}
.margin-three-half-left {
  margin-left: 3.5%;
}
.margin-four-left {
  margin-left: 4%;
}
.margin-four-half-left {
  margin-left: 4.5%;
}
.margin-five-left {
  margin-left: 5%;
}
.margin-five-half-left {
  margin-left: 5.5%;
}
.margin-six-left {
  margin-left: 6%;
}
.margin-six-half-left {
  margin-left: 6.5%;
}
.margin-seven-left {
  margin-left: 7%;
}
.margin-seven-half-left {
  margin-left: 7.5%;
}
.margin-eight-left {
  margin-left: 8%;
}
.margin-eight-half-left {
  margin-left: 8.5%;
}
.margin-nine-left {
  margin-left: 9%;
}
.margin-nine-half-left {
  margin-left: 9.5%;
}
.margin-ten-left {
  margin-left: 10%;
}
.margin-ten-half-left {
  margin-left: 10.5%;
}
.margin-eleven-left {
  margin-left: 11%;
}
.margin-eleven-half-left {
  margin-left: 11.5%;
}
.margin-twelve-left {
  margin-left: 12%;
}
.margin-twelve-half-left {
  margin-left: 12.5%;
}
.margin-thirteen-left {
  margin-left: 13%;
}
.margin-thirteen-half-left {
  margin-left: 13.5%;
}
.margin-fourteen-left {
  margin-left: 14%;
}
.margin-fourteen-half-left {
  margin-left: 14.5%;
}
.margin-fifteen-left {
  margin-left: 15%;
}
.margin-fifteen-half-left {
  margin-left: 15.5%;
}
.margin-sixteen-left {
  margin-left: 16%;
}
.margin-sixteen-half-left {
  margin-left: 16.5%;
}
.margin-seventeen-left {
  margin-left: 17%;
}
.margin-seventeen-half-left {
  margin-left: 17.5%;
}
.margin-eighteen-left {
  margin-left: 18%;
}
.margin-eighteen-half-left {
  margin-left: 18.5%;
}
.margin-nineteen-left {
  margin-left: 19%;
}
.margin-nineteen-half-left {
  margin-left: 19.5%;
}
.margin-twenty-left {
  margin-left: 20%;
}
.margin-twenty-half-left {
  margin-left: 20.5%;
}
.margin-twenty-one-left {
  margin-left: 21%;
}
.margin-twenty-one-half-left {
  margin-left: 21.5%;
}
.margin-twenty-two-left {
  margin-left: 22%;
}
.margin-twenty-two-half-left {
  margin-left: 22.5%;
}
.margin-twenty-three-left {
  margin-left: 23%;
}
.margin-twenty-three-half-left {
  margin-left: 23.5%;
}
.margin-twenty-four-left {
  margin-left: 24%;
}
.margin-twenty-four-half-left {
  margin-left: 24.5%;
}
.margin-twenty-five-left {
  margin-left: 25%;
}
.margin-5px-left {
  margin-left: 5px;
}
.margin-10px-left {
  margin-left: 10px;
}
.margin-15px-left {
  margin-left: 15px;
}
.margin-20px-left {
  margin-left: 20px;
}
.margin-25px-left {
  margin-left: 25px;
}
.margin-30px-left {
  margin-left: 30px;
}
.margin-35px-left {
  margin-left: 35px;
}
.margin-40px-left {
  margin-left: 40px;
}
.margin-45px-left {
  margin-left: 45px;
}
.margin-50px-left {
  margin-left: 50px;
}
.margin-55px-left {
  margin-left: 55px;
}
.margin-60px-left {
  margin-left: 60px;
}
.margin-65px-left {
  margin-left: 65px;
}
.margin-70px-left {
  margin-left: 70px;
}
.margin-75px-left {
  margin-left: 75px;
}
.margin-80px-left {
  margin-left: 80px;
}
.margin-85px-left {
  margin-left: 85px;
}
.margin-90px-left {
  margin-left: 90px;
}
.margin-95px-left {
  margin-left: 95px;
}
.margin-100px-left {
  margin-left: 100px;
}

/* margin left and right */
.margin-one-lr {
  margin-left: 1%;
  margin-right: 1%;
}
.margin-one-half-lr {
  margin-left: 1.5%;
  margin-right: 1.5%;
}
.margin-two-lr {
  margin-left: 2%;
  margin-right: 2%;
}
.margin-two-half-lr {
  margin-left: 2.5%;
  margin-right: 2.5%;
}
.margin-three-lr {
  margin-left: 3%;
  margin-right: 3%;
}
.margin-three-half-lr {
  margin-left: 3.5%;
  margin-right: 3.5%;
}
.margin-four-lr {
  margin-left: 4%;
  margin-right: 4%;
}
.margin-four-half-lr {
  margin-left: 4.5%;
  margin-right: 4.5%;
}
.margin-five-lr {
  margin-left: 5%;
  margin-right: 5%;
}
.margin-five-half-lr {
  margin-left: 5.5%;
  margin-right: 5.5%;
}
.margin-six-lr {
  margin-left: 6%;
  margin-right: 6%;
}
.margin-six-half-lr {
  margin-left: 6.5%;
  margin-right: 6.5%;
}
.margin-seven-lr {
  margin-left: 7%;
  margin-right: 7%;
}
.margin-seven-half-lr {
  margin-left: 7.5%;
  margin-right: 7.5%;
}
.margin-eight-lr {
  margin-left: 8%;
  margin-right: 8%;
}
.margin-eight-half-lr {
  margin-left: 8.5%;
  margin-right: 8.5%;
}
.margin-nine-lr {
  margin-left: 9%;
  margin-right: 9%;
}
.margin-nine-half-lr {
  margin-left: 9.5%;
  margin-right: 9.5%;
}
.margin-ten-lr {
  margin-left: 10%;
  margin-right: 10%;
}
.margin-ten-half-lr {
  margin-left: 10.5%;
  margin-right: 10.5%;
}
.margin-eleven-lr {
  margin-left: 11%;
  margin-right: 11%;
}
.margin-eleven-half-lr {
  margin-left: 11.5%;
  margin-right: 11.5%;
}
.margin-twelve-lr {
  margin-left: 12%;
  margin-right: 12%;
}
.margin-twelve-half-lr {
  margin-left: 12.5%;
  margin-right: 12.5%;
}
.margin-thirteen-lr {
  margin-left: 13%;
  margin-right: 13%;
}
.margin-thirteen-half-lr {
  margin-left: 13.5%;
  margin-right: 13.5%;
}
.margin-fourteen-lr {
  margin-left: 14%;
  margin-right: 14%;
}
.margin-fourteen-half-lr {
  margin-left: 14.5%;
  margin-right: 14.5%;
}
.margin-fifteen-lr {
  margin-left: 15%;
  margin-right: 15%;
}
.margin-fifteen-half-lr {
  margin-left: 15.5%;
  margin-right: 15.5%;
}
.margin-sixteen-lr {
  margin-left: 16%;
  margin-right: 16%;
}
.margin-sixteen-half-lr {
  margin-left: 16.5%;
  margin-right: 16.5%;
}
.margin-seventeen-lr {
  margin-left: 17%;
  margin-right: 17%;
}
.margin-seventeen-half-lr {
  margin-left: 17.5%;
  margin-right: 17.5%;
}
.margin-eighteen-lr {
  margin-left: 18%;
  margin-right: 18%;
}
.margin-eighteen-half-lr {
  margin-left: 18.5%;
  margin-right: 18.5%;
}
.margin-nineteen-lr {
  margin-left: 19%;
  margin-right: 19%;
}
.margin-nineteen-half-lr {
  margin-left: 19.5%;
  margin-right: 19.5%;
}
.margin-twenty-lr {
  margin-left: 20%;
  margin-right: 20%;
}
.margin-twenty-half-lr {
  margin-left: 20.5%;
  margin-right: 20.5%;
}
.margin-twenty-one-lr {
  margin-left: 21%;
  margin-right: 21%;
}
.margin-twenty-one-half-lr {
  margin-left: 21.5%;
  margin-right: 21.5%;
}
.margin-twenty-two-lr {
  margin-left: 22%;
  margin-right: 22%;
}
.margin-twenty-two-half-lr {
  margin-left: 22.5%;
  margin-right: 22.5%;
}
.margin-twenty-three-lr {
  margin-left: 23%;
  margin-right: 23%;
}
.margin-twenty-three-half-lr {
  margin-left: 23.5%;
  margin-right: 23.5%;
}
.margin-twenty-four-lr {
  margin-left: 24%;
  margin-right: 24%;
}
.margin-twenty-four-half-lr {
  margin-left: 24.5%;
  margin-right: 24.5%;
}
.margin-twenty-five-lr {
  margin-left: 25%;
  margin-right: 25%;
}
.margin-5px-lr {
  margin-left: 5px;
  margin-right: 5px;
}
.margin-10px-lr {
  margin-left: 10px;
  margin-right: 10px;
}
.margin-15px-lr {
  margin-left: 15px;
  margin-right: 15px;
}
.margin-20px-lr {
  margin-left: 20px;
  margin-right: 20px;
}
.margin-25px-lr {
  margin-left: 25px;
  margin-right: 25px;
}
.margin-30px-lr {
  margin-left: 30px;
  margin-right: 30px;
}
.margin-35px-lr {
  margin-left: 35px;
  margin-right: 35px;
}
.margin-40px-lr {
  margin-left: 40px;
  margin-right: 40px;
}
.margin-45px-lr {
  margin-left: 45px;
  margin-right: 45px;
}
.margin-50px-lr {
  margin-left: 50px;
  margin-right: 50px;
}
.margin-55px-lr {
  margin-left: 55px;
  margin-right: 55px;
}
.margin-60px-lr {
  margin-left: 60px;
  margin-right: 60px;
}
.margin-65px-lr {
  margin-left: 65px;
  margin-right: 65px;
}
.margin-70px-lr {
  margin-left: 70px;
  margin-right: 70px;
}
.margin-75px-lr {
  margin-left: 75px;
  margin-right: 75px;
}
.margin-80px-lr {
  margin-left: 80px;
  margin-right: 80px;
}
.margin-85px-lr {
  margin-left: 85px;
  margin-right: 85px;
}
.margin-90px-lr {
  margin-left: 90px;
  margin-right: 90px;
}
.margin-95px-lr {
  margin-left: 95px;
  margin-right: 95px;
}
.margin-100px-lr {
  margin-left: 100px;
  margin-right: 100px;
}

/* margin top and bottom */
.margin-one-tb {
  margin-top: 1%;
  margin-bottom: 1%;
}
.margin-one-half-tb {
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}
.margin-two-tb {
  margin-top: 2%;
  margin-bottom: 2%;
}
.margin-two-half-tb {
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}
.margin-three-tb {
  margin-top: 3%;
  margin-bottom: 3%;
}
.margin-three-half-tb {
  margin-top: 3.5%;
  margin-bottom: 3.5%;
}
.margin-four-tb {
  margin-top: 4%;
  margin-bottom: 4%;
}
.margin-four-half-tb {
  margin-top: 4.5%;
  margin-bottom: 4.5%;
}
.margin-five-tb {
  margin-top: 5%;
  margin-bottom: 5%;
}
.margin-five-half-tb {
  margin-top: 5.5%;
  margin-bottom: 5.5%;
}
.margin-six-tb {
  margin-top: 6%;
  margin-bottom: 6%;
}
.margin-six-half-tb {
  margin-top: 6.5%;
  margin-bottom: 6.5%;
}
.margin-seven-tb {
  margin-top: 7%;
  margin-bottom: 7%;
}
.margin-seven-half-tb {
  margin-top: 7.5%;
  margin-bottom: 7.5%;
}
.margin-eight-tb {
  margin-top: 8%;
  margin-bottom: 8%;
}
.margin-eight-half-tb {
  margin-top: 8.5%;
  margin-bottom: 8.5%;
}
.margin-nine-tb {
  margin-top: 9%;
  margin-bottom: 9%;
}
.margin-nine-half-tb {
  margin-top: 9.5%;
  margin-bottom: 9.5%;
}
.margin-ten-tb {
  margin-top: 10%;
  margin-bottom: 10%;
}
.margin-ten-half-tb {
  margin-top: 10.5%;
  margin-bottom: 10.5%;
}
.margin-eleven-tb {
  margin-top: 11%;
  margin-bottom: 11%;
}
.margin-eleven-half-tb {
  margin-top: 11.5%;
  margin-bottom: 11.5%;
}
.margin-twelve-tb {
  margin-top: 12%;
  margin-bottom: 12%;
}
.margin-twelve-half-tb {
  margin-top: 12.5%;
  margin-bottom: 12.5%;
}
.margin-thirteen-tb {
  margin-top: 13%;
  margin-bottom: 13%;
}
.margin-thirteen-half-tb {
  margin-top: 13.5%;
  margin-bottom: 13.5%;
}
.margin-fourteen-tb {
  margin-top: 14%;
  margin-bottom: 14%;
}
.margin-fourteen-half-tb {
  margin-top: 14.5%;
  margin-bottom: 14.5%;
}
.margin-fifteen-tb {
  margin-top: 15%;
  margin-bottom: 15%;
}
.margin-fifteen-half-tb {
  margin-top: 15.5%;
  margin-bottom: 15.5%;
}
.margin-sixteen-tb {
  margin-top: 16%;
  margin-bottom: 16%;
}
.margin-sixteen-half-tb {
  margin-top: 16.5%;
  margin-bottom: 16.5%;
}
.margin-seventeen-tb {
  margin-top: 17%;
  margin-bottom: 17%;
}
.margin-seventeen-half-tb {
  margin-top: 17.5%;
  margin-bottom: 17.5%;
}
.margin-eighteen-tb {
  margin-top: 18%;
  margin-bottom: 18%;
}
.margin-eighteen-half-tb {
  margin-top: 18.5%;
  margin-bottom: 18.5%;
}
.margin-nineteen-tb {
  margin-top: 19%;
  margin-bottom: 19%;
}
.margin-nineteen-half-tb {
  margin-top: 19.5%;
  margin-bottom: 19.5%;
}
.margin-twenty-tb {
  margin-top: 20%;
  margin-bottom: 20%;
}
.margin-twenty-half-tb {
  margin-top: 20.5%;
  margin-bottom: 20.5%;
}
.margin-twenty-one-tb {
  margin-top: 21%;
  margin-bottom: 21%;
}
.margin-twenty-one-half-tb {
  margin-top: 21.5%;
  margin-bottom: 21.5%;
}
.margin-twenty-two-tb {
  margin-top: 22%;
  margin-bottom: 22%;
}
.margin-twenty-two-half-tb {
  margin-top: 22.5%;
  margin-bottom: 22.5%;
}
.margin-twenty-three-tb {
  margin-top: 23%;
  margin-bottom: 23%;
}
.margin-twenty-three-half-tb {
  margin-top: 23.5%;
  margin-bottom: 23.5%;
}
.margin-twenty-four-tb {
  margin-top: 24%;
  margin-bottom: 24%;
}
.margin-twenty-four-half-tb {
  margin-top: 24.5%;
  margin-bottom: 24.5%;
}
.margin-twenty-five-tb {
  margin-top: 25%;
  margin-bottom: 25%;
}
.margin-5px-tb {
  margin-top: 5px;
  margin-bottom: 5px;
}
.margin-10px-tb {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-15px-tb {
  margin-top: 15px;
  margin-bottom: 15px;
}
.margin-20px-tb {
  margin-top: 20px;
  margin-bottom: 20px;
}
.margin-25px-tb {
  margin-top: 25px;
  margin-bottom: 25px;
}
.margin-30px-tb {
  margin-top: 30px;
  margin-bottom: 30px;
}
.margin-35px-tb {
  margin-top: 35px;
  margin-bottom: 35px;
}
.margin-40px-tb {
  margin-top: 40px;
  margin-bottom: 40px;
}
.margin-45px-tb {
  margin-top: 45px;
  margin-bottom: 45px;
}
.margin-50px-tb {
  margin-top: 50px;
  margin-bottom: 50px;
}
.margin-55px-tb {
  margin-top: 55px;
  margin-bottom: 55px;
}
.margin-60px-tb {
  margin-top: 60px;
  margin-bottom: 60px;
}
.margin-65px-tb {
  margin-top: 65px;
  margin-bottom: 65px;
}
.margin-70px-tb {
  margin-top: 70px;
  margin-bottom: 70px;
}
.margin-75px-tb {
  margin-top: 75px;
  margin-bottom: 75px;
}
.margin-80px-tb {
  margin-top: 80px;
  margin-bottom: 80px;
}
.margin-85px-tb {
  margin-top: 85px;
  margin-bottom: 85px;
}
.margin-90px-tb {
  margin-top: 90px;
  margin-bottom: 90px;
}
.margin-95px-tb {
  margin-top: 95px;
  margin-bottom: 95px;
}
.margin-100px-tb {
  margin-top: 100px;
  margin-bottom: 100px;
}

/*===============================
    Padding
=================================*/

.no-padding {
  padding: 0 !important;
}
.no-padding-lr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-padding-tb {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.padding-one-all {
  padding: 1%;
}
.padding-one-half-all {
  padding: 1.5%;
}
.padding-two-all {
  padding: 2%;
}
.padding-two-half-all {
  padding: 2.5%;
}
.padding-three-all {
  padding: 3%;
}
.padding-three-half-all {
  padding: 3.5%;
}
.padding-four-all {
  padding: 4%;
}
.padding-four-half-all {
  padding: 4.5%;
}
.padding-five-all {
  padding: 5%;
}
.padding-five-half-all {
  padding: 5.5%;
}
.padding-six-all {
  padding: 6%;
}
.padding-six-half-all {
  padding: 6.5%;
}
.padding-seven-all {
  padding: 7%;
}
.padding-seven-half-all {
  padding: 7.5%;
}
.padding-eight-all {
  padding: 8%;
}
.padding-eight-half-all {
  padding: 8.5%;
}
.padding-nine-all {
  padding: 9%;
}
.padding-nine-half-all {
  padding: 9.5%;
}
.padding-ten-all {
  padding: 10%;
}
.padding-ten-half-all {
  padding: 10.5%;
}
.padding-eleven-all {
  padding: 11%;
}
.padding-eleven-half-all {
  padding: 11.5%;
}
.padding-twelve-all {
  padding: 12%;
}
.padding-twelve-half-all {
  padding: 12.5%;
}
.padding-thirteen-all {
  padding: 13%;
}
.padding-thirteen-half-all {
  padding: 13.5%;
}
.padding-fourteen-all {
  padding: 14%;
}
.padding-fourteen-half-all {
  padding: 14.5%;
}
.padding-fifteen-all {
  padding: 15%;
}
.padding-fifteen-half-all {
  padding: 15.5%;
}
.padding-sixteen-all {
  padding: 16%;
}
.padding-sixteen-half-all {
  padding: 16.5%;
}
.padding-seventeen-all {
  padding: 17%;
}
.padding-seventeen-half-all {
  padding: 17.5%;
}
.padding-eighteen-all {
  padding: 18%;
}
.padding-eighteen-half-all {
  padding: 18.5%;
}
.padding-nineteen-all {
  padding: 19%;
}
.padding-nineteen-half-all {
  padding: 19.5%;
}
.padding-twenty-all {
  padding: 20%;
}
.padding-twenty-half-all {
  padding: 20.5%;
}
.padding-twenty-one-all {
  padding: 21%;
}
.padding-twenty-one-half-all {
  padding: 21.5%;
}
.padding-twenty-two-all {
  padding: 22%;
}
.padding-twenty-two-half-all {
  padding: 22.5%;
}
.padding-twenty-three-all {
  padding: 23%;
}
.padding-twenty-three-half-all {
  padding: 23.5%;
}
.padding-twenty-four-all {
  padding: 24%;
}
.padding-twenty-four-half-all {
  padding: 24.5%;
}
.padding-twenty-five-all {
  padding: 25%;
}
.padding-twenty-five-half-all {
  padding: 25.5%;
}
.padding-5px-all {
  padding: 5px;
}
.padding-10px-all {
  padding: 10px;
}
.padding-15px-all {
  padding: 15px;
}
.padding-20px-all {
  padding: 20px;
}
.padding-25px-all {
  padding: 25px;
}
.padding-30px-all {
  padding: 30px;
}
.padding-35px-all {
  padding: 35px;
}
.padding-40px-all {
  padding: 40px;
}
.padding-45px-all {
  padding: 45px;
}
.padding-50px-all {
  padding: 50px;
}
.padding-55px-all {
  padding: 55px;
}
.padding-60px-all {
  padding: 60px;
}
.padding-65px-all {
  padding: 65px;
}
.padding-70px-all {
  padding: 70px;
}
.padding-75px-all {
  padding: 75px;
}
.padding-80px-all {
  padding: 80px;
}
.padding-85px-all {
  padding: 85px;
}
.padding-90px-all {
  padding: 90px;
}
.padding-95px-all {
  padding: 95px;
}
.padding-100px-all {
  padding: 100px;
}

/* padding top */
.padding-one-top {
  padding-top: 1%;
}
.padding-one-half-top {
  padding-top: 1.5%;
}
.padding-two-top {
  padding-top: 2%;
}
.padding-two-half-top {
  padding-top: 2.5%;
}
.padding-three-top {
  padding-top: 3%;
}
.padding-three-half-top {
  padding-top: 3.5%;
}
.padding-four-top {
  padding-top: 4%;
}
.padding-four-half-top {
  padding-top: 4.5%;
}
.padding-five-top {
  padding-top: 5%;
}
.padding-five-half-top {
  padding-top: 5.5%;
}
.padding-six-top {
  padding-top: 6%;
}
.padding-six-half-top {
  padding-top: 6.5%;
}
.padding-seven-top {
  padding-top: 7%;
}
.padding-seven-half-top {
  padding-top: 7.5%;
}
.padding-eight-top {
  padding-top: 8%;
}
.padding-eight-half-top {
  padding-top: 8.5%;
}
.padding-nine-top {
  padding-top: 9%;
}
.padding-nine-half-top {
  padding-top: 9.5%;
}
.padding-ten-top {
  padding-top: 10%;
}
.padding-ten-half-top {
  padding-top: 10.5%;
}
.padding-eleven-top {
  padding-top: 11%;
}
.padding-eleven-half-top {
  padding-top: 11.5%;
}
.padding-twelve-top {
  padding-top: 12%;
}
.padding-twelve-half-top {
  padding-top: 12.5%;
}
.padding-thirteen-top {
  padding-top: 13%;
}
.padding-thirteen-half-top {
  padding-top: 13.5%;
}
.padding-fourteen-top {
  padding-top: 14%;
}
.padding-fourteen-half-top {
  padding-top: 14.5%;
}
.padding-fifteen-top {
  padding-top: 15%;
}
.padding-fifteen-half-top {
  padding-top: 15.5%;
}
.padding-sixteen-top {
  padding-top: 16%;
}
.padding-sixteen-half-top {
  padding-top: 16.5%;
}
.padding-seventeen-top {
  padding-top: 17%;
}
.padding-seventeen-half-top {
  padding-top: 17.5%;
}
.padding-eighteen-top {
  padding-top: 18%;
}
.padding-eighteen-half-top {
  padding-top: 18.5%;
}
.padding-nineteen-top {
  padding-top: 19%;
}
.padding-nineteen-half-top {
  padding-top: 19.5%;
}
.padding-twenty-top {
  padding-top: 20%;
}
.padding-twenty-half-top {
  padding-top: 20.5%;
}
.padding-twenty-one-top {
  padding-top: 21%;
}
.padding-twenty-one-half-top {
  padding-top: 21.5%;
}
.padding-twenty-two-top {
  padding-top: 22%;
}
.padding-twenty-two-half-top {
  padding-top: 22.5%;
}
.padding-twenty-two-top {
  padding-top: 22%;
}
.padding-twenty-two-half-top {
  padding-top: 22.5%;
}
.padding-twenty-three-top {
  padding-top: 23%;
}
.padding-twenty-three-half-top {
  padding-top: 23.5%;
}
.padding-twenty-four-top {
  padding-top: 24%;
}
.padding-twenty-four-half-top {
  padding-top: 24.5%;
}
.padding-twenty-five-top {
  padding-top: 25%;
}
.padding-5px-top {
  padding-top: 5px;
}
.padding-10px-top {
  padding-top: 10px;
}
.padding-15px-top {
  padding-top: 15px;
}
.padding-20px-top {
  padding-top: 20px;
}
.padding-25px-top {
  padding-top: 25px;
}
.padding-30px-top {
  padding-top: 30px;
}
.padding-35px-top {
  padding-top: 35px;
}
.padding-40px-top {
  padding-top: 40px;
}
.padding-45px-top {
  padding-top: 45px;
}
.padding-50px-top {
  padding-top: 50px;
}
.padding-55px-top {
  padding-top: 55px;
}
.padding-60px-top {
  padding-top: 60px;
}
.padding-65px-top {
  padding-top: 65px;
}
.padding-70px-top {
  padding-top: 70px;
}
.padding-75px-top {
  padding-top: 75px;
}
.padding-80px-top {
  padding-top: 80px;
}
.padding-85px-top {
  padding-top: 85px;
}
.padding-90px-top {
  padding-top: 90px;
}
.padding-95px-top {
  padding-top: 95px;
}
.padding-100px-top {
  padding-top: 100px;
}

/* padding bottom */
.padding-one-bottom {
  padding-bottom: 1%;
}
.padding-one-half-bottom {
  padding-bottom: 1.5%;
}
.padding-two-bottom {
  padding-bottom: 2%;
}
.padding-two-half-bottom {
  padding-bottom: 2.5%;
}
.padding-three-bottom {
  padding-bottom: 3%;
}
.padding-three-half-bottom {
  padding-bottom: 3.5%;
}
.padding-four-bottom {
  padding-bottom: 4%;
}
.padding-four-half-bottom {
  padding-bottom: 4.5%;
}
.padding-five-bottom {
  padding-bottom: 5%;
}
.padding-five-half-bottom {
  padding-bottom: 5.5%;
}
.padding-six-bottom {
  padding-bottom: 6%;
}
.padding-six-half-bottom {
  padding-bottom: 6.5%;
}
.padding-seven-bottom {
  padding-bottom: 7%;
}
.padding-seven-half-bottom {
  padding-bottom: 7.5%;
}
.padding-eight-bottom {
  padding-bottom: 8%;
}
.padding-eight-half-bottom {
  padding-bottom: 8.5%;
}
.padding-nine-bottom {
  padding-bottom: 9%;
}
.padding-nine-half-bottom {
  padding-bottom: 9.5%;
}
.padding-ten-bottom {
  padding-bottom: 10%;
}
.padding-ten-half-bottom {
  padding-bottom: 10.5%;
}
.padding-eleven-bottom {
  padding-bottom: 11%;
}
.padding-eleven-half-bottom {
  padding-bottom: 11.5%;
}
.padding-twelve-bottom {
  padding-bottom: 12%;
}
.padding-twelve-half-bottom {
  padding-bottom: 12.5%;
}
.padding-thirteen-bottom {
  padding-bottom: 13%;
}
.padding-thirteen-half-bottom {
  padding-bottom: 13.5%;
}
.padding-fourteen-bottom {
  padding-bottom: 14%;
}
.padding-fourteen-half-bottom {
  padding-bottom: 14.5%;
}
.padding-fifteen-bottom {
  padding-bottom: 15%;
}
.padding-fifteen-half-bottom {
  padding-bottom: 15.5%;
}
.padding-sixteen-bottom {
  padding-bottom: 16%;
}
.padding-sixteen-half-bottom {
  padding-bottom: 16.5%;
}
.padding-seventeen-bottom {
  padding-bottom: 17%;
}
.padding-seventeen-half-bottom {
  padding-bottom: 17.5%;
}
.padding-eighteen-bottom {
  padding-bottom: 18%;
}
.padding-eighteen-half-bottom {
  padding-bottom: 18.5%;
}
.padding-nineteen-bottom {
  padding-bottom: 19%;
}
.padding-nineteen-half-bottom {
  padding-bottom: 19.5%;
}
.padding-twenty-bottom {
  padding-bottom: 20%;
}
.padding-twenty-half-bottom {
  padding-bottom: 20.5%;
}
.padding-twenty-one-bottom {
  padding-bottom: 21%;
}
.padding-twenty-one-half-bottom {
  padding-bottom: 21.5%;
}
.padding-twenty-two-bottom {
  padding-bottom: 22%;
}
.padding-twenty-two-half-bottom {
  padding-bottom: 22.5%;
}
.padding-twenty-three-bottom {
  padding-bottom: 23%;
}
.padding-twenty-three-half-bottom {
  padding-bottom: 23.5%;
}
.padding-twenty-four-bottom {
  padding-bottom: 24%;
}
.padding-twenty-four-half-bottom {
  padding-bottom: 24.5%;
}
.padding-twenty-five-bottom {
  padding-bottom: 25%;
}
.padding-5px-bottom {
  padding-bottom: 5px;
}
.padding-10px-bottom {
  padding-bottom: 10px;
}
.padding-15px-bottom {
  padding-bottom: 15px;
}
.padding-20px-bottom {
  padding-bottom: 20px;
}
.padding-25px-bottom {
  padding-bottom: 25px;
}
.padding-30px-bottom {
  padding-bottom: 30px;
}
.padding-35px-bottom {
  padding-bottom: 35px;
}
.padding-40px-bottom {
  padding-bottom: 40px;
}
.padding-45px-bottom {
  padding-bottom: 45px;
}
.padding-50px-bottom {
  padding-bottom: 50px;
}
.padding-55px-bottom {
  padding-bottom: 55px;
}
.padding-60px-bottom {
  padding-bottom: 60px;
}
.padding-65px-bottom {
  padding-bottom: 65px;
}
.padding-70px-bottom {
  padding-bottom: 70px;
}
.padding-75px-bottom {
  padding-bottom: 75px;
}
.padding-80px-bottom {
  padding-bottom: 80px;
}
.padding-85px-bottom {
  padding-bottom: 85px;
}
.padding-90px-bottom {
  padding-bottom: 90px;
}
.padding-95px-bottom {
  padding-bottom: 95px;
}
.padding-100px-bottom {
  padding-bottom: 100px;
}

/* padding right */
.padding-one-right {
  padding-right: 1%;
}
.padding-one-half-right {
  padding-right: 1.5%;
}
.padding-two-right {
  padding-right: 2%;
}
.padding-two-half-right {
  padding-right: 2.5%;
}
.padding-three-right {
  padding-right: 3%;
}
.padding-three-half-right {
  padding-right: 3.5%;
}
.padding-four-right {
  padding-right: 4%;
}
.padding-four-half-right {
  padding-right: 4.5%;
}
.padding-five-right {
  padding-right: 5%;
}
.padding-five-half-right {
  padding-right: 5.5%;
}
.padding-six-right {
  padding-right: 6%;
}
.padding-six-half-right {
  padding-right: 6.5%;
}
.padding-seven-right {
  padding-right: 7%;
}
.padding-seven-half-right {
  padding-right: 7.5%;
}
.padding-eight-right {
  padding-right: 8%;
}
.padding-eight-half-right {
  padding-right: 8.5%;
}
.padding-nine-right {
  padding-right: 9%;
}
.padding-nine-half-right {
  padding-right: 9.5%;
}
.padding-ten-right {
  padding-right: 10%;
}
.padding-ten-half-right {
  padding-right: 10.5%;
}
.padding-eleven-right {
  padding-right: 11%;
}
.padding-eleven-half-right {
  padding-right: 11.5%;
}
.padding-twelve-right {
  padding-right: 12%;
}
.padding-twelve-half-right {
  padding-right: 12.5%;
}
.padding-thirteen-right {
  padding-right: 13%;
}
.padding-thirteen-half-right {
  padding-right: 13.5%;
}
.padding-fourteen-right {
  padding-right: 14%;
}
.padding-fourteen-half-right {
  padding-right: 14.5%;
}
.padding-fifteen-right {
  padding-right: 15%;
}
.padding-fifteen-half-right {
  padding-right: 15.5%;
}
.padding-sixteen-right {
  padding-right: 16%;
}
.padding-sixteen-half-right {
  padding-right: 16.5%;
}
.padding-seventeen-right {
  padding-right: 17%;
}
.padding-seventeen-half-right {
  padding-right: 17.5%;
}
.padding-eighteen-right {
  padding-right: 18%;
}
.padding-eighteen-half-right {
  padding-right: 18.5%;
}
.padding-nineteen-right {
  padding-right: 19%;
}
.padding-nineteen-half-right {
  padding-right: 19.5%;
}
.padding-twenty-right {
  padding-right: 20%;
}
.padding-twenty-half-right {
  padding-right: 20.5%;
}
.padding-twenty-one-right {
  padding-right: 21%;
}
.padding-twenty-one-half-right {
  padding-right: 21.5%;
}
.padding-twenty-two-right {
  padding-right: 22%;
}
.padding-twenty-two-half-right {
  padding-right: 22.5%;
}
.padding-twenty-three-right {
  padding-right: 23%;
}
.padding-twenty-three-half-right {
  padding-right: 23.5%;
}
.padding-twenty-four-right {
  padding-right: 24%;
}
.padding-twenty-four-half-right {
  padding-right: 24.5%;
}
.padding-twenty-five-right {
  padding-right: 25%;
}
.padding-5px-right {
  padding-right: 5px;
}
.padding-10px-right {
  padding-right: 10px;
}
.padding-15px-right {
  padding-right: 15px;
}
.padding-20px-right {
  padding-right: 20px;
}
.padding-25px-right {
  padding-right: 25px;
}
.padding-30px-right {
  padding-right: 30px;
}
.padding-35px-right {
  padding-right: 35px;
}
.padding-40px-right {
  padding-right: 40px;
}
.padding-45px-right {
  padding-right: 45px;
}
.padding-50px-right {
  padding-right: 50px;
}
.padding-55px-right {
  padding-right: 55px;
}
.padding-60px-right {
  padding-right: 60px;
}
.padding-65px-right {
  padding-right: 65px;
}
.padding-70px-right {
  padding-right: 70px;
}
.padding-75px-right {
  padding-right: 75px;
}
.padding-80px-right {
  padding-right: 80px;
}
.padding-85px-right {
  padding-right: 85px;
}
.padding-90px-right {
  padding-right: 90px;
}
.padding-95px-right {
  padding-right: 95px;
}
.padding-100px-right {
  padding-right: 100px;
}

/* padding left */
.padding-one-left {
  padding-left: 1%;
}
.padding-one-half-left {
  padding-left: 1.5%;
}
.padding-two-left {
  padding-left: 2%;
}
.padding-two-half-left {
  padding-left: 2.5%;
}
.padding-three-left {
  padding-left: 3%;
}
.padding-three-half-left {
  padding-left: 3.5%;
}
.padding-four-left {
  padding-left: 4%;
}
.padding-four-half-left {
  padding-left: 4.5%;
}
.padding-five-left {
  padding-left: 5%;
}
.padding-five-half-left {
  padding-left: 5.5%;
}
.padding-six-left {
  padding-left: 6%;
}
.padding-six-half-left {
  padding-left: 6.5%;
}
.padding-seven-left {
  padding-left: 7%;
}
.padding-seven-half-left {
  padding-left: 7.5%;
}
.padding-eight-left {
  padding-left: 8%;
}
.padding-eight-half-left {
  padding-left: 8.5%;
}
.padding-nine-left {
  padding-left: 9%;
}
.padding-nine-half-left {
  padding-left: 9.5%;
}
.padding-ten-left {
  padding-left: 10%;
}
.padding-ten-half-left {
  padding-left: 10.5%;
}
.padding-eleven-left {
  padding-left: 11%;
}
.padding-eleven-half-left {
  padding-left: 11.5%;
}
.padding-twelve-left {
  padding-left: 12%;
}
.padding-twelve-half-left {
  padding-left: 12.5%;
}
.padding-thirteen-left {
  padding-left: 13%;
}
.padding-thirteen-half-left {
  padding-left: 13.5%;
}
.padding-fourteen-left {
  padding-left: 14%;
}
.padding-fourteen-half-left {
  padding-left: 14.5%;
}
.padding-fifteen-left {
  padding-left: 15%;
}
.padding-fifteen-half-left {
  padding-left: 15.5%;
}
.padding-sixteen-left {
  padding-left: 16%;
}
.padding-sixteen-half-left {
  padding-left: 16.5%;
}
.padding-seventeen-left {
  padding-left: 17%;
}
.padding-seventeen-half-left {
  padding-left: 17.5%;
}
.padding-eighteen-left {
  padding-left: 18%;
}
.padding-eighteen-half-left {
  padding-left: 18.5%;
}
.padding-nineteen-left {
  padding-left: 19%;
}
.padding-nineteen-half-left {
  padding-left: 19.5%;
}
.padding-twenty-left {
  padding-left: 20%;
}
.padding-twenty-half-left {
  padding-left: 20.5%;
}
.padding-twenty-one-left {
  padding-left: 21%;
}
.padding-twenty-one-half-left {
  padding-left: 21.5%;
}
.padding-twenty-two-left {
  padding-left: 22%;
}
.padding-twenty-two-half-left {
  padding-left: 22.5%;
}
.padding-twenty-three-left {
  padding-left: 23%;
}
.padding-twenty-three-half-left {
  padding-left: 23.5%;
}
.padding-twenty-four-left {
  padding-left: 24%;
}
.padding-twenty-four-half-left {
  padding-left: 24.5%;
}
.padding-twenty-five-left {
  padding-left: 25%;
}
.padding-5px-left {
  padding-left: 5px;
}
.padding-10px-left {
  padding-left: 10px;
}
.padding-15px-left {
  padding-left: 15px;
}
.padding-20px-left {
  padding-left: 20px;
}
.padding-25px-left {
  padding-left: 25px;
}
.padding-30px-left {
  padding-left: 30px;
}
.padding-35px-left {
  padding-left: 35px;
}
.padding-40px-left {
  padding-left: 40px;
}
.padding-45px-left {
  padding-left: 45px;
}
.padding-50px-left {
  padding-left: 50px;
}
.padding-55px-left {
  padding-left: 55px;
}
.padding-60px-left {
  padding-left: 60px;
}
.padding-65px-left {
  padding-left: 65px;
}
.padding-70px-left {
  padding-left: 70px;
}
.padding-75px-left {
  padding-left: 75px;
}
.padding-80px-left {
  padding-left: 80px;
}
.padding-85px-left {
  padding-left: 85px;
}
.padding-90px-left {
  padding-left: 90px;
}
.padding-95px-left {
  padding-left: 95px;
}
.padding-100px-left {
  padding-left: 100px;
}

/* padding top and bottom */
.padding-one-tb {
  padding-top: 1%;
  padding-bottom: 1%;
}
.padding-one-half-tb {
  padding-top: 1.5%;
  padding-bottom: 1.5%;
}
.padding-two-tb {
  padding-top: 2%;
  padding-bottom: 2%;
}
.padding-two-half-tb {
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}
.padding-three-tb {
  padding-top: 3%;
  padding-bottom: 3%;
}
.padding-three-half-tb {
  padding-top: 3.5%;
  padding-bottom: 3.5%;
}
.padding-four-tb {
  padding-top: 4%;
  padding-bottom: 4%;
}
.padding-four-half-tb {
  padding-top: 4.5%;
  padding-bottom: 4.5%;
}
.padding-five-tb {
  padding-top: 5%;
  padding-bottom: 5%;
}
.padding-five-half-tb {
  padding-top: 5.5%;
  padding-bottom: 5.5%;
}
.padding-six-tb {
  padding-top: 6%;
  padding-bottom: 6%;
}
.padding-six-half-tb {
  padding-top: 6.5%;
  padding-bottom: 6.5%;
}
.padding-seven-tb {
  padding-top: 7%;
  padding-bottom: 7%;
}
.padding-seven-half-tb {
  padding-top: 7.5%;
  padding-bottom: 7.5%;
}
.padding-eight-tb {
  padding-top: 8%;
  padding-bottom: 8%;
}
.padding-eight-half-tb {
  padding-top: 8.5%;
  padding-bottom: 8.5%;
}
.padding-nine-tb {
  padding-top: 9%;
  padding-bottom: 9%;
}
.padding-nine-half-tb {
  padding-top: 9.5%;
  padding-bottom: 9.5%;
}
.padding-ten-tb {
  padding-top: 10%;
  padding-bottom: 10%;
}
.padding-ten-half-tb {
  padding-top: 10.5%;
  padding-bottom: 10.5%;
}
.padding-eleven-tb {
  padding-top: 11%;
  padding-bottom: 11%;
}
.padding-eleven-half-tb {
  padding-top: 11.5%;
  padding-bottom: 11.5%;
}
.padding-twelve-tb {
  padding-top: 12%;
  padding-bottom: 12%;
}
.padding-twelve-half-tb {
  padding-top: 12.5%;
  padding-bottom: 12.5%;
}
.padding-thirteen-tb {
  padding-top: 13%;
  padding-bottom: 13%;
}
.padding-thirteen-half-tb {
  padding-top: 13.5%;
  padding-bottom: 13.5%;
}
.padding-fourteen-tb {
  padding-top: 14%;
  padding-bottom: 14%;
}
.padding-fourteen-half-tb {
  padding-top: 14.5%;
  padding-bottom: 14.5%;
}
.padding-fifteen-tb {
  padding-top: 15%;
  padding-bottom: 15%;
}
.padding-fifteen-half-tb {
  padding-top: 15.5%;
  padding-bottom: 15.5%;
}
.padding-sixteen-tb {
  padding-top: 16%;
  padding-bottom: 16%;
}
.padding-sixteen-half-tb {
  padding-top: 16.5%;
  padding-bottom: 16.5%;
}
.padding-seventeen-tb {
  padding-top: 17%;
  padding-bottom: 17%;
}
.padding-seventeen-half-tb {
  padding-top: 17.5%;
  padding-bottom: 17.5%;
}
.padding-eighteen-tb {
  padding-top: 18%;
  padding-bottom: 18%;
}
.padding-eighteen-half-tb {
  padding-top: 18.5%;
  padding-bottom: 18.5%;
}
.padding-nineteen-tb {
  padding-top: 19%;
  padding-bottom: 19%;
}
.padding-nineteen-half-tb {
  padding-top: 19.5%;
  padding-bottom: 19.5%;
}
.padding-twenty-tb {
  padding-top: 20%;
  padding-bottom: 20%;
}
.padding-twenty-half-tb {
  padding-top: 20.5%;
  padding-bottom: 20%;
}
.padding-twenty-one-tb {
  padding-top: 21%;
  padding-bottom: 21%;
}
.padding-twenty-one-half-tb {
  padding-top: 21.5%;
  padding-bottom: 21.5%;
}
.padding-twenty-two-tb {
  padding-top: 22%;
  padding-bottom: 22%;
}
.padding-twenty-two-half-tb {
  padding-top: 22.5%;
  padding-bottom: 22.5%;
}
.padding-twenty-three-tb {
  padding-top: 23%;
  padding-bottom: 23%;
}
.padding-twenty-three-half-tb {
  padding-top: 23.5%;
  padding-bottom: 23.5%;
}
.padding-twenty-four-tb {
  padding-top: 24%;
  padding-bottom: 24%;
}
.padding-twenty-four-half-tb {
  padding-top: 24.5%;
  padding-bottom: 24.5%;
}
.padding-twenty-five-tb {
  padding-top: 25%;
  padding-bottom: 25%;
}
.padding-thirty-tb {
  padding-top: 30%;
  padding-bottom: 30%;
}
.padding-5px-tb {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-10px-tb {
  padding-top: 10px;
  padding-bottom: 10px;
}
.padding-15px-tb {
  padding-top: 15px;
  padding-bottom: 15px;
}
.padding-20px-tb {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding-25px-tb {
  padding-top: 25px;
  padding-bottom: 25px;
}
.padding-30px-tb {
  padding-top: 30px;
  padding-bottom: 30px;
}
.padding-35px-tb {
  padding-top: 35px;
  padding-bottom: 35px;
}
.padding-40px-tb {
  padding-top: 40px;
  padding-bottom: 40px;
}
.padding-45px-tb {
  padding-top: 45px;
  padding-bottom: 45px;
}
.padding-50px-tb {
  padding-top: 50px;
  padding-bottom: 50px;
}
.padding-55px-tb {
  padding-top: 55px;
  padding-bottom: 55px;
}
.padding-60px-tb {
  padding-top: 60px;
  padding-bottom: 60px;
}
.padding-65px-tb {
  padding-top: 65px;
  padding-bottom: 65px;
}
.padding-70px-tb {
  padding-top: 70px;
  padding-bottom: 70px;
}
.padding-75px-tb {
  padding-top: 75px;
  padding-bottom: 75px;
}
.padding-80px-tb {
  padding-top: 80px;
  padding-bottom: 80px;
}
.padding-85px-tb {
  padding-top: 85px;
  padding-bottom: 85px;
}
.padding-90px-tb {
  padding-top: 90px;
  padding-bottom: 90px;
}
.padding-95px-tb {
  padding-top: 95px;
  padding-bottom: 95px;
}
.padding-100px-tb {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* padding left and right */
.padding-one-lr {
  padding-left: 1%;
  padding-right: 1%;
}
.padding-one-half-lr {
  padding-left: 1.5%;
  padding-right: 1.5%;
}
.padding-two-lr {
  padding-left: 2%;
  padding-right: 2%;
}
.padding-two-half-lr {
  padding-left: 2.5%;
  padding-right: 2.5%;
}
.padding-three-lr {
  padding-left: 3%;
  padding-right: 3%;
}
.padding-three-half-lr {
  padding-left: 3.5%;
  padding-right: 3.5%;
}
.padding-four-lr {
  padding-left: 4%;
  padding-right: 4%;
}
.padding-four-half-lr {
  padding-left: 4.5%;
  padding-right: 4.5%;
}
.padding-five-lr {
  padding-left: 5%;
  padding-right: 5%;
}
.padding-five-half-lr {
  padding-left: 5.5%;
  padding-right: 5.5%;
}
.padding-six-lr {
  padding-left: 6%;
  padding-right: 6%;
}
.padding-six-half-lr {
  padding-left: 6.5%;
  padding-right: 6.5%;
}
.padding-seven-lr {
  padding-left: 7%;
  padding-right: 7%;
}
.padding-seven-half-lr {
  padding-left: 7.5%;
  padding-right: 7.5%;
}
.padding-eight-lr {
  padding-left: 8%;
  padding-right: 8%;
}
.padding-eight-half-lr {
  padding-left: 8.5%;
  padding-right: 8.5%;
}
.padding-nine-lr {
  padding-left: 9%;
  padding-right: 9%;
}
.padding-nine-half-lr {
  padding-left: 9.5%;
  padding-right: 9.5%;
}
.padding-ten-lr {
  padding-left: 10%;
  padding-right: 10%;
}
.padding-ten-half-lr {
  padding-left: 10.5%;
  padding-right: 10.5%;
}
.padding-eleven-lr {
  padding-left: 11%;
  padding-right: 11%;
}
.padding-eleven-half-lr {
  padding-left: 11.5%;
  padding-right: 11.5%;
}
.padding-twelve-lr {
  padding-left: 12%;
  padding-right: 12%;
}
.padding-twelve-half-lr {
  padding-left: 12.5%;
  padding-right: 12.5%;
}
.padding-thirteen-lr {
  padding-left: 13%;
  padding-right: 13%;
}
.padding-thirteen-half-lr {
  padding-left: 13.5%;
  padding-right: 13.5%;
}
.padding-fourteen-lr {
  padding-left: 14%;
  padding-right: 14%;
}
.padding-fourteen-half-lr {
  padding-left: 14.5%;
  padding-right: 14.5%;
}
.padding-fifteen-lr {
  padding-left: 15%;
  padding-right: 15%;
}
.padding-fifteen-half-lr {
  padding-left: 15.5%;
  padding-right: 15.5%;
}
.padding-sixteen-lr {
  padding-left: 16%;
  padding-right: 16%;
}
.padding-sixteen-half-lr {
  padding-left: 16.5%;
  padding-right: 16.5%;
}
.padding-seventeen-lr {
  padding-left: 17%;
  padding-right: 17%;
}
.padding-seventeen-half-lr {
  padding-left: 17.5%;
  padding-right: 17.5%;
}
.padding-eighteen-lr {
  padding-left: 18%;
  padding-right: 18%;
}
.padding-eighteen-half-lr {
  padding-left: 18.5%;
  padding-right: 18.5%;
}
.padding-nineteen-lr {
  padding-left: 19%;
  padding-right: 19%;
}
.padding-nineteen-half-lr {
  padding-left: 19.5%;
  padding-right: 19.5%;
}
.padding-twenty-lr {
  padding-left: 20%;
  padding-right: 20%;
}
.padding-twenty-half-lr {
  padding-left: 20.5%;
  padding-right: 20.5%;
}
.padding-twenty-one-lr {
  padding-left: 21%;
  padding-right: 21%;
}
.padding-twenty-one-half-lr {
  padding-left: 21.5%;
  padding-right: 21.5%;
}
.padding-twenty-two-lr {
  padding-left: 22%;
  padding-right: 22%;
}
.padding-twenty-two-half-lr {
  padding-left: 22.5%;
  padding-right: 22.5%;
}
.padding-twenty-three-lr {
  padding-left: 23%;
  padding-right: 23%;
}
.padding-twenty-three-half-lr {
  padding-left: 23.5%;
  padding-right: 23.5%;
}
.padding-twenty-four-lr {
  padding-left: 24%;
  padding-right: 24%;
}
.padding-twenty-four-half-lr {
  padding-left: 24.5%;
  padding-right: 24.5%;
}
.padding-twenty-five-lr {
  padding-left: 25%;
  padding-right: 25%;
}
.padding-5px-lr {
  padding-left: 5px;
  padding-right: 5px;
}
.padding-10px-lr {
  padding-left: 10px;
  padding-right: 10px;
}
.padding-15px-lr {
  padding-left: 15px;
  padding-right: 15px;
}
.padding-20px-lr {
  padding-left: 20px;
  padding-right: 20px;
}
.padding-25px-lr {
  padding-left: 25px;
  padding-right: 25px;
}
.padding-30px-lr {
  padding-left: 30px;
  padding-right: 30px;
}
.padding-35px-lr {
  padding-left: 35px;
  padding-right: 35px;
}
.padding-40px-lr {
  padding-left: 40px;
  padding-right: 40px;
}
.padding-45px-lr {
  padding-left: 45px;
  padding-right: 45px;
}
.padding-50px-lr {
  padding-left: 50px;
  padding-right: 50px;
}
.padding-55px-lr {
  padding-left: 55px;
  padding-right: 55px;
}
.padding-60px-lr {
  padding-left: 60px;
  padding-right: 60px;
}
.padding-65px-lr {
  padding-left: 65px;
  padding-right: 65px;
}
.padding-70px-lr {
  padding-left: 70px;
  padding-right: 70px;
}
.padding-75px-lr {
  padding-left: 75px;
  padding-right: 75px;
}
.padding-80px-lr {
  padding-left: 80px;
  padding-right: 80px;
}
.padding-85px-lr {
  padding-left: 85px;
  padding-right: 85px;
}
.padding-90px-lr {
  padding-left: 90px;
  padding-right: 90px;
}
.padding-95px-lr {
  padding-left: 95px;
  padding-right: 95px;
}
.padding-100px-lr {
  padding-left: 100px;
  padding-right: 100px;
}

/*==============================
    Display and float
================================*/

.display-block {
  display: block !important;
}
.display-inline-block {
  display: inline-block !important;
}
.display-inline {
  display: inline !important;
}
.display-none {
  display: none !important;
}
.display-inherit {
  display: inherit !important;
}
.display-table {
  display: table !important;
}
.display-table-cell {
  display: table-cell !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-auto {
  overflow: auto !important;
}

.display-flex {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
  flex-flow: row;
  -ms-flex-flow: row;
  -moz-flex-flow: row;
  -o-flex-flow: row;
}
.display-flex > div:nth-of-type(1) {
  order: 2;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-order: 2;
}
.display-flex > div:nth-of-type(2) {
  order: 1;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1;
}
.display-flex > div:nth-of-type(3) {
  order: 3;
  -webkit-order: 3;
  -moz-order: 3;
  -ms-order: 3;
}

/*float*/
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}

/*============================== 
    Position
================================*/

.position-inherit {
  position: inherit !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-right {
  right: 0 !important;
}
.position-left {
  left: 0 !important;
}
.position-top {
  top: 0 !important;
}

/* top */
.top-auto {
  top: auto;
}
.top-inherit {
  top: inherit !important;
}
.top-0 {
  top: 0 !important;
}
.top-1 {
  top: 1px;
}
.top-2 {
  top: 2px;
}
.top-3 {
  top: 3px;
}
.top-4 {
  top: 4px;
}
.top-5 {
  top: 5px;
}
.top-6 {
  top: 6px;
}
.top-7 {
  top: 7px;
}
.top-8 {
  top: 8px;
}
.top-9 {
  top: 9px;
}
.top-10 {
  top: 10px;
}
.top-15 {
  top: 15px;
}
.top-20 {
  top: 20px;
}
.top-25 {
  top: 25px;
}
.top-30 {
  top: 30px;
}
.top-35 {
  top: 35px;
}
.top-40 {
  top: 40px;
}
.top-45 {
  top: 45px;
}
.top-50 {
  top: 50px;
}

.top-minus1 {
  top: -1px;
}
.top-minus2 {
  top: -2px;
}
.top-minus3 {
  top: -3px;
}
.top-minus4 {
  top: -4px;
}
.top-minus5 {
  top: -5px;
}
.top-minus6 {
  top: -6px;
}
.top-minus7 {
  top: -7px;
}
.top-minus8 {
  top: -8px;
}
.top-minus9 {
  top: -9px;
}
.top-minus10 {
  top: -10px;
}
.top-minus15 {
  top: -15px;
}
.top-minus20 {
  top: -20px;
}
.top-minus25 {
  top: -25px;
}
.top-minus30 {
  top: -30px;
}
.top-minus35 {
  top: -35px;
}
.top-minus40 {
  top: -40px;
}

/* bottom */
.bottom-auto {
  bottom: auto;
}
.bottom-0 {
  bottom: 0;
}
.bottom-1 {
  bottom: 1px;
}
.bottom-2 {
  bottom: 2px;
}
.bottom-3 {
  bottom: 3px;
}
.bottom-4 {
  bottom: 4px;
}
.bottom-5 {
  bottom: 5px;
}
.bottom-6 {
  bottom: 6px;
}
.bottom-7 {
  bottom: 7px;
}
.bottom-8 {
  bottom: 8px;
}
.bottom-9 {
  bottom: 9px;
}
.bottom-10 {
  bottom: 10px;
}
.bottom-15 {
  bottom: 15px;
}
.bottom-20 {
  bottom: 20px;
}
.bottom-25 {
  bottom: 25px;
}
.bottom-30 {
  bottom: 30px;
}
.bottom-35 {
  bottom: 35px;
}
.bottom-40 {
  bottom: 40px;
}
.bottom-45 {
  bottom: 45px;
}
.bottom-50 {
  bottom: 50px;
}

/* left */
.left-auto {
  left: auto;
}
.left-0 {
  left: 0;
}
.left-1 {
  left: 1px;
}
.left-2 {
  left: 2px;
}
.left-3 {
  left: 3px;
}
.left-4 {
  left: 4px;
}
.left-5 {
  left: 5px;
}
.left-6 {
  left: 6px;
}
.left-7 {
  left: 7px;
}
.left-8 {
  left: 8px;
}
.left-9 {
  left: 9px;
}
.left-10 {
  left: 10px;
}
.left-15 {
  left: 15px;
}
.left-20 {
  left: 20px;
}
.left-25 {
  left: 25px;
}
.left-30 {
  left: 30px;
}
.left-35 {
  left: 35px;
}
.left-40 {
  left: 40px;
}
.left-45 {
  left: 45px;
}
.left-50 {
  left: 50px;
}

/* right */
.right-auto {
  right: auto;
}
.right-0 {
  right: 0;
}
.right-1 {
  right: 1px;
}
.right-2 {
  right: 2px;
}
.right-3 {
  right: 3px;
}
.right-4 {
  right: 4px;
}
.right-5 {
  right: 5px;
}
.right-6 {
  right: 6px;
}
.right-7 {
  right: 7px;
}
.right-8 {
  right: 8px;
}
.right-9 {
  right: 9px;
}
.right-10 {
  right: 10px;
}

/*================================
    Width
================================*/

.width-1px {
  width: 1px;
}
.width-2px {
  width: 2px;
}
.width-3px {
  width: 3px;
}
.width-4px {
  width: 4px;
}
.width-5px {
  width: 5px;
}
.width-6px {
  width: 6px;
}
.width-7px {
  width: 7px;
}
.width-8px {
  width: 8px;
}
.width-9px {
  width: 9px;
}
.width-10px {
  width: 10px;
}
.width-15px {
  width: 15px;
}
.width-20px {
  width: 20px;
}
.width-25px {
  width: 25px;
}
.width-30px {
  width: 30px;
}
.width-35px {
  width: 35px;
}
.width-40px {
  width: 40px;
}
.width-50px {
  width: 50px;
}
.width-55px {
  width: 55px;
}
.width-60px {
  width: 60px;
}
.width-65px {
  width: 65px;
}
.width-70px {
  width: 70px;
}
.width-75px {
  width: 75px;
}
.width-80px {
  width: 80px;
}
.width-85px {
  width: 85px;
}
.width-90px {
  width: 90px;
}
.width-100px {
  width: 100px;
}
.width-120px {
  width: 120px;
}
.width-130px {
  width: 130px;
}
.width-150px {
  width: 150px;
}
.width-180px {
  width: 180px;
}
.width-200px {
  width: 200px;
}
.width-250px {
  width: 250px;
}
.width-300px {
  width: 300px;
}
.width-350px {
  width: 350px;
}
.width-400px {
  width: 400px;
}
.width-450px {
  width: 450px;
}
.width-500px {
  width: 500px;
}
.width-550px {
  width: 550px;
}
.width-600px {
  width: 600px;
}
.width-650px {
  width: 650px;
}
.width-700px {
  width: 700px;
}
.width-750px {
  width: 750px;
}
.width-800px {
  width: 800px;
}
.width-850px {
  width: 850px;
}
.width-900px {
  width: 900px;
}
.width-950px {
  width: 950px;
}
.width-1000px {
  width: 1000px;
}

.width-10 {
  width: 10%;
}
.width-12 {
  width: 12%;
}
.width-15 {
  width: 15%;
}
.width-20 {
  width: 20%;
}
.width-25 {
  width: 25%;
}
.width-30 {
  width: 30%;
}
.width-35 {
  width: 35%;
}
.width-40 {
  width: 40%;
}
.width-45 {
  width: 45%;
}
.width-50 {
  width: 50%;
}
.width-55 {
  width: 55%;
}
.width-60 {
  width: 60%;
}
.width-65 {
  width: 65%;
}
.width-70 {
  width: 70%;
}
.width-75 {
  width: 75%;
}
.width-80 {
  width: 80%;
}
.width-85 {
  width: 85%;
}
.width-90 {
  width: 90%;
}
.width-95 {
  width: 95%;
}
.width-100 {
  width: 100%;
}
.width-auto {
  width: auto;
}

/*================================ 
    Height
================================*/

.height-1px {
  height: 1px;
}
.height-2px {
  height: 2px;
}
.height-3px {
  height: 3px;
}
.height-4px {
  height: 4px;
}
.height-5px {
  height: 5px;
}
.height-6px {
  height: 6px;
}
.height-7px {
  height: 7px;
}
.height-8px {
  height: 8px;
}
.height-9px {
  height: 9px;
}
.height-10px {
  height: 10px;
}
.height-80px {
  height: 80px;
}
.height-90px {
  height: 90px;
}
.height-100px {
  height: 100px;
}
.height-130px {
  height: 130px;
}
.height-150px {
  height: 150px;
}
.height-200px {
  height: 200px;
}
.height-250px {
  height: 250px;
}
.height-300px {
  height: 300px;
}
.height-350px {
  height: 350px;
}
.height-400px {
  height: 400px;
}
.height-450px {
  height: 450px;
}
.height-500px {
  height: 500px;
}
.height-550px {
  height: 550px;
}
.height-600px {
  height: 600px;
}
.height-650px {
  height: 650px;
}
.height-700px {
  height: 700px;
}

.height-50 {
  height: 50%;
}
.height-100 {
  height: 100% !important;
}
.height-auto {
  height: auto !important;
}
.max-height-100 {
  max-height: 100%;
}
.full-screen {
  min-height: 980px;
}

/* min-height */
.min-height-100px {
  min-height: 100px;
}
.min-height-200px {
  min-height: 200px;
}
.min-height-300px {
  min-height: 300px;
}
.min-height-400px {
  min-height: 400px;
}
.min-height-500px {
  min-height: 500px;
}
.min-height-600px {
  min-height: 600px;
}
.min-height-700px {
  min-height: 700px;
}

/* screen height  */
.extra-small-screen {
  height: 300px;
}
.small-screen {
  height: 400px;
}
.one-second-screen {
  height: 600px;
}
.one-third-screen {
  height: 700px;
}
.one-fourth-screen {
  height: 800px;
}
.one-fifth-screen {
  height: 900px;
}
.one-sixth-screen {
  height: 887px;
}

/* equalize inner match height */
.inner-match-height {
  position: relative;
  height: 100%;
}

/* ===================================
    Icon
====================================== */

/* icon size */
.icon-very-small {
  font-size: 16px;
}
.icon-small {
  font-size: 24px;
}
.icon-medium {
  font-size: 35px;
}
.icon-extra-medium {
  font-size: 40px;
}
.icon-large {
  font-size: 50px;
}
.icon-round-small {
  border-radius: 100%;
  display: table-cell;
  height: 80px;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  width: 80px;
  font-size: 32px;
}
.icon-round-medium {
  border-radius: 100%;
  display: table-cell;
  height: 90px;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  width: 90px;
}

/* social media icon */
.icon-social-very-small {
  font-size: 12px;
}
.icon-social-small {
  font-size: 14px;
}
.icon-social-medium {
  font-size: 16px;
}
.icon-social-large {
  font-size: 20px;
}
.icon-social-extra-large {
  font-size: 24px;
}
.icon-social-very-small a i,
.icon-social-small a i,
.icon-social-medium a i {
  margin: 0 8px;
  vertical-align: middle;
}
.icon-social-large a i {
  margin: 0 4px;
  vertical-align: middle;
}

/* social media icon style */
.extra-small-icon,
.small-icon,
.medium-icon,
.large-icon,
.extra-large-icon {
  list-style: none;
  padding: 0;
}
.extra-small-icon li,
.small-icon li,
.medium-icon li,
.large-icon li,
.extra-large-icon li {
  display: inline-block;
}
footer .extra-small-icon li:first-child,
footer .small-icon li:first-child,
footer .medium-icon li:first-child,
footer .large-icon li:first-child,
footer .extra-large-icon li:first-child {
  margin-left: 0;
}
footer .extra-small-icon li:last-child,
footer .small-icon li:last-child,
footer .medium-icon li:last-child,
footer .large-icon li:last-child,
footer .extra-large-icon li:last-child {
  margin-right: 0;
}
.extra-small-icon li {
  margin: 0 2px;
}
.small-icon li {
  margin: 0 5px;
}
.medium-icon li {
  margin: 0 8px;
}
.large-icon li {
  margin: 0 10px;
}
.extra-large-icon li {
  margin: 0 12px;
}
.extra-small-icon a {
  font-size: 14px;
  width: 30px;
  height: 30px;
  line-height: 29px;
}
.small-icon a {
  font-size: 16px;
  width: 40px;
  height: 40px;
  line-height: 38px;
}
.medium-icon a {
  font-size: 20px;
  width: 50px;
  height: 50px;
  line-height: 48px;
}
.large-icon a {
  font-size: 24px;
  width: 60px;
  height: 60px;
  line-height: 61px;
}
.extra-large-icon a {
  font-size: 30px;
  width: 70px;
  height: 70px;
  line-height: 68px;
}

/* social icon style */
.social-icon-style-1 a.facebook,
.social-icon-style-2 a.facebook,
.social-icon-style-3 a.facebook,
.social-icon-style-4 a.facebook span,
.social-icon-style-10 a.facebook span {
  background-color: #3b5998;
}
.social-icon-style-1 a.twitter,
.social-icon-style-2 a.twitter,
.social-icon-style-3 a.twitter,
.social-icon-style-4 a.twitter span,
.social-icon-style-10 a.twitter span {
  background-color: #00aced;
}
.social-icon-style-1 a.google,
.social-icon-style-2 a.google,
.social-icon-style-3 a.google,
.social-icon-style-4 a.google span,
.social-icon-style-10 a.google span {
  background-color: #dc4a38;
}
.social-icon-style-1 a.dribbble,
.social-icon-style-2 a.dribbble,
.social-icon-style-3 a.dribbble,
.social-icon-style-4 a.dribbble span,
.social-icon-style-10 a.dribbble span {
  background-color: #ea4c89;
}
.social-icon-style-1 a.youtube,
.social-icon-style-2 a.youtube,
.social-icon-style-3 a.youtube,
.social-icon-style-4 a.youtube span,
.social-icon-style-10 a.youtube span {
  background-color: #bb0000;
}
.social-icon-style-1 a.linkedin,
.social-icon-style-2 a.linkedin,
.social-icon-style-3 a.linkedin,
.social-icon-style-4 a.linkedin span,
.social-icon-style-10 a.linkedin span {
  background-color: #007bb6;
}
.social-icon-style-1 a.instagram,
.social-icon-style-2 a.instagram,
.social-icon-style-3 a.instagram,
.social-icon-style-4 a.instagram span,
.social-icon-style-10 a.instagram span {
  background-color: #fe1f49;
}
.social-icon-style-1 a.pinterest,
.social-icon-style-2 a.pinterest,
.social-icon-style-3 a.pinterest,
.social-icon-style-4 a.pinterest span,
.social-icon-style-10 a.pinterest span {
  background-color: #cb2027;
}
.social-icon-style-1 a.flickr,
.social-icon-style-2 a.flickr,
.social-icon-style-3 a.flickr,
.social-icon-style-4 a.flickr span,
.social-icon-style-10 a.flickr span {
  background-color: #ff0084;
}
.social-icon-style-1 a.rss,
.social-icon-style-2 a.rss,
.social-icon-style-3 a.rss,
.social-icon-style-4 a.rss span,
.social-icon-style-10 a.rss span {
  background-color: #f2890a;
}
.social-icon-style-1 a.behance,
.social-icon-style-2 a.behance,
.social-icon-style-3 a.behance,
.social-icon-style-4 a.behance span,
.social-icon-style-10 a.behance span {
  background-color: #1769ff;
}
.social-icon-style-1 a.vine,
.social-icon-style-2 a.vine,
.social-icon-style-3 a.vine,
.social-icon-style-4 a.vine span,
.social-icon-style-10 a.vine span {
  background-color: #00bf8f;
}
.social-icon-style-1 a.email,
.social-icon-style-2 a.email,
.social-icon-style-3 a.email,
.social-icon-style-4 a.email span,
.social-icon-style-10 a.email span {
  background-color: #7d7d7d;
}
.social-icon-style-1 a.sms,
.social-icon-style-2 a.sms,
.social-icon-style-3 a.sms,
.social-icon-style-4 a.sms span,
.social-icon-style-10 a.sms span {
  background-color: #ffbd00;
}
.social-icon-style-1 a.sharethis,
.social-icon-style-2 a.sharethis,
.social-icon-style-3 a.sharethis,
.social-icon-style-4 a.sharethis span,
.social-icon-style-10 a.sharethis span {
  background-color: #95d03a;
}
.social-icon-style-1 a.reddit,
.social-icon-style-2 a.reddit,
.social-icon-style-3 a.reddit,
.social-icon-style-4 a.reddit span,
.social-icon-style-10 a.reddit span {
  background-color: #ff4500;
}
.social-icon-style-1 a.tumblr,
.social-icon-style-2 a.tumblr,
.social-icon-style-3 a.tumblr,
.social-icon-style-4 a.tumblr span,
.social-icon-style-10 a.tumblr span {
  background-color: #32506d;
}
.social-icon-style-1 a.digg,
.social-icon-style-2 a.digg,
.social-icon-style-3 a.digg,
.social-icon-style-4 a.digg span,
.social-icon-style-10 a.digg span {
  background-color: #262626;
}
.social-icon-style-1 a.stumbleupon,
.social-icon-style-2 a.stumbleupon,
.social-icon-style-3 a.stumbleupon,
.social-icon-style-4 a.stumbleupon span,
.social-icon-style-10 a.stumbleupon span {
  background-color: #eb4924;
}
.social-icon-style-1 a.whatsapp,
.social-icon-style-2 a.whatsapp,
.social-icon-style-3 a.whatsapp,
.social-icon-style-4 a.whatsapp span,
.social-icon-style-10 a.whatsapp span {
  background-color: #25d366;
}
.social-icon-style-1 a.vk,
.social-icon-style-2 a.vk,
.social-icon-style-3 a.vk,
.social-icon-style-4 a.vk span,
.social-icon-style-10 a.vk span {
  background-color: #4c6c91;
}
.social-icon-style-1 a.weibo,
.social-icon-style-2 a.weibo,
.social-icon-style-3 a.weibo,
.social-icon-style-4 a.weibo span,
.social-icon-style-10 a.weibo span {
  background-color: #ff9933;
}
.social-icon-style-1 a.odnoklassniki,
.social-icon-style-2 a.odnoklassniki,
.social-icon-style-3 a.odnoklassniki,
.social-icon-style-4 a.odnoklassniki span,
.social-icon-style-10 a.odnoklassniki span {
  background-color: #d7772d;
}
.social-icon-style-1 a.xing,
.social-icon-style-2 a.xing,
.social-icon-style-3 a.xing,
.social-icon-style-4 a.xing span,
.social-icon-style-10 a.xing span {
  background-color: #1a7576;
}
.social-icon-style-1 a.print,
.social-icon-style-2 a.print,
.social-icon-style-3 a.print,
.social-icon-style-4 a.print span,
.social-icon-style-10 a.print span {
  background-color: #222222;
}
.social-icon-style-1 a.blogger,
.social-icon-style-2 a.blogger,
.social-icon-style-3 a.blogger,
.social-icon-style-4 a.blogger span,
.social-icon-style-10 a.blogger span {
  background-color: #ff8000;
}
.social-icon-style-1 a.flipboard,
.social-icon-style-2 a.flipboard,
.social-icon-style-3 a.flipboard,
.social-icon-style-4 a.flipboard span,
.social-icon-style-10 a.flipboard span {
  background-color: #e12828;
}
.social-icon-style-1 a.meneame,
.social-icon-style-2 a.meneame,
.social-icon-style-3 a.meneame,
.social-icon-style-4 a.meneame span,
.social-icon-style-10 a.meneame span {
  background-color: #ff6400;
}
.social-icon-style-1 a.mailru,
.social-icon-style-2 a.mailru,
.social-icon-style-3 a.mailru,
.social-icon-style-4 a.mailru span,
.social-icon-style-10 a.mailru span {
  background-color: #168de2;
}
.social-icon-style-1 a.delicious,
.social-icon-style-2 a.delicious,
.social-icon-style-3 a.delicious,
.social-icon-style-4 a.delicious span,
.social-icon-style-10 a.delicious span {
  background-color: #205cc0;
}
.social-icon-style-1 a.livejournal,
.social-icon-style-2 a.livejournal,
.social-icon-style-3 a.livejournal,
.social-icon-style-4 a.livejournal span,
.social-icon-style-10 a.livejournal span {
  background-color: #00b0ea;
}

/* social icon style */
.social-icon-style-6 a.facebook:hover {
  border-color: #3b5998;
  color: #3b5998;
}
.social-icon-style-6 a.twitter:hover {
  border-color: #00aced;
  color: #00aced;
}
.social-icon-style-6 a.google:hover {
  border-color: #dc4a38;
  color: #dc4a38;
}
.social-icon-style-6 a.dribbble:hover {
  border-color: #ea4c89;
  color: #ea4c89;
}
.social-icon-style-6 a.youtube:hover {
  border-color: #bb0000;
  color: #bb0000;
}
.social-icon-style-6 a.linkedin:hover {
  border-color: #0077b5;
  color: #0077b5;
}
.social-icon-style-6 a.instagram:hover {
  border-color: #fe1f49;
  color: #fe1f49;
}
.social-icon-style-6 a.pinterest:hover {
  border-color: #cb2027;
  color: #cb2027;
}
.social-icon-style-6 a.flickr:hover {
  border-color: #ff0084;
  color: #ff0084;
}
.social-icon-style-6 a.rss:hover {
  border-color: #f2890a;
  color: #f2890a;
}
.social-icon-style-6 a.behance:hover {
  border-color: #1769ff;
  color: #1769ff;
}
.social-icon-style-6 a.vine:hover {
  border-color: #00bf8f;
  color: #00bf8f;
}
.social-icon-style-6 a.email:hover {
  border-color: #7d7d7d;
  color: #7d7d7d;
}
.social-icon-style-6 a.sms:hover {
  border-color: #ffbd00;
  color: #ffbd00;
}
.social-icon-style-6 a.sharethis:hover {
  border-color: #95d03a;
  color: #95d03a;
}
.social-icon-style-6 a.reddit:hover {
  border-color: #ff4500;
  color: #ff4500;
}
.social-icon-style-6 a.tumblr:hover {
  border-color: #32506d;
  color: #32506d;
}
.social-icon-style-6 a.digg:hover {
  border-color: #262626;
  color: #262626;
}
.social-icon-style-6 a.stumbleupon:hover {
  border-color: #eb4924;
  color: #eb4924;
}
.social-icon-style-6 a.whatsapp:hover {
  border-color: #25d366;
  color: #25d366;
}
.social-icon-style-6 a.vk:hover {
  border-color: #4c6c91;
  color: #4c6c91;
}
.social-icon-style-6 a.weibo:hover {
  border-color: #ff9933;
  color: #ff9933;
}
.social-icon-style-6 a.odnoklassniki:hover {
  border-color: #d7772d;
  color: #d7772d;
}
.social-icon-style-6 a.xing:hover {
  border-color: #1a7576;
  color: #1a7576;
}
.social-icon-style-6 a.print:hover {
  border-color: #222222;
  color: #222222;
}
.social-icon-style-6 a.blogger:hover {
  border-color: #ff8000;
  color: #ff8000;
}
.social-icon-style-6 a.flipboard:hover {
  border-color: #e12828;
  color: #e12828;
}
.social-icon-style-6 a.meneame:hover {
  border-color: #ff6400;
  color: #ff6400;
}
.social-icon-style-6 a.mailru:hover {
  border-color: #168de2;
  color: #168de2;
}
.social-icon-style-6 a.delicious:hover {
  border-color: #205cc0;
  color: #205cc0;
}
.social-icon-style-6 a.livejournal:hover {
  border-color: #00b0ea;
  color: #00b0ea;
}

/* social icon style */
.social-icon-style-7 a.facebook:hover {
  border-color: #3b5998;
  background-color: #3b5998;
}
.social-icon-style-7 a.twitter:hover {
  border-color: #00aced;
  background-color: #00aced;
}
.social-icon-style-7 a.google:hover {
  border-color: #dc4a38;
  background-color: #dc4a38;
}
.social-icon-style-7 a.dribbble:hover {
  border-color: #ea4c89;
  background-color: #ea4c89;
}
.social-icon-style-7 a.youtube:hover {
  border-color: #bb0000;
  background-color: #bb0000;
}
.social-icon-style-7 a.linkedin:hover {
  border-color: #0077b5;
  background-color: #0077b5;
}
.social-icon-style-7 a.instagram:hover {
  border-color: #fe1f49;
  background-color: #fe1f49;
}
.social-icon-style-7 a.pinterest:hover {
  border-color: #cb2027;
  background-color: #cb2027;
}
.social-icon-style-7 a.flickr:hover {
  border-color: #ff0084;
  background-color: #ff0084;
}
.social-icon-style-7 a.rss:hover {
  border-color: #f2890a;
  background-color: #f2890a;
}
.social-icon-style-7 a.behance:hover {
  border-color: #1769ff;
  background-color: #1769ff;
}
.social-icon-style-7 a.vine:hover {
  border-color: #00bf8f;
  background-color: #00bf8f;
}
.social-icon-style-7 a.email:hover {
  border-color: #7d7d7d;
  background-color: #7d7d7d;
}
.social-icon-style-7 a.sms:hover {
  border-color: #ffbd00;
  background-color: #ffbd00;
}
.social-icon-style-7 a.sharethis:hover {
  border-color: #95d03a;
  background-color: #95d03a;
}
.social-icon-style-7 a.reddit:hover {
  border-color: #ff4500;
  background-color: #ff4500;
}
.social-icon-style-7 a.tumblr:hover {
  border-color: #32506d;
  background-color: #32506d;
}
.social-icon-style-7 a.digg:hover {
  border-color: #262626;
  background-color: #262626;
}
.social-icon-style-7 a.stumbleupon:hover {
  border-color: #eb4924;
  background-color: #eb4924;
}
.social-icon-style-7 a.whatsapp:hover {
  border-color: #25d366;
  background-color: #25d366;
}
.social-icon-style-7 a.vk:hover {
  border-color: #4c6c91;
  background-color: #4c6c91;
}
.social-icon-style-7 a.weibo:hover {
  border-color: #ff9933;
  background-color: #ff9933;
}
.social-icon-style-7 a.odnoklassniki:hover {
  border-color: #d7772d;
  background-color: #d7772d;
}
.social-icon-style-7 a.xing:hover {
  border-color: #1a7576;
  background-color: #1a7576;
}
.social-icon-style-7 a.print:hover {
  border-color: #222222;
  background-color: #222222;
}
.social-icon-style-7 a.blogger:hover {
  border-color: #ff8000;
  background-color: #ff8000;
}
.social-icon-style-7 a.flipboard:hover {
  border-color: #e12828;
  background-color: #e12828;
}
.social-icon-style-7 a.meneame:hover {
  border-color: #ff6400;
  background-color: #ff6400;
}
.social-icon-style-7 a.mailru:hover {
  border-color: #168de2;
  background-color: #168de2;
}
.social-icon-style-7 a.delicious:hover {
  border-color: #205cc0;
  background-color: #205cc0;
}
.social-icon-style-7 a.livejournal:hover {
  border-color: #00b0ea;
  background-color: #00b0ea;
}

/* icon like counter */
a.likes-count {
  padding: 0 15px;
  width: auto;
  border-radius: 30px !important;
}
a.likes-count i {
  margin-right: 8px;
}
a.likes-count span {
  position: relative;
  top: -1px;
}

/* icon hover effect style 1 */
.social-icon-style-1 a {
  text-align: center;
  display: inline-block;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  color: #fff;
}
.social-icon-style-1 a:hover {
  border-radius: 100%;
}

/* icon hover effect style 2 */
.social-icon-style-2 a {
  text-align: center;
  display: inline-block;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  border-radius: 100%;
  color: #fff;
}
.social-icon-style-2 a:hover {
  border-radius: 0;
}

/* icon hover effect style 3 */
.social-icon-style-3 a {
  text-align: center;
  display: inline-block;
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  border-radius: 100%;
  color: #fff;
}
.social-icon-style-3 a:hover i {
  transition: transform 0.4s ease-out;
  -webkit-transition: transform 0.4s ease-out;
  -moz-transition: transform 0.4s ease-out;
  -ms-transition: transform 0.4s ease-out;
  -o-transition: transform 0.4s ease-out;
  transform: rotateZ(360deg);
  -webkit-transform: rotateZ(360deg);
  -moz-transform: rotateZ(360deg);
  -ms-transform: rotateZ(360deg);
  -o-transform: rotateZ(360deg);
}

/* icon hover effect style 4 */
.social-icon-style-4 a {
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #232323;
  text-align: center;
  z-index: 1;
}
.social-icon-style-4 a:hover {
  color: #fff;
}
.social-icon-style-4 a span {
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  width: 0;
  z-index: -1;
}
.social-icon-style-4 a:hover span {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  top: 0;
  left: 0;
}

/* icon hover effect style 5 */
.social-icon-style-5 a {
  text-align: center;
  display: inline-block;
  color: #232323;
}
.social-icon-style-5 a:hover {
  color: #fff;
  border-radius: 100%;
  background-color: #ff214f;
}

/* icon hover effect style- 5 light*/
.social-icon-style-5-light a {
  text-align: center;
  display: inline-block;
  color: #fff;
}
.social-icon-style-5-light a:hover {
  color: #fff;
  border-radius: 100%;
  background-color: #ff214f;
}

/* icon hover effect style 6 */
.social-icon-style-6 a {
  text-align: center;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #232323;
  background-color: transparent;
  border-radius: 100%;
}
.social-icon-style-6 a:hover {
  border-color: #ff214f;
  color: #ff214f;
  background-color: transparent;
}

/* icon hover effect style 7 */
.social-icon-style-7 a {
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  color: #232323;
  background-color: #fff;
}
.social-icon-style-7 a:hover {
  color: #fff;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
  -o-transform: scale(1.2);
}

/* icon hover effect style 8 */
.social-icon-style-8 ul li a {
  text-align: center;
  width: auto;
  height: auto;
}
.social-icon-style-8 a:hover {
  color: #ff214f;
}

/* icon hover effect style 9 */
.social-icon-style-9 ul li a {
  text-align: center;
  width: auto;
  height: auto;
}

/* social icon style */
.social-icon-style-9 a.facebook:hover {
  color: #3b5998;
}
.social-icon-style-9 a.twitter:hover {
  color: #00aced;
}
.social-icon-style-9 a.google:hover {
  color: #dc4a38;
}
.social-icon-style-9 a.dribbble:hover {
  color: #ea4c89;
}
.social-icon-style-9 a.youtube:hover {
  color: #bb0000;
}
.social-icon-style-9 a.linkedin:hover {
  color: #007bb6;
}
.social-icon-style-9 a.pinterest:hover {
  color: #cb2027;
}
.social-icon-style-9 a.email:hover {
  color: #7d7d7d;
}
.social-icon-style-9 a.sms:hover {
  color: #ffbd00;
}
.social-icon-style-9 a.sharethis:hover {
  color: #95d03a;
}
.social-icon-style-9 a.reddit:hover {
  color: #ff4500;
}
.social-icon-style-9 a.tumblr:hover {
  color: #32506d;
}
.social-icon-style-9 a.digg:hover {
  color: #262626;
}
.social-icon-style-9 a.stumbleupon:hover {
  color: #eb4924;
}
.social-icon-style-9 a.whatsapp:hover {
  color: #25d366;
}
.social-icon-style-9 a.vk:hover {
  color: #4c6c91;
}
.social-icon-style-9 a.weibo:hover {
  color: #ff9933;
}
.social-icon-style-9 a.odnoklassniki:hover {
  color: #d7772d;
}
.social-icon-style-9 a.xing:hover {
  color: #1a7576;
}
.social-icon-style-9 a.print:hover {
  color: #222222;
}
.social-icon-style-9 a.blogger:hover {
  color: #ff8000;
}
.social-icon-style-9 a.flipboard:hover {
  color: #e12828;
}
.social-icon-style-9 a.meneame:hover {
  color: #ff6400;
}
.social-icon-style-9 a.mailru:hover {
  color: #168de2;
}
.social-icon-style-9 a.delicious:hover {
  color: #205cc0;
}
.social-icon-style-9 a.livejournal:hover {
  color: #00b0ea;
}

/* icon hover effect style 10 light */
.social-icon-style-10 {
  position: relative;
}
.social-icon-style-10 a {
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  text-align: center;
}
.social-icon-style-10 a:hover {
  color: #fff;
}
.social-icon-style-10 a i {
  position: relative;
  z-index: 1;
}
.social-icon-style-10 a span {
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  width: 0;
}
.social-icon-style-10 a:hover span {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  top: 0;
  left: 0;
}

/* social icon style */
.social-icon-style-11 {
  position: relative;
}
.social-icon-style-11 a {
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  text-align: center;
}
.social-icon-style-11 a.facebook {
  color: #3b5998;
}
.social-icon-style-11 a.twitter {
  color: #00aced;
}
.social-icon-style-11 a.google {
  color: #dc4a38;
}
.social-icon-style-11 a.dribbble {
  color: #ea4c89;
}
.social-icon-style-11 a.youtube {
  color: #bb0000;
}
.social-icon-style-11 a.linkedin {
  color: #0077b5;
}
.social-icon-style-11 a.instagram {
  color: #fe1f49;
}
.social-icon-style-11 a.pinterest {
  color: #cb2027;
}
.social-icon-style-11 a.flickr {
  color: #ff0084;
}
.social-icon-style-11 a.rss {
  color: #f2890a;
}
.social-icon-style-11 a.behance {
  color: #1769ff;
}
.social-icon-style-11 a.vine {
  color: #00bf8f;
}
.social-icon-style-11 a.email {
  color: #7d7d7d;
}
.social-icon-style-11 a.sms {
  color: #ffbd00;
}
.social-icon-style-11 a.sharethis {
  color: #95d03a;
}
.social-icon-style-11 a.reddit {
  color: #ff4500;
}
.social-icon-style-11 a.tumblr {
  color: #32506d;
}
.social-icon-style-11 a.digg {
  color: #262626;
}
.social-icon-style-11 a.stumbleupon {
  color: #eb4924;
}
.social-icon-style-11 a.whatsapp {
  color: #25d366;
}
.social-icon-style-11 a.vk {
  color: #4c6c91;
}
.social-icon-style-11 a.weibo {
  color: #ff9933;
}
.social-icon-style-11 a.odnoklassniki {
  color: #d7772d;
}
.social-icon-style-11 a.xing {
  color: #1a7576;
}
.social-icon-style-11 a.print {
  color: #222222;
}
.social-icon-style-11 a.blogger {
  color: #ff8000;
}
.social-icon-style-11 a.flipboard {
  color: #e12828;
}
.social-icon-style-11 a.meneame {
  color: #ff6400;
}
.social-icon-style-11 a.mailru {
  color: #168de2;
}
.social-icon-style-11 a.delicious {
  color: #205cc0;
}
.social-icon-style-11 a.livejournal {
  color: #00b0ea;
}
.social-icon-style-11 a:hover {
  color: #fff;
}

footer .small-icon li {
  margin: 0 8px;
}

/* ===================================
    Magnific popup
====================================== */

.mfp-bg,
.my-mfp-zoom-in.mfp-ready.mfp-bg,
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  background: #000;
  opacity: 0.93;
}
.mfp-bg,
.mfp-wrap {
  z-index: 10007;
}
#popup-form {
  cursor: default;
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  background: transparent;
}
.mfp-iframe-scaler iframe {
  box-shadow: none;
}

.mfp-close,
.mfp-close:active {
  opacity: 1;
  background-color: transparent;
  color: #fff;
  right: 30px;
  top: 30px;
}
.modal-popup-main .mfp-close,
.modal-popup-main .mfp-close:active {
  right: 0;
  top: 0;
}

.lightbox-gallery a {
  position: relative;
  display: block;
}
.lightbox-gallery .gallary-over-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-arrow-right:after,
.mfp-arrow-left:after {
  display: none;
}
.mfp-arrow-right:before {
  border-left: none;
  content: '\e628';
  font-size: 2em;
  font-family: 'themify';
  color: #fff;
}
.mfp-arrow-left:before {
  border-right: none;
  content: '\e629';
  font-size: 2em;
  font-family: 'themify';
  color: #fff;
}
.mfp-bottom-bar {
  margin-top: -24px;
  font-size: 12px;
}
.mfp-close {
  font-size: 36px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mfp-figure {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  -ms-animation-duration: 0.4s;
  -o-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

/* dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/* fade move animation for second dialog */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

/* dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* simple fade transition */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.97;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* grid */
.show-grid [class^='col-'] {
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  padding: 10px 0;
  text-align: center;
}

/* message box */
.alert-dismissable .close,
.alert-dismissible .close {
  top: 0;
}

/* ===================================
    Header
====================================== */
.sidebar-nav-style-1 {
  z-index: -1;
}

.side-left-menu-close {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
}

/* header icon */
.header-searchbar {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 15px;
  display: inline-block;
  line-height: 15px;
  position: relative;
  top: -1px;
}
.header-social-icon {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 15px;
  margin-left: 12px;
  display: inline-block;
  line-height: 15px;
  position: relative;
  top: -1px;
}
.header-menu-button {
  display: inline-block;
  line-height: 14px;
  padding-left: 5px;
  position: relative;
  top: -1px;
}
nav.navbar.bootsnav .header-menu-button .navbar-toggle {
  float: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  top: 4px;
}
.white-link .header-searchbar,
.white-link .header-social-icon {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
.header-social-icon a {
  color: #232323;
}
.header-social-icon a i,
.header-searchbar a i {
  margin: 0 11px;
}
.header-searchbar a i {
  position: relative;
  top: -1px;
}
.header-social-icon a:last-child i {
  margin-right: 0;
}
.search-icon {
  padding-right: 10px;
}

/* menu center */
.menu-center .header-right {
  flex: 1 2 auto;
}
.navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

/* menu center logo */
.menu-logo-center .navbar-left > li {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
header nav.menu-logo-center .navbar-nav > li > a {
  margin: 0 20px;
}
header nav.menu-logo-center .navbar-nav > li:last-child a {
  margin-right: 20px;
}

/* header navigation color and logo */
header nav.navbar .navbar-nav > li > a,
nav.navbar.bootsnav ul.nav > li > a {
  color: #ffffff;
}
header nav.navbar.white-link .navbar-nav > li > a,
nav.navbar.bootsnav.white-link ul.nav > li > a,
header nav.navbar.white-link .header-social-icon a,
nav.navbar.bootsnav.white-link .header-social-icon a,
header nav.navbar.white-link .header-searchbar a,
nav.navbar.bootsnav.white-link .header-searchbar a,
header nav.navbar.bootsnav ul.nav.white-link > li > a,
nav.navbar.white-link .header-menu-button a {
  color: #fff;
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a,
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-searchbar a,
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-social-icon a,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-searchbar a,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-social-icon a,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a {
  color: #232323;
}

header .logo .logo-dark,
header .logo .logo-light {
  visibility: hidden;
  opacity: 0;
  width: 0;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
header .logo .logo-dark.default,
header .logo .logo-light.default {
  visibility: visible;
  opacity: 1;
  width: auto;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
header.sticky nav.header-dark-transparent .logo .logo-light,
header.sticky nav.header-dark .logo .logo-light {
  visibility: visible;
  opacity: 1;
  width: auto;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
header.sticky nav.header-dark-transparent .logo .logo-dark,
header.sticky nav.header-dark .logo .logo-dark {
  visibility: hidden;
  opacity: 0;
  width: 0;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
header.sticky nav.header-light-transparent .logo .logo-dark,
header.sticky nav.header-light .logo .logo-dark {
  visibility: visible;
  opacity: 1;
  width: auto;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
header.sticky nav.header-light-transparent .logo .logo-light,
header.sticky nav.header-light .logo .logo-light {
  visibility: hidden;
  opacity: 0;
  width: 0;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

.nav-top-scroll {
  position: absolute;
  top: 0;
  z-index: 5;
}
header.sticky nav.nav-top-scroll {
  background-color: inherit;
}
header a.logo {
  display: inline-block;
  vertical-align: middle;
}
/*header a.logo img {max-height:0px;}*/
header .left-nav .sidebar-part1 a.logo img {
  max-height: inherit;
}
.logo-holder img {
  max-height: 100%;
}

/* header animation */
.navbar-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
}
.sticky .navbar-top {
  top: -160px;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
}
.sticky.header-appear .navbar-top.navbar-transparent-no-sticky,
.sticky.header-appear .navbar-fixed-top.navbar-transparent-no-sticky {
  top: -160px;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
}
.sticky.header-appear .navbar-top {
  top: 0;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
}

header .bootsnav .dropdown .animated {
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -ms-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul:before {
  position: absolute;
  height: calc(100% + 20px);
  width: 100%;
  display: block;
  content: '';
}

header.sticky .navbar-scroll-fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
}
header.sticky .navbar-scroll-top {
  position: fixed;
  top: -120px;
  width: 100%;
  left: 0;
  transition-duration: 0s;
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
}
header.sticky.header-appear .navbar-scroll-top {
  top: 0;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
}

/* navigation */
header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a:hover,
header.header-appear nav.bootsnav.header-light .navbar-nav > li > a:hover,
header.header-appear nav.header-light-transparent .header-social-icon a:hover,
header.header-appear nav.header-light .header-social-icon a:hover,
header.header-appear nav.header-light.white-link .header-social-icon a:hover,
header.header-appear nav.header-light-transparent.white-link .header-social-icon a:hover,
header.header-appear nav.header-light-transparent.white-link .header-menu-button a:hover,
header.header-appear nav.header-light-transparent .header-searchbar a:hover,
header.header-appear nav.header-light .header-searchbar a:hover,
header.header-appear nav.header-light.white-link .header-searchbar a:hover,
header.header-appear nav.header-light-transparent.white-link .header-searchbar a:hover,
header nav.navbar .navbar-nav > li > a:hover,
nav.navbar.bootsnav ul.nav > li > a:hover,
.header-search-form:hover,
.header-social-icon a:hover {
  color: #be1e2d;
}
header nav.navbar.white-link .navbar-nav > li > a:hover,
nav.navbar.bootsnav.white-link ul.nav > li > a:hover,
header nav.navbar.white-link .header-social-icon a:hover,
nav.navbar.bootsnav.white-link .header-social-icon a:hover,
header nav.navbar.white-link .header-searchbar a:hover,
nav.navbar.bootsnav.white-link .header-searchbar a:hover,
header nav.navbar.bootsnav ul.nav.white-link > li > a:hover,
header.header-appear nav.header-dark-transparent .navbar-nav > li > a:hover,
header.header-appear nav.header-dark .navbar-nav > li > a:hover,
header.sticky nav.header-dark .navbar-nav > li > a:hover,
header.sticky nav.header-dark-transparent .navbar-nav > li > a:hover,
header.header-appear nav.header-dark-transparent .search-button:hover,
header.header-appear nav.header-dark .search-button:hover,
header.sticky nav.header-dark .search-button:hover,
header.sticky nav.header-dark-transparent .search-button:hover {
  color: rgba(255, 255, 255, 0.6);
}
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a:hover,
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-searchbar a:hover,
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-social-icon a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-searchbar a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-social-icon a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover {
  color: rgba(0, 0, 0, 0.6);
}

header.sticky nav.navbar.white-link .navbar-nav > li > a:hover,
header.sticky nav.navbar.bootsnav.white-link ul.nav > li > a:hover {
  color: rgba(0, 0, 0, 0.6);
}
header.sticky nav.navbar.header-dark.white-link .navbar-nav > li > a:hover,
header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li > a:hover,
header.sticky nav.header-dark .header-social-icon a:hover {
  color: rgba(255, 255, 255, 0.6);
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li.active > a,
header.header-appear nav.bootsnav.header-light .navbar-nav > li.active > a,
header nav.navbar .navbar-nav > li.active > a,
nav.navbar.bootsnav ul.nav > li.active > a {
  color: #d84449;
}
header nav.navbar.white-link .navbar-nav > li.active > a,
nav.navbar.bootsnav.white-link ul.nav > li.active > a,
header nav.navbar.bootsnav ul.nav.white-link > li.active > a,
header.header-appear nav.header-dark-transparent .navbar-nav > li.active > a,
header.header-appear nav.header-dark .navbar-nav > li.active > a,
header.sticky nav.header-dark .navbar-nav > li.active > a,
header.sticky nav.header-dark-transparent .navbar-nav > li.active > a,
header.sticky nav.navbar.bootsnav.header-dark-transparent.white-link .navbar-nav > li.active > a {
  color: rgba(255, 255, 255, 0.6);
}
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li.active > a,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li.active > a {
  color: rgba(0, 0, 0, 0.6);
}
header.sticky nav.navbar.white-link .navbar-nav > li.active > a,
header.sticky nav.navbar.bootsnav.white-link ul.nav > li.active > a {
  color: rgba(0, 0, 0, 0.6);
}
header.sticky nav.navbar.header-dark.white-link .navbar-nav > li.active > a,
header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li.active > a {
  color: rgba(255, 255, 255, 0.6);
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a.active,
header.header-appear nav.bootsnav.header-light .navbar-nav > li > a.active,
header nav.navbar .navbar-nav > li > a.active,
nav.navbar.bootsnav ul.nav > li > a.active {
  color: rgba(0, 0, 0, 0.6);
}
header nav.navbar.white-link .navbar-nav > li > a.active,
nav.navbar.bootsnav.white-link ul.nav > li > a.active,
header nav.navbar.bootsnav ul.nav.white-link > li > a.active,
header.header-appear nav.header-dark-transparent .navbar-nav > li > a.active,
header.header-appear nav.header-dark .navbar-nav > li > a.active,
header.sticky nav.header-dark .navbar-nav > li > a.active,
header.sticky nav.header-dark-transparent .navbar-nav > li > a.active {
  color: rgba(255, 255, 255, 0.6);
}
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a.active,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a.active {
  color: rgba(0, 0, 0, 0.6);
}

header.sticky nav.navbar.white-link .navbar-nav > li > a.active,
header.sticky nav.navbar.bootsnav.white-link ul.nav > li > a.active {
  color: rgba(0, 0, 0, 0.6);
}
header.sticky nav.navbar.header-dark.white-link .navbar-nav > li > a.active,
header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li > a.active {
  color: rgba(255, 255, 255, 0.6);
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li.dropdown.on > a,
header.header-appear nav.bootsnav.header-light .navbar-nav > li.dropdown.on > a,
header nav.navbar .navbar-nav > li.dropdown.on > a,
nav.navbar.bootsnav ul.nav > li.dropdown.on > a {
  color: #be1e2d;
}
header nav.navbar.white-link .navbar-nav > li.dropdown.on > a,
nav.navbar.bootsnav.white-link ul.nav > li.dropdown.on > a,
header nav.navbar.bootsnav ul.nav.white-link > li.dropdown.on > a,
header.header-appear nav.header-dark-transparent .navbar-nav > li.dropdown.on > a,
header.header-appear nav.header-dark .navbar-nav > li.dropdown.on > a,
header.sticky nav.header-dark .navbar-nav > li.dropdown.on > a,
header.sticky nav.header-dark-transparent .navbar-nav > li.dropdown.on > a {
  color: #f65f56;
}
header.sticky
  nav.navbar-fixed-top.header-light-transparent.white-link
  .navbar-nav
  > li.dropdown.on
  > a,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li.dropdown.on > a {
  color: rgba(0, 0, 0, 0.6);
}
header.sticky nav.navbar.white-link .navbar-nav > li.dropdown.on > a,
header.sticky nav.navbar.bootsnav.white-link ul.nav > li.dropdown.on > a {
  color: rgba(0, 0, 0, 0.6);
}
header.sticky nav.navbar.header-dark.white-link .navbar-nav > li.dropdown.on > a,
header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li.dropdown.on > a {
  color: rgba(255, 255, 255, 0.6);
}

header {
  width: 100%;
  z-index: 99;
}
.header-with-topbar .top-header-area {
  font-size: 11px;
  position: fixed;
  top: 0;
  z-index: 10005;
  width: 100%;
  transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -ms-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
}
.sticky.header-with-topbar .top-header-area {
  top: -45px;
}

.sticky.header-with-topbar.fixed-topbar .top-header-area {
  top: 0;
}
.header-with-topbar.fixed-topbar nav.navbar.bootsnav.navbar-fixed-top,
.sticky.header-with-topbar.fixed-topbar nav.navbar.bootsnav.navbar-fixed-top,
.sticky.header-with-topbar.header-appear.fixed-topbar nav.navbar.bootsnav.navbar-top {
  top: 36px;
}

header .top-header-area .icon-social-very-small a:last-child i {
  margin-right: 0;
}
header nav .row {
  align-items: center;
  display: -ms-flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  height: auto;
  padding: 0;
}
header nav .row > div:first-child {
  flex: 1 1 auto;
}
header nav.navbar {
  border-radius: 0;
  padding: 0;
}
/*header nav.navbar-default, nav.navbar.bootsnav {border-bottom: 0;}*/
header nav {
  width: 100%;
  top: 0px;
  z-index: 99;
  margin-bottom: 0;
  display: table;
}
header nav .nav-header-container {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}
header nav .navbar-nav > li > a {
  color: #232323;
  font-size: 12px;
  font-weight: 800;
  margin: 0 20px;
  padding: 5px 0px 5px;
  text-transform: uppercase;
  letter-spacing: 0.015em;
}
header nav .navbar-nav > li > a:hover,
header nav .navbar-nav > li > a:focus,
header nav .navbar-nav > li.active > a {
  background: transparent;
}
header nav .navbar-nav > li:last-child a {
  margin-right: 0;
}
.navbar-fixed-bottom,
.navbar-fixed-top {
  z-index: 9999;
}
header nav.navbar-default .navbar-nav > li > a:hover,
header nav.navbar-default .navbar-nav > li > a:focus,
header nav.navbar-default .navbar-nav > li.active > a {
  border-bottom-color: #232323;
}
header nav .navbar-nav.text-normal > li > a {
  font-weight: 600;
  font-size: 12px;
}
.navbar-nav > li {
  position: inherit;
}
.navbar-nav > li.dropdown > .fas {
  display: none;
}
.navbar-nav > li ul {
  margin: 0;
  padding: 0;
}
.navbar-toggle {
  border-radius: 0;
  margin-right: 0;
}
.mobile-toggle {
  display: block;
}
.sidebar-nav .mobile-toggle {
  display: none;
}
.mobile-toggle span {
  display: block;
  width: 16px;
  height: 2px;
  background: #ffffff;
  content: '';
  margin: 3px 0px;
}
.mobile-toggle:hover span,
.white-link .mobile-toggle:hover span {
  background-color: red;
}
.white-link .mobile-toggle span {
  background: #fff;
}
header nav .brand-logo {
  padding-top: 20px;
  padding-bottom: 20px;
}
header.sticky nav .brand-logo {
  padding-top: 15px;
  padding-bottom: 15px;
}
header.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav > li > a,
header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a {
  padding: 20px 0 20px;
}

/* mega menu */
nav .accordion-menu {
  position: inherit;
}
nav.navbar.bootsnav li.dropdown ul li {
  list-style: none;
  padding: 0;
}
nav.navbar.bootsnav li.dropdown .mega-menu-full {
  width: 100%;
  box-sizing: border-box;
  background: rgba(35, 35, 35, 1);
  padding: 45px 50px;
  box-shadow: none;
  border-radius: 0;
  border: 0;
}
nav.navbar.bootsnav li.dropdown .mega-menu-full ul {
  margin: 0;
  list-style: none;
}
nav.navbar.bootsnav li.dropdown .menu-back-div > ul {
  margin: 0 auto;
  width: 1070px;
  display: table;
  background: rgba(35, 35, 35, 1);
}
nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li.dropdown-header {
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 8px;
  white-space: inherit;
  padding: 0 40px;
  border-bottom: none;
  text-transform: uppercase;
}
nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li a {
  text-align: left;
  font-size: 12px;
  color: #9d9d9d;
  padding: 3px 40px;
  text-transform: capitalize;
  margin: 0;
  display: block;
  position: relative;
  overflow: hidden;
  left: 0;
}
nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li.mega-menu-column:first-child a,
nav.navbar.bootsnav
  li.dropdown
  .mega-menu-full
  > ul
  li.mega-menu-column:first-child
  .dropdown-header {
  padding-left: 0;
}
nav.navbar.bootsnav li.dropdown.megamenu-fw .icon-list-menu li > a > i {
  font-size: 14px;
  vertical-align: middle;
  width: 24px;
}
nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}
nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li:last-child {
  border: 0;
}
nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li:last-child img {
  padding-left: 40px;
  background-size: cover;
  background-position: bottom right;
}
nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li > ul {
  margin-top: 18px;
}
nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li > ul:first-child {
  margin-top: 0;
}
nav.navbar.bootsnav li.dropdown .megamenu-fw .icon-list-menu li > a > i {
  font-size: 14px;
  vertical-align: middle;
  width: 24px;
}

nav.navbar.navbar-default ul li.dropdown .dropdown-menu .mega-menu-column li > a.menu-banner-image,
nav.navbar.navbar-default
  ul
  li.dropdown
  .dropdown-menu
  .mega-menu-column
  li
  > a:hover.menu-banner-image {
  left: 0;
  padding: 0;
  margin-bottom: 20px;
}
nav.navbar.navbar-default
  ul
  li.dropdown
  .dropdown-menu
  .mega-menu-column
  li:last-child
  > a.menu-banner-image {
  margin-bottom: 0;
}

/* header center logo */
.nav-left,
.nav-right,
.nav-center {
  display: table-cell;
  vertical-align: middle;
  width: 33.33%;
}
header nav .navbar-nav.navbar-center > li:last-child a {
  margin-right: 20px;
}
.social-icon a {
  margin: 0 15px;
}
.social-icon a:first-child {
  margin-left: 0;
}
.social-icon a:last-child {
  margin-right: 0;
}

header nav.nav-center-logo .row {
  min-height: 0;
  height: auto;
}
header nav.nav-center-logo .row {
  display: block;
}
header nav.nav-center-logo .row > div:first-child {
  flex: none;
}
.nav-center-logo .navbar-nav > li {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
.nav-center-logo .navbar-nav > li > a {
  display: flex;
}
.nav-center-logo .navbar-left {
  margin-left: -15px;
}
.nav-center-logo .navbar-left li:first-child a {
  margin-left: 0;
}
nav.nav-center-logo.navbar.bootsnav li.dropdown ul.dropdown-menu,
nav.bootsnav.brand-center ul.nav > li.dropdown > ul.dropdown-menu {
  margin-top: 0px;
}
.center-logo {
  left: 50%;
  position: absolute;
  margin-top: -14px;
  top: 50%;
  text-align: center;
  transform: translateX(-50%);
  z-index: 1;
  max-width: 20%;
}
nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a {
  text-align: left;
}
header nav.navbar-fixed .nav-header-container {
  width: 100%;
  float: left;
}

header .menu-logo-center .navbar-nav > li.social-links {
  padding: 26px 0;
}
header.sticky .menu-logo-center .navbar-nav > li.social-links {
  padding: 20px 0;
}

/* top logo */
.navbar.navbar-brand-top > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
  margin-left: 0;
}
.navbar-brand-top .navbar-brand {
  height: auto;
}
nav.navbar-brand-top.navbar.navbar-default ul.nav > li > a {
  padding: 20px 0;
}
.navbar-brand-top .navbar-collapse.collapse {
  display: inline-block !important;
  vertical-align: middle;
}

/* center navigation */
.center-nav {
  float: none;
  text-align: center;
}
.center-nav > li {
  float: none;
  display: inline-block;
}
nav.navbar.bootsnav.menu-center ul.nav.navbar-center {
  display: table;
  float: none;
  margin: 0 auto;
  table-layout: fixed;
}

/* simple dropdown menu */
.simple-dropdown.dropdown {
  position: relative;
}
.simple-dropdown .dropdown-menu {
  position: absolute;
  left: 0;
  background: rgba(35, 35, 35, 1);
  min-width: 220px;
  padding: 13px 0 15px;
  box-shadow: none;
}
nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li {
  padding-left: 28px;
  padding-right: 28px;
}
.simple-dropdown .dropdown-menu > li > a {
  font-size: 12px;
  color: #9d9d9d;
}
.simple-dropdown .dropdown-menu > li a {
  padding: 9px 0 7px;
  overflow: hidden;
  position: relative;
  left: 0;
  font-weight: 500;
}
.simple-dropdown .dropdown-menu > li a:hover {
  background-color: #000;
  color: #fff;
}
.simple-dropdown .dropdown-menu > li > a.dropdown-toggle .fas,
nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li.dropdown > a > .fas {
  position: absolute;
  right: 0;
  font-size: 12px;
  margin-top: 0;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li a .fas {
  font-size: 16px;
}
nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul,
nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul > li.dropdown > ul {
  background: rgba(35, 35, 35, 1);
  display: none;
  left: 220px;
  margin: -13px 0 0 1px;
  min-width: 220px;
  white-space: nowrap;
  position: absolute;
  top: 0;
  padding: 13px 0;
  font-weight: 500;
  text-transform: capitalize;
}
.simple-dropdown .dropdown-menu > li.dropdown > ul li a {
  color: #9d9d9d;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
nav.navbar.bootsnav.navbar-default li.dropdown.simple-dropdown > ul.dropdown-menu > li.active > a {
  color: #fff;
}

/* light transparent navigation */
header.header-appear nav.header-light-transparent,
header.sticky nav.navbar-fixed-top.header-light-transparent,
header.sticky nav.navbar-scroll-fixed-top.header-light-transparent {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.6);
}
header.header-appear nav.header-light-transparent .separator-line-verticle-small,
header.header-appear nav.header-light .separator-line-verticle-small {
  background: rgba(0, 0, 0, 0.4);
}
header.header-appear nav.header-light-transparent .header-social-icon a,
header.header-appear nav.header-light .header-social-icon a,
header.header-appear nav.header-light.white-link .header-social-icon a,
header.header-appear nav.header-light-transparent.white-link .header-social-icon a,
header.header-appear nav.header-light-transparent.white-link .header-menu-button a,
header.header-appear nav.header-light-transparent .header-searchbar a,
header.header-appear nav.header-light .header-searchbar a,
header.header-appear nav.header-light.white-link .header-searchbar a,
header.header-appear nav.header-light-transparent.white-link .header-searchbar a {
  color: #232323;
}
header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a,
header.header-appear nav.bootsnav.header-light .navbar-nav > li > a {
  color: #232323;
}
header.header-appear nav.header-light-transparent .header-searchbar,
header.header-appear.header-light-transparent .header-social-icon,
header.header-appear nav.header-light .header-searchbar,
header.header-appear nav.header-light-transparent.white-link .header-social-icon,
header.sticky nav.header-light-transparent .header-searchbar,
header.sticky nav.header-light-transparent .header-social-icon,
header.sticky nav.header-light .header-searchbar,
header.sticky nav.header-light .header-social-icon {
  border-left: 1px solid rgba(0, 0, 0, 0.25);
}
header.header-appear nav.nav-fixed-top.header-light-transparent.white-link .navbar-nav > li > a,
header.header-appear nav.nav-fixed-top.header-light-transparent.white-link .header-social-icon a,
header.header-appear nav.nav-fixed-top.header-light-transparent.white-link .header-searchbar a,
header.header-appear
  nav.nav-scroll-fixed-top.header-light-transparent.white-link
  .navbar-nav
  > li
  > a,
header.header-appear
  nav.nav-scroll-fixed-top.header-light-transparent.white-link
  .header-social-icon
  a,
header.header-appear
  nav.nav-scroll-fixed-top.header-light-transparent.white-link
  .header-searchbar
  a {
  color: #232323;
}
header.sticky nav.header-light-transparent .header-menu-button .mobile-toggle span,
header.sticky nav.header-light .header-menu-button .mobile-toggle span {
  background: #232323;
}

/* dark transparent navigation */
header.header-appear nav.header-dark-transparent,
header.sticky nav.navbar-fixed-top.header-dark-transparent,
header.sticky nav.navbar-scroll-fixed-top.header-dark-transparent {
  background: rgba(23, 23, 23, 0.85);
}
header.header-appear nav.header-dark-transparent .separator-line-verticle-small,
header.header-appear nav.header-dark .separator-line-verticle-small {
  background: #ffffff;
}
header.header-appear nav.header-dark-transparent .navbar-nav > li > a,
header.header-appear nav.header-dark .navbar-nav > li > a,
header.sticky nav.header-dark .navbar-nav > li > a,
header.sticky nav.header-dark-transparent .navbar-nav > li > a {
  color: #ffffff;
}
header.header-appear nav.header-dark-transparent .header-social-icon a,
header.header-appear nav.header-dark .header-social-icon a,
header.sticky nav.header-dark .header-social-icon a,
header.sticky nav.header-dark-transparent .header-social-icon a {
  color: #ffffff;
}
header.header-appear nav.header-dark-transparent .search-button,
header.header-appear nav.header-dark-transparent .right-menu-button,
header.header-appear nav.header-dark .search-button,
header.header-appear nav.header-dark .right-menu-button,
header.sticky nav.header-dark .search-button,
header.sticky nav.header-dark .right-menu-button,
header.sticky nav.header-dark-transparent .search-button,
header.sticky nav.header-dark-transparent .right-menu-button {
  color: #ffffff;
}
header.sticky nav.header-dark-transparent .header-social-icon,
header.sticky nav.header-dark .header-social-icon,
header.sticky nav.header-dark-transparent .header-searchbar,
header.sticky nav.header-dark .header-searchbar {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}
header.sticky nav.header-dark-transparent .mobile-toggle span,
header.sticky .header-dark .mobile-toggle span {
  background: #fff;
}
header.sticky nav.header-dark-transparent .mobile-toggle:hover span,
header.sticky .header-dark .mobile-toggle:hover span {
  background: red;
}

/* light navigation */
header.header-appear nav.header-light,
header.sticky nav.navbar-fixed-top.header-light,
header.sticky nav.navbar-scroll-fixed-top.header-light {
  background-color: #ffffff;
  box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.6);
}

/* dark navigation */
header.header-appear nav.header-dark,
header.sticky nav.navbar-fixed-top.header-dark,
header.sticky nav.navbar-scroll-fixed-top.header-dark {
  background-image: linear-gradient(to right, #d84449, #660099);
}

/* collepsed menu icon */
.navbar-default .navbar-toggle .icon-bar,
header.sticky .navbar-default.header-light .navbar-toggle .icon-bar,
header.sticky .navbar-default.header-light-transparent .navbar-toggle .icon-bar {
  background: #ffffff;
}
.navbar-default.white-link .navbar-toggle .icon-bar,
header.sticky .navbar-default.header-dark .navbar-toggle .icon-bar,
header.sticky .navbar-default.header-dark-transparent .navbar-toggle .icon-bar {
  background: #fff;
}

/* hamburger menu */
nav.navbar.bootsnav .btn-hamburger {
  padding: 0;
}
nav.navbar.bootsnav .btn-hamburger .navbar-toggle {
  margin: 0;
  top: -2px;
}

.hamburger-menu-wrepper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: -10005;
  padding: 55px 80px;
  display: table;
  opacity: 0;
  visibility: collapse;
}
.menu-middle {
  display: table;
  height: 100%;
  width: 65%;
  margin: 0 auto;
  opacity: 0;
}
.hamburger-menu-links {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 52px;
  line-height: 55px;
  opacity: 0;
}
.hamburger-menu-links li {
  float: left;
  margin-bottom: 36px;
  width: 50%;
}
.hamburger-menu-links li a {
  color: #fff;
  position: relative;
}
.hamburger-menu-links li.open > a {
  color: #ff2149;
}
.hamburger-menu-links li a:hover,
.follow-box a:hover {
  color: #ff2149;
}
.animation-box {
  position: absolute;
  top: 5%;
  height: 100%;
  width: 100%;
  left: 0;
  transform: perspective(600px) rotateX(-20deg);
  -weblit-transform: perspective(600px) rotateX(-20deg);
  -moz-transform: perspective(600px) rotateX(-20deg);
  -ms-transform: perspective(600px) rotateX(-20deg);
  -o-transform: perspective(600px) rotateX(-20deg);
}
.hamburger-menu.show-menu .hamburger-menu-wrepper {
  z-index: 10004;
  opacity: 1;
  visibility: visible;
}
.hamburger-menu.show-menu .animation-box {
  top: 0;
  transform: perspective(600px) rotateX(0deg);
  -webkit-transform: perspective(600px) rotateX(0deg);
  -moz-transform: perspective(600px) rotateX(0deg);
  -o-transform: perspective(600px) rotateX(0deg);
  -ms-transform: perspective(600px) rotateX(0deg);
  opacity: 1;
}
.hamburger-menu.show-menu .hamburger-menu-links,
.hamburger-menu.show-menu .menu-middle {
  opacity: 1;
}
.hamburger-menu.show-menu .btn-hamburger .mobile-toggle,
.hamburger-menu .btn-hamburger .close-menu {
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
}
.hamburger-menu.show-menu .btn-hamburger .close-menu,
.hamburger-menu .btn-hamburger .mobile-toggle {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
}
.hamburger-menu .hamburger-menu-wrepper .btn-hamburger {
  border-color: #fff;
  height: 18px;
  position: absolute;
  width: 18px;
  z-index: 99;
  padding: 0;
  margin: -27px 0 0 -15px;
  opacity: 0;
}
.hamburger-menu.show-menu .hamburger-menu-wrepper .btn-hamburger {
  opacity: 1;
  right: 40px;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
}
.hamburger-menu .close-button-menu {
  float: none;
  height: auto;
  margin: 0;
  padding: 0;
  position: inherit;
  right: -26px;
  top: 44px;
  width: auto;
}
.hamburger-menu .close-button-menu:after,
.hamburger-menu .close-button-menu:before {
  background: #fff;
  width: 22px;
  right: 26px;
  top: -34px;
}
.hamburger-menu-links li.dropdown > a:after {
  content: '\f107';
  display: inline-block;
  position: absolute;
  right: -60px;
  top: 15px;
  font-family: Font Awesome\5 Free;
  font-weight: 900;
  font-size: 42px;
}

/* header style two */
.header-with-topbar .navbar-top,
.header-with-topbar .navbar-fixed-top {
  top: 36px;
  background: #fff;
  box-shadow: 0 4px 10px -10px rgba(0, 0, 0, 0.6);
}
.header-with-topbar.sticky .navbar-top {
  top: -160px;
}
.header-with-topbar.sticky.header-appear .navbar-top {
  top: 0;
}

/* full width light transparent navigation */
header nav.nav-full-width {
  width: 100%;
}
.close-button-menu {
  float: right;
  padding: 10px;
  background-color: transparent;
  color: #ffffff;
  border: 0;
  position: absolute;
  height: 40px;
  width: 40px;
  outline: none;
  top: 20px;
  right: 20px;
}
.full-width-pull-menu .dropdown-toggle {
  position: relative;
}
.full-width-pull-menu span.dropdown-toggle {
  position: absolute;
  right: 30px;
  width: 40px;
  height: 40px;
  text-align: center;
  top: 43px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.full-width-pull-menu .dropdown .dropdown-toggle:before {
  content: '';
  display: block;
  position: absolute;
  right: 20px;
  top: 15px;
  height: 11px;
  width: 1px;
  background: #232323;
}
.full-width-pull-menu .dropdown.open .dropdown-toggle:before {
  height: 0;
}
.full-width-pull-menu .dropdown .dropdown-toggle:after {
  content: '';
  display: block;
  position: absolute;
  right: 15px;
  top: 20px;
  height: 1px;
  width: 11px;
  background: #232323;
}
.full-width-pull-menu .dropdown ul {
  background-color: inherit;
}

/* full width navigation style 1 */
.full-width-pull-menu .link-style-1 .link-style-1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.full-width-pull-menu .link-style-1 ul li {
  margin: 20px 0;
  display: table;
}
.full-width-pull-menu .link-style-1 ul li a {
  background: inherit;
  color: #232323;
  font-size: 22px;
  line-height: 26px;
  position: relative;
  padding-left: 35px;
}
.full-width-pull-menu .link-style-1 ul li:hover > a,
.full-width-pull-menu .link-style-1 ul li.open > a {
  color: #ff214f;
}
.full-width-pull-menu .link-style-1 ul li:hover > a:after,
.full-width-pull-menu .link-style-1 ul li.open > a:after {
  background: #ff214f;
}
.full-width-pull-menu .link-style-1 ul li a:after {
  background: #dbdbdb;
  content: '';
  display: inline-block;
  height: 4px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 18px;
}
.full-width-pull-menu .link-style-1 .dropdown.open ul {
  display: block;
}
.full-width-pull-menu .link-style-1 .dropdown ul {
  position: inherit;
  left: 0;
  right: 0;
  padding: 10px 30px 0;
  background-color: inherit;
  border: 0;
  float: none;
  box-shadow: none;
  margin: 0;
}
.full-width-pull-menu .link-style-1 ul li li a {
  font-size: 18px;
  line-height: 22px;
  padding-left: 30px;
}
.full-width-pull-menu .link-style-1 .dropdown ul li a:hover,
.dropdown ul li a:focus {
  background-color: inherit;
}
.full-width-pull-menu .link-style-1 .dropdown ul li {
  margin: 5px;
}
.full-width-pull-menu .link-style-1 .dropdown ul li:last-child {
  margin-bottom: 0;
}

/* full width navigation style 2 */
.full-width-pull-menu .link-style-2 .link-style-2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.full-width-pull-menu .link-style-2 ul li {
  display: table;
  width: 90%;
}
.full-width-pull-menu .link-style-2 ul li a {
  background: inherit;
  color: #232323;
  font-size: 48px;
  line-height: 85px;
  position: relative;
}
.full-width-pull-menu .link-style-2 ul li:hover > a,
.full-width-pull-menu .link-style-2 ul li.open > a {
  color: #ff214f;
}
.full-width-pull-menu .link-style-2 ul li.open > a:after {
  background: #ff214f;
}
.full-width-pull-menu .link-style-2 .dropdown ul {
  display: block !important;
  height: 0;
  overflow: hidden;
}
.full-width-pull-menu .link-style-2 .dropdown ul {
  position: inherit;
  left: 0;
  right: 0;
  padding: 0;
  background-color: inherit;
  border: 0;
  float: none;
  box-shadow: none;
  margin: 0;
}
.full-width-pull-menu .link-style-2 .dropdown.open ul {
  display: block;
  height: 100%;
}
.full-width-pull-menu .link-style-2 .dropdown ul li a {
  font-size: 14px;
  line-height: 18px;
  color: #757575;
  padding: 0;
  margin: 0;
}
.full-width-pull-menu .link-style-2 .dropdown ul li {
  border-bottom: 1px solid #e3e3e3;
  padding: 7px 0;
}
.full-width-pull-menu .link-style-2 .dropdown ul li:last-child {
  border-bottom: 1px solid transparent;
}
.full-width-pull-menu .link-style-2 .dropdown ul li a:hover,
.dropdown ul li a:focus {
  background-color: inherit;
}
.full-width-pull-menu .link-style-2 .dropdown ul li ul.sub-menu {
  padding-left: 15px;
  padding-top: 2px;
}
.full-width-pull-menu .link-style-2 .dropdown ul li ul.sub-menu li {
  border-bottom: 0;
  padding: 5px 0 0 0;
}
.full-width-pull-menu .link-style-2 .dropdown ul li:last-child {
  margin-bottom: 0;
}
.full-width-pull-menu .link-style-2 .icon-social-medium a:first-child {
  margin-left: 0;
}
.full-width-pull-menu .link-style-2 .icon-social-medium a:last-child {
  margin-right: 0;
}
.close-button-menu:after,
.close-button-menu:before {
  content: '';
  display: block;
  background: #ffffff;
  height: 2px;
  width: 18px;
  position: absolute;
}
.close-button-menu:after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.close-button-menu:before {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

/* full navigation */
.show-menu .menu-wrap,
.menu-wrap {
  left: auto;
}
.show-menu .menu-wrap {
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  opacity: 1;
  visibility: visible;
  z-index: 10001;
}
.menu-wrap {
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  width: 100%;
  z-index: -10001;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

/* container fluid navigation padding */
header nav.nav-box-width {
  padding: 0 50px;
}

/* hamburger menu right side */
.big-menu-right {
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  padding: 0 5%;
  opacity: 0;
  transform: translateX(30%) scaleX(0.6);
  -webkit-transform: translateX(30%) scaleX(0.6);
  -moz-transform: translateX(30%) scaleX(0.6);
  -o-transform: translateX(30%) scaleX(0.6);
  -ms-transform: translateX(30%) scaleX(0.6);
  transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  z-index: -1;
}
.big-menu-links {
  list-style: none;
  margin: 0;
  padding: 0;
}
.big-menu-links li {
  margin-bottom: 15px;
  display: block;
  width: 100%;
  float: left;
}
.big-menu-links li a {
  font-size: 50px;
  line-height: 1.5;
  color: #676767;
  float: left;
}
.big-menu-links li a:after {
  width: 0;
  background: #ff214f;
  height: 3px;
  content: '';
  display: block;
  transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
}
.big-menu-links li a:hover {
  color: #ddd;
}
.big-menu-links li a:hover:after {
  width: 100px;
  transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
}
.big-menu-right.open {
  transform: translateX(0) scaleX(1);
  -moz-transform: translateX(0) scaleX(1);
  -webkit-transform: translateX(0) scaleX(1);
  -o-transform: translateX(0) scaleX(1);
  -ms-transform: translateX(0) scaleX(1);
  opacity: 1;
  z-index: 9999;
}
.big-menu-close {
  background: #fff;
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  color: #232323;
}

/* dropdown menu */
nav.navbar.navbar-default ul.nav > li > a {
  padding: 26px 0;
  background: transparent;
  border-color: transparent;
  font-weight: 600;
}
nav.navbar.navbar-default ul.nav > li.dropdown > a.dropdown-toggle::after,
nav.navbar.sidebar-nav ul.nav > li.dropdown > a.dropdown-toggle::after {
  content: '';
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-top: 0;
}
nav.navbar.navbar-default ul li.dropdown ul.dropdown-menu > li:last-child {
  border-bottom: 0;
  border-bottom: 0;
}
nav.navbar.navbar-default ul li.dropdown .dropdown-menu li > a:hover {
  color: #fff;
  background: transparent;
  left: 5px;
}
nav.navbar.navbar-default ul li.dropdown .dropdown-menu li.active > a {
  color: #fff;
}
nav.navbar.navbar-default ul.menu-links li.dropdown ul.dropdown-menu li > a:hover,
nav.navbar.navbar-default ul.menu-links li.dropdown ul.dropdown-menu li.active > a {
  color: #232323;
}
.dropdown ul.second-level {
  left: 270px;
  top: 0;
  height: 100%;
}
.dropdown ul.third-level {
  left: 530px;
  top: 0;
  height: 100%;
}
nav.navbar ul.nav .dropdown-menu.second-level .dropdown-menu {
  margin-top: 0;
}
.navbar-default ul li li.dropdown ul {
  display: none;
}
.navbar-default ul li li.dropdown.on > ul {
  display: block;
  position: absolute;
  left: 100%;
  margin-top: 0;
  padding: 0;
  top: 0;
}
.navbar-default ul li li.dropdown.on > ul > li.dropdown.on > ul {
  display: block;
  position: absolute;
  left: 100%;
  margin-top: 0;
  padding: 0;
  top: 0;
}
.navbar-default ul li li.dropdown ul.dropdown-menu {
  position: absolute;
  left: 100%;
  margin-top: 0;
  padding: 0;
  top: 0;
  box-shadow: none;
}
.navbar-default ul li li.dropdown ul.dropdown-menu li {
  padding: 0;
}
.dropdown-menu .dropdown a {
  position: relative;
}

/* navigation left sidebar */
header .menu-wrap .widget {
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
}
.nav.navbar-left-sidebar {
  transition: all 0s ease-in-out 0s;
  -webkit-transition: all 0s ease-in-out 0s;
  -moz-transition: all 0s ease-in-out 0s;
  -ms-transition: all 0s ease-in-out 0s;
  -o-transition: all 0s ease-in-out 0s;
  overflow-y: auto;
}
.left-nav-on .sidebar-part2 .sidebar-middle .sidebar-middle-menu .nav {
  overflow-x: hidden;
}
.nav.navbar-left-sidebar li {
  display: inline-block;
  width: 100%;
  padding: 0 45px;
}
.nav.navbar-left-sidebar li a,
nav.navbar.bootsnav.navbar-left-sidebar ul.nav > li > a {
  font-size: 11px;
  text-transform: uppercase;
  color: #939393;
  background: transparent !important;
  font-weight: 600;
  border-bottom: 1px solid #e5e5e5 !important;
  padding: 15px 0;
  margin: 0;
}
nav.navbar.sidebar-nav .navbar-left-sidebar li.dropdown .third-level a > [class^='ti-'],
nav.navbar.sidebar-nav .navbar-left-sidebar li.dropdown .third-level a > [class*=' ti-'] {
  margin-right: 5px;
}
.nav.navbar-left-sidebar li:last-child a {
  border-bottom: none;
}
.nav.navbar-left-sidebar li a:hover {
  color: #fff;
}
.nav.navbar-left-sidebar li a .nav-caret {
  height: 35px;
  text-align: right;
  width: 35px;
}
.nav.navbar-left-sidebar .dropdown .second-level {
  left: 290px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s; /* display: block !important; visibility: hidden;*/
}
.nav.navbar-left-sidebar .dropdown.on .second-level {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s; /*  visibility: visible;*/
}
.nav.navbar-left-sidebar .second-level .dropdown .third-level {
  left: 570px;
  opacity: 0 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s; /*display: block !important; visibility: hidden;*/
}
.nav.navbar-left-sidebar .second-level .dropdown.on .third-level {
  opacity: 1 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  opacity: 1 !important; /* visibility: visible;*/
}
.nav.navbar-left-sidebar .dropdown-menu .dropdown > a:after {
  display: none;
}

/* pull left sidebar */
.cbp-spmenu {
  background: #fff;
  position: fixed;
  z-index: 1;
}
.cbp-spmenu-vertical {
  width: 455px;
  height: 100%;
  top: 0;
  z-index: 10005;
}
.cbp-spmenu-right {
  right: -455px;
}
.cbp-spmenu-left {
  left: -100%;
}
.cbp-spmenu-left.cbp-spmenu-open {
  left: 0;
}
.cbp-spmenu-right.cbp-spmenu-open {
  right: 0px;
  overflow-x: auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: #141414;
}
.cbp-spmenu-push {
  overflow-x: hidden;
  position: relative;
  left: 0;
}
.cbp-spmenu,
.cbp-spmenu-push {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* custome scrollbar */
.cbp-spmenu-right::-webkit-scrollbar,
.navbar-left-sidebar::-webkit-scrollbar {
  width: 5px;
}
.cbp-spmenu-right::-webkit-scrollbar-track,
.navbar-left-sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.cbp-spmenu-right::-webkit-scrollbar-thumb,
.navbar-left-sidebar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* sidebar navigation */
nav.navbar.sidebar-nav .container {
  display: table-cell;
  height: 75px;
  position: relative;
  vertical-align: top;
}
nav.navbar.bootsnav.sidebar-nav .navbar-left-sidebar > li > a {
  color: #626262;
  border-color: #ededed;
  padding: 15px 0;
}
nav.navbar.sidebar-nav .navbar-left-sidebar li a:hover,
nav.navbar.bootsnav.sidebar-nav ul.nav.navbar-left-sidebar li.active > a {
  color: #000;
}
.sidebar-nav .logo-holder {
  text-align: center;
  min-height: 107px;
}
.sidebar-nav .footer-holder {
  text-align: center;
}
nav.navbar .toggle-button {
  background: #000;
  border: medium none;
  height: auto;
  margin: 0;
  padding: 0;
  width: auto;
}
.sidebar-nav .dropdown-menu {
  box-shadow: none;
  height: 100%;
  left: 270px;
  padding: 0;
  position: fixed;
  top: 0;
  width: 240px;
  padding: 80px 0; /*overflow-y: auto; overflow: visible;*/
}
nav.navbar.sidebar-nav li.dropdown ul.dropdown-menu {
  border: none;
}
nav.navbar.bootsnav.sidebar-nav li.dropdown ul.dropdown-menu {
  padding-top: 160px;
}
.sidebar-nav .dropdown-menu li > a {
  margin: 0 45px;
}
nav.navbar.sidebar-nav {
  border: none;
  display: inline-table;
  width: 290px;
  border-radius: 0;
  position: fixed;
  top: 0;
  z-index: 10005;
  height: 100%;
  padding: 50px 0px 25px;
  left: 0;
}
.sidebar-wrapper {
  padding-left: 290px;
}

/* sidebar navigation style 1 */
.sidebar-nav-style-1 .dropdown .second-level {
  top: 0;
  left: 290px;
  width: 280px;
}
.sidebar-nav-style-1 .dropdown .second-level {
  background: #fbfbfb;
}
.sidebar-nav-style-1 .dropdown .third-level {
  top: 0;
  left: 570px;
  width: 280px;
  background: #f3f3f3;
}
/*.sidebar-nav-style-1 .dropdown ul.second-level li ul { display: none; opacity: 0}*/

/* sidebar navigation style 2 */
.left-nav-sidebar {
  padding-left: 90px;
  transition: all 0.3s ease 0s;
}
.left-nav {
  position: fixed;
  top: 0;
  width: auto;
  left: 0;
  height: 100%;
  background-color: #fff;
  z-index: 99999;
  transition: all 0.3s ease 0s;
}
.sidebar-part1 {
  position: absolute;
  top: 0;
  width: 90px;
}
.sidebar-part1 .logo img {
  width: 100%;
}
.sidebar-part2 {
  padding: 0 0 0 115px;
  border-right: 1px solid #dfdfdf;
  width: 410px;
  height: 100%;
  position: fixed;
  background-color: #fff;
  z-index: -1;
  left: -370px;
}
.sidebar-part3 {
  position: absolute;
  bottom: 0;
}
.left-nav-on .sidebar-part2 {
  left: 0;
}
.sidebar-part2:before {
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
  content: '';
  position: absolute;
  bottom: 75px;
  left: 0;
  display: inline-block;
}
.sidebar-part2 .sidebar-middle {
  padding: 180px 0;
}
.sidebar-part2 .sidebar-middle-menu {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}
.bottom-menu-icon {
  width: 90px;
  padding: 22px 30px;
  text-align: center;
}
header .sidebar-part2 nav.navbar.bootsnav ul > li > a {
  font-size: 36px;
  display: block;
  padding: 0;
  color: #0f0f0f;
  text-transform: none;
  margin: 0;
  line-height: 40px;
  font-weight: 600;
  position: relative;
}
header .sidebar-part2 nav.navbar.bootsnav ul > li > a > .fas {
  font-size: 25px;
  top: 10px;
  position: absolute;
  right: 18px;
}
header .sidebar-part2 nav.navbar.bootsnav ul > li > .second-level > li > a > .fas {
  font-size: 14px;
  line-height: 30px;
}
.sidebar-part2 ul > li {
  margin: 0;
  float: none;
  padding: 15px 0;
  width: 291px;
  word-break: break-all;
  position: initial;
}
.sidebar-part2 ul li a:hover {
  background-color: inherit;
}
.sidebar-part2 nav.navbar.bootsnav {
  border-bottom: 0;
  position: inherit;
}
.sidebar-part2 .right-bg {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 90px;
}
.sidebar-part2 .right-bg:before {
  background-color: #dfdfdf;
  bottom: 75px;
  content: '';
  display: inline-block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.sidebar-part2 .right-bg img {
  position: absolute;
  top: 50%;
  left: 37px;
  transform: translateY(-100px);
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  -o-transform: translateY(-100px);
}
header .sidebar-part2 nav.navbar.bootsnav ul li ul li a {
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #f0f0f0;
  color: #939393;
  font-weight: 500;
}
header .sidebar-part2 nav.navbar.bootsnav ul li.dropdown > a,
.sidebar-nav-style-1 .navbar-left-sidebar li.dropdown > a {
  padding-right: 40px;
}
header .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover {
  border-bottom: 1px solid #ff214f;
  background: inherit;
}
header .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li:last-child > a:hover {
  border-bottom: 0;
}
header .sidebar-part2 nav.navbar.bootsnav ul li ul li a > i {
  vertical-align: middle;
  margin-right: 5px;
}
header .sidebar-part2 nav.navbar.bootsnav ul li ul li a:hover {
  color: #ff214f;
}
header .sidebar-part2 nav.navbar.bootsnav ul li ul li {
  margin: 0;
  padding: 0 30px;
  width: 100%;
  word-break: normal;
}
header .sidebar-part2 nav.navbar.bootsnav ul li ul li.dropdown {
  border-left: 0;
}
.sidebar-part2 .dropdown ul.second-level,
.sidebar-part2 .dropdown ul.third-level {
  border-left: 1px solid #eee !important;
}

.sidebar-part2 .dropdown ul.second-level {
  left: 405px;
  opacity: 0 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.sidebar-part2 .dropdown.on ul.second-level {
  opacity: 1 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.sidebar-part2 .dropdown ul.second-level .dropdown ul.third-level {
  left: 650px;
  opacity: 0 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.sidebar-part2 .dropdown ul.second-level .dropdown.on ul.third-level {
  opacity: 1 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
  box-shadow: none;
  height: 100%;
  position: fixed;
  top: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  width: 250px;
  padding: 180px 0;
  background: #fff;
}

.bottom-menu-icon a {
  font-size: 24px;
  width: 23px;
  height: 18px;
  display: inline-block;
  position: relative;
}
header .sidebar-part2 nav.navbar.bootsnav ul.second-level li.active > a,
header .sidebar-part2 nav.navbar.bootsnav ul.second-level li.active ul li.active > a {
  color: #ff214f;
}

.sidebar-part2 .navbar-collapse.collapse {
  overflow-y: auto !important;
}

.nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 23px;
  background: #232323;
  border-radius: 0;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.nav-icon:hover span,
.nav-icon.active span {
  background-color: red;
}
.nav-icon span:nth-child(1) {
  top: 0px;
}
.nav-icon span:nth-child(2),
.nav-icon span:nth-child(3) {
  top: 8px;
}
.nav-icon span:nth-child(4) {
  top: 16px;
}
.nav-icon.active span:nth-child(1) {
  top: 8px;
  width: 0%;
  left: 50%;
}
.nav-icon.active span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.nav-icon.active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.nav-icon.active span:nth-child(4) {
  top: 8px;
  width: 0%;
  left: 50%;
}

nav.navbar.brand-center ul.nav > li > a {
  padding: 26px;
  margin: 0;
}
header.sticky nav.navbar.brand-center ul.nav > li > a {
  padding: 20px;
  margin: 0 20px;
}

.sidebar-middle ::-webkit-scrollbar,
.sidebar-nav-style-1 ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
}
.sidebar-middle ::-webkit-scrollbar-thumb,
.sidebar-nav-style-1 ::-webkit-scrollbar-thumb {
  background-color: #9e9f9f;
}
.navbar-collapse,
.sub-menu {
  -webkit-overflow-scrolling: auto;
  -moz--webkit-overflow-scrolling: auto;
  -ms--webkit-overflow-scrolling: auto;
  -o--webkit-overflow-scrolling: auto;
}
.left-menu-inner {
  overflow-y: auto;
  overflow-x: hidden;
}
.nav.navbar-left-sidebar > li > ul li a {
  display: block;
  font-weight: 500;
}

/* navigation widget */
.menu-wrap .widget {
  display: inline-block;
  width: 100%;
}

/* ===================================
    Page title
====================================== */

/* page title font size */
.page-title-extra-small h1 {
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}
.page-title-small h1 {
  font-size: 20px;
  line-height: 26px;
}
.page-title-medium h1 {
  font-size: 32px;
  line-height: 37px;
}
.page-title-large h1 {
  font-size: 46px;
  line-height: 49px;
}
.page-title-extra-large h1 {
  font-size: 55px;
  line-height: 50px;
}

/* breadcrumb */
.breadcrumb {
  background: transparent;
  border-radius: 0;
  padding: 0;
}
.breadcrumb ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.breadcrumb ul li {
  display: inline-block;
}
.breadcrumb ul > li:after {
  content: '\f111';
  font-family: Font Awesome\5 Free;
  font-weight: 900;
  padding: 0 11px 0 14px;
  display: inline-block;
  vertical-align: middle;
  font-size: 5px;
  opacity: 0.6;
}
.breadcrumb ul > li:last-child:after {
  border-right: 0;
  content: '';
  padding: 0;
}

/* ===================================
    Swiper carousel
====================================== */

.swiper-container {
  overflow: hidden;
  position: relative;
}
.swiper-pagination-bullet-active {
  background: #232323;
}
.swiper-pagination {
  width: 100%;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 35px;
  width: 100%;
}
.swiper-pagination-bullet {
  height: 9px;
  width: 9px;
}
.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}
.swiper-pagination-bottom .swiper-wrapper {
  margin: 0 0 35px 0;
}
.swiper-pagination-bottom.swiper-container-horizontal .swiper-wrapper {
  margin: 0 0 95px 0;
}
.swiper-slider-second {
  text-align: center;
}

/* swiper bottom scrollbar full */
.swiper-bottom-scrollbar-full.swiper-container {
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in-out 0.7s;
  -webkit-transition: all 0.3s ease-in-out 0.7s;
  -moz-transition: all 0.3s ease-in-out 0.7s;
  -ms-transition: all 0.3s ease-in-out 0.7s;
  -o-transition: all 0.3s ease-in-out 0.7s;
  width: 100%;
}
.swiper-bottom-scrollbar-full .swiper-scrollbar {
  left: 0;
  opacity: 1;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-radius: 0;
  height: 2px;
}
.swiper-bottom-scrollbar-full .swiper-scrollbar-drag {
  border-radius: 0;
  background: #ff214f;
}
.swiper-bottom-scrollbar-full .swiper-scrollbar {
  background: rgba(255, 255, 255, 0.2);
}
.swiper-bottom-scrollbar-full .swiper-slide img {
  max-height: 450px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  opacity: 0.8;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
}
.swiper-bottom-scrollbar-full .swiper-slide:hover img {
  opacity: 1;
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
  filter: contrast(130%);
  -webkit-filter: contrast(130%);
  -moz-filter: contrast(130%);
  -ms-filter: contrast(130%);
  -o-filter: contrast(130%);
  max-height: 500px;
}
.swiper-bottom-scrollbar-full .swiper-slide .hover-title-box {
  visibility: hidden;
}
.swiper-bottom-scrollbar-full .swiper-slide:hover {
  z-index: 1;
}
.swiper-bottom-scrollbar-full .swiper-slide:hover .hover-title-box {
  right: -160px;
  opacity: 1;
  visibility: visible;
}
.swiper-bottom-scrollbar-full .bottom-text {
  position: absolute;
  bottom: 50px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.swiper-bottom-scrollbar-full .swiper-slide:hover .bottom-text {
  opacity: 0.7;
  letter-spacing: 5px;
  left: 4px;
}

/* swiper vertical pagination */
.swiper-vertical-pagination {
  width: 100%;
  height: 100%;
  position: absolute;
}
.swiper-vertical-pagination .swiper-slide {
  text-align: center;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-vertical-pagination > .swiper-pagination-bullets {
  right: 60px;
}
.swiper-vertical-pagination .swiper-pagination {
  width: auto;
}
.swiper-vertical-pagination .swiper-slide .swiper-bottom-content {
  opacity: 0;
  transform: translate3d(0px, 150px, 0px);
  -webkit-transform: translate3d(0px, 150px, 0px);
  -moz-transform: translate3d(0px, 150px, 0px);
  -ms-transform: translate3d(0px, 150px, 0px);
  -o-transform: translate3d(0px, 150px, 0px);
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}
.swiper-vertical-pagination .swiper-slide-active .swiper-bottom-content {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate3d(0px, 0px, 0px);
  transition: all 0.3s ease-in-out 0.3s;
  -webkit-transition: all 0.3s ease-in-out 0.3s;
  -moz-transition: all 0.3s ease-in-out 0.3s;
  -ms-transition: all 0.3s ease-in-out 0.3s;
  -o-transition: all 0.3s ease-in-out 0.3s;
}
.swiper-vertical-pagination .box-arrow {
  position: absolute;
  right: -45px;
  width: 90px;
  height: 90px;
}

/* swiper auto width */
.swiper-auto-slide .swiper-slide {
  width: 60%;
}
.swiper-auto-slide.swiper-container {
  height: calc(100% - 200px);
  top: 100px;
  margin: 0 auto;
  overflow: visible;
  position: absolute;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transition: all 0.3s ease-in-out 0.7s;
  -webkit-transition: all 0.3s ease-in-out 0.7s;
  -moz-transition: all 0.3s ease-in-out 0.7s;
  -ms-transition: all 0.3s ease-in-out 0.7s;
  -o-transition: all 0.3s ease-in-out 0.7s;
  width: 100%;
}
.swiper-auto-slide .swiper-scrollbar {
  left: 20%;
  opacity: 1;
  position: absolute;
  width: 60%;
  bottom: -50px;
  border-radius: 0;
  height: 1px;
}
.swiper-auto-slide .swiper-scrollbar-drag {
  border-radius: 0;
  background: transparent;
}
.swiper-auto-slide .swiper-scrollbar-drag:before {
  cursor: move;
  position: absolute;
  content: '';
  background: #ff214f;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  top: -6px;
  left: calc(50% - 13px);
}
.swiper-auto-slide .swiper-slide {
  filter: contrast(130%);
  -webkit-filter: contrast(130%);
  -moz-filter: contrast(130%);
  -ms-filter: contrast(130%);
  -o-filter: contrast(130%);
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}
.swiper-auto-slide .swiper-slide.swiper-slide-active {
  filter: contrast(0);
  -webkit-filter: contrast(0);
  -moz-filter: contrast(0);
  -ms-filter: contrast(0);
  -o-filter: contrast(0);
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.swiper-auto-slide .swiper-slide .absolute-middle-center h2 {
  opacity: 0;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transform: translateY(-60px);
  -webkit-transform: translateY(-60px);
  -moz-transform: translateY(-60px);
  -ms-transform: translateY(-60px);
  -o-transform: translateY(-60px);
}
.swiper-auto-slide .swiper-slide.swiper-slide-active .absolute-middle-center h2 {
  opacity: 1;
  transition: all 0.3s ease-in-out 0.7s;
  -webkit-transition: all 0.3s ease-in-out 0.7s;
  -moz-transition: all 0.3s ease-in-out 0.7s;
  -ms-transition: all 0.3s ease-in-out 0.7s;
  -o-transition: all 0.3s ease-in-out 0.7s;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
}
.swiper-auto-slide .swiper-slide span {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.swiper-auto-slide .swiper-slide.swiper-slide-active span {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

/* swiper auto width */
.swiper-auto-slide .swiper-slide {
  width: 60%;
}
.swiper-auto-slide.swiper-container {
  height: calc(100% - 200px);
  top: 100px;
  margin: 0 auto;
  overflow: visible;
  position: absolute;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transition: all 0.3s ease-in-out 0.7s;
  -webkit-transition: all 0.3s ease-in-out 0.7s;
  -moz-transition: all 0.3s ease-in-out 0.7s;
  -ms-transition: all 0.3s ease-in-out 0.7s;
  -o-transition: all 0.3s ease-in-out 0.7s;
  width: 100%;
}
.swiper-auto-slide .swiper-scrollbar {
  left: 20%;
  opacity: 1;
  position: absolute;
  width: 60%;
  bottom: -50px;
  border-radius: 0;
  height: 1px;
}
.swiper-auto-slide .swiper-scrollbar-drag {
  border-radius: 0;
  background: transparent;
}
.swiper-auto-slide .swiper-scrollbar-drag:before {
  cursor: move;
  position: absolute;
  content: '';
  background: #ff214f;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  top: -6px;
  left: calc(50% - 13px);
}
.swiper-auto-slide .swiper-slide {
  filter: contrast(130%);
  -webkit-filter: contrast(130%);
  -moz-filter: contrast(130%);
  -ms-filter: contrast(130%);
  -o-filter: contrast(130%);
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}
.swiper-auto-slide .swiper-slide.swiper-slide-active {
  filter: contrast(0);
  -webkit-filter: contrast(0);
  -moz-filter: contrast(0);
  -ms-filter: contrast(0);
  -o-filter: contrast(0);
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.swiper-auto-slide .swiper-slide .absolute-middle-center h2 {
  opacity: 0;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transform: translateY(-60px);
  -webkit-transform: translateY(-60px);
  -moz-transform: translateY(-60px);
  -ms-transform: translateY(-60px);
  -o-transform: translateY(-60px);
}
.swiper-auto-slide .swiper-slide.swiper-slide-active .absolute-middle-center h2 {
  opacity: 1;
  transition: all 0.3s ease-in-out 0.7s;
  -webkit-transition: all 0.3s ease-in-out 0.7s;
  -moz-transition: all 0.3s ease-in-out 0.7s;
  -ms-transition: all 0.3s ease-in-out 0.7s;
  -o-transition: all 0.3s ease-in-out 0.7s;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
}
.swiper-auto-slide .swiper-slide span {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.swiper-auto-slide .swiper-slide.swiper-slide-active span {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

/* arrow pagination black and white */
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-white-highlight,
.swiper-container-rtl .swiper-button-next.swiper-button-white-highlight {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white-highlight,
.swiper-container-rtl .swiper-button-prev.swiper-button-white-highlight {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white-highlight,
.swiper-button-prev.swiper-button-white-highlight {
  background-color: #000;
  background-size: 28% auto;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  margin: -15px 10px 0;
  top: 50%;
}
.swiper-button-next.swiper-button-black-highlight,
.swiper-container-rtl .swiper-button-prev.swiper-button-black-highlight {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-black-highlight,
.swiper-container-rtl .swiper-button-next.swiper-button-black-highlight {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-black-highlight,
.swiper-button-prev.swiper-button-black-highlight {
  background-color: #fff;
  background-size: 28% auto;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  margin: -15px 10px 0;
  top: 50%;
}
.swiper-button-next,
.swiper-button-prev {
  background-size: inherit;
  height: 22px;
  width: 50px;
  top: 51%;
}

.swiper-fullscreen-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-fullscreen-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-fullscreen-next,
.swiper-fullscreen-prev {
  background-color: #fff;
  background-size: 28% auto;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  margin: 10px;
  top: 47.5%;
}

/* blog swiper */
.swiper-blog .swiper-slide .slide-content {
  opacity: 0;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
}
.swiper-blog .swiper-slide.swiper-slide-active .slide-content {
  opacity: 1;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -ms-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

/* long arrow */
.swiper-button-prev.slider-long-arrow-white,
.swiper-container-rtl .swiper-button-next.slider-long-arrow-white {
  background-image: url('../images/arrow-prev-light-dark.png');
  left: 0;
}
.swiper-button-next.slider-long-arrow-white,
.swiper-container-rtl .swiper-button-prev.slider-long-arrow-white {
  background-image: url('../images/arrow-next-light-dark.png');
  right: 0;
}

/* next prev button style 2 */
.swiper-next-style2,
.swiper-prev-style2 {
  position: absolute;
  bottom: -60px;
  cursor: pointer;
  text-transform: uppercase;
}
.swiper-next-style2 {
  right: 15%;
}
.swiper-prev-style2 {
  left: 15%;
}

/* portfolio next prev button */
.swiper-portfolio-prev,
.swiper-portfolio-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  background: #fff;
  color: #232323;
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.swiper-portfolio-prev.swiper-button-disabled,
.swiper-portfolio-next.swiper-button-disabled {
  opacity: 0;
  visibility: hidden;
}
.swiper-portfolio-prev {
  left: 15px;
}
.swiper-portfolio-next {
  right: 15px;
}

/* next prev button style 3 */
.swiper-button-prev.swiper-prev-style3,
.swiper-button-next.swiper-next-style3 {
  background-image: none;
  position: absolute;
  cursor: pointer;
  left: 41.1%;
  top: inherit;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #ff214f;
  color: #fff;
  z-index: 9;
}
.swiper-button-prev.swiper-prev-style3 {
  bottom: 141px;
}
.swiper-button-next.swiper-next-style3 {
  bottom: 100px;
}

/* next prev button style 4 */
.navigation-area {
  position: absolute;
  bottom: 0;
  display: inline-block;
  width: 40px;
  height: 82px;
}
.swiper-button-prev.swiper-prev-style4,
.swiper-button-next.swiper-next-style4 {
  background-image: none;
  background: #ff214f;
  color: #fff;
  text-align: center;
  line-height: 40px;
  height: 40px;
  width: 40px;
  display: block;
  float: left;
  position: relative;
  left: auto;
  right: auto;
  top: 0;
  margin: 0 auto;
}
.swiper-button-next.swiper-next-style4 {
  margin-bottom: 1px;
}

/* next prev button style 5 */
.swiper-button-prev.swiper-prev-style5,
.swiper-button-next.swiper-next-style5 {
  position: absolute;
  top: 22px;
  bottom: 0;
  height: 100%;
  width: 15%;
  background-image: none;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
}
.swiper-button-prev.swiper-prev-style5 {
  opacity: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-button-next.swiper-next-style5 {
  opacity: 0;
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  background: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-button-prev.swiper-prev-style5:hover,
.swiper-button-next.swiper-next-style5:hover {
  opacity: 1;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
}
.swiper-button-prev.swiper-prev-style5 {
  left: 0;
  cursor: url('../images/arrow-prev-light-dark.png'), pointer;
}
.swiper-button-next.swiper-next-style5 {
  right: 0;
  cursor: url('../images/arrow-next-light-dark.png'), pointer;
}

/* dot pagination white */
.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}
.swiper-pagination-square-bottom.swiper-pagination-bullets {
  bottom: -50px;
}

/* square pagination */
.swiper-pagination-square .swiper-pagination-bullet {
  height: 3px;
  width: 30px;
  border-radius: 0;
}

/* square pagination */
.white-move .swiper-slide {
  cursor: url('../images/icon-move-light.png'), move;
}
.black-move .swiper-slide {
  cursor: url('../images/icon-move-black.png'), move;
}

/* number pagination */
.swiper-number {
  left: auto !important;
  right: 0 !important;
  width: 35px !important;
  text-align: right !important;
  top: 38% !important;
  bottom: inherit !important;
}
.swiper-number .swiper-pagination-bullet {
  background: transparent;
  color: #939393;
  display: block;
  font-size: 20px;
  height: 45px;
  line-height: 20px;
  opacity: 0.7;
  padding: 14px 0 0;
  text-align: center;
  width: 23px;
  border-radius: 0;
  margin: 0 0 5px 0;
}
.swiper-number .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.swiper-number .swiper-pagination-bullet-active {
  color: #000;
  background: transparent;
}

/* revolution slider bullet */
.tp-bullet {
  border-radius: 100%;
  background: transparent;
  border: 1px solid #fff;
}
.tp-bullet.selected,
.tp-bullet:hover {
  background: #fff;
}
.hermes .tp-bullet {
  box-shadow: 0 0 0 0 #fff;
  height: 12px;
  width: 12px;
  border: 2px solid #fff;
}
.hermes .tp-bullet::after {
  box-shadow: 0 0 0 0 #fff inset;
}

/* slider height */
.slider-half-small-screen {
  min-height: 630px;
}
.slider-half-screen {
  min-height: 765px;
}
.slider-medium-screen {
  min-height: 898px;
}

/* scroll down */
.scroll-down {
  bottom: 0px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 11;
}
.scroll-down a {
  background: #232323;
  display: inline-block;
  height: 57px;
  width: 57px;
  text-align: center;
}
/* .scroll-down a i.scroll-arrow-down {vertical-align: top; background-image: url('../images/scroll-down-icon.png'); width: 17px; height: 30px; display: inline-block} */
.scroll-down a:hover {
  background: #fff;
}
/* .scroll-to-down {cursor:url("../images/arrow-down.png"), pointer;} */
/* .scroll-to-down-white {cursor:url("../images/arrow-down-white.png"), pointer;} */
.background-slider-text {
  position: absolute;
  width: 100%;
}

/* cursor */
.cursor-default {
  cursor: default;
}

/* ===================================
    Slider and parallax typography
====================================== */

.slider-text-middle-main {
  display: table;
  height: 100%;
  width: 100%;
}
.slider-text-middle {
  display: table-cell;
  vertical-align: middle;
}
.slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
}
.slider-text-bottom {
  display: table-cell;
  vertical-align: bottom;
}
.slider-text-top {
  display: table-cell;
  vertical-align: top;
}
.down-section {
  width: 100%;
  top: 500px;
  left: 0;
  right: 0;
  height: auto !important;
}
.blog-slider .swiper-slide .slider-typography {
  padding: 60px;
}

/* ===================================
    Portfolio
====================================== */

/* gutter type */
.portfolio-grid.gutter-very-small li,
.blog-grid.gutter-very-small li {
  padding: 3px 3px;
}
.portfolio-grid.gutter-small li,
.blog-grid.gutter-small li {
  padding: 5px 5px;
}
.portfolio-grid.gutter-medium li,
.blog-grid.gutter-medium li {
  padding: 7px 7px;
}
.portfolio-grid.gutter-large li,
.blog-grid.gutter-large li {
  padding: 10px 10px;
}
.portfolio-grid.gutter-extra-large li,
.blog-grid.gutter-extra-large li {
  padding: 20px;
}
.portfolio-grid.gutter-extra-large,
.blog-grid.gutter-extra-large {
  margin: 0 -20px;
}
.blog-grid.gutter-large {
  margin-left: 10px;
  margin-right: 10px;
}
.portfolio-grid.gutter-large {
  margin: 0 10px;
}
.portfolio-grid.gutter-very-small,
.blog-grid.gutter-very-small {
  margin: 0 3px;
}
.portfolio-grid.gutter-small,
.blog-grid.gutter-small {
  margin: 0 5px;
}
.portfolio-grid.gutter-medium,
.blog-grid.gutter-medium {
  margin: 0 7px;
}

/* portfolio 6 column */
.portfolio-grid.work-6col li,
.blog-grid.blog-6col li {
  width: 16.67%;
}
.portfolio-grid.work-6col li.grid-item-double,
.blog-grid.blog-6col li.grid-item-double {
  width: 33.33%;
}

/* portfolio 5 column */
.portfolio-grid.work-5col li,
.blog-grid.blog-5col li {
  width: 20%;
}
.portfolio-grid.work-5col li.grid-item-double,
.blog-grid.blog-5col li.grid-item-double {
  width: 40%;
}

/* portfolio 4 column */
.portfolio-grid.work-4col li,
.blog-grid.blog-4col li {
  width: 25%;
}
.portfolio-grid.work-4col li.grid-item-double,
.blog-grid.blog-4col li.grid-item-double {
  width: 50%;
}

/* portfolio 3 column */
.portfolio-grid.work-3col li,
.blog-grid.blog-3col li {
  width: 33.33%;
  float: left;
}
.portfolio-grid.work-3col li.grid-item-double,
.blog-grid.blog-3col li.grid-item-double {
  width: 66.67%;
}

/* portfolio 2 column */
.portfolio-grid.work-2col li,
.blog-grid.blog-2col li {
  width: 50%;
}
.portfolio-grid.work-2col li.grid-item-double,
.blog-grid.blog-2col li.grid-item-double {
  width: 100%;
}

/* portfolio 1 column */
.portfolio-grid.work-1col li {
  width: 100%;
}

/* portfolio grid */
.portfolio-grid {
  list-style: none;
  padding: 0;
  margin: 0;
}
.portfolio-grid .grid-item figure {
  margin: 0;
  position: relative;
  overflow: hidden;
}
.portfolio-grid .grid-item figure img {
  display: block;
  width: 100%;
  cursor: pointer;
}
.portfolio-grid .grid-item {
  opacity: 1;
  overflow: hidden;
}
.portfolio-grid .grid-item figure img {
  cursor: pointer;
  display: block;
  opacity: 1;
  width: 100%;
  transition: all 0.3s ease 0s;
}
.portfolio-grid .grid-item figcaption {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  width: 100%;
  height: 100%;
  padding: 45px;
  z-index: 1;
  text-align: center;
}
.portfolio-grid .grid-item figure:hover .portfolio-img {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.portfolio-grid .grid-item figure .portfolio-img {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.portfolio-grid .grid-item figure:hover img {
  opacity: 0.15;
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.portfolio-grid .grid-item figure:hover figcaption {
  opacity: 1;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.container .hover-option4 .grid-item figcaption {
  padding: 30px;
}

/* blog grid */
.blog-grid {
  list-style: none;
  padding: 0;
  margin: 0;
}
.blog-grid .grid-item a {
  position: relative;
  z-index: 11;
}
.blog-grid .grid-item a img {
  width: 100%;
}

/* justified gallery */
.justified-gallery > a > .caption,
.justified-gallery > div > .caption {
  padding: 7px 15px;
  opacity: 0;
  display: initial !important;
  bottom: -20px;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
}
.caption.caption-visible {
  opacity: 1;
  bottom: 0 !important;
}

/* portfolio hover style */
.grid-item .portfolio-hover-box {
  display: table-cell;
  height: 100%;
}

/* hover option 1 */
.hover-option1 .grid-item figcaption {
  height: 100%;
}
.hover-option1 .grid-item .portfolio-hover-main {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  display: table;
  height: 100%;
  top: 0;
  width: 100%;
}

/* hover option 2 */
.hover-option2 .grid-item figcaption {
  height: 100%;
}
.hover-option2 .grid-item .portfolio-hover-main {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  display: table;
  height: 100%;
  top: 0;
  width: 100%;
}
.hover-option2 .grid-item .portfolio-hover-content {
  bottom: -15px;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.hover-option2 .grid-item:hover .portfolio-hover-content {
  bottom: 0;
}
.hover-option2 .separator-line-horrizontal-medium-light2 {
  opacity: 0.5;
}
.hover-option2 .grid-item figure:hover img {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -ms-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
  opacity: 0.1;
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.container .hover-option2 .grid-item figcaption {
  padding: 30px;
}

/* hover option 3 */
.hover-option3 .grid-item .slide-hover-box {
  position: absolute;
  width: 100%;
  height: 100%;
}
.hover-option3 .grid-item .opacity-medium {
  opacity: 0;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.hover-option3 .grid-item .slide-hover-box:hover .opacity-medium {
  opacity: 0.5;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.hover-option3 .grid-item figure {
  bottom: 130px;
  z-index: 5;
  width: 100%;
  overflow: visible;
}
.hover-option3 .grid-item figure h6 {
  position: relative;
  bottom: -70px;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.hover-option3 .grid-item .slide-hover-box:hover h6 {
  bottom: 0;
}
.hover-option3 .grid-item figure p {
  bottom: -40px;
  opacity: 0;
  position: relative;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.hover-option3 .grid-item .slide-hover-box:hover p {
  opacity: 0.6;
  bottom: 0;
}
.hover-option3 .grid-item figure figcaption {
  left: 0;
  right: 0;
  opacity: 1;
  padding: 0 60px;
  height: auto;
  text-align: left;
}
.hover-option3 .grid-item .separator-line-horrizontal-medium-light2 {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  width: 20%;
}
.hover-option3 .grid-item .slide-hover-box:hover .separator-line-horrizontal-medium-light2 {
  width: 60%;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

/* hover option 4 */
.hover-option4 .grid-item figcaption {
  height: 100%;
}
.hover-option4 .grid-item .portfolio-hover-main {
  transform: none;
  display: table;
  height: 100%;
  top: 0;
  width: 100%;
}
.hover-option4 .grid-item figure:hover img {
  opacity: 0.08;
  transform: scale(1, 1);
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
}
.hover-option4 .grid-item .portfolio-hover-content {
  bottom: -15px;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
}
.hover-option4 .grid-item:hover .portfolio-hover-content {
  bottom: 0;
}
.hover-option4 .swiper-slide.grid-item figcaption {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
}
.hover-option4 .swiper-slide.grid-item:hover figcaption {
  opacity: 1;
}

/* hover option 5 */
.hover-option5 .grid-item figcaption {
  height: 100%;
  padding: 20px;
}
.hover-option5 .grid-item .portfolio-hover-main {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  display: table;
  height: 100%;
  top: 0;
  width: 100%;
}
.hover-option5 .grid-item .portfolio-hover-box {
  padding: 20px;
}
.hover-option5 .grid-item:hover .portfolio-hover-box {
  background: rgba(0, 0, 0, 0.8);
}
.hover-option5 .grid-item .separator-line-horrizontal-medium-light2 {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  top: 5px;
  width: 100px;
}
.hover-option5 .grid-item:hover .separator-line-horrizontal-medium-light2 {
  transform: translateY(-30px);
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  -o-transform: translateY(-30px);
}
.hover-option5 .grid-item figure:hover img {
  opacity: 0.8;
}
.container .hover-option5 .grid-item .portfolio-hover-box {
  padding: 10px;
}

/* hover option 6 */
.hover-option6 .grid-item figcaption {
  height: 100%;
  position: relative;
  opacity: 1;
  padding: 35px 10px;
  background: #fff;
}
.hover-option6 .grid-item .portfolio-hover-main {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  display: table;
  height: 100%;
  top: 0;
  width: 100%;
}
.hover-option6 .grid-item .portfolio-icon {
  transition: all 0.3s ease 0s;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
  opacity: 0;
}
.hover-option6 .grid-item figure:hover .portfolio-icon {
  opacity: 1;
  top: 50%;
}
.hover-option6 .grid-item .portfolio-icon a {
  border-radius: 50%;
  border: 2px solid transparent;
  background: #fff;
  margin: 0 7px;
  height: 46px;
  width: 46px;
  display: inline-block;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.hover-option6 .grid-item .portfolio-icon i.fa-link {
  font-size: 20px;
  position: relative;
  top: 11px;
}
.hover-option6 .grid-item .portfolio-icon i.fa-search {
  font-size: 16px;
  position: relative;
  top: 10px;
}
.hover-option6 .grid-item .portfolio-icon a:hover,
.hover-option6 .grid-item .portfolio-icon a:hover i {
  background: transparent;
  color: #fff;
}
.hover-option6 .grid-item .portfolio-icon a:hover {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
}
.hover-option6 .grid-item figure:hover img {
  cursor: default;
  opacity: 0.15;
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
}
.hover-option6 .grid-item figure:hover figcaption {
  cursor: default;
}
.hover-option6 .grid-item figure:hover .portfolio-img {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.container .hover-option6 .grid-item figcaption {
  padding: 25px 10px;
}

/* hover option 7 */
.hover-option7 .grid-item figcaption {
  height: auto;
  position: absolute;
  opacity: 0;
  padding: 32px;
  bottom: -101px;
}
.hover-option7 .grid-item figure:hover figcaption {
  bottom: 0;
}
.hover-option7 .grid-item .portfolio-hover-main {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  display: table;
  height: 100%;
  top: 0;
  width: 100%;
}
.hover-option7 .grid-item figure img {
  top: 0;
  position: relative;
}
.hover-option7 .grid-item figure:hover img {
  cursor: pointer;
  opacity: 1;
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  top: -101px;
}
.hover-option7 .grid-item figure:hover figcaption {
  cursor: pointer;
}
.hover-option7 .grid-item figure:hover .portfolio-img {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  background: transparent;
}
.container .hover-option7 .grid-item figcaption {
  padding: 20px;
}
.container .hover-option7 .grid-item figure:hover img {
  top: -81px;
}

/* hover option 8 */
.hover-option8 .grid-item figure figcaption {
  opacity: 0;
  padding: 0;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
}
.hover-option8 .grid-item figure:hover figcaption {
  opacity: 1;
  cursor: pointer;
}
.hover-option8 .grid-item .portfolio-hover-main {
  display: table;
  height: 100%;
  top: 0;
  transform: none;
  -webkit-transform: none;
  border-radius: 30px;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  width: 100%;
}
.hover-option8 .grid-item .portfolio-icon {
  position: absolute;
  right: 55px;
  top: 51px;
  transition: all 0.1s ease 0s;
  -webkit-transition: all 0.1s ease 0s;
  -moz-transition: all 0.1s ease 0s;
  -ms-transition: all 0.1s ease 0s;
  -o-transition: all 0.1s ease 0s;
}
.hover-option8 .grid-item figure:hover figcaption {
  padding: 25px;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}
.hover-option8 .grid-item figure:hover figcaption .portfolio-hover-main {
  background: #fff;
}
.hover-option8.portfolio-grid .grid-item figure:hover img {
  opacity: 1;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
}
.container .hover-option8 .grid-item figure:hover figcaption {
  padding: 20px;
}
.container .hover-option8 .grid-item .portfolio-icon {
  top: 30px;
  right: 35px;
}

/* hover option 10 */
.hover-option10 .grid-item * {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.hover-option10 .grid-item figure .portfolio-img img {
  transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
}
.hover-option10 .grid-item figure:hover .portfolio-img img {
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  opacity: 0.15;
}
.hover-option10 .grid-item figure figcaption {
  padding: 0;
}
.hover-option10 .grid-item figure .portfolio-hover-main {
  display: table;
  width: 100%;
  height: 100%;
  padding: 50px;
}
.hover-option10 .grid-item figure .portfolio-icon {
  margin-bottom: 30px;
  position: relative;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.hover-option10 .grid-item figure .portfolio-hover-content {
  position: relative;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
}
.hover-option10 .grid-item figure:hover .portfolio-icon,
.hover-option10 .grid-item figure:hover .portfolio-hover-content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}
.hover-option10 .grid-item .portfolio-icon a {
  margin: 0 7px;
  height: 50px;
  width: 50px;
  display: inline-block;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 1);
  box-sizing: border-box;
  vertical-align: top;
  text-align: center;
  color: #fff;
}
.hover-option10 .grid-item .portfolio-icon a:hover {
  color: #232323;
  background: #fff;
  border-radius: 5px;
}
.hover-option10 .grid-item .portfolio-icon a:hover i {
  color: #232323;
}
.hover-option10 .grid-item .portfolio-icon i.fa-link {
  font-size: 22px;
  position: relative;
  top: 14px;
}
.hover-option10 .grid-item .portfolio-icon i.fa-search {
  font-size: 20px;
  position: relative;
  top: 13px;
}
.container .hover-option10 .grid-item .portfolio-icon i.fa-link {
  font-size: 16px;
  position: relative;
  top: 10px;
}
.container .hover-option10 .grid-item .portfolio-icon i.fa-search {
  font-size: 14px;
  position: relative;
  top: 8px;
}
.container .hover-option10 .grid-item .portfolio-icon a {
  width: 45px;
  height: 45px;
}
.container .hover-option10 .grid-item figure .portfolio-hover-main {
  padding: 30px;
}

/* hover option 11 */
.hover-option11 .grid-item figcaption {
  height: 100%;
}
.hover-option11 .grid-item .portfolio-hover-main {
  transform: none;
  display: table;
  height: 100%;
  top: 0;
  width: 100%;
}
.hover-option11 .grid-item figure:hover img {
  opacity: 0.05;
  transform: scale(1, 1);
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
}
.hover-option11 .grid-item .portfolio-hover-content {
  bottom: -15px;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
}

.hover-option11 .grid-item:hover .portfolio-hover-content {
  bottom: 0;
}
.hover-option11 .swiper-slide.grid-item figcaption {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
}
.hover-option11 .swiper-slide.grid-item:hover figcaption {
  opacity: 1;
}
.hover-option11 .grid-item .portfolio-item-title {
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
}

.hover-option11 .grid-item > a:hover .portfolio-item-title {
  color: #fff;
}
.hover-option11 .grid-item .portfolio-img {
  margin-bottom: 33px;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
}
.hover-option11 .grid-item .portfolio-img img {
  box-shadow: 0 0 8px rgba(0, 0, 0, 1);
}
.hover-option11 .grid-item:hover .portfolio-img img {
  opacity: 0.6;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
}

.hover-option11 .grid-item > a {
  display: block;
  position: relative;
}
.hover-option11 .grid-item > a:hover .portfolio-img {
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(-10px);
}

/* portfolio filter tab style 1 */
.portfolio-filter-tab-1 li {
  margin: 0 25px;
  display: inline-block;
  float: none;
}
.portfolio-filter-tab-1 li a {
  background: transparent;
  border: none;
  padding: 0 0 2px 0;
  display: inline;
  color: #6f6f6f;
  margin: 0;
}
.portfolio-filter-tab-1 li a:hover,
.portfolio-filter-tab-1 li a:focus {
  background: transparent;
  border-bottom: 1px solid #232323;
  color: #232323;
}
.portfolio-filter-tab-1 li.active a {
  background: transparent !important;
  border-bottom: 1px solid #232323;
  color: #232323;
}

/* portfolio filter tab style 2 */
.portfolio-filter-tab-2 li {
  display: inline-block;
  float: none;
}
.portfolio-filter-tab-2 li a {
  border: none;
  border-radius: 50px;
  padding: 7px 24px;
  margin-right: 4px;
}
.portfolio-filter-tab-2 li a:hover,
.portfolio-filter-tab-2 li > a:focus {
  background: rgba(144, 144, 144, 0.22);
  color: #fff;
}
.portfolio-filter-tab-2.nav-tabs li.active a,
.portfolio-filter-tab-2.nav-tabs li.active a:focus,
.portfolio-filter-tab-2.nav-tabs li.active a:hover {
  border: none;
  background: rgba(144, 144, 144, 0.22);
  color: #fff;
  border: none;
}

/* blog and portfolio next pagination */
.blog-nav-link {
  padding: 30px 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  position: relative;
}
.blog-nav-link-prev .blog-nav-icon,
.blog-nav-link-next .blog-nav-icon {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  position: absolute;
}
.blog-nav-link-next {
  padding-right: 50px;
}
.blog-nav-link-next .blog-nav-icon {
  right: 0;
}
.blog-nav-link-prev {
  padding-left: 50px;
}
.blog-nav-link-prev .blog-nav-icon {
  left: 0;
}

/* ===================================
    Elements
====================================== */

/* double quotes */
.special-char-small {
  font-size: 40px;
  line-height: 60px;
}
.special-char-medium {
  font-size: 80px;
  line-height: 120px;
}
.special-char-large {
  font-size: 140px;
  line-height: 120px;
}
.special-char-extra-large {
  font-size: 200px;
  line-height: 150px;
}

/* search box */
.search-box .add-on .input-group-btn > .btn {
  border-left-width: 0;
  left: 0px;
  border-color: #ededed;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  margin-right: 0;
  margin-left: -1px;
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  padding: 5px 9px;
  height: 31px;
}
.search-box .add-on .form-control:focus,
.search-box .add-on .input-group-btn > .btn:focus,
.search-box .add-on .input-group-btn > .btn:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  border-color: #ededed;
  background: #f7f7f7;
}
.search-box .add-on i {
  color: #939393;
  font-size: 11px;
  top: -5px;
  left: -2px;
}
.sidebar-nav-style-1 .search-box .add-on i {
  top: -3px;
}
.search-box input[type='text'],
.search-box input[type='search'] {
  border-color: #ededed;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  font-size: 12px;
  font-weight: normal;
  color: #232323;
  height: 31px;
}
.search-box .add-on input[type='text'] {
  padding: 5px 8px;
}
.search-box input[type='text']::-webkit-input-placeholder {
  color: #939393;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.015em;
}
.search-box input[type='text']::-moz-placeholder {
  color: #939393;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.015em;
}
.search-box input[type='text']::-ms-input-placeholder {
  color: #939393;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.015em;
}
.search-box input[type='text']::-o-placeholder {
  color: #939393;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.015em;
}

/* search box style 2 */
.search-box2 .add-on .input-group-btn > .btn {
  border-left-width: 0;
  left: 0px;
  border-color: #4f4f4f;
  background-color: #4f4f4f;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  margin-right: 0;
  margin-left: -1px;
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  padding: 5px 8px 5px 10px;
  height: 45px;
  width: 45px;
}
.search-box2 .add-on .input-group-btn {
  width: 45px;
}
.search-box2 .add-on .input-group-btn > .btn:focus,
.search-box2 .add-on .input-group-btn > .btn:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  border-color: #4f4f4f;
  background-color: #4f4f4f;
}
.search-box2 .add-on i {
  color: #fff;
  font-size: 17px;
}
.search-box2 input[type='text'],
.search-box2 input[type='search'] {
  border-color: #474747;
  background-color: transparent;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  font-size: 14px;
  font-weight: normal;
  color: #636363;
  height: 45px;
}
.search-box2 .add-on input[type='text'] {
  padding: 10px 14px;
}
.search-box2 input[type='text']::-webkit-input-placeholder {
  color: #636363;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.015em;
  background-color: transparent;
}
.search-box2 input[type='text']::-moz-placeholder {
  color: #636363;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.015em;
  background-color: transparent;
}
.search-box2 input[type='text']::-ms-input-placeholder {
  color: #636363;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.015em;
  background-color: transparent;
}
.search-box2 input[type='text']::-o-placeholder {
  color: #636363;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.015em;
  background-color: transparent;
}

/* search form */
.search-cart-header {
  padding-top: 14px;
  float: right;
  letter-spacing: 1px;
}
.header-search-form {
  color: #ffffff;
}
.search-form .search-input {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  font-size: 30px;
  font-weight: 100;
  padding: 20px 38px 20px 2px;
  text-transform: capitalize;
  background: transparent;
  line-height: 40px;
  color: #fff;
}
.search-form .search-input:focus {
  outline: none;
}
.search-input ::-moz-placeholder,
.search-input [placeholder] {
  color: #ff0000;
}
.search-form .search-button {
  font-size: 21px;
  color: #fff;
  top: 31px;
  right: 0;
  outline: none;
}
#search-header {
  height: 100%;
  width: 45%;
  margin: 0 auto;
}
.close-search {
  color: #373737;
  position: absolute;
  right: 0;
  top: 23px;
  z-index: 998;
  cursor: pointer;
}
.close-search:hover {
  color: #9a9a9a;
}
.search-button {
  cursor: pointer;
  z-index: 0;
  margin-right: 0;
  border: none;
  background: none;
}

/* list style 1 */
.list-style-none li {
  list-style: none;
}

.list-style-1 li {
  padding: 5px 0;
}

/* list style 2 */
.list-style-2 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-2 li {
  position: relative;
  padding: 0 0 0 12px;
  margin: 0 0 9px 0;
}
.list-style-2 li:before {
  content: '';
  width: 5px;
  height: 1px;
  background-color: #ff214f;
  position: absolute;
  top: 12px;
  left: 0;
}

/* list style 3 */
.list-style-3 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-3 li {
  position: relative;
  padding: 0 0 0 22px;
  margin: 0 0 15px 0;
}
.list-style-3 li:before {
  content: '';
  width: 10px;
  height: 1px;
  background-color: #ff214f;
  position: absolute;
  top: 11px;
  left: 0;
}

/* list style 4 */
.list-style-4 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-4 li {
  position: relative;
  padding: 0 0 10px 22px;
  margin: 0 0 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.list-style-4 li:before {
  content: '\f00c';
  position: absolute;
  top: 1px;
  left: 0;
  font-family: Font Awesome\5 Free;
  font-weight: 900;
}
.list-style-4 li:last-child {
  border-bottom: 0;
}
.list-style-4.list-style-color li {
  border-color: rgba(255, 255, 255, 0.15);
}
.list-style-4.list-style-color li:before {
  color: #ff214f;
}

/* list style 5 */
.list-style-5 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-5 li {
  position: relative;
  padding: 0 0 10px 22px;
  margin: 0 0 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.list-style-5 li:before {
  content: '\f192';
  position: absolute;
  top: 1px;
  left: 0;
  font-family: Font Awesome\5 Free;
  font-weight: 400;
  color: #c0c0c0;
}
.list-style-5 li:last-child {
  border-bottom: 0;
}
.list-style-5.list-style-color li {
  border-color: rgba(255, 255, 255, 0.15);
}
.list-style-5.list-style-color li:before {
  color: #ff214f;
}

/* list style 6 */
.list-style-6 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-6 li {
  position: relative;
  padding: 0 15px 8px 0;
  margin: 0 0 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.list-style-6 li span {
  position: absolute;
  top: 3px;
  right: 0;
}
.list-style-6 li:last-child {
  border-bottom: none;
}
.list-style-6 li:before {
  content: '';
}

/* list style 7 */
.list-style-7 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-7 li {
  padding: 0 0 13px 0;
  margin: 0 0 10px 0;
}
.list-style-7 li:last-child {
  margin: 0;
  padding: 0;
}

/* list style 8 */
.list-style-8 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-8 li {
  position: relative;
  padding: 15px 0;
  margin: 0 0 4px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.list-style-8 li span {
  margin-bottom: 2px;
}
.list-style-8 li p {
  margin-bottom: 0;
}
.list-style-8 li:last-child {
  border-bottom: none;
}
.list-style-8 li:before {
  content: '';
}

/* list style 9 */
.list-style-9 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-9 li {
  position: relative;
  padding: 15px 0;
  margin: 0 0 4px 0;
  border-bottom: 1px solid #373737;
}
.list-style-9 li span {
  margin-bottom: 2px;
}
.list-style-9 li p {
  margin-bottom: 0;
}
.list-style-9 li:last-child {
  border-bottom: none;
}
.list-style-9 li:before {
  content: '';
}

/* list style 10 */
.list-style-10 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-10 li {
  padding: 15px 0;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.list-style-10 li i {
  float: left;
  margin-top: 5px;
}
.list-style-10 li span {
  padding-left: 15px;
  display: table;
}
.list-style-10 li:last-child {
  border-bottom: none;
}

/* list style 6 */
.list-style-11 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style-11 li {
  position: relative;
  padding: 0 0 8px 0;
  margin: 0 0 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.list-style-11 li span {
  position: absolute;
  top: 3px;
  right: 0;
}
.list-style-11 li:last-child {
  border-bottom: none;
}
.list-style-11 li:before {
  content: '';
}

/* image hover style 1 */
.image-hover-style-1 {
  background: #222222;
}
.image-hover-style-1:hover img {
  opacity: 0.5;
  transform: scale(1.2, 1.2) rotate(-5deg);
  transition: all 0.3s ease 0s;
}

/* image hover style 3 */
.image-hover-style-3:hover .cover-background {
  filter: contrast(130%);
  -webkit-filter: contrast(130%);
  -moz-filter: contrast(130%);
  -ms-filter: contrast(130%);
  -o-filter: contrast(130%);
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
}

/* info banner style 1 */
.banner-style1 figure {
  position: relative;
  overflow: hidden;
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
}
.banner-style1 figure figcaption {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  height: 100%;
  width: 100%;
}
.banner-style1 figure:hover .banner-image img,
.banner-style1 figure:hover .banner-image {
  opacity: 0.3;
  filter: contrast(130%);
  -webkit-filter: contrast(130%);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
}
.banner-style1 figure figcaption p {
  opacity: 0;
  margin-bottom: 0;
  height: 0;
}
.banner-style1 figure:hover figcaption p {
  height: 100px;
  opacity: 1;
}

/* info banner style 2 */
.banner-style2 figure {
  position: relative;
  overflow: hidden;
}
.banner-style2 figure:hover .banner-image img {
  opacity: 0.4;
}
.banner-style2 figcaption {
  position: absolute;
  right: -50px;
  top: 40px;
  height: 100%;
  height: calc(100% - 80px);
  width: 50%;
  opacity: 0;
  visibility: hidden;
}
.banner-style2 figure:hover figcaption {
  right: 40px;
  opacity: 1;
  visibility: visible;
}

/* info banner style 3 */
.banner-style3 figure {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
}
.banner-style3 figure figcaption {
  position: absolute;
  left: 0;
  top: 52%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  height: 100%;
  width: 100%;
  opacity: 0;
}
.banner-style3 figure:hover figcaption {
  opacity: 1;
  top: 50%;
}
.banner-style3 figure:hover .banner-image img,
.banner-style1 figure:hover .banner-image {
  opacity: 0.3;
}

/* pricing box */
.highlight .pricing-box {
  box-shadow: 0px 0px 40px rgba(200, 200, 200, 0.3);
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
}

.feature-box {
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.feature-box .content {
  padding: 35px 15px;
}
.feature-box:before,
.feature-box .content:before,
.feature-box:after,
.feature-box .content:after {
  display: inline-block;
  position: absolute;
  content: '';
  background-color: #ff214f;
  z-index: 1;
}
.feature-box:before,
.feature-box .content:before {
  right: 0;
  top: 0;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
.feature-box:after,
.feature-box .content:after {
  left: 0;
  bottom: 0;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
.feature-box:hover:before,
.feature-box:hover:after {
  transform: scaleY(1);
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
.feature-box:before {
  transform-origin: right top 0;
  -webkit-transform-origin: right top 0;
  -moz-transform-origin: right top 0;
  -ms-transform-origin: right top 0;
  -o-transform-origin: right top 0;
}
.feature-box:after {
  transform-origin: left bottom 0;
  -webkit-transform-origin: left bottom 0;
  -moz-transform-origin: left bottom 0;
  -ms-transform-origin: left bottom 0;
  -o-transform-origin: left bottom 0;
}
.feature-box .content:before {
  transform-origin: right top 0;
  -webkit-transform-origin: right top 0;
  -moz-transform-origin: right top 0;
  -ms-transform-origin: right top 0;
  -o-transform-origin: right top 0;
}
.feature-box .content:after {
  transform-origin: top left 0;
  -webkit-transform-origin: top left 0;
  -moz-transform-origin: top left 0;
  -ms-transform-origin: top left 0;
  -o-transform-origin: top left 0;
}
.feature-box:before,
.feature-box:after {
  height: 100%;
  width: 3px;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
.feature-box:hover .content:before,
.feature-box:hover .content:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
.feature-box .content:before,
.feature-box .content:after {
  width: 100%;
  height: 3px;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

/* feature box style 1 */
.feature-box-1 {
  display: table;
}
.feature-box-1 .char-value {
  display: table-cell;
  vertical-align: middle;
}

/* feature box style 4 */
.grid-item.feature-box-4 {
  overflow: hidden;
  position: relative;
}
.grid-item.feature-box-4 figure img {
  width: 100%;
  cursor: auto;
}
.grid-item.feature-box-4 figure figcaption {
  position: absolute;
  left: 0px;
  top: auto;
  z-index: 9;
  bottom: 0px;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transition: transform 0.35s ease-out;
  -webkit-transition: transform 0.35s ease-out;
  -moz-transition: transform 0.35s ease-out;
  -ms-transition: transform 0.35s ease-out;
  -o-transition: transform 0.35s ease-out;
  padding: 0 0 15px 60px;
  width: auto;
  height: auto;
  text-align: left;
  opacity: 1;
  cursor: default;
}
.grid-item.feature-box-4 figure:hover img,
.feature-box-4 figure:focus img {
  opacity: 1;
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
}
.grid-item.feature-box-4 figure:hover figcaption {
  transform: translateY(-50px);
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  -o-transform: translateY(-50px);
}
.grid-item.feature-box-4 figure figcaption .btn {
  opacity: 0;
}
.grid-item.feature-box-4 figure:hover .btn {
  opacity: 1;
  display: inline-block;
}
.grid-item.feature-box-4 figure:hover img {
  transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transform: scale(1.09, 1.09);
  -webkit-transform: scale(1.09, 1.09);
  -moz-transform: scale(1.09, 1.09);
  -ms-transform: scale(1.09, 1.09);
  -o-transform: scale(1.09, 1.09);
}

/* feature box style 5 */
.feature-box-5 {
  padding-left: 75px;
}
.feature-box-5 i {
  position: absolute;
  top: 0;
  left: 0;
}

/* feature box style 6 */
.feature-box-6 {
  padding-left: 70px;
}
.feature-box-6 i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-22px);
  -webkit-transform: translateY(-22px);
  -moz-transform: translateY(-22px);
  -ms-transform: translateY(-22px);
  -o-transform: translateY(-22px);
}

/* feature box style 7 */
.feature-box.feature-box-7:before,
.feature-box.feature-box-7:after {
  content: '';
  display: table;
}
.feature-box.feature-box-7:hover,
.feature-box.feature-box-7:hover:before,
.feature-box.feature-box-7:hover:after {
  border: none;
}
.feature-box.feature-box-7::before,
.feature-box.feature-box-7::after,
.feature-box.feature-box-7 .content::before,
.feature-box.feature-box-7 .content::after {
  background: transparent;
}
.feature-box.feature-box-7:after {
  clear: both;
}
.feature-box.feature-box-7 .box {
  cursor: default;
  height: 100%;
  width: 100%;
  display: table;
  position: relative;
  -webkit-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -moz-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -mstransition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -o-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  border: 1px solid #ededed;
  border-right: none;
  overflow: hidden;
}
.feature-box.feature-box-7 div:first-child .box {
  border-left: none;
}
.feature-box.feature-box-7 .box figure {
  -webkit-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -moz-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -ms-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -o-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  padding: 8% 0;
}
.feature-box.feature-box-7 .box .content {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  padding: 0;
  position: relative;
  top: 30px;
}
.feature-box.feature-box-7 .box .content .details {
  opacity: 0;
}
.feature-box.feature-box-7 .box:hover .content .details {
  opacity: 1;
}
.feature-box.feature-box-7 .box:hover .content {
  top: 0;
  border-bottom-color: #ff214f;
}
.feature-box.feature-box-7 .box:hover i {
  color: #ff214f;
}
.container .feature-box.feature-box-7 .box .content {
  top: 43px;
}
.container .feature-box.feature-box-7 .box:hover .content {
  top: 0;
}

/* feature box style 8 */
.feature-box-8 .feature-box-overlay {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  z-index: -2;
}
.feature-box-8:hover .feature-box-overlay {
  height: 100%;
  background: #2f2f2f;
}
.feature-box-8:hover div,
.feature-box-8:hover p {
  color: #fff;
}
.feature-box-8:hover .icon-round-small {
  background: #fff;
  color: #ff214f;
}
.parallax-feature-box-bottom {
  position: absolute;
  bottom: -150%;
}
.parallax-feature-box {
  overflow: visible;
}

/* feature box style 9 */
.feature-box-9 i {
  position: relative;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  top: 0;
}
.feature-box-9:hover i {
  top: -8px;
  color: #fff;
}
.feature-box-9:hover p {
  color: #ff214f;
}
.feature-box-9 .text-bottom-line {
  margin-bottom: 15px;
}
.feature-box-9:hover .text-bottom-line {
  border-top: 45px solid;
  margin-bottom: 0;
}

/* feature box style 10 */
.feature-box-10 .number {
  display: table-cell;
  font-size: 30px;
  height: 90px;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  width: 90px;
  position: relative;
}
.feature-box-10:hover .number {
  background: #ff214f;
  color: #fff;
}
.feature-box-10 .number-center:after {
  width: 250%;
  position: absolute;
  content: '';
  height: 1px;
  background: #e2e2e2;
  top: 45px;
  z-index: -1;
  left: 130px;
}
.feature-box-10 .number-center:before {
  width: 250%;
  position: absolute;
  content: '';
  height: 1px;
  background: #e2e2e2;
  top: 45px;
  z-index: -1;
  right: 130px;
}

/* feature box style 11 */
.feature-box-11 .progress-line:after {
  content: '';
  display: block;
  width: 12%;
  height: 1px;
  background: #d8d8d8;
  position: absolute;
  right: -6%;
  top: 65px;
  text-align: center;
}
.feature-box-11 .process-bg {
  border-radius: 100%;
  height: 130px;
  width: 130px;
  line-height: 130px;
}

/* feature box style 12 */
.feature-box-12 .feature-icon-box {
  width: 90px;
  height: 90px;
  border-radius: 6px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #d6d5d5;
  display: table-cell;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transition: ease-in-out 0.6s;
  -webkit-transition: ease-in-out 0.6s;
  -moz-transition: ease-in-out 0.6s;
  -ms-transition: ease-in-out 0.6s;
  -o-transition: ease-in-out 0.6s;
}
.feature-box-12 .feature-content-box {
  display: table-cell;
  vertical-align: middle;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transition: ease-in-out 0.6s;
  -webkit-transition: ease-in-out 0.6s;
  -moz-transition: ease-in-out 0.6s;
  -ms-transition: ease-in-out 0.6s;
  -o-transition: ease-in-out 0.6s;
  opacity: 1;
}
.feature-box-12 .hover-content-box,
.feature-box-12 .feature-content-box {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
}
.feature-box-12 .hover-content-box {
  background-color: #232323;
  width: 80%;
  transition: ease-in-out 0.6s;
  -webkit-transition: ease-in-out 0.6s;
  -moz-transition: ease-in-out 0.6s;
  -ms-transition: ease-in-out 0.6s;
  -o-transition: ease-in-out 0.6s;
  transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  position: absolute;
  top: -45%;
  left: 0;
  opacity: 0;
}
.feature-box-12:hover .hover-content-box {
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  opacity: 1;
}
.feature-box-12:hover .feature-icon-box,
.feature-box-12:hover .feature-content-box {
  transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  opacity: 0;
}

/* feature box style 13 */
.feature-box-13 {
  overflow: hidden;
  z-index: 1;
  border-radius: 20px;
}
.feature-box-13:before {
  background: #d84449;
  left: 0;
  height: 100%;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
  display: block;
  content: '';
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  opacity: 0;
}
.feature-box-13:hover:before {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  opacity: 1;
}
.feature-box-13:hover i {
  color: #fff;
}
.feature-box-13:hover p {
  color: #fff;
}

/* feature box style 14 */
.feature-box-14 > div {
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.feature-box-14 > div:last-child {
  border-right: 0;
}

/* feature box style 15 */
.feature-box-15 .feature-box-content {
  position: relative;
  overflow: hidden;
}
.feature-box-15 .feature-box-image {
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
}
.feature-box-15 .hover-content,
.feature-box-15 .feature-box-image {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  transition: ease-in-out 0.4s;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg);
  -webkit-transition: ease-in-out 0.4s;
  -moz-transform-style: preserve-3d;
  -moz-transform: rotateY(0deg);
  -moz-transition: ease-in-out 0.4s;
  -ms-transform-style: preserve-3d;
  -ms-transform: rotateY(0deg);
  -ms-transition: ease-in-out 0.4s;
  -o-transform-style: preserve-3d;
  -o-transform: rotateY(0deg);
  -o-transition: ease-in-out 0.4s;
}
.feature-box-15 .hover-content {
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  width: 100%;
  opacity: 0;
}
.feature-box-15 .feature-box-content:hover .feature-box-image {
  transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  opacity: 0;
}
.feature-box-15 .feature-box-content:hover .hover-content {
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  opacity: 1;
}

/* feature box style 16 */
.feature-box-16 .feature-box-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: ease-in-out 0.4s;
  height: 60px;
}
.feature-box-16 .feature-box-content p {
  opacity: 0;
  transition: ease-in-out 0.5s;
  -webkit-transition: ease-in-out 0.5s;
  -moz-transition: ease-in-out 0.5s;
  -ms-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
}
.feature-box-16 {
  position: relative;
  overflow: hidden;
}
.feature-box-16:hover .feature-box-content {
  height: 100%;
}
.feature-box-16:hover .feature-box-content p {
  opacity: 1;
}

/* feature box style 17 */
.feature-box.feature-box-17:before,
.feature-box.feature-box-17:after {
  content: '';
  display: table;
}
.feature-box.feature-box-17:hover,
.feature-box.feature-box-17:hover:before,
.feature-box.feature-box-17:hover:after {
  border: none;
}
.feature-box.feature-box-17::before,
.feature-box.feature-box-17::after,
.feature-box.feature-box-17 .content::before,
.feature-box.feature-box-17 .content::after {
  background: transparent;
}
.feature-box.feature-box-17:after {
  clear: both;
}
.feature-box.feature-box-17 .box {
  cursor: default;
  height: 100%;
  width: 100%;
  display: table;
  position: relative;
  -webkit-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -moz-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -ms-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  overflow: hidden;
}
.feature-box.feature-box-17 div:first-child .box {
  border-left: none;
}
.feature-box.feature-box-17 .box figure {
  -webkit-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -ms-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -moz-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -o-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  padding: 10% 0;
}
.feature-box.feature-box-17 .box .content {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  padding: 0;
  position: relative;
  top: 30px;
}
.feature-box.feature-box-17 .box .content .details {
  opacity: 0;
}
.feature-box.feature-box-17 .box:hover .content .details {
  opacity: 1;
}
.feature-box.feature-box-17 .box:hover .content {
  top: 0;
  border-bottom-color: #ff214f;
}
.feature-box.feature-box-17 .box:hover {
  background-color: #ff214f;
}
.feature-box.feature-box-17 .box:hover i,
.feature-box.feature-box-17 .box:hover .text-white-hover {
  color: #ffffff;
}

/* info banner box style 2 */
.info-banner-box-2 {
  position: relative;
  overflow: hidden;
  height: 200px;
}
.info-banner-box-2 .hover-box {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  transition: ease-in-out 0.5s;
  -webkit-transition: ease-in-out 0.5s;
  -moz-transition: ease-in-out 0.5s;
  -ms-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
}
.info-banner-box-2 .content-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transition: ease-in-out 0.5s;
  -webkit-transition: ease-in-out 0.5s;
  -moz-transition: ease-in-out 0.5s;
  -ms-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
}
.info-banner-box-2:hover .hover-box {
  left: 0;
}
.info-banner-box-2:hover .content-box {
  left: -100%;
}

/* counter box style 1 */
.counter-feature-box-1 {
  height: 199px;
}
.counter-feature-box-1:hover {
  background: #ff214f;
  border-color: #ff214f;
}

/* counter box style 3 */
.counter-box-3 .counter-box {
  display: inline-block;
  width: 140px;
  padding: 0;
  vertical-align: top;
  text-align: center;
  position: relative;
}
.counter-box-3 .counter-box:after {
  position: absolute;
  right: 0;
  width: 1px;
  height: 36px;
  background-color: #eb214b;
  content: '';
  top: 8px;
}
.counter-box-3 .counter-box:last-child:after {
  width: 0;
}
.counter-box-3 .counter-box .number {
  display: block;
  font-weight: 400;
  font-size: 32px;
  line-height: 30px;
}
.counter-box-3 .counter-box span {
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  text-transform: uppercase;
}

/* counter box style 4 */
.counter-box-4 .timer:after {
  content: '+';
  position: absolute;
  right: -5%;
  top: 35%;
  color: #b6b6b6;
  font-weight: 300;
}
.counter-box-4:last-child .timer:after {
  display: none;
}

/* counter box style 5 */
.counter-box-5 .counter-box {
  display: inline-block;
  width: 13%;
  padding: 0;
  vertical-align: top;
  text-align: center;
  position: relative;
}
.counter-box-5 .counter-box .number {
  display: block;
  font-weight: 400;
  font-size: 80px;
  line-height: 80px;
  letter-spacing: -3px;
}
.counter-box-5 .counter-box span {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  opacity: 0.5;
  line-height: 28px;
  text-transform: uppercase;
}

/* skillbar bar style 1  */
.skillbar-bar-style1 .skillbar {
  position: relative;
  display: inline-block;
  margin: 35px 0 10px;
  background: #5239b2;
  height: 5px;
  width: 88%;
  border-radius: 10px;
  text-align: left;
}
.skillbar-bar-style1 .skillbar-bar {
  height: 5px;
  border-radius: 10px;
  width: 0px;
  background: #4dcfd0;
  display: inline-block;
  position: absolute;
  top: 0;
  margin-bottom: 0px;
}
.skillbar-bar-style1 .skill-bar-percent {
  position: absolute;
  top: -12px;
  right: -39px;
}
.skillbar-bar-style1 .skill-bar-text {
  top: -22px;
  position: absolute;
}

/* skillbar bar style 2  */
.skillbar-bar-style2 .skillbar {
  position: relative;
  display: inline-block;
  margin: 35px 0 10px;
  background: rgba(0, 0, 0, 0.15);
  height: 3px;
  width: 88%;
  text-align: left;
}
.skillbar-bar-style2 .skillbar-bar {
  height: 3px;
  width: 0px;
  background: rgba(0, 0, 0, 0.7);
  display: inline-block;
  position: absolute;
  top: 0;
  margin: 0;
}
.skillbar-bar-style2 .skill-bar-percent {
  position: absolute;
  top: -9px;
  right: -39px;
}
.skillbar-bar-style2 .skill-bar-text {
  top: -24px;
  position: absolute;
}

/* skillbar bar style 3  */
.skillbar-bar-style3 .skillbar {
  position: relative;
  display: inline-block;
  margin: 15px 0 30px;
  background: rgba(255, 255, 255, 0.2);
  height: 5px;
  width: 88%;
  text-align: left;
}
.skillbar-bar-style3 .skillbar-bar {
  height: 5px;
  width: 0px;
  display: inline-block;
  position: absolute;
  top: 0;
  margin: 0;
  overflow: visible;
  background: #ff214f;
  background: -moz-linear-gradient(left, #ff214f 0%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #ff214f 0%, #ffffff 100%);
  background: linear-gradient(to right, #ff214f 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff214f', endColorstr='#ffffff',GradientType=1 );
}
.skillbar-bar-style3 .skill-bar-percent {
  position: absolute;
  top: 13px;
  right: 0;
}
.skillbar-bar-style3 .skill-bar-text {
  top: 15px;
  position: absolute;
}

/* piechart style 1 */
.chart-style1 .chart1 {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  text-align: center;
  border-radius: 50%;
}
.chart-style1 .chart1 canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.chart-style1 .chart-percent {
  margin: 0 0 15px 0;
}
.chart-style1 .percent {
  display: inline-block;
  line-height: 150px;
  z-index: 2;
}
.percent:after {
  content: '%';
}

/* piechart style 2 */
.chart-style2 .chart2 {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  text-align: center;
  border-radius: 50%;
}
.chart-style2 .chart2 canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.chart-style2 .percent {
  display: inline-block;
  line-height: 120px;
  z-index: 2;
}
.chart-style2 .chart-percent {
  margin: 0 0 10px 0;
}

/* piechart style 3 */
.chart-style3 .chart3 {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 140px;
  text-align: center;
  border-radius: 50%;
}
.chart-style3 .chart3 canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.chart-style3 .percent {
  display: inline-block;
  line-height: 68px;
  z-index: 2;
  background: #fff;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  color: #232323;
  right: -60px;
  top: 10px;
  position: relative;
}
.chart-style3 .percent:after {
  content: '%';
}
.chart-style3 .chart-percent {
  margin: 0 0 15px 0;
}

/* team style 1 */
.team-style-1 figure .team-image {
  position: relative;
  overflow: hidden;
}
.team-style-1 .team-overlay {
  display: block;
  position: absolute;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.team-style-1 .team-overlay {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
}
.team-style-1 figure:hover .team-overlay {
  height: 100%;
}
.team-style-1 figure figcaption {
  width: 100%;
  position: relative;
  margin-top: 17px;
}
.team-style-1 figure .overlay-content {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  top: -15px;
}
.team-style-1 figure:hover .overlay-content {
  opacity: 1;
  z-index: 1111;
  top: 0;
}
.team-style-1 figure figcaption .team-member-position {
  position: relative;
  top: 0;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}

/* team style 2 */
.team-style-2 figure {
  position: relative;
}
.team-style-2 figure .team-image {
  position: relative;
}
.team-style-2 .team-overlay {
  display: block;
  position: absolute;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.team-style-2 figure:hover .team-overlay {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}
.team-style-2 figure .team-member-position {
  position: absolute;
  top: 100%;
  width: 100%;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.team-style-2 figure:hover .team-member-position {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  margin: 0;
}
.team-style-2 figure:hover .team-member-position .text-medium-gray,
.team-style-2 figure:hover .team-member-position .text-extra-dark-gray {
  color: #fff;
}
.team-style-2 figure figcaption {
  min-height: 20px;
}
.team-style-2 figure figcaption .overlay-content {
  opacity: 0;
  top: 0;
  position: relative;
}
.team-style-2 figure:hover figcaption .overlay-content {
  opacity: 1;
  top: 30px;
}

/* accordion style1 */
.accordion-style1 .panel {
  background: transparent;
  box-shadow: none;
  margin-top: 0 !important;
}
.accordion-style1.panel-group .panel-heading {
  border: 0px;
  border-bottom: 1px solid #e4e4e4;
}
.accordion-style1 .panel-heading {
  padding: 16px 0;
}
.accordion-style1 .panel-title {
  font-size: 13px;
  padding-right: 30px;
  position: relative;
}
.accordion-style1 .panel-title > span.pull-right {
  position: absolute;
  right: 0;
  top: 0;
}
.accordion-style1 .panel-heading i {
  font-size: 12px;
  color: #626262;
}
.accordion-style1.panel-group .panel-heading + .panel-collapse > .list-group,
.accordion-style1.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
  padding: 25px 0;
}

/* accordion style 2 */
.accordion-style2 .panel {
  background: transparent;
  border: none;
  margin-bottom: 15px;
  box-shadow: none;
}
.accordion-style2 .panel-title {
  background: transparent;
  border: none;
}
.accordion-style2 .panel .panel-heading {
  background: #fff;
  border-radius: 0;
  padding: 18px 28px 19px;
}
.accordion-style2 .panel-body {
  padding: 35px 28px 25px;
  border-top: none;
}
.accordion-style2 .panel-heading i {
  font-size: 25px;
  margin: 0;
}
.accordion-style2 .panel-title {
  padding: 0;
}
.accordion-style2 .tab-tag {
  font-size: 20px;
  padding: 0;
  margin-right: 7px;
  display: inline-block;
  vertical-align: top;
}
.accordion-style2 a,
.accordion-style2 a:hover,
.accordion-style2 a:hover i {
  color: #282828;
}
.accordion-style2 .panel-default:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 0;
}
.accordion-style2 {
  margin-bottom: 0;
}

/* accordion style 3 */
.accordion-style3 .panel {
  background-color: transparent;
  border: none;
  margin-bottom: 15px;
  box-shadow: none;
  border-radius: 0;
}
.accordion-style3 .panel-title {
  background: transparent;
  border: none;
}
.accordion-style3 .panel .panel-heading {
  background: #000;
  color: #fff;
  border-radius: 0;
  padding: 18px 28px 19px;
}
.accordion-style3 .panel-body {
  padding: 35px 28px 25px;
  border-top: none;
}
.accordion-style3 .panel-heading i {
  font-size: 25px;
  margin: 0;
  color: #fff;
}
.accordion-style3 .tab-tag {
  font-size: 20px;
  padding: 0;
  margin-right: 7px;
  display: inline-block;
  vertical-align: top;
}
.accordion-style3 a,
.accordion-style2 a:hover,
.accordion-style3 a:hover i {
  color: #fff;
}
.accordion-style3 .panel-default:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 0;
}
.accordion-style3 {
  margin-bottom: 0;
}

/* toggles */
.toggles .panel {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  border: 1px solid #ddd;
}
.toggles .panel-title {
  font-size: 13px;
  padding-right: 30px;
  position: relative;
}
.toggles .panel-title > span.pull-right {
  position: absolute;
  right: 0;
  top: 0;
}
.toggles .panel-heading {
  padding: 18px;
  background-color: transparent;
}
.toggles .panel-heading i {
  font-size: 12px;
  color: #626262;
}
.toggles .panel + .panel {
  margin-top: -1px;
}
.toggles .panel-body {
  padding: 25px;
}

/* tab */
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: 0;
  border-bottom: 1px solid;
}

/* tab style 1 */
.tab-style1 .nav-tabs {
  border: none;
  display: inline-block;
  width: 100%;
}
.tab-style1 .nav-tabs li {
  display: inline-block;
  float: none;
  width: 24%;
}
.tab-style1 .nav-tabs li a {
  /*padding: 0 41.6%;*/
  margin: 0;
}
.tab-style1 .nav-tabs li:hover {
  background: transparent;
}
.tab-style1 .nav-tabs > li > a,
.tab-style1 .nav-tabs > li > a:focus,
.tab-style1 .nav-tabs > li > a:hover {
  border: none;
  background-color: transparent;
}
.tab-style1 .nav-tabs li:hover i,
.tab-style1 .nav-tabs li.active i {
  color: #ff214f;
}
.tab-style1 .nav-tabs li:hover span,
.tab-style1 .nav-tabs li.active span {
  color: #232323;
}

/* tab style 2 */
.tab-style2 .tab-content {
  padding: 55px 0 0;
  border-top: 1px solid #e5e5e5;
}
.tab-style2 .nav-tabs {
  border: 0;
}
.tab-style2 .nav-tabs li {
  border: 1px solid #e5e5e5;
  border-bottom: none;
  border-radius: 0;
  margin-bottom: 0;
  display: inline-block;
  float: none;
  margin-right: 2px;
}
.tab-style2 .nav-tabs li a {
  background-color: #f7f7f7;
  border: none;
  color: #575757;
  line-height: 44px;
  margin: 0 !important;
  padding: 0 25px;
  border-radius: 0;
}
.tab-style2 .nav-tabs-light li:first-child {
  border-left: 1px solid #e5e5e5;
  margin-left: 15px;
}
.tab-style2 .nav-tabs > li.active > a {
  border-color: transparent;
  border: none;
  border-radius: 0;
  background: #fff;
  top: 1px;
}

/* tab style 3 */
.tab-style3 .nav-tabs {
  border-bottom: 1px solid transparent;
}
.tab-style3 .nav-tabs li {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
.tab-style3 .nav-tabs li {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
  padding: 3px 0;
}
.tab-style3 .nav-tabs li.active {
  border-bottom: 1px solid #232323;
}
.tab-style3 .nav-tabs li.active a {
  color: #232323;
}
.tab-style3 .nav-tabs > li > a,
.tab-style3 .nav-tabs > li > a:focus,
.tab-style3 .nav-tabs > li.active > a:hover,
.tab-style3 .nav-tabs > li > a:hover {
  background-color: transparent;
  border: none;
  padding: 0;
}

/* tab style 4 */
.tab-style4 .tab-content {
  padding: 0 0 0 55px;
  border-left: 1px solid #e5e5e5;
}
.tab-style4 .nav-tabs {
  border: 0;
}
.tab-style4 .nav-tabs li {
  border: 1px solid #e5e5e5;
  border-right: none;
  border-radius: 0;
  margin-bottom: 0;
  display: block;
  float: left;
  width: 100%;
  left: 1px;
}
.tab-style4 .nav-tabs li.active {
  z-index: 1;
}
.tab-style4 .nav-tabs li a {
  background-color: #f7f7f7;
  border: none;
  color: #575757;
  line-height: 44px;
  margin: 0 !important;
  padding: 0 25px;
  border-radius: 0;
  top: 1px;
}
.tab-style4 .nav-tabs li:last-child a {
  top: 0;
}
.tab-style4 .nav-tabs-light li:first-child {
  border-left: 1px solid #e5e5e5;
  margin-left: 15px;
}
.tab-style4 .nav-tabs > li.active > a {
  border-color: transparent;
  border: none;
  border-radius: 0;
  background: #fff;
}

/* testimonial style 2 */
.testimonial-style2 .profile-box {
  margin-top: -50px;
}

/* testimonial style 3 */
.testimonial-style3 .arrow-bottom:after {
  border-top-color: #d6d5d5;
  left: 15%;
  border-width: 10px;
  margin-left: -10px;
}

/* testimonial-box */
.testimonial-box .image-box {
  display: table-cell;
  vertical-align: middle;
}
.testimonial-box .name-box {
  display: table-cell;
  vertical-align: middle;
}

/* ===================================
   Blog
====================================== */

.blog-image a > img {
  width: 100%;
}

/* blog post style 1 */
.blog-post.blog-post-style1 .blog-post-images {
  background: #232323;
}
.blog-post.blog-post-style1 .blog-post-images img {
  width: 100%;
}
.blog-post.blog-post-style1:hover .blog-post-images img {
  opacity: 0.5;
  transform: scale(1.2, 1.2);
  transition: all 0.3s ease 0s;
}

/* blog post style 2 */
.blog-post.blog-post-style2 .author img {
  width: 30px;
  display: inline-block;
  vertical-align: middle;
}
.blog-post.blog-post-style2 .author span {
  max-width: calc(100% - 40px);
  vertical-align: middle;
  display: inline-block;
}

/* blog post style 3 */
.blog-post-style3 .grid-item .blog-post * {
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
}
.blog-post-style3 .grid-item .blog-post .author img {
  width: 25px;
}
.blog-post-style3 .grid-item .blog-post .blog-post-images a {
  display: block;
}
.blog-post-style3 .grid-item .blog-post .blog-post-images .blog-hover-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0;
}
.blog-post-style3 .grid-item .blog-post:hover .blog-post-images .blog-hover-icon {
  opacity: 1;
}
.blog-post-style3 .grid-item .blog-post .blog-post-images .blog-hover-icon span {
  top: 35%;
  position: relative;
  font-size: 48px;
  color: #fff;
  opacity: 0;
}
.blog-post-style3 .grid-item .blog-post:hover .blog-post-images .blog-hover-icon span {
  top: 40%;
  opacity: 1;
}

/* blog post style 4 */
.blog-post-style4 .blog-grid .grid-item figure {
  position: relative;
  overflow: hidden;
}
.blog-post-style4 figure figcaption {
  padding: 45px;
  bottom: 0px;
  position: absolute;
  background: rgba(0, 0, 0, 0.85);
  width: 100%;
  transition: ease-in-out 0.5s;
  -webkit-transition: ease-in-out 0.5s;
  -moz-transition: ease-in-out 0.5s;
  -ms-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
}
.blog-post-style4 .grid-item figure figcaption p {
  margin-bottom: 0;
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  display: none;
}
.blog-post-style4 .blog-grid .grid-item .blog-img img {
  cursor: pointer;
  display: block;
  width: 100%;
  opacity: 0.9;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.blog-post-style4 .blog-grid .grid-item:hover .blog-img img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  opacity: 0.5;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.blog-post-style4 .blog-grid a {
  position: relative;
  z-index: auto;
}

.container .blog-post-style4 figure figcaption {
  padding: 35px;
}

/* blog post style 5 */
.blog-post-style5 .blog-post .blog-post-images {
  height: 100%;
  position: relative;
}
.blog-post-style5 .blog-post .blog-categories {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 25;
  padding: 10px 20px 8px;
}

/* blog post style 6 */
.blog-post-style6:hover {
  background-color: #fff;
}
.blog-post-style6:hover .text-white {
  color: #000;
}
.blog-post-style6:hover .author:before {
  background-color: #000;
}
.blog-post-style6 .author:before {
  width: 100px;
  height: 1px;
  background-color: #fff;
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  content: '';
}

/* blog post style 7 */
.blog-post-style7:hover {
  background-color: #1c1c1c;
  color: #fff;
}
.blog-post-style7:hover a {
  color: #fff;
}
.blog-post-style7:hover .author:before {
  background-color: #fff;
  opacity: 0.8;
}
.blog-post-style7 .author:before {
  width: 100px;
  height: 1px;
  background-color: #939393;
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  content: '';
}

/* pagination */
.pagination {
  border-radius: 0;
  padding: 0;
  margin: 0;
}
.pagination ul {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin-left: 0;
  margin-bottom: 0;
  padding: 0;
}
.pagination li {
  display: inline;
}
.pagination a {
  float: left;
  padding: 0 18px;
  line-height: 40px;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
  background: #fff;
}
.pagination a:hover {
  background-color: #232323;
  color: #fff;
}
.pagination .active a {
  background-color: #f5f5f5;
}
.pagination .active a {
  color: #999999;
  cursor: default;
}
.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999999;
  background-color: transparent;
  cursor: default;
}
.pagination li:first-child a {
  border-left-width: 1px;
}
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}

/* blog details page */
/* author */
.about-author-img {
  float: left;
  max-width: 100px;
  margin-right: 35px;
  margin-bottom: 15px;
}
.about-author-text {
  top: -5px;
}
.author-sharing a {
  margin-right: 15px;
  color: #737373;
}
.author-sharing a:hover {
  color: #000;
}
.alignnone {
  margin: 5px 30px 40px 0;
}
.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}
.alignright {
  float: right;
  margin: 5px 0 30px 40px;
}
.alignleft {
  float: left;
  margin: 5px 40px 30px 0;
}
a img.alignright {
  float: right;
  margin: 5px 0 40px 30px;
}
a img.alignnone {
  margin: 5px 20px 20px 0;
}
a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wp-caption {
  background: #f7f7f7;
  padding: 17px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 50%;
}
.wp-caption figcaption {
  padding: 18px 25px 0 25px;
}
.wp-caption img {
  width: 100%;
}
.wp-caption.alignnone {
  margin: 5px 30px 40px 0;
}
.wp-caption.alignleft {
  margin: 5px 40px 30px 0;
}
.wp-caption.alignright {
  margin: 5px 0 40px 30px;
}
.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 1;
}

/* list style 6 */
.blog-comment {
  list-style: none;
  margin: 0;
  padding: 0;
}
.blog-comment li {
  padding: 50px 0;
  border-bottom: 1px solid #ededed;
  position: relative;
}
.blog-comment li .btn-reply {
  float: right;
  border: 1px solid #ededed;
  padding: 0 8px;
  font-size: 10px;
  font-weight: 600;
  position: relative;
}
.blog-comment > li:first-child {
  padding-top: 0;
}
.blog-comment li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.blog-comment li ul.child-comment {
  margin-left: 40px;
  list-style: none;
}
.blog-comment li ul.child-comment li {
  border: none;
  padding-bottom: 0;
  border-top: 1px solid #ededed;
  margin-top: 50px;
}

/* ===================================
    Footer
====================================== */

/* scroll to top */
.scroll-top-arrow,
.scroll-top-arrow:focus {
  color: #fff;
  background: #a024de;
  line-height: 28px;
  display: none;
  height: 30px;
  width: 30px;
  padding: 0;
  position: fixed;
  right: 45px;
  text-align: center;
  text-decoration: none;
  top: 91%;
  z-index: 10006;
  border-radius: 100%;
}
.scroll-top-arrow:hover {
  background: #000;
  opacity: 0.8;
  color: #fff;
  border-color: #ff214f;
}
.scroll-top-arrow i {
  line-height: 30px;
  position: relative;
}

.bg-dark-footer {
  background-color: #141414;
}
.footer-logo {
  max-height: none;
}

/* footer input */
footer input::-webkit-input-placeholder {
  color: #6f6f6f;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: 400;
  opacity: 1;
}
footer input::-moz-placeholder {
  color: #6f6f6f;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: 400;
  opacity: 1;
}
footer input:-ms-input-placeholder {
  color: #6f6f6f;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: 400;
  opacity: 1;
}
footer .newsletter input {
  color: #6f6f6f;
  letter-spacing: normal;
}

/* latest post */
.latest-post {
  list-style: none;
  padding: 0;
  margin: 0;
}
.latest-post li {
  display: table;
  padding: 15px 0;
}
aside .latest-post li {
  padding: 10px 0;
}
.latest-post li:first-child {
  padding-top: 0;
}
.latest-post li:last-child {
  border-bottom: none;
}
.latest-post li figure {
  height: 33px;
  width: 60px;
  float: left;
}
.latest-post li figure,
footer .latest-post li div {
  display: table-cell;
  vertical-align: top;
}
aside .latest-post li figure {
  width: 75px;
}
.latest-post li div {
  line-height: normal;
  padding-left: 13px;
  position: relative;
  top: -1px;
}
.latest-post li figure:hover img,
.latest-post li figure:focus img {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
}

/* newsletter */
.newsletter input {
  float: left;
  font-size: 12px;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 0 38px 0 12px;
}
.newsletter .btn.btn-arrow-small {
  background: transparent none repeat scroll 0 0;
  margin-right: 0;
  height: 100%;
  padding-top: 0;
  padding-bottom: 5px;
  text-align: center;
  right: 0;
  border-width: 0 0 0 1px;
}
.newsletter .btn.btn-arrow-small i {
  color: #626262;
  font-size: 18px;
  display: block;
  margin-top: 6px;
}
.newsletter {
  width: 100%;
  height: 33px;
}

.newsletter .btn.border-color-white {
  border-color: #fff;
}
.newsletter .btn.border-color-black {
  border-color: #000;
}
.newsletter .btn.border-color-extra-dark-gray {
  border-color: #232323;
}
.newsletter .btn.border-color-medium-dark-gray {
  border-color: #363636;
}
.newsletter .btn.border-color-dark-gray {
  border-color: #939393;
}
.newsletter .btn.border-color-extra-medium-gray {
  border-color: #dbdbdb;
}
.newsletter .btn.border-color-medium-gray {
  border-color: #e4e4e4;
}
.newsletter .btn.border-color-extra-light-gray {
  border-color: #ededed;
}
.newsletter .btn.border-color-light-gray {
  border-color: #f5f5f5;
}
.newsletter .btn.border-color-light-pink {
  border-color: #862237;
}
.newsletter .btn.border-color-deep-pink {
  border-color: #ff214f;
}
.newsletter .btn.border-color-transparent-pink {
  border-color: rgba(255, 33, 79, 0.45);
}

/* instagram feed */
.instagram-follow-api ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
}
.instagram-follow-api li {
  padding: 0 10px 10px 0;
  width: 33.333%;
  box-sizing: border-box;
  display: inline-block;
}
.instagram-follow-api li img {
  width: 100%;
}
.instagram-follow-api li figure {
  position: relative;
  overflow: hidden;
}
.instagram-follow-api li figure a .insta-counts {
  position: absolute;
  bottom: -100%;
  left: 0;
  text-align: center;
  background: rgba(35, 35, 35, 0.8);
  color: #fff;
  width: 100%;
  font-size: 12px;
}
.instagram-follow-api li figure:hover a .insta-counts {
  bottom: 0;
}
.instagram-follow-api li figure a .insta-counts i {
  margin-right: 5px;
  margin-left: 5px;
  vertical-align: middle;
}
.instagram-follow-api li figure span {
  vertical-align: middle;
}

/*instagram feed style 1*/
.instafeed-style1 {
  margin-bottom: 30px;
  overflow: hidden;
}
.instafeed-style1 .insta-image {
  width: 100%;
}
.instafeed-style1 .insta-link {
  position: relative;
  display: block;
  background: #232323;
}
.instafeed-style1 .insta-counts {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-30%);
  -webkit-transform: translateY(-30%);
  -moz-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  -o-transform: translateY(-30%);
  left: 0;
  width: 100%;
  color: #fff;
  opacity: 0;
}
.instafeed-style1 .insta-link:hover .insta-counts {
  opacity: 1;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.instafeed-style1 .insta-link:hover .insta-image {
  opacity: 0.2;
}
.instafeed-style1 .insta-counts i {
  vertical-align: middle;
  font-size: 28px;
}
.instafeed-style1 .insta-counts span {
  margin-right: 25px;
  position: relative;
}
.instafeed-style1 .insta-counts span.count-number {
  position: absolute;
  top: -14px;
  font-size: 12px;
  left: 15px;
  width: 22px;
  height: 22px;
  text-align: center;
  background: #ff214f;
  line-height: 20px;
  border-radius: 100%;
}

/* ===================================
   Home page
====================================== */

/* home - creative branding agency */
.box-layout {
  padding: 0 60px;
}

/* home - creative business */
.icon-box {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.icon-box:last-child {
  border-bottom: none;
}
.icon-box .icon-box-holder {
  padding-left: 74px;
  height: 112px;
}
.icon-box .icon-box-holder i {
  position: absolute;
  left: 0;
  vertical-align: middle;
  padding-top: 10px;
}

/* home - creative simple portfolio */
.head-text {
  font-size: 80px;
  line-height: 95px;
}

/* home - classic digital agency */
.image-block {
  box-shadow: 0 0 0 rgba(35, 35, 35, 0.6);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
  -ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
}
.attr-nav > ul > li > a {
  padding: 3px 15px;
}
.icon-play img {
  width: 60%;
}
.icon-play:hover img {
  width: 68%;
}

/* home - creative studio */
.icon-box-holder a:hover {
  color: #fff;
}

/* home - portfolio personal */
.hover-title-box {
  position: absolute;
  right: -100px;
  opacity: 0;
  top: 50%;
  transform: translateY(-53px);
  -webkit-transform: translateY(-53px);
  -moz-transform: translateY(-53px);
  -ms-transform: translateY(-53px);
  -o-transform: translateY(-53px);
  z-index: 99;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.hover-title-box .separator {
  height: 1px;
  content: '';
  position: absolute;
  left: -10px;
  top: 30px;
}

/* home - creative small business */
.gridblock-main {
  padding: 110px 90px;
}
.gridblock-list li {
  width: 33.33%;
  float: left;
  padding: 0;
  text-align: center;
}
.gridblock-list > * {
  vertical-align: top;
  display: inline-block;
  content: '';
}
.gridblock-list > :nth-child(3n) {
  margin-right: 0;
}

/* home - blog masonry */
.blog-header-style1 {
  margin: 0 px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: start;
}
.blog-header-style1 li {
  width: 30%;
  padding: 0 px;
  margin: 0 -4px 0 0;
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  vertical-align: top;
  transition: 0.8s;
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -ms-transition: 0.8s;
  -o-transition: 0.8s;
}
.blog-header-style1 li .blog-banner {
  display: block;
  position: relative;
  height: 100%;
}
.blog-header-style1 li figure figcaption {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 50px;
  transform: translateY(60px);
  -webkit-transform: translateY(60px);
  -moz-transform: translateY(60px);
  -ms-transform: translateY(60px);
  -o-transform: translateY(60px);
}
.blog-header-style1 li.blog-column-active figure figcaption {
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
}
.blog-header-style1 li figure figcaption .btn {
  opacity: 0;
  transform: translateY(60px);
  -webkit-transform: translateY(60px);
  -moz-transform: translateY(60px);
  -ms-transform: translateY(60px);
  -o-transform: translateY(60px);
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -ms-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
}
.blog-header-style1 li.blog-column-active figure figcaption .btn {
  opacity: 1;
  transition-delay: 0.6s;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}
.blog-header-style1 li.blog-column-active {
  width: 50%;
  transition-duration: 0.8s;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
}
.blog-header-style1 li .blog-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* home - blog clean */
.blog-box .blog-box-image,
.blog-box .blog-box-content {
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.blog-box .blog-box-image {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  opacity: 1;
}
.blog-box .blog-box-content {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  opacity: 0;
}
.blog-box:hover .blog-box-image {
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  opacity: 0;
}
.blog-box:hover .blog-box-content {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  opacity: 1;
}

/* home - classic innovation agency */
.box-separator-line {
  height: 1px;
  content: '';
  position: absolute;
  left: -90px;
  top: 130px;
}
.right-image {
  bottom: -200px;
  position: absolute;
  right: -100px;
  width: 60%;
}
.right-image img {
  max-width: none;
}
.content-right-slider .swiper-button-next {
  right: 70px;
}
.content-right-slider .swiper-button-prev {
  left: 70px;
}

/* home - creative designer */
.color-code {
  justify-content: space-around;
  align-items: center;
  z-index: 0;
}
.bg-background-fade {
  background-color: #ff5851;
}
.color-1,
.color-2,
.color-3,
.color-4,
.color-5 {
  -webkit-transition: background-color 700ms linear;
  -moz-transition: background-color 700ms linear;
  -o-transition: background-color 700ms linear;
  -ms-transition: background-color 700ms linear;
  transition: background-color 700ms linear;
}
.color-1 {
  background-color: #ff5851;
}
.color-2 {
  background-color: #e86618;
}
.color-3 {
  background-color: #ef3158;
}
.color-4 {
  background-color: #6b0990;
}
.color-5 {
  background-color: #4c0990;
}
.color-6 {
  background-color: #1b3194;
}

/* home - classic web agency */
.after-before-separator:before,
.after-before-separator:after {
  width: 35px;
  margin: 0 15px;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  content: '';
  display: inline-block;
  vertical-align: super;
}
.background-slide {
  height: auto;
  min-height: 700px;
}

/* home - classic corporate */
.rev-slider .tp-bullet {
  width: 35px;
  height: 3px;
  background-color: #ffffff;
  opacity: 0.5;
  border-radius: 0;
  padding: 0 4px;
}
.rev-slider .tp-bullet.selected,
.tp-bullet:hover {
  opacity: 1;
}

/* home - portfolio metro */
.text-bold-underline:before {
  background-color: #ff214f;
  height: 3px;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 5px;
  content: '';
}

/* home - portfolio  parallax */
.parallax-title {
  padding: 16px 35px;
  display: inline-block;
}

/* home - blog grid */
.highlight-bg-text {
  padding: 5px 16px;
  display: inline-block;
  margin-bottom: 15px;
}

/* ===================================
   Others
====================================== */

/* services modern */
.arrow-bottom {
  position: relative;
}
.arrow-bottom:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(245, 245, 245, 0);
  border-top-color: #f5f5f5;
  border-width: 15px;
  margin-left: -15px;
  z-index: 9;
}
.arrow-top {
  position: relative;
}
.arrow-top:after {
  top: -29px;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(245, 245, 245, 0);
  border-bottom-color: #f5f5f5;
  border-width: 15px;
  margin-left: -15px;
  z-index: 9;
}

/* our clients */
.clients-list {
  height: 190px;
}
.clients-list:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
.clients-list:hover img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

/* sidebar nav style 2 */
.tparrows:before {
  font-family: 'FontAwesome';
}
.tparrows.tp-leftarrow:before {
  content: '\f060';
}
.tparrows.tp-rightarrow:before {
  content: '\f061';
}

/* rev slider */
.rev_slider_wrapper {
  height: 980px;
}

/* animation */
@-webkit-keyframes up-down-animation {
  0% {
    opacity: 1;
    top: 0px;
  }
  25% {
    opacity: 0.4;
    top: 2px;
  }
  50% {
    opacity: 0.3;
    top: 4px;
  }
  75% {
    opacity: 0.2;
    top: 5px;
  }
  100% {
    opacity: 0;
    top: 9px;
  }
}
@keyframes up-down-animation {
  0% {
    opacity: 1;
    top: 0px;
  }
  25% {
    opacity: 0.4;
    top: 4px;
  }
  50% {
    opacity: 0.3;
    top: 8px;
  }
  75% {
    opacity: 0.2;
    top: 12px;
  }
  100% {
    opacity: 0;
    top: 16px;
  }
}

body::-webkit-scrollbar {
  background: #3e0065;
  height: 8px;
  width: 8px;
}

body::-webkit-scrollbar-track {
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #a024de;
}

/* ===================================
   demo page css
====================================== */

.demo-heading {
  font-size: 40px;
  line-height: 46px;
}
.buy-theme {
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  position: fixed;
  top: 130px;
  right: -70px;
  background: #fff;
  z-index: 1000;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}
.buy-theme i {
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  color: #6f6f6f;
}
.all-demo i {
  font-size: 15px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  color: #6f6f6f;
}
.buy-theme:hover,
.all-demo:hover {
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  right: 0px;
  background: #ff214f;
}
.buy-theme span,
.all-demo span {
  padding: 0 9px;
  position: relative;
  top: 0;
  opacity: 0;
}
.buy-theme:hover span,
.all-demo:hover span {
  opacity: 1;
  color: #fff;
}
.buy-theme:hover i,
.all-demo:hover i {
  color: #fff;
}
.buy-theme a,
.all-demo a {
  color: #232323;
  font-size: 10px;
  text-transform: uppercase;
  padding: 2px 10px;
  display: block;
  text-decoration: none;
  font-weight: 500;
}
.all-demo {
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  position: fixed;
  top: 172px;
  right: -102px;
  background: #fff;
  z-index: 1000;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}
.progress-bar {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  height: 2px;
  background: #660099;
  overflow: hidden;
}
.progress-bar .progress-bar-inner {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: #660099; */
  background: -moz-linear-gradient(left, #d84449 50%, #660099 100%);
  background: -webkit-linear-gradient(left, #d84449 50%, #660099 100%);
  background: -webkit-gradient(left, #d84449 50%, #660099 100%);
  background: -o-linear-gradient(left, #d84449 50%, #660099 100%);
  background: linear-gradient(left, #d84449 50%, #660099 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#660099', endColorstr='#660099',GradientType=1 );
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

#error-contact-form-2 {
  clear: both;
  margin: 0 15px 15px;
  text-align: center;
  border: 1px solid #940a38;
  color: #940a38;
  padding: 2px 0;
  display: none;
  font-size: 11px;
}
