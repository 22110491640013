@import '~react-pro-sidebar/dist/scss/styles.scss';

.btn-save{
    background-color: var(--cor-principal) !important;
    border-color: var(--cor-principal) !important;

    &:hover {
        background-color: #3b3e4d !important;
        border-color: var(--cor-principal) !important;
    }
}