@media (min-width: 992px) {
  .feature-box-14 > div:nth-child(even) {
    border-bottom: 0;
  }
  .feature-box-14 > div:nth-child(odd) {
    border-bottom: 0;
  }

  /*top logo*/
  .navbar-brand-top .navbar-collapse.collapse.display-inline-block {
    display: inline-block !important;
    vertical-align: middle;
  }
  .navbar-brand-top .nav-header-container {
    text-align: center;
  }
  .navbar-brand-top .nav-header-container .row {
    display: block !important;
    display: block !important;
    display: block !important;
  }
  .navbar-brand-top .brand-top-menu-right {
    vertical-align: middle;
    position: relative;
  }
  .navbar.navbar-brand-top .row > div:first-child {
    flex: inherit;
  }
}
@media (min-width: 768px) {
  .feature-box-14 > div:nth-last-child(2) {
    border-bottom: 0;
  }
}

@media (max-width: 1500px) {
  /* sidebar navigation */
  .sidebar-wrapper .container {
    width: 1000px;
  }

  /* blog post style 2 */
  .blog-post.blog-post-style2 .author span {
    padding-left: 10px;
  }

  /* homepage 14 */
  .right-image {
    width: 70%;
  }
}

@media (max-width: 1400px) {
  .head-text {
    font-size: 60px;
    line-height: 70px;
  }
  .banner-style2 figcaption {
    width: 70%;
  }
  .banner-style1 figure figcaption img {
    width: 100px;
  }
  .banner-style1 figure:hover figcaption p {
    height: 150px;
  }
  .feature-box.feature-box-17 .box .content {
    top: 40px;
  }
}

@media (max-width: 1300px) {
  /* sidebar navigation */
  .sidebar-wrapper .container {
    width: 830px;
  }

  /* feature box style 7 */
  .feature-box.feature-box-7 div:first-child .box {
    border-left: medium none;
  }

  /* feature box style 4 */
  .grid-item.feature-box-4 figure figcaption {
    width: 90%;
    padding: 0 0 15px 30px;
  }

  /* menu center */
  .menu-center .header-social-icon a i,
  .menu-center .header-searchbar a i {
    margin: 0 8px;
  }
  .menu-center .header-social-icon {
    padding-left: 10px;
  }
  .menu-center .header-searchbar {
    padding-left: 0;
    padding-right: 5px;
  }
  .menu-center .header-social-icon {
    margin-left: 0;
  }

  /* sidebar nav style 2 */
  .left-nav-sidebar .container {
    width: 1070px;
  }
}

@media (max-width: 1250px) {
  /*skillbar bar style2*/
  .box-layout .skillbar-bar-style2 .skillbar {
    width: 75%;
  }
}

@media (max-width: 1199px) {
  .panel-group * {
    transition-timing-function: none;
    -moz-transition-timing-function: none;
    -webkit-transition-timing-function: none;
    -o-transition-timing-function: none;
    transition-duration: 0s;
    -ms-transition-timing-function: none;
    -moz-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
  }
  section {
    padding: 90px 0;
  }

  /* typography */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 22px;
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
  }
  h1 {
    font-size: 65px;
    line-height: 65px;
  }
  h2 {
    font-size: 50px;
    line-height: 55px;
  }
  h3 {
    font-size: 42px;
    line-height: 48px;
  }
  h4 {
    font-size: 35px;
    line-height: 41px;
  }
  h5 {
    font-size: 27px;
    line-height: 32px;
  }
  h6 {
    font-size: 22px;
    line-height: 27px;
  }

  /*text-alignment*/
  .md-text-center {
    text-align: center;
  }
  .md-text-left {
    text-align: left;
  }
  .md-text-right {
    text-align: right;
  }

  /*float*/
  .md-float-left {
    float: left !important;
  }
  .md-float-right {
    float: right !important;
  }
  .md-float-none {
    float: none !important;
  }

  /* verticle align */
  .md-vertical-align-middle {
    vertical-align: middle;
  }
  .md-vertical-align-top {
    vertical-align: top;
  }
  .md-vertical-align-bottom {
    vertical-align: bottom;
  }

  /* image position*/
  .md-background-image-left {
    background-position: left;
  }
  .md-background-image-right {
    background-position: right;
  }
  .md-background-image-center {
    background-position: center;
  }

  /* margin */
  .md-margin-one-all {
    margin: 1%;
  }
  .md-margin-one-half-all {
    margin: 1.5%;
  }
  .md-margin-two-all {
    margin: 2%;
  }
  .md-margin-two-half-all {
    margin: 2.5%;
  }
  .md-margin-three-all {
    margin: 3%;
  }
  .md-margin-three-half-all {
    margin: 3.5%;
  }
  .md-margin-four-all {
    margin: 4%;
  }
  .md-margin-four-half-all {
    margin: 4.5%;
  }
  .md-margin-five-all {
    margin: 5%;
  }
  .md-margin-five-half-all {
    margin: 5.5%;
  }
  .md-margin-six-all {
    margin: 6%;
  }
  .md-margin-six-half-all {
    margin: 6.5%;
  }
  .md-margin-seven-all {
    margin: 7%;
  }
  .md-margin-seven-half-all {
    margin: 7.5%;
  }
  .md-margin-eight-all {
    margin: 8%;
  }
  .md-margin-eight-half-all {
    margin: 8.5%;
  }
  .md-margin-nine-all {
    margin: 9%;
  }
  .md-margin-nine-half-all {
    margin: 9.5%;
  }
  .md-margin-ten-all {
    margin: 10%;
  }
  .md-margin-ten-half-all {
    margin: 10.5%;
  }
  .md-margin-eleven-all {
    margin: 11%;
  }
  .md-margin-eleven-half-all {
    margin: 11.5%;
  }
  .md-margin-twelve-all {
    margin: 12%;
  }
  .md-margin-twelve-half-all {
    margin: 12.5%;
  }
  .md-margin-thirteen-all {
    margin: 13%;
  }
  .md-margin-thirteen-half-all {
    margin: 13.5%;
  }
  .md-margin-fourteen-all {
    margin: 14%;
  }
  .md-margin-fourteen-half-all {
    margin: 14.5%;
  }
  .md-margin-fifteen-all {
    margin: 15%;
  }
  .md-margin-fifteen-half-all {
    margin: 15.5%;
  }
  .md-margin-sixteen-all {
    margin: 16%;
  }
  .md-margin-sixteen-half-all {
    margin: 16.5%;
  }
  .md-margin-seventeen-all {
    margin: 17%;
  }
  .md-margin-seventeen-half-all {
    margin: 17.5%;
  }
  .md-margin-eighteen-all {
    margin: 18%;
  }
  .md-margin-eighteen-half-all {
    margin: 18.5%;
  }
  .md-margin-nineteen-all {
    margin: 19%;
  }
  .md-margin-nineteen-half-all {
    margin: 19.5%;
  }
  .md-margin-twenty-all {
    margin: 20%;
  }
  .md-margin-twenty-half-all {
    margin: 20.5%;
  }
  .md-margin-twenty-one-all {
    margin: 21%;
  }
  .md-margin-twenty-one-half-all {
    margin: 21.5%;
  }
  .md-margin-twenty-two-all {
    margin: 22%;
  }
  .md-margin-twenty-two-half-all {
    margin: 22.5%;
  }
  .md-margin-twenty-three-all {
    margin: 23%;
  }
  .md-margin-twenty-three-half-all {
    margin: 23.5%;
  }
  .md-margin-twenty-four-all {
    margin: 24%;
  }
  .md-margin-twenty-four-half-all {
    margin: 24.5%;
  }
  .md-margin-twenty-five-all {
    margin: 25%;
  }
  .md-margin-5px-all {
    margin: 5px !important;
  }
  .md-margin-10px-all {
    margin: 10px !important;
  }
  .md-margin-15px-all {
    margin: 15px !important;
  }
  .md-margin-20px-all {
    margin: 20px !important;
  }
  .md-margin-25px-all {
    margin: 25px !important;
  }
  .md-margin-30px-all {
    margin: 30px !important;
  }
  .md-margin-35px-all {
    margin: 35px !important;
  }
  .md-margin-40px-all {
    margin: 40px !important;
  }
  .md-margin-45px-all {
    margin: 45px !important;
  }
  .md-margin-50px-all {
    margin: 50px !important;
  }
  .md-margin-55px-all {
    margin: 55px !important;
  }
  .md-margin-60px-all {
    margin: 60px !important;
  }
  .md-margin-65px-all {
    margin: 65px !important;
  }
  .md-margin-70px-all {
    margin: 70px !important;
  }
  .md-margin-75px-all {
    margin: 75px !important;
  }
  .md-margin-80px-all {
    margin: 80px !important;
  }
  .md-margin-85px-all {
    margin: 85px !important;
  }
  .md-margin-90px-all {
    margin: 90px !important;
  }
  .md-margin-95px-all {
    margin: 95px !important;
  }
  .md-margin-100px-all {
    margin: 100px !important;
  }

  .md-no-margin {
    margin: 0 !important;
  }
  .md-no-margin-lr {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .md-no-margin-tb {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .md-no-margin-top {
    margin-top: 0 !important;
  }
  .md-no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .md-no-margin-left {
    margin-left: 0 !important;
  }
  .md-no-margin-right {
    margin-right: 0 !important;
  }
  .md-margin-lr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .md-margin-auto {
    margin: 0 auto !important;
  }

  /* margin top */
  .md-margin-one-top {
    margin-top: 1%;
  }
  .md-margin-one-half-top {
    margin-top: 1.5%;
  }
  .md-margin-two-top {
    margin-top: 2%;
  }
  .md-margin-two-half-top {
    margin-top: 2.5%;
  }
  .md-margin-three-top {
    margin-top: 3%;
  }
  .md-margin-three-half-top {
    margin-top: 3.5%;
  }
  .md-margin-four-top {
    margin-top: 4%;
  }
  .md-margin-four-half-top {
    margin-top: 4.5%;
  }
  .md-margin-five-top {
    margin-top: 5%;
  }
  .md-margin-five-half-top {
    margin-top: 5.5%;
  }
  .md-margin-six-top {
    margin-top: 6%;
  }
  .md-margin-six-half-top {
    margin-top: 6.5%;
  }
  .md-margin-seven-top {
    margin-top: 7%;
  }
  .md-margin-seven-half-top {
    margin-top: 7.5%;
  }
  .md-margin-eight-top {
    margin-top: 8%;
  }
  .md-margin-eight-half-top {
    margin-top: 8.5%;
  }
  .md-margin-nine-top {
    margin-top: 9%;
  }
  .md-margin-nine-half-top {
    margin-top: 9.5%;
  }
  .md-margin-ten-top {
    margin-top: 10%;
  }
  .md-margin-ten-half-top {
    margin-top: 10.5%;
  }
  .md-margin-eleven-top {
    margin-top: 11%;
  }
  .md-margin-eleven-half-top {
    margin-top: 11.5%;
  }
  .md-margin-twelve-top {
    margin-top: 12%;
  }
  .md-margin-twelve-half-top {
    margin-top: 12.5%;
  }
  .md-margin-thirteen-top {
    margin-top: 13%;
  }
  .md-margin-thirteen-half-top {
    margin-top: 13.5%;
  }
  .md-margin-fourteen-top {
    margin-top: 14%;
  }
  .md-margin-fourteen-half-top {
    margin-top: 14.5%;
  }
  .md-margin-fifteen-top {
    margin-top: 15%;
  }
  .md-margin-fifteen-half-top {
    margin-top: 15.5%;
  }
  .md-margin-sixteen-top {
    margin-top: 16%;
  }
  .md-margin-sixteen-half-top {
    margin-top: 16.5%;
  }
  .md-margin-seventeen-top {
    margin-top: 17%;
  }
  .md-margin-seventeen-half-top {
    margin-top: 17.5%;
  }
  .md-margin-eighteen-top {
    margin-top: 18%;
  }
  .md-margin-eighteen-half-top {
    margin-top: 18.5%;
  }
  .md-margin-nineteen-top {
    margin-top: 19%;
  }
  .md-margin-nineteen-half-top {
    margin-top: 19.5%;
  }
  .md-margin-twenty-top {
    margin-top: 20%;
  }
  .md-margin-twenty-half-top {
    margin-top: 20.5%;
  }
  .md-margin-twenty-one-top {
    margin-top: 21%;
  }
  .md-margin-twenty-one-half-top {
    margin-top: 21.5%;
  }
  .md-margin-twenty-two-top {
    margin-top: 22%;
  }
  .md-margin-twenty-two-half-top {
    margin-top: 22.5%;
  }
  .md-margin-twenty-top {
    margin-top: 23%;
  }
  .md-margin-twenty-half-top {
    margin-top: 23.5%;
  }
  .md-margin-twenty-four-top {
    margin-top: 24%;
  }
  .md-margin-twenty-four-half-top {
    margin-top: 24.5%;
  }
  .md-margin-twenty-five-top {
    margin-top: 25%;
  }
  .md-margin-5px-top {
    margin-top: 5px !important;
  }
  .md-margin-10px-top {
    margin-top: 10px !important;
  }
  .md-margin-15px-top {
    margin-top: 15px !important;
  }
  .md-margin-20px-top {
    margin-top: 20px !important;
  }
  .md-margin-25px-top {
    margin-top: 25px !important;
  }
  .md-margin-30px-top {
    margin-top: 30px !important;
  }
  .md-margin-35px-top {
    margin-top: 35px !important;
  }
  .md-margin-40px-top {
    margin-top: 40px !important;
  }
  .md-margin-45px-top {
    margin-top: 45px !important;
  }
  .md-margin-50px-top {
    margin-top: 50px !important;
  }
  .md-margin-55px-top {
    margin-top: 55px !important;
  }
  .md-margin-60px-top {
    margin-top: 60px !important;
  }
  .md-margin-65px-top {
    margin-top: 65px !important;
  }
  .md-margin-70px-top {
    margin-top: 70px !important;
  }
  .md-margin-75px-top {
    margin-top: 75px !important;
  }
  .md-margin-80px-top {
    margin-top: 80px !important;
  }
  .md-margin-85px-top {
    margin-top: 85px !important;
  }
  .md-margin-90px-top {
    margin-top: 90px !important;
  }
  .md-margin-95px-top {
    margin-top: 95px !important;
  }
  .md-margin-100px-top {
    margin-top: 100px !important;
  }

  /* margin bottom */
  .md-margin-one-bottom {
    margin-bottom: 1%;
  }
  .md-margin-one-half-bottom {
    margin-bottom: 1.5%;
  }
  .md-margin-two-bottom {
    margin-bottom: 2%;
  }
  .md-margin-two-half-bottom {
    margin-bottom: 2.5%;
  }
  .md-margin-three-bottom {
    margin-bottom: 3%;
  }
  .md-margin-three-half-bottom {
    margin-bottom: 3.5%;
  }
  .md-margin-four-bottom {
    margin-bottom: 4%;
  }
  .md-margin-four-half-bottom {
    margin-bottom: 4.5%;
  }
  .md-margin-five-bottom {
    margin-bottom: 5%;
  }
  .md-margin-five-half-bottom {
    margin-bottom: 5.5%;
  }
  .md-margin-six-bottom {
    margin-bottom: 6%;
  }
  .md-margin-six-half-bottom {
    margin-bottom: 6.5%;
  }
  .md-margin-seven-bottom {
    margin-bottom: 7%;
  }
  .md-margin-seven-half-bottom {
    margin-bottom: 7.5%;
  }
  .md-margin-eight-bottom {
    margin-bottom: 8%;
  }
  .md-margin-eight-half-bottom {
    margin-bottom: 8.5%;
  }
  .md-margin-nine-bottom {
    margin-bottom: 9%;
  }
  .md-margin-nine-half-bottom {
    margin-bottom: 9.5%;
  }
  .md-margin-ten-bottom {
    margin-bottom: 10%;
  }
  .md-margin-ten-half-bottom {
    margin-bottom: 10.5%;
  }
  .md-margin-eleven-bottom {
    margin-bottom: 11%;
  }
  .md-margin-eleven-half-bottom {
    margin-bottom: 11.5%;
  }
  .md-margin-twelve-bottom {
    margin-bottom: 12%;
  }
  .md-margin-twelve-half-bottom {
    margin-bottom: 12.5%;
  }
  .md-margin-thirteen-bottom {
    margin-bottom: 13%;
  }
  .md-margin-thirteen-half-bottom {
    margin-bottom: 13.5%;
  }
  .md-margin-fourteen-bottom {
    margin-bottom: 14%;
  }
  .md-margin-fourteen-half-bottom {
    margin-bottom: 14.5%;
  }
  .md-margin-fifteen-bottom {
    margin-bottom: 15%;
  }
  .md-margin-fifteen-half-bottom {
    margin-bottom: 15.5%;
  }
  .md-margin-sixteen-bottom {
    margin-bottom: 16%;
  }
  .md-margin-sixteen-half-bottom {
    margin-bottom: 16.5%;
  }
  .md-margin-seventeen-bottom {
    margin-bottom: 17%;
  }
  .md-margin-seventeen-half-bottom {
    margin-bottom: 17.5%;
  }
  .md-margin-eighteen-bottom {
    margin-bottom: 18%;
  }
  .md-margin-eighteen-half-bottom {
    margin-bottom: 18.5%;
  }
  .md-margin-nineteen-bottom {
    margin-bottom: 19%;
  }
  .md-margin-nineteen-half-bottom {
    margin-bottom: 19.5%;
  }
  .md-margin-twenty-bottom {
    margin-bottom: 20%;
  }
  .md-margin-twenty-half-bottom {
    margin-bottom: 20.5%;
  }
  .md-margin-twenty-one-bottom {
    margin-bottom: 21%;
  }
  .md-margin-twenty-one-half-bottom {
    margin-bottom: 21.5%;
  }
  .md-margin-twenty-two-bottom {
    margin-bottom: 22%;
  }
  .md-margin-twenty-two-half-bottom {
    margin-bottom: 22.5%;
  }
  .md-margin-twenty-three-bottom {
    margin-bottom: 23%;
  }
  .md-margin-twenty-three-half-bottom {
    margin-bottom: 23.5%;
  }
  .md-margin-twenty-five-bottom {
    margin-bottom: 24%;
  }
  .md-margin-twenty-five-half-bottom {
    margin-bottom: 24.5%;
  }
  .md-margin-twenty-five-bottom {
    margin-bottom: 25%;
  }
  .md-margin-5px-bottom {
    margin-bottom: 5px !important;
  }
  .md-margin-10px-bottom {
    margin-bottom: 10px !important;
  }
  .md-margin-15px-bottom {
    margin-bottom: 15px !important;
  }
  .md-margin-20px-bottom {
    margin-bottom: 20px !important;
  }
  .md-margin-25px-bottom {
    margin-bottom: 25px !important;
  }
  .md-margin-30px-bottom {
    margin-bottom: 30px !important;
  }
  .md-margin-35px-bottom {
    margin-bottom: 35px !important;
  }
  .md-margin-40px-bottom {
    margin-bottom: 40px !important;
  }
  .md-margin-45px-bottom {
    margin-bottom: 45px !important;
  }
  .md-margin-50px-bottom {
    margin-bottom: 50px !important;
  }
  .md-margin-55px-bottom {
    margin-bottom: 55px !important;
  }
  .md-margin-60px-bottom {
    margin-bottom: 60px !important;
  }
  .md-margin-65px-bottom {
    margin-bottom: 65px !important;
  }
  .md-margin-70px-bottom {
    margin-bottom: 70px !important;
  }
  .md-margin-75px-bottom {
    margin-bottom: 75px !important;
  }
  .md-margin-80px-bottom {
    margin-bottom: 80px !important;
  }
  .md-margin-85px-bottom {
    margin-bottom: 85px !important;
  }
  .md-margin-90px-bottom {
    margin-bottom: 90px !important;
  }
  .md-margin-95px-bottom {
    margin-bottom: 95px !important;
  }
  .md-margin-100px-bottom {
    margin-bottom: 100px !important;
  }

  /* margin right */
  .md-margin-one-right {
    margin-right: 1%;
  }
  .md-margin-one-half-right {
    margin-right: 1.5%;
  }
  .md-margin-two-right {
    margin-right: 2%;
  }
  .md-margin-two-half-right {
    margin-right: 2.5%;
  }
  .md-margin-three-right {
    margin-right: 3%;
  }
  .md-margin-three-half-right {
    margin-right: 3.5%;
  }
  .md-margin-four-right {
    margin-right: 4%;
  }
  .md-margin-four-half-right {
    margin-right: 4.5%;
  }
  .md-margin-five-right {
    margin-right: 5%;
  }
  .md-margin-five-half-right {
    margin-right: 5.5%;
  }
  .md-margin-six-right {
    margin-right: 6%;
  }
  .md-margin-six-half-right {
    margin-right: 6.5%;
  }
  .md-margin-seven-right {
    margin-right: 7%;
  }
  .md-margin-seven-half-right {
    margin-right: 7.5%;
  }
  .md-margin-eight-right {
    margin-right: 8%;
  }
  .md-margin-eight-half-right {
    margin-right: 8.5%;
  }
  .md-margin-nine-right {
    margin-right: 9%;
  }
  .md-margin-nine-half-right {
    margin-right: 9.5%;
  }
  .md-margin-ten-right {
    margin-right: 10%;
  }
  .md-margin-ten-half-right {
    margin-right: 10.5%;
  }
  .md-margin-eleven-right {
    margin-right: 11%;
  }
  .md-margin-eleven-half-right {
    margin-right: 11.5%;
  }
  .md-margin-twelve-right {
    margin-right: 12%;
  }
  .md-margin-twelve-half-right {
    margin-right: 12.5%;
  }
  .md-margin-thirteen-right {
    margin-right: 13%;
  }
  .md-margin-thirteen-half-right {
    margin-right: 13.5%;
  }
  .md-margin-fourteen-right {
    margin-right: 14%;
  }
  .md-margin-fourteen-half-right {
    margin-right: 14.5%;
  }
  .md-margin-fifteen-right {
    margin-right: 15%;
  }
  .md-margin-fifteen-half-right {
    margin-right: 15.5%;
  }
  .md-margin-sixteen-right {
    margin-right: 16%;
  }
  .md-margin-sixteen-half-right {
    margin-right: 16.5%;
  }
  .md-margin-seventeen-right {
    margin-right: 17%;
  }
  .md-margin-seventeen-half-right {
    margin-right: 17.5%;
  }
  .md-margin-eighteen-right {
    margin-right: 18%;
  }
  .md-margin-eighteen-half-right {
    margin-right: 18.5%;
  }
  .md-margin-nineteen-right {
    margin-right: 19%;
  }
  .md-margin-nineteen-half-right {
    margin-right: 19.5%;
  }
  .md-margin-twenty-right {
    margin-right: 20%;
  }
  .md-margin-twenty-half-right {
    margin-right: 20.5%;
  }
  .md-margin-twenty-one-right {
    margin-right: 21%;
  }
  .md-margin-twenty-one-half-right {
    margin-right: 21.5%;
  }
  .md-margin-twenty-two-right {
    margin-right: 22%;
  }
  .md-margin-twenty-two-half-right {
    margin-right: 22.5%;
  }
  .md-margin-twenty-three-right {
    margin-right: 23%;
  }
  .md-margin-twenty-three-half-right {
    margin-right: 23.5%;
  }
  .md-margin-twenty-four-right {
    margin-right: 24%;
  }
  .md-margin-twenty-four-half-right {
    margin-right: 24.5%;
  }
  .md-margin-twenty-five-right {
    margin-right: 25%;
  }
  .md-margin-10px-right {
    margin-right: 10px !important;
  }
  .md-margin-15px-right {
    margin-right: 15px !important;
  }
  .md-margin-20px-right {
    margin-right: 20px !important;
  }
  .md-margin-25px-right {
    margin-right: 25px !important;
  }
  .md-margin-30px-right {
    margin-right: 30px !important;
  }
  .md-margin-35px-right {
    margin-right: 35px !important;
  }
  .md-margin-40px-right {
    margin-right: 40px !important;
  }
  .md-margin-45px-right {
    margin-right: 45px !important;
  }
  .md-margin-50px-right {
    margin-right: 50px !important;
  }
  .md-margin-55px-right {
    margin-right: 55px !important;
  }
  .md-margin-60px-right {
    margin-right: 60px !important;
  }
  .md-margin-65px-right {
    margin-right: 65px !important;
  }
  .md-margin-70px-right {
    margin-right: 70px !important;
  }
  .md-margin-75px-right {
    margin-right: 75px !important;
  }
  .md-margin-80px-right {
    margin-right: 80px !important;
  }
  .md-margin-85px-right {
    margin-right: 85px !important;
  }
  .md-margin-90px-right {
    margin-right: 90px !important;
  }
  .md-margin-95px-right {
    margin-right: 95px !important;
  }
  .md-margin-100px-right {
    margin-right: 100px !important;
  }

  /* margin left */
  .md-margin-one-left {
    margin-left: 1%;
  }
  .md-margin-one-half-left {
    margin-left: 1.5%;
  }
  .md-margin-two-left {
    margin-left: 2%;
  }
  .md-margin-two-half-left {
    margin-left: 2.5%;
  }
  .md-margin-three-left {
    margin-left: 3%;
  }
  .md-margin-three-half-left {
    margin-left: 3.5%;
  }
  .md-margin-four-left {
    margin-left: 4%;
  }
  .md-margin-four-half-left {
    margin-left: 4.5%;
  }
  .md-margin-five-left {
    margin-left: 5%;
  }
  .md-margin-five-half-left {
    margin-left: 5.5%;
  }
  .md-margin-six-left {
    margin-left: 6%;
  }
  .md-margin-six-half-left {
    margin-left: 6.5%;
  }
  .md-margin-seven-left {
    margin-left: 7%;
  }
  .md-margin-seven-half-left {
    margin-left: 7.5%;
  }
  .md-margin-eight-left {
    margin-left: 8%;
  }
  .md-margin-eight-half-left {
    margin-left: 8.5%;
  }
  .md-margin-nine-left {
    margin-left: 9%;
  }
  .md-margin-nine-half-left {
    margin-left: 9.5%;
  }
  .md-margin-ten-left {
    margin-left: 10%;
  }
  .md-margin-ten-half-left {
    margin-left: 10.5%;
  }
  .md-margin-eleven-left {
    margin-left: 11%;
  }
  .md-margin-eleven-half-left {
    margin-left: 11.5%;
  }
  .md-margin-twelve-left {
    margin-left: 12%;
  }
  .md-margin-twelve-half-left {
    margin-left: 12.5%;
  }
  .md-margin-thirteen-left {
    margin-left: 13%;
  }
  .md-margin-thirteen-half-left {
    margin-left: 13.5%;
  }
  .md-margin-fourteen-left {
    margin-left: 14%;
  }
  .md-margin-fourteen-half-left {
    margin-left: 14.5%;
  }
  .md-margin-fifteen-left {
    margin-left: 15%;
  }
  .md-margin-fifteen-half-left {
    margin-left: 15.5%;
  }
  .md-margin-sixteen-left {
    margin-left: 16%;
  }
  .md-margin-sixteen-half-left {
    margin-left: 16.5%;
  }
  .md-margin-seventeen-left {
    margin-left: 17%;
  }
  .md-margin-seventeen-half-left {
    margin-left: 17.5%;
  }
  .md-margin-eighteen-left {
    margin-left: 18%;
  }
  .md-margin-eighteen-half-left {
    margin-left: 18.5%;
  }
  .md-margin-nineteen-left {
    margin-left: 19%;
  }
  .md-margin-nineteen-half-left {
    margin-left: 19.5%;
  }
  .md-margin-twenty-left {
    margin-left: 20%;
  }
  .md-margin-twenty-half-left {
    margin-left: 20.5%;
  }
  .md-margin-twenty-one-left {
    margin-left: 21%;
  }
  .md-margin-twenty-one-half-left {
    margin-left: 21.5%;
  }
  .md-margin-twenty-two-left {
    margin-left: 22%;
  }
  .md-margin-twenty-two-half-left {
    margin-left: 22.5%;
  }
  .md-margin-twenty-three-left {
    margin-left: 23%;
  }
  .md-margin-twenty-three-half-left {
    margin-left: 23.5%;
  }
  .md-margin-twenty-four-left {
    margin-left: 24%;
  }
  .md-margin-twenty-four-half-left {
    margin-left: 24.5%;
  }
  .md-margin-twenty-five-left {
    margin-left: 25%;
  }
  .md-margin-5px-left {
    margin-left: 5px !important;
  }
  .md-margin-10px-left {
    margin-left: 10px !important;
  }
  .md-margin-15px-left {
    margin-left: 15px !important;
  }
  .md-margin-20px-left {
    margin-left: 20px !important;
  }
  .md-margin-25px-left {
    margin-left: 25px !important;
  }
  .md-margin-30px-left {
    margin-left: 30px !important;
  }
  .md-margin-35px-left {
    margin-left: 35px !important;
  }
  .md-margin-40px-left {
    margin-left: 40px !important;
  }
  .md-margin-45px-left {
    margin-left: 45px !important;
  }
  .md-margin-50px-left {
    margin-left: 50px !important;
  }
  .md-margin-55px-left {
    margin-left: 55px !important;
  }
  .md-margin-60px-left {
    margin-left: 60px !important;
  }
  .md-margin-65px-left {
    margin-left: 65px !important;
  }
  .md-margin-70px-left {
    margin-left: 70px !important;
  }
  .md-margin-75px-left {
    margin-left: 75px !important;
  }
  .md-margin-80px-left {
    margin-left: 80px !important;
  }
  .md-margin-85px-left {
    margin-left: 85px !important;
  }
  .md-margin-90px-left {
    margin-left: 90px !important;
  }
  .md-margin-95px-left {
    margin-left: 95px !important;
  }
  .md-margin-100px-left {
    margin-left: 100px !important;
  }

  /* margin left right */
  .md-margin-one-lr {
    margin-left: 1%;
    margin-right: 1%;
  }
  .md-margin-one-half-lr {
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
  .md-margin-two-lr {
    margin-left: 2%;
    margin-right: 2%;
  }
  .md-margin-two-half-lr {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  .md-margin-three-lr {
    margin-left: 3%;
    margin-right: 3%;
  }
  .md-margin-three-half-lr {
    margin-left: 3.5%;
    margin-right: 3.5%;
  }
  .md-margin-four-lr {
    margin-left: 4%;
    margin-right: 4%;
  }
  .md-margin-four-half-lr {
    margin-left: 4.5%;
    margin-right: 4.5%;
  }
  .md-margin-five-lr {
    margin-left: 5%;
    margin-right: 5%;
  }
  .md-margin-five-half-lr {
    margin-left: 5.5%;
    margin-right: 5.5%;
  }
  .md-margin-six-lr {
    margin-left: 6%;
    margin-right: 6%;
  }
  .md-margin-six-half-lr {
    margin-left: 6.5%;
    margin-right: 6.5%;
  }
  .md-margin-seven-lr {
    margin-left: 7%;
    margin-right: 7%;
  }
  .md-margin-seven-half-lr {
    margin-left: 7.5%;
    margin-right: 7.5%;
  }
  .md-margin-eight-lr {
    margin-left: 8%;
    margin-right: 8%;
  }
  .md-margin-eight-half-lr {
    margin-left: 8.5%;
    margin-right: 8.5%;
  }
  .md-margin-nine-lr {
    margin-left: 9%;
    margin-right: 9%;
  }
  .md-margin-nine-half-lr {
    margin-left: 9.5%;
    margin-right: 9.5%;
  }
  .md-margin-ten-lr {
    margin-left: 10%;
    margin-right: 10%;
  }
  .md-margin-ten-half-lr {
    margin-left: 10.5%;
    margin-right: 10.5%;
  }
  .md-margin-eleven-lr {
    margin-left: 11%;
    margin-right: 11%;
  }
  .md-margin-eleven-half-lr {
    margin-left: 11.5%;
    margin-right: 11.5%;
  }
  .md-margin-twelve-lr {
    margin-left: 12%;
    margin-right: 12%;
  }
  .md-margin-twelve-half-lr {
    margin-left: 12.5%;
    margin-right: 12.5%;
  }
  .md-margin-thirteen-lr {
    margin-left: 13%;
    margin-right: 13%;
  }
  .md-margin-thirteen-half-lr {
    margin-left: 13.5%;
    margin-right: 13.5%;
  }
  .md-margin-fourteen-lr {
    margin-left: 14%;
    margin-right: 14%;
  }
  .md-margin-fourteen-half-lr {
    margin-left: 14.5%;
    margin-right: 14.5%;
  }
  .md-margin-fifteen-lr {
    margin-left: 15%;
    margin-right: 15%;
  }
  .md-margin-fifteen-half-lr {
    margin-left: 15.5%;
    margin-right: 15.5%;
  }
  .md-margin-sixteen-lr {
    margin-left: 16%;
    margin-right: 16%;
  }
  .md-margin-sixteen-half-lr {
    margin-left: 16.5%;
    margin-right: 16.5%;
  }
  .md-margin-seventeen-lr {
    margin-left: 17%;
    margin-right: 17%;
  }
  .md-margin-seventeen-half-lr {
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  .md-margin-eighteen-lr {
    margin-left: 18%;
    margin-right: 18%;
  }
  .md-margin-eighteen-half-lr {
    margin-left: 18.5%;
    margin-right: 18.5%;
  }
  .md-margin-nineteen-lr {
    margin-left: 19%;
    margin-right: 19%;
  }
  .md-margin-nineteen-half-lr {
    margin-left: 19.5%;
    margin-right: 19.5%;
  }
  .md-margin-twenty-lr {
    margin-left: 20%;
    margin-right: 20%;
  }
  .md-margin-twenty-half-lr {
    margin-left: 20.5%;
    margin-right: 20.5%;
  }
  .md-margin-twenty-one-lr {
    margin-left: 21%;
    margin-right: 21%;
  }
  .md-margin-twenty-one-half-lr {
    margin-left: 21.5%;
    margin-right: 21.5%;
  }
  .md-margin-twenty-two-lr {
    margin-left: 22%;
    margin-right: 22%;
  }
  .md-margin-twenty-two-half-lr {
    margin-left: 22.5%;
    margin-right: 22.5%;
  }
  .md-margin-twenty-three-lr {
    margin-left: 23%;
    margin-right: 23%;
  }
  .md-margin-twenty-three-half-lr {
    margin-left: 23.5%;
    margin-right: 23.5%;
  }
  .md-margin-twenty-four-lr {
    margin-left: 24%;
    margin-right: 24%;
  }
  .md-margin-twenty-four-half-lr {
    margin-left: 24.5%;
    margin-right: 24.5%;
  }
  .md-margin-twenty-five-lr {
    margin-left: 25%;
    margin-right: 25%;
  }
  .md-margin-5px-lr {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .md-margin-10px-lr {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .md-margin-15px-lr {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .md-margin-20px-lr {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .md-margin-25px-lr {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .md-margin-30px-lr {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .md-margin-35px-lr {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .md-margin-40px-lr {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .md-margin-45px-lr {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .md-margin-50px-lr {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .md-margin-55px-lr {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .md-margin-60px-lr {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .md-margin-65px-lr {
    margin-left: 65px !important;
    margin-right: 60px !important;
  }
  .md-margin-70px-lr {
    margin-left: 70px !important;
    margin-right: 65px !important;
  }
  .md-margin-75px-lr {
    margin-left: 75px !important;
    margin-right: 70px !important;
  }
  .md-margin-80px-lr {
    margin-left: 80px !important;
    margin-right: 75px !important;
  }
  .md-margin-85px-lr {
    margin-left: 85px !important;
    margin-right: 80px !important;
  }
  .md-margin-90px-lr {
    margin-left: 90px !important;
    margin-right: 85px !important;
  }
  .md-margin-95px-lr {
    margin-left: 95px !important;
    margin-right: 90px !important;
  }
  .md-margin-100px-lr {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  /* margin top bottom */
  .md-margin-one-tb {
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .md-margin-one-half-tb {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
  }
  .md-margin-two-tb {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .md-margin-two-half-tb {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
  }
  .md-margin-three-tb {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .md-margin-three-half-tb {
    margin-top: 3.5%;
    margin-bottom: 3.5%;
  }
  .md-margin-four-tb {
    margin-top: 4%;
    margin-bottom: 4%;
  }
  .md-margin-four-half-tb {
    margin-top: 4.5%;
    margin-bottom: 4.5%;
  }
  .md-margin-five-tb {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .md-margin-five-half-tb {
    margin-top: 5.5%;
    margin-bottom: 5.5%;
  }
  .md-margin-six-tb {
    margin-top: 6%;
    margin-bottom: 6%;
  }
  .md-margin-six-half-tb {
    margin-top: 6.5%;
    margin-bottom: 6.5%;
  }
  .md-margin-seven-tb {
    margin-top: 7%;
    margin-bottom: 7%;
  }
  .md-margin-seven-half-tb {
    margin-top: 7.5%;
    margin-bottom: 7.5%;
  }
  .md-margin-eight-tb {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  .md-margin-eight-half-tb {
    margin-top: 8.5%;
    margin-bottom: 8.5%;
  }
  .md-margin-nine-tb {
    margin-top: 9%;
    margin-bottom: 9%;
  }
  .md-margin-nine-half-tb {
    margin-top: 9.5%;
    margin-bottom: 9.5%;
  }
  .md-margin-ten-tb {
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .md-margin-ten-half-tb {
    margin-top: 10.5%;
    margin-bottom: 10.5%;
  }
  .md-margin-eleven-tb {
    margin-top: 11%;
    margin-bottom: 11%;
  }
  .md-margin-eleven-half-tb {
    margin-top: 11.5%;
    margin-bottom: 11.5%;
  }
  .md-margin-twelve-tb {
    margin-top: 12%;
    margin-bottom: 12%;
  }
  .md-margin-twelve-half-tb {
    margin-top: 12.5%;
    margin-bottom: 12.5%;
  }
  .md-margin-thirteen-tb {
    margin-top: 13%;
    margin-bottom: 13%;
  }
  .md-margin-thirteen-half-tb {
    margin-top: 13.5%;
    margin-bottom: 13.5%;
  }
  .md-margin-fourteen-tb {
    margin-top: 14%;
    margin-bottom: 14%;
  }
  .md-margin-fourteen-half-tb {
    margin-top: 14.5%;
    margin-bottom: 14.5%;
  }
  .md-margin-fifteen-tb {
    margin-top: 15%;
    margin-bottom: 15%;
  }
  .md-margin-fifteen-half-tb {
    margin-top: 15.5%;
    margin-bottom: 15.5%;
  }
  .md-margin-sixteen-tb {
    margin-top: 16%;
    margin-bottom: 16%;
  }
  .md-margin-sixteen-half-tb {
    margin-top: 16.5%;
    margin-bottom: 16.5%;
  }
  .md-margin-seventeen-tb {
    margin-top: 17%;
    margin-bottom: 17%;
  }
  .md-margin-seventeen-half-tb {
    margin-top: 17.5%;
    margin-bottom: 17.5%;
  }
  .md-margin-eighteen-tb {
    margin-top: 18%;
    margin-bottom: 18%;
  }
  .md-margin-eighteen-half-tb {
    margin-top: 18.5%;
    margin-bottom: 18.5%;
  }
  .md-margin-nineteen-tb {
    margin-top: 19%;
    margin-bottom: 19%;
  }
  .md-margin-nineteen-half-tb {
    margin-top: 19.5%;
    margin-bottom: 19.5%;
  }
  .md-margin-twenty-tb {
    margin-top: 20%;
    margin-bottom: 20%;
  }
  .md-margin-twenty-half-tb {
    margin-top: 20.5%;
    margin-bottom: 20.5%;
  }
  .md-margin-twenty-one-tb {
    margin-top: 21%;
    margin-bottom: 21%;
  }
  .md-margin-twenty-one-half-tb {
    margin-top: 21.5%;
    margin-bottom: 21.5%;
  }
  .md-margin-twenty-two-tb {
    margin-top: 22%;
    margin-bottom: 22%;
  }
  .md-margin-twenty-two-half-tb {
    margin-top: 22.5%;
    margin-bottom: 22.5%;
  }
  .md-margin-twenty-three-tb {
    margin-top: 23%;
    margin-bottom: 23%;
  }
  .md-margin-twenty-three-half-tb {
    margin-top: 23.5%;
    margin-bottom: 23.5%;
  }
  .md-margin-twenty-four-tb {
    margin-top: 24%;
    margin-bottom: 24%;
  }
  .md-margin-twenty-four-half-tb {
    margin-top: 24.5%;
    margin-bottom: 24.5%;
  }
  .md-margin-twenty-five-tb {
    margin-top: 25%;
    margin-bottom: 25%;
  }
  .md-margin-5px-tb {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .md-margin-10px-tb {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .md-margin-15px-tb {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .md-margin-20px-tb {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .md-margin-25px-tb {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .md-margin-30px-tb {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .md-margin-35px-tb {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .md-margin-40px-tb {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .md-margin-45px-tb {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .md-margin-50px-tb {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .md-margin-55px-tb {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .md-margin-60px-tb {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .md-margin-65px-tb {
    margin-top: 65px !important;
    margin-bottom: 60px !important;
  }
  .md-margin-70px-tb {
    margin-top: 70px !important;
    margin-bottom: 65px !important;
  }
  .md-margin-75px-tb {
    margin-top: 75px !important;
    margin-bottom: 70px !important;
  }
  .md-margin-80px-tb {
    margin-top: 80px !important;
    margin-bottom: 75px !important;
  }
  .md-margin-85px-tb {
    margin-top: 85px !important;
    margin-bottom: 80px !important;
  }
  .md-margin-90px-tb {
    margin-top: 90px !important;
    margin-bottom: 85px !important;
  }
  .md-margin-95px-tb {
    margin-top: 95px !important;
    margin-bottom: 90px !important;
  }
  .md-margin-100px-tb {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  /* header padding top */
  .md-header-padding-top {
    padding-top: 82px;
  }
  .md-header-margin-top {
    margin-top: 80px;
  }

  /* padding */
  .md-no-padding {
    padding: 0 !important;
  }
  .md-no-padding-lr {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .md-no-padding-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .md-no-padding-top {
    padding-top: 0 !important;
  }
  .md-no-padding-bottom {
    padding-bottom: 0 !important;
  }
  .md-no-padding-left {
    padding-left: 0 !important;
  }
  .md-no-padding-right {
    padding-right: 0 !important;
  }
  .md-padding-one-all {
    padding: 1%;
  }
  .md-padding-one-half-all {
    padding: 1.5%;
  }
  .md-padding-two-all {
    padding: 2%;
  }
  .md-padding-two-half-all {
    padding: 2.5%;
  }
  .md-padding-three-all {
    padding: 3%;
  }
  .md-padding-three-half-all {
    padding: 3.5%;
  }
  .md-padding-four-all {
    padding: 4%;
  }
  .md-padding-four-half-all {
    padding: 4.5%;
  }
  .md-padding-five-all {
    padding: 5%;
  }
  .md-padding-five-half-all {
    padding: 5.5%;
  }
  .md-padding-six-all {
    padding: 6%;
  }
  .md-padding-six-half-all {
    padding: 6.5%;
  }
  .md-padding-seven-all {
    padding: 7%;
  }
  .md-padding-seven-half-all {
    padding: 7.5%;
  }
  .md-padding-eight-all {
    padding: 8%;
  }
  .md-padding-eight-half-all {
    padding: 8.5%;
  }
  .md-padding-nine-all {
    padding: 9%;
  }
  .md-padding-nine-half-all {
    padding: 9.5%;
  }
  .md-padding-ten-all {
    padding: 10%;
  }
  .md-padding-ten-half-all {
    padding: 10.5%;
  }
  .md-padding-eleven-all {
    padding: 11%;
  }
  .md-padding-eleven-half-all {
    padding: 11.5%;
  }
  .md-padding-twelve-all {
    padding: 12%;
  }
  .md-padding-twelve-half-all {
    padding: 12.5%;
  }
  .md-padding-thirteen-all {
    padding: 13%;
  }
  .md-padding-thirteen-half-all {
    padding: 13.5%;
  }
  .md-padding-fourteen-all {
    padding: 14%;
  }
  .md-padding-fourteen-half-all {
    padding: 14.5%;
  }
  .md-padding-fifteen-all {
    padding: 15%;
  }
  .md-padding-fifteen-half-all {
    padding: 15.5%;
  }
  .md-padding-sixteen-all {
    padding: 16%;
  }
  .md-padding-sixteen-half-all {
    padding: 16.5%;
  }
  .md-padding-seventeen-all {
    padding: 17%;
  }
  .md-padding-seventeen-half-all {
    padding: 17.5%;
  }
  .md-padding-eighteen-all {
    padding: 18%;
  }
  .md-padding-eighteen-half-all {
    padding: 18.5%;
  }
  .md-padding-nineteen-all {
    padding: 19%;
  }
  .md-padding-nineteen-half-all {
    padding: 19.5%;
  }
  .md-padding-twenty-all {
    padding: 20%;
  }
  .md-padding-twenty-half-all {
    padding: 20.5%;
  }
  .md-padding-twenty-one-all {
    padding: 21%;
  }
  .md-padding-twenty-one-half-all {
    padding: 21.5%;
  }
  .md-padding-twenty-two-all {
    padding: 22%;
  }
  .md-padding-twenty-two-half-all {
    padding: 22.5%;
  }
  .md-padding-twenty-three-all {
    padding: 23%;
  }
  .md-padding-twenty-three-half-all {
    padding: 23.5%;
  }
  .md-padding-twenty-four-all {
    padding: 24%;
  }
  .md-padding-twenty-four-half-all {
    padding: 24.5%;
  }
  .md-padding-twenty-five-all {
    padding: 25%;
  }
  .md-padding-5px-all {
    padding: 5px !important;
  }
  .md-padding-10px-all {
    padding: 10px !important;
  }
  .md-padding-15px-all {
    padding: 15px !important;
  }
  .md-padding-20px-all {
    padding: 20px !important;
  }
  .md-padding-25px-all {
    padding: 25px !important;
  }
  .md-padding-30px-all {
    padding: 30px !important;
  }
  .md-padding-35px-all {
    padding: 35px !important;
  }
  .md-padding-40px-all {
    padding: 40px !important;
  }
  .md-padding-45px-all {
    padding: 45px !important;
  }
  .md-padding-50px-all {
    padding: 50px !important;
  }
  .md-padding-55px-all {
    padding: 55px !important;
  }
  .md-padding-60px-all {
    padding: 60px !important;
  }
  .md-padding-65px-all {
    padding: 65px !important;
  }
  .md-padding-70px-all {
    padding: 70px !important;
  }
  .md-padding-75px-all {
    padding: 75px !important;
  }
  .md-padding-80px-all {
    padding: 80px !important;
  }
  .md-padding-85px-all {
    padding: 85px !important;
  }
  .md-padding-90px-all {
    padding: 90px !important;
  }
  .md-padding-95px-all {
    padding: 95px !important;
  }
  .md-padding-100px-all {
    padding: 100px !important;
  }

  /* padding top */
  .md-padding-one-top {
    padding-top: 1%;
  }
  .md-padding-one-half-top {
    padding-top: 1.5%;
  }
  .md-padding-two-top {
    padding-top: 2%;
  }
  .md-padding-two-half-top {
    padding-top: 2.5%;
  }
  .md-padding-three-top {
    padding-top: 3%;
  }
  .md-padding-three-half-top {
    padding-top: 3.5%;
  }
  .md-padding-four-top {
    padding-top: 4%;
  }
  .md-padding-four-half-top {
    padding-top: 4.5%;
  }
  .md-padding-five-top {
    padding-top: 5%;
  }
  .md-padding-five-half-top {
    padding-top: 5.5%;
  }
  .md-padding-six-top {
    padding-top: 6%;
  }
  .md-padding-six-half-top {
    padding-top: 6.5%;
  }
  .md-padding-seven-top {
    padding-top: 7%;
  }
  .md-padding-seven-half-top {
    padding-top: 7.5%;
  }
  .md-padding-eight-top {
    padding-top: 8%;
  }
  .md-padding-eight-half-top {
    padding-top: 8.5%;
  }
  .md-padding-nine-top {
    padding-top: 9%;
  }
  .md-padding-nine-half-top {
    padding-top: 9.5%;
  }
  .md-padding-ten-top {
    padding-top: 10%;
  }
  .md-padding-ten-half-top {
    padding-top: 10.5%;
  }
  .md-padding-eleven-top {
    padding-top: 11%;
  }
  .md-padding-eleven-half-top {
    padding-top: 11.5%;
  }
  .md-padding-twelve-top {
    padding-top: 12%;
  }
  .md-padding-twelve-half-top {
    padding-top: 12.5%;
  }
  .md-padding-thirteen-top {
    padding-top: 13%;
  }
  .md-padding-thirteen-half-top {
    padding-top: 13.5%;
  }
  .md-padding-fourteen-top {
    padding-top: 14%;
  }
  .md-padding-fourteen-half-top {
    padding-top: 14.5%;
  }
  .md-padding-fifteen-top {
    padding-top: 15%;
  }
  .md-padding-fifteen-half-top {
    padding-top: 15.5%;
  }
  .md-padding-sixteen-top {
    padding-top: 16%;
  }
  .md-padding-sixteen-half-top {
    padding-top: 16.5%;
  }
  .md-padding-seventeen-top {
    padding-top: 17%;
  }
  .md-padding-seventeen-half-top {
    padding-top: 17.5%;
  }
  .md-padding-eighteen-top {
    padding-top: 18%;
  }
  .md-padding-eighteen-half-top {
    padding-top: 18.5%;
  }
  .md-padding-nineteen-top {
    padding-top: 19%;
  }
  .md-padding-nineteen-half-top {
    padding-top: 19.5%;
  }
  .md-padding-twenty-top {
    padding-top: 20%;
  }
  .md-padding-twenty-half-top {
    padding-top: 20.5%;
  }
  .md-padding-twenty-one-top {
    padding-top: 21%;
  }
  .md-padding-twenty-one-half-top {
    padding-top: 21.5%;
  }
  .md-padding-twenty-two-top {
    padding-top: 22%;
  }
  .md-padding-twenty-two-half-top {
    padding-top: 22.5%;
  }
  .md-padding-twenty-three-top {
    padding-top: 23%;
  }
  .md-padding-twenty-three-half-top {
    padding-top: 23.5%;
  }
  .md-padding-twenty-four-top {
    padding-top: 24%;
  }
  .md-padding-twenty-four-half-top {
    padding-top: 24.5%;
  }
  .md-padding-twenty-five-top {
    padding-top: 25%;
  }
  .md-padding-5px-top {
    padding-top: 5px !important;
  }
  .md-padding-10px-top {
    padding-top: 10px !important;
  }
  .md-padding-15px-top {
    padding-top: 15px !important;
  }
  .md-padding-20px-top {
    padding-top: 20px !important;
  }
  .md-padding-25px-top {
    padding-top: 25px !important;
  }
  .md-padding-30px-top {
    padding-top: 30px !important;
  }
  .md-padding-35px-top {
    padding-top: 35px !important;
  }
  .md-padding-40px-top {
    padding-top: 40px !important;
  }
  .md-padding-45px-top {
    padding-top: 45px !important;
  }
  .md-padding-50px-top {
    padding-top: 50px !important;
  }
  .md-padding-55px-top {
    padding-top: 55px !important;
  }
  .md-padding-60px-top {
    padding-top: 60px !important;
  }
  .md-padding-65px-top {
    padding-top: 65px !important;
  }
  .md-padding-70px-top {
    padding-top: 70px !important;
  }
  .md-padding-75px-top {
    padding-top: 75px !important;
  }
  .md-padding-80px-top {
    padding-top: 80px !important;
  }
  .md-padding-85px-top {
    padding-top: 85px !important;
  }
  .md-padding-90px-top {
    padding-top: 90px !important;
  }
  .md-padding-95px-top {
    padding-top: 95px !important;
  }
  .md-padding-100px-top {
    padding-top: 100px !important;
  }

  /* padding bottom */
  .md-padding-one-bottom {
    padding-bottom: 1%;
  }
  .md-padding-one-half-bottom {
    padding-bottom: 1.5%;
  }
  .md-padding-two-bottom {
    padding-bottom: 2%;
  }
  .md-padding-two-half-bottom {
    padding-bottom: 2.5%;
  }
  .md-padding-three-bottom {
    padding-bottom: 3%;
  }
  .md-padding-three-half-bottom {
    padding-bottom: 3.5%;
  }
  .md-padding-four-bottom {
    padding-bottom: 4%;
  }
  .md-padding-four-half-bottom {
    padding-bottom: 4.5%;
  }
  .md-padding-five-bottom {
    padding-bottom: 5%;
  }
  .md-padding-five-half-bottom {
    padding-bottom: 5.5%;
  }
  .md-padding-six-bottom {
    padding-bottom: 6%;
  }
  .md-padding-six-half-bottom {
    padding-bottom: 6.5%;
  }
  .md-padding-seven-bottom {
    padding-bottom: 7%;
  }
  .md-padding-seven-half-bottom {
    padding-bottom: 7.5%;
  }
  .md-padding-eight-bottom {
    padding-bottom: 8%;
  }
  .md-padding-eight-half-bottom {
    padding-bottom: 8.5%;
  }
  .md-padding-nine-bottom {
    padding-bottom: 9%;
  }
  .md-padding-nine-half-bottom {
    padding-bottom: 9.5%;
  }
  .md-padding-ten-bottom {
    padding-bottom: 10%;
  }
  .md-padding-ten-half-bottom {
    padding-bottom: 10.5%;
  }
  .md-padding-eleven-bottom {
    padding-bottom: 11%;
  }
  .md-padding-eleven-half-bottom {
    padding-bottom: 11.5%;
  }
  .md-padding-twelve-bottom {
    padding-bottom: 12%;
  }
  .md-padding-twelve-half-bottom {
    padding-bottom: 12.5%;
  }
  .md-padding-thirteen-bottom {
    padding-bottom: 13%;
  }
  .md-padding-thirteen-half-bottom {
    padding-bottom: 13.5%;
  }
  .md-padding-fourteen-bottom {
    padding-bottom: 14%;
  }
  .md-padding-fourteen-half-bottom {
    padding-bottom: 14.5%;
  }
  .md-padding-fifteen-bottom {
    padding-bottom: 15%;
  }
  .md-padding-fifteen-half-bottom {
    padding-bottom: 15.5%;
  }
  .md-padding-sixteen-bottom {
    padding-bottom: 16%;
  }
  .md-padding-sixteen-half-bottom {
    padding-bottom: 16.5%;
  }
  .md-padding-seventeen-bottom {
    padding-bottom: 17%;
  }
  .md-padding-seventeen-half-bottom {
    padding-bottom: 17.5%;
  }
  .md-padding-eighteen-bottom {
    padding-bottom: 18%;
  }
  .md-padding-eighteen-half-bottom {
    padding-bottom: 18.5%;
  }
  .md-padding-nineteen-bottom {
    padding-bottom: 19%;
  }
  .md-padding-nineteen-half-bottom {
    padding-bottom: 19.5%;
  }
  .md-padding-twenty-bottom {
    padding-bottom: 20%;
  }
  .md-padding-twenty-half-bottom {
    padding-bottom: 20.5%;
  }
  .md-padding-twenty-one-bottom {
    padding-bottom: 21%;
  }
  .md-padding-twenty-one-half-bottom {
    padding-bottom: 21.5%;
  }
  .md-padding-twenty-two-bottom {
    padding-bottom: 22%;
  }
  .md-padding-twenty-two-half-bottom {
    padding-bottom: 22.5%;
  }
  .md-padding-twenty-three-bottom {
    padding-bottom: 23%;
  }
  .md-padding-twenty-three-half-bottom {
    padding-bottom: 23.5%;
  }
  .md-padding-twenty-four-bottom {
    padding-bottom: 24%;
  }
  .md-padding-twenty-four-half-bottom {
    padding-bottom: 24.5%;
  }
  .md-padding-twenty-five-bottom {
    padding-bottom: 25%;
  }
  .md-padding-5px-bottom {
    padding-bottom: 5px !important;
  }
  .md-padding-10px-bottom {
    padding-bottom: 10px !important;
  }
  .md-padding-15px-bottom {
    padding-bottom: 15px !important;
  }
  .md-padding-20px-bottom {
    padding-bottom: 20px !important;
  }
  .md-padding-25px-bottom {
    padding-bottom: 25px !important;
  }
  .md-padding-30px-bottom {
    padding-bottom: 30px !important;
  }
  .md-padding-35px-bottom {
    padding-bottom: 35px !important;
  }
  .md-padding-40px-bottom {
    padding-bottom: 40px !important;
  }
  .md-padding-45px-bottom {
    padding-bottom: 45px !important;
  }
  .md-padding-50px-bottom {
    padding-bottom: 50px !important;
  }
  .md-padding-55px-bottom {
    padding-bottom: 55px !important;
  }
  .md-padding-60px-bottom {
    padding-bottom: 60px !important;
  }
  .md-padding-65px-bottom {
    padding-bottom: 65px !important;
  }
  .md-padding-70px-bottom {
    padding-bottom: 70px !important;
  }
  .md-padding-75px-bottom {
    padding-bottom: 75px !important;
  }
  .md-padding-80px-bottom {
    padding-bottom: 80px !important;
  }
  .md-padding-85px-bottom {
    padding-bottom: 85px !important;
  }
  .md-padding-90px-bottom {
    padding-bottom: 90px !important;
  }
  .md-padding-95px-bottom {
    padding-bottom: 95px !important;
  }
  .md-padding-100px-bottom {
    padding-bottom: 100px !important;
  }

  /* padding right */
  .md-padding-one-right {
    padding-right: 1%;
  }
  .md-padding-one-half-right {
    padding-right: 1.5%;
  }
  .md-padding-two-right {
    padding-right: 2%;
  }
  .md-padding-two-half-right {
    padding-right: 2.5%;
  }
  .md-padding-three-right {
    padding-right: 3%;
  }
  .md-padding-three-half-right {
    padding-right: 3.5%;
  }
  .md-padding-four-right {
    padding-right: 4%;
  }
  .md-padding-four-half-right {
    padding-right: 4.5%;
  }
  .md-padding-five-right {
    padding-right: 5%;
  }
  .md-padding-five-half-right {
    padding-right: 5.5%;
  }
  .md-padding-six-right {
    padding-right: 6%;
  }
  .md-padding-six-half-right {
    padding-right: 6.5%;
  }
  .md-padding-seven-right {
    padding-right: 7%;
  }
  .md-padding-seven-half-right {
    padding-right: 7.5%;
  }
  .md-padding-eight-right {
    padding-right: 8%;
  }
  .md-padding-eight-half-right {
    padding-right: 8.5%;
  }
  .md-padding-nine-right {
    padding-right: 9%;
  }
  .md-padding-nine-half-right {
    padding-right: 9.5%;
  }
  .md-padding-ten-right {
    padding-right: 10%;
  }
  .md-padding-ten-half-right {
    padding-right: 10.5%;
  }
  .md-padding-eleven-right {
    padding-right: 11%;
  }
  .md-padding-eleven-half-right {
    padding-right: 11.5%;
  }
  .md-padding-twelve-right {
    padding-right: 12%;
  }
  .md-padding-twelve-half-right {
    padding-right: 12.5%;
  }
  .md-padding-thirteen-right {
    padding-right: 13%;
  }
  .md-padding-thirteen-half-right {
    padding-right: 13.5%;
  }
  .md-padding-fourteen-right {
    padding-right: 14%;
  }
  .md-padding-fourteen-half-right {
    padding-right: 14.5%;
  }
  .md-padding-fifteen-right {
    padding-right: 15%;
  }
  .md-padding-fifteen-half-right {
    padding-right: 15.5%;
  }
  .md-padding-sixteen-right {
    padding-right: 16%;
  }
  .md-padding-sixteen-half-right {
    padding-right: 16.5%;
  }
  .md-padding-seventeen-right {
    padding-right: 17%;
  }
  .md-padding-seventeen-half-right {
    padding-right: 17.5%;
  }
  .md-padding-eighteen-right {
    padding-right: 18%;
  }
  .md-padding-eighteen-half-right {
    padding-right: 18.5%;
  }
  .md-padding-nineteen-right {
    padding-right: 19%;
  }
  .md-padding-nineteen-half-right {
    padding-right: 19.5%;
  }
  .md-padding-twenty-right {
    padding-right: 20%;
  }
  .md-padding-twenty-half-right {
    padding-right: 20.5%;
  }
  .md-padding-twenty-one-right {
    padding-right: 21%;
  }
  .md-padding-twenty-one-half-right {
    padding-right: 21.5%;
  }
  .md-padding-twenty-two-right {
    padding-right: 22%;
  }
  .md-padding-twenty-two-half-right {
    padding-right: 22.5%;
  }
  .md-padding-twenty-three-right {
    padding-right: 23%;
  }
  .md-padding-twenty-three-half-right {
    padding-right: 23.5%;
  }
  .md-padding-twenty-four-right {
    padding-right: 24%;
  }
  .md-padding-twenty-four-half-right {
    padding-right: 24.5%;
  }
  .md-padding-twenty-five-right {
    padding-right: 25%;
  }
  .md-padding-5px-right {
    padding-right: 5px !important;
  }
  .md-padding-10px-right {
    padding-right: 10px !important;
  }
  .md-padding-15px-right {
    padding-right: 15px !important;
  }
  .md-padding-20px-right {
    padding-right: 20px !important;
  }
  .md-padding-25px-right {
    padding-right: 25px !important;
  }
  .md-padding-30px-right {
    padding-right: 30px !important;
  }
  .md-padding-35px-right {
    padding-right: 35px !important;
  }
  .md-padding-40px-right {
    padding-right: 40px !important;
  }
  .md-padding-45px-right {
    padding-right: 45px !important;
  }
  .md-padding-50px-right {
    padding-right: 50px !important;
  }
  .md-padding-55px-right {
    padding-right: 55px !important;
  }
  .md-padding-60px-right {
    padding-right: 60px !important;
  }
  .md-padding-65px-right {
    padding-right: 65px !important;
  }
  .md-padding-70px-right {
    padding-right: 70px !important;
  }
  .md-padding-75px-right {
    padding-right: 75px !important;
  }
  .md-padding-80px-right {
    padding-right: 80px !important;
  }
  .md-padding-85px-right {
    padding-right: 85px !important;
  }
  .md-padding-90px-right {
    padding-right: 90px !important;
  }
  .md-padding-95px-right {
    padding-right: 95px !important;
  }
  .md-padding-100px-right {
    padding-right: 100px !important;
  }

  /* padding left */
  .md-padding-one-left {
    padding-left: 1%;
  }
  .md-padding-one-half-left {
    padding-left: 1.5%;
  }
  .md-padding-two-left {
    padding-left: 2%;
  }
  .md-padding-two-half-left {
    padding-left: 2.5%;
  }
  .md-padding-three-left {
    padding-left: 3%;
  }
  .md-padding-three-half-left {
    padding-left: 3.5%;
  }
  .md-padding-four-left {
    padding-left: 4%;
  }
  .md-padding-four-half-left {
    padding-left: 4.5%;
  }
  .md-padding-five-left {
    padding-left: 5%;
  }
  .md-padding-five-half-left {
    padding-left: 5.5%;
  }
  .md-padding-six-left {
    padding-left: 6%;
  }
  .md-padding-six-half-left {
    padding-left: 6.5%;
  }
  .md-padding-seven-left {
    padding-left: 7%;
  }
  .md-padding-seven-half-left {
    padding-left: 7.5%;
  }
  .md-padding-eight-left {
    padding-left: 8%;
  }
  .md-padding-eight-half-left {
    padding-left: 8.5%;
  }
  .md-padding-nine-left {
    padding-left: 9%;
  }
  .md-padding-nine-half-left {
    padding-left: 9.5%;
  }
  .md-padding-ten-left {
    padding-left: 10%;
  }
  .md-padding-ten-half-left {
    padding-left: 10.5%;
  }
  .md-padding-eleven-left {
    padding-left: 11%;
  }
  .md-padding-eleven-half-left {
    padding-left: 11.5%;
  }
  .md-padding-twelve-left {
    padding-left: 12%;
  }
  .md-padding-twelve-half-left {
    padding-left: 12.5%;
  }
  .md-padding-thirteen-left {
    padding-left: 13%;
  }
  .md-padding-thirteen-half-left {
    padding-left: 13.5%;
  }
  .md-padding-fourteen-left {
    padding-left: 14%;
  }
  .md-padding-fourteen-half-left {
    padding-left: 14.5%;
  }
  .md-padding-fifteen-left {
    padding-left: 15%;
  }
  .md-padding-fifteen-half-left {
    padding-left: 15.5%;
  }
  .md-padding-sixteen-left {
    padding-left: 16%;
  }
  .md-padding-sixteen-half-left {
    padding-left: 16.5%;
  }
  .md-padding-seventeen-left {
    padding-left: 17%;
  }
  .md-padding-seventeen-half-left {
    padding-left: 17.5%;
  }
  .md-padding-eighteen-left {
    padding-left: 18%;
  }
  .md-padding-eighteen-half-left {
    padding-left: 18.5%;
  }
  .md-padding-nineteen-left {
    padding-left: 19%;
  }
  .md-padding-nineteen-half-left {
    padding-left: 19.5%;
  }
  .md-padding-twenty-left {
    padding-left: 20%;
  }
  .md-padding-twenty-half-left {
    padding-left: 20.5%;
  }
  .md-padding-twenty-one-left {
    padding-left: 21%;
  }
  .md-padding-twenty-one-half-left {
    padding-left: 21.5%;
  }
  .md-padding-twenty-two-left {
    padding-left: 22%;
  }
  .md-padding-twenty-two-half-left {
    padding-left: 22.5%;
  }
  .md-padding-twenty-three-left {
    padding-left: 23%;
  }
  .md-padding-twenty-three-half-left {
    padding-left: 23.5%;
  }
  .md-padding-twenty-four-left {
    padding-left: 24%;
  }
  .md-padding-twenty-four-half-left {
    padding-left: 24.5%;
  }
  .md-padding-twenty-five-left {
    padding-left: 25%;
  }
  .md-padding-5px-left {
    padding-left: 5px !important;
  }
  .md-padding-10px-left {
    padding-left: 10px !important;
  }
  .md-padding-15px-left {
    padding-left: 15px !important;
  }
  .md-padding-20px-left {
    padding-left: 20px !important;
  }
  .md-padding-25px-left {
    padding-left: 25px !important;
  }
  .md-padding-30px-left {
    padding-left: 30px !important;
  }
  .md-padding-35px-left {
    padding-left: 35px !important;
  }
  .md-padding-40px-left {
    padding-left: 40px !important;
  }
  .md-padding-45px-left {
    padding-left: 45px !important;
  }
  .md-padding-50px-left {
    padding-left: 50px !important;
  }
  .md-padding-55px-left {
    padding-left: 55px !important;
  }
  .md-padding-60px-left {
    padding-left: 60px !important;
  }
  .md-padding-65px-left {
    padding-left: 65px !important;
  }
  .md-padding-70px-left {
    padding-left: 70px !important;
  }
  .md-padding-75px-left {
    padding-left: 75px !important;
  }
  .md-padding-80px-left {
    padding-left: 80px !important;
  }
  .md-padding-85px-left {
    padding-left: 85px !important;
  }
  .md-padding-90px-left {
    padding-left: 90px !important;
  }
  .md-padding-95px-left {
    padding-left: 95px !important;
  }
  .md-padding-100px-left {
    padding-left: 100px !important;
  }

  /* padding top bottom */
  .md-padding-one-tb {
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .md-padding-one-half-tb {
    padding-top: 1.5%;
    padding-bottom: 1.5%;
  }
  .md-padding-two-tb {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .md-padding-two-half-tb {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
  }
  .md-padding-three-tb {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .md-padding-three-half-tb {
    padding-top: 3.5%;
    padding-bottom: 3.5%;
  }
  .md-padding-four-tb {
    padding-top: 4%;
    padding-bottom: 4%;
  }
  .md-padding-four-half-tb {
    padding-top: 4.5%;
    padding-bottom: 4.5%;
  }
  .md-padding-five-tb {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .md-padding-five-half-tb {
    padding-top: 5.5%;
    padding-bottom: 5.5%;
  }
  .md-padding-six-tb {
    padding-top: 6%;
    padding-bottom: 6%;
  }
  .md-padding-six-half-tb {
    padding-top: 6.5%;
    padding-bottom: 6.5%;
  }
  .md-padding-seven-tb {
    padding-top: 7%;
    padding-bottom: 7%;
  }
  .md-padding-seven-half-tb {
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }
  .md-padding-eight-tb {
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .md-padding-eight-half-tb {
    padding-top: 8.5%;
    padding-bottom: 8.5%;
  }
  .md-padding-nine-tb {
    padding-top: 9%;
    padding-bottom: 9%;
  }
  .md-padding-nine-half-tb {
    padding-top: 9.5%;
    padding-bottom: 9.5%;
  }
  .md-padding-ten-tb {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .md-padding-ten-half-tb {
    padding-top: 10.5%;
    padding-bottom: 10.5%;
  }
  .md-padding-eleven-tb {
    padding-top: 11%;
    padding-bottom: 11%;
  }
  .md-padding-eleven-half-tb {
    padding-top: 11.5%;
    padding-bottom: 11.5%;
  }
  .md-padding-twelve-tb {
    padding-top: 12%;
    padding-bottom: 12%;
  }
  .md-padding-twelve-half-tb {
    padding-top: 12.5%;
    padding-bottom: 12.5%;
  }
  .md-padding-thirteen-tb {
    padding-top: 13%;
    padding-bottom: 13%;
  }
  .md-padding-thirteen-half-tb {
    padding-top: 13.5%;
    padding-bottom: 13.5%;
  }
  .md-padding-fourteen-tb {
    padding-top: 14%;
    padding-bottom: 14%;
  }
  .md-padding-fourteen-half-tb {
    padding-top: 14.5%;
    padding-bottom: 14.5%;
  }
  .md-padding-fifteen-tb {
    padding-top: 15%;
    padding-bottom: 15%;
  }
  .md-padding-fifteen-half-tb {
    padding-top: 15.5%;
    padding-bottom: 15.5%;
  }
  .md-padding-sixteen-tb {
    padding-top: 16%;
    padding-bottom: 16%;
  }
  .md-padding-sixteen-half-tb {
    padding-top: 16.5%;
    padding-bottom: 16.5%;
  }
  .md-padding-seventeen-tb {
    padding-top: 17%;
    padding-bottom: 17%;
  }
  .md-padding-seventeen-half-tb {
    padding-top: 17.5%;
    padding-bottom: 17.5%;
  }
  .md-padding-eighteen-tb {
    padding-top: 18%;
    padding-bottom: 18%;
  }
  .md-padding-eighteen-half-tb {
    padding-top: 18.5%;
    padding-bottom: 18.5%;
  }
  .md-padding-nineteen-tb {
    padding-top: 19%;
    padding-bottom: 19%;
  }
  .md-padding-nineteen-half-tb {
    padding-top: 19.5%;
    padding-bottom: 19.5%;
  }
  .md-padding-twenty-tb {
    padding-top: 20%;
    padding-bottom: 20%;
  }
  .md-padding-twenty-half-tb {
    padding-top: 20.5%;
    padding-bottom: 20.5%;
  }
  .md-padding-twenty-one-tb {
    padding-top: 21%;
    padding-bottom: 21%;
  }
  .md-padding-twenty-one-half-tb {
    padding-top: 21.5%;
    padding-bottom: 21.5%;
  }
  .md-padding-twenty-two-tb {
    padding-top: 22%;
    padding-bottom: 22%;
  }
  .md-padding-twenty-two-half-tb {
    padding-top: 22.5%;
    padding-bottom: 22.5%;
  }
  .md-padding-twenty-three-tb {
    padding-top: 23%;
    padding-bottom: 23%;
  }
  .md-padding-twenty-three-half-tb {
    padding-top: 23.5%;
    padding-bottom: 23.5%;
  }
  .md-padding-twenty-four-tb {
    padding-top: 24%;
    padding-bottom: 24%;
  }
  .md-padding-twenty-four-half-tb {
    padding-top: 24.5%;
    padding-bottom: 24.5%;
  }
  .md-padding-twenty-five-tb {
    padding-top: 25%;
    padding-bottom: 25%;
  }
  .md-padding-5px-tb {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .md-padding-10px-tb {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .md-padding-15px-tb {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .md-padding-20px-tb {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .md-padding-25px-tb {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .md-padding-30px-tb {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .md-padding-35px-tb {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .md-padding-40px-tb {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .md-padding-45px-tb {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .md-padding-50px-tb {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .md-padding-55px-tb {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .md-padding-60px-tb {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .md-padding-65px-tb {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .md-padding-70px-tb {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .md-padding-75px-tb {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .md-padding-80px-tb {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .md-padding-85px-tb {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .md-padding-90px-tb {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .md-padding-95px-tb {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .md-padding-100px-tb {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  /* padding left right */
  .md-padding-one-lr {
    padding-left: 1%;
    padding-right: 1%;
  }
  .md-padding-one-half-lr {
    padding-left: 1.5%;
    padding-right: 1.5%;
  }
  .md-padding-two-lr {
    padding-left: 2%;
    padding-right: 2%;
  }
  .md-padding-two-half-lr {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  .md-padding-three-lr {
    padding-left: 3%;
    padding-right: 3%;
  }
  .md-padding-three-half-lr {
    padding-left: 3.5%;
    padding-right: 3.5%;
  }
  .md-padding-four-lr {
    padding-left: 4%;
    padding-right: 4%;
  }
  .md-padding-four-half-lr {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }
  .md-padding-five-lr {
    padding-left: 5%;
    padding-right: 5%;
  }
  .md-padding-five-half-lr {
    padding-left: 5.5%;
    padding-right: 5.5%;
  }
  .md-padding-six-lr {
    padding-left: 6%;
    padding-right: 6%;
  }
  .md-padding-six-half-lr {
    padding-left: 6.5%;
    padding-right: 6.5%;
  }
  .md-padding-seven-lr {
    padding-left: 7%;
    padding-right: 7%;
  }
  .md-padding-seven-half-lr {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
  .md-padding-eight-lr {
    padding-left: 8%;
    padding-right: 8%;
  }
  .md-padding-eight-half-lr {
    padding-left: 8.5%;
    padding-right: 8.5%;
  }
  .md-padding-nine-lr {
    padding-left: 9%;
    padding-right: 9%;
  }
  .md-padding-nine-half-lr {
    padding-left: 9.5%;
    padding-right: 9.5%;
  }
  .md-padding-ten-lr {
    padding-left: 10%;
    padding-right: 10%;
  }
  .md-padding-ten-half-lr {
    padding-left: 10.5%;
    padding-right: 10.5%;
  }
  .md-padding-eleven-lr {
    padding-left: 11%;
    padding-right: 11%;
  }
  .md-padding-eleven-half-lr {
    padding-left: 11.5%;
    padding-right: 11.5%;
  }
  .md-padding-twelve-lr {
    padding-left: 12%;
    padding-right: 12%;
  }
  .md-padding-twelve-half-lr {
    padding-left: 12.5%;
    padding-right: 12.5%;
  }
  .md-padding-thirteen-lr {
    padding-left: 13%;
    padding-right: 13%;
  }
  .md-padding-thirteen-half-lr {
    padding-left: 13.5%;
    padding-right: 13.5%;
  }
  .md-padding-fourteen-lr {
    padding-left: 14%;
    padding-right: 14%;
  }
  .md-padding-fourteen-half-lr {
    padding-left: 14.5%;
    padding-right: 14.5%;
  }
  .md-padding-fifteen-lr {
    padding-left: 15%;
    padding-right: 15%;
  }
  .md-padding-fifteen-half-lr {
    padding-left: 15.5%;
    padding-right: 15.5%;
  }
  .md-padding-sixteen-lr {
    padding-left: 16%;
    padding-right: 16%;
  }
  .md-padding-sixteen-half-lr {
    padding-left: 16.5%;
    padding-right: 16.5%;
  }
  .md-padding-seventeen-lr {
    padding-left: 17%;
    padding-right: 17%;
  }
  .md-padding-seventeen-half-lr {
    padding-left: 17.5%;
    padding-right: 17.5%;
  }
  .md-padding-eighteen-lr {
    padding-left: 18%;
    padding-right: 18%;
  }
  .md-padding-eighteen-half-lr {
    padding-left: 18.5%;
    padding-right: 18.5%;
  }
  .md-padding-nineteen-lr {
    padding-left: 19%;
    padding-right: 19%;
  }
  .md-padding-nineteen-half-lr {
    padding-left: 19.5%;
    padding-right: 19.5%;
  }
  .md-padding-twenty-lr {
    padding-left: 20%;
    padding-right: 20%;
  }
  .md-padding-twenty-half-lr {
    padding-left: 20.5%;
    padding-right: 20.5%;
  }
  .md-padding-twenty-one-lr {
    padding-left: 21%;
    padding-right: 21%;
  }
  .md-padding-twenty-one-half-lr {
    padding-left: 21.5%;
    padding-right: 21.5%;
  }
  .md-padding-twenty-two-lr {
    padding-left: 22%;
    padding-right: 22%;
  }
  .md-padding-twenty-two-half-lr {
    padding-left: 22.5%;
    padding-right: 22.5%;
  }
  .md-padding-twenty-three-lr {
    padding-left: 23%;
    padding-right: 23%;
  }
  .md-padding-twenty-three-half-lr {
    padding-left: 23.5%;
    padding-right: 23.5%;
  }
  .md-padding-twenty-four-lr {
    padding-left: 24%;
    padding-right: 24%;
  }
  .md-padding-twenty-four-half-lr {
    padding-left: 24.5%;
    padding-right: 24.5%;
  }
  .md-padding-twenty-five-lr {
    padding-left: 25%;
    padding-right: 25%;
  }
  .md-padding-5px-lr {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .md-padding-10px-lr {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .md-padding-15px-lr {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .md-padding-20px-lr {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .md-padding-25px-lr {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .md-padding-30px-lr {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .md-padding-35px-lr {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .md-padding-40px-lr {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .md-padding-45px-lr {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .md-padding-50px-lr {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .md-padding-55px-lr {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .md-padding-60px-lr {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .md-padding-65px-lr {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .md-padding-70px-lr {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .md-padding-75px-lr {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .md-padding-80px-lr {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .md-padding-85px-lr {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .md-padding-90px-lr {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .md-padding-95px-lr {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .md-padding-100px-lr {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  /* col height  */
  .one-fourth-screen {
    height: 700px;
  }
  .md-small-screen {
    height: 300px;
  }

  /* owl slider height */
  .slider-half-screen {
    min-height: 650px;
  }

  /* top */
  .md-top-auto {
    top: auto;
  }
  .md-top-0 {
    top: 0 !important;
  }
  .md-top-1 {
    top: 1px;
  }
  .md-top-2 {
    top: 2px;
  }
  .md-top-3 {
    top: 3px;
  }
  .md-top-4 {
    top: 4px;
  }
  .md-top-5 {
    top: 5px;
  }
  .md-top-6 {
    top: 6px;
  }
  .md-top-7 {
    top: 7px;
  }
  .md-top-8 {
    top: 8px;
  }
  .md-top-9 {
    top: 9px;
  }
  .md-top-10 {
    top: 10px;
  }

  .md-top-minus1 {
    top: -1px;
  }
  .md-top-minus2 {
    top: -2px;
  }
  .md-top-minus3 {
    top: -3px;
  }
  .md-top-minus4 {
    top: -4px;
  }
  .md-top-minus5 {
    top: -5px;
  }
  .md-top-minus6 {
    top: -6px;
  }
  .md-top-minus7 {
    top: -7px;
  }
  .md-top-minus8 {
    top: -8px;
  }
  .md-top-minus9 {
    top: -9px;
  }
  .md-top-minus10 {
    top: -10px;
  }
  .md-top-minus15 {
    top: -15px;
  }
  .md-top-minus20 {
    top: -20px;
  }
  .md-top-minus25 {
    top: -25px;
  }
  .md-top-minus30 {
    top: -30px;
  }
  .md-top-minus35 {
    top: -35px;
  }
  .md-top-minus40 {
    top: -40px;
  }

  /* bottom */
  .md-bottom-auto {
    bottom: auto;
  }
  .md-bottom-0 {
    bottom: 0;
  }
  .md-bottom-1 {
    bottom: 1px;
  }
  .md-bottom-2 {
    bottom: 2px;
  }
  .md-bottom-3 {
    bottom: 3px;
  }
  .md-bottom-4 {
    bottom: 4px;
  }
  .md-bottom-5 {
    bottom: 5px;
  }
  .md-bottom-6 {
    bottom: 6px;
  }
  .md-bottom-7 {
    bottom: 7px;
  }
  .md-bottom-8 {
    bottom: 8px;
  }
  .md-bottom-9 {
    bottom: 9px;
  }
  .md-bottom-10 {
    bottom: 10px;
  }
  .md-bottom-15 {
    bottom: 15px;
  }
  .md-bottom-20 {
    bottom: 20px;
  }
  .md-bottom-25 {
    bottom: 25px;
  }
  .md-bottom-30 {
    bottom: 30px;
  }
  .md-bottom-35 {
    bottom: 35px;
  }
  .md-bottom-40 {
    bottom: 40px;
  }

  /* left */
  .md-left-auto {
    left: auto;
  }
  .md-left-0 {
    left: 0;
  }
  .md-left-1 {
    left: 1px;
  }
  .md-left-2 {
    left: 2px;
  }
  .md-left-3 {
    left: 3px;
  }
  .md-left-4 {
    left: 4px;
  }
  .md-left-5 {
    left: 5px;
  }
  .md-left-6 {
    left: 6px;
  }
  .md-left-7 {
    left: 7px;
  }
  .md-left-8 {
    left: 8px;
  }
  .md-left-9 {
    left: 9px;
  }
  .md-left-10 {
    bottom: 10px;
  }
  .md-left-15 {
    bottom: 15px;
  }
  .md-left-20 {
    bottom: 20px;
  }
  .md-left-25 {
    bottom: 25px;
  }
  .md-left-30 {
    bottom: 30px;
  }
  .md-left-35 {
    bottom: 35px;
  }
  .md-left-40 {
    bottom: 40px;
  }

  /* right */
  .md-right-auto {
    right: auto;
  }
  .md-right-0 {
    right: 0;
  }
  .md-right-1 {
    right: 1px;
  }
  .md-right-2 {
    right: 2px;
  }
  .md-right-3 {
    right: 3px;
  }
  .md-right-4 {
    right: 4px;
  }
  .md-right-5 {
    right: 5px;
  }
  .md-right-6 {
    right: 6px;
  }
  .md-right-7 {
    right: 7px;
  }
  .md-right-8 {
    right: 8px;
  }
  .md-right-9 {
    right: 9px;
  }
  .md-right-10 {
    right: 10px;
  }
  .md-right-15 {
    right: 15px;
  }
  .md-right-20 {
    right: 20px;
  }
  .md-right-25 {
    right: 25px;
  }
  .md-right-30 {
    right: 30px;
  }
  .md-right-35 {
    right: 35px;
  }
  .md-right-40 {
    right: 40px;
  }

  /* width */
  .md-width-10 {
    width: 10%;
  }
  .md-width-15 {
    width: 15%;
  }
  .md-width-20 {
    width: 20%;
  }
  .md-width-25 {
    width: 25%;
  }
  .md-width-30 {
    width: 30%;
  }
  .md-width-35 {
    width: 35%;
  }
  .md-width-40 {
    width: 40%;
  }
  .md-width-45 {
    width: 45%;
  }
  .md-width-50 {
    width: 50%;
  }
  .md-width-55 {
    width: 55%;
  }
  .md-width-60 {
    width: 60%;
  }
  .md-width-65 {
    width: 65%;
  }
  .md-width-70 {
    width: 70%;
  }
  .md-width-75 {
    width: 75%;
  }
  .md-width-80 {
    width: 80%;
  }
  .md-width-85 {
    width: 85%;
  }
  .md-width-90 {
    width: 90%;
  }
  .md-width-95 {
    width: 95%;
  }
  .md-width-100 {
    width: 100%;
  }
  .md-width-auto {
    width: auto;
  }

  .md-width-50px {
    width: 50px;
  }
  .md-width-70px {
    width: 70px;
  }
  .md-width-75px {
    width: 75px;
  }
  .md-width-80px {
    width: 80px;
  }
  .md-width-85px {
    width: 85px;
  }
  .md-width-90px {
    width: 90px;
  }
  .md-width-95px {
    width: 95px;
  }
  .md-width-100px {
    width: 100px;
  }
  .md-width-120px {
    width: 120px;
  }
  .md-width-130px {
    width: 130px;
  }
  .md-width-150px {
    width: 150px;
  }
  .md-width-180px {
    width: 180px;
  }
  .md-width-200px {
    width: 200px;
  }
  .md-width-250px {
    width: 250px;
  }
  .md-width-300px {
    width: 300px;
  }
  .md-width-350px {
    width: 350px;
  }
  .md-width-400px {
    width: 400px;
  }
  .md-width-450px {
    width: 450px;
  }
  .md-width-500px {
    width: 500px;
  }
  .md-width-550px {
    width: 550px;
  }
  .md-width-600px {
    width: 600px;
  }
  .md-width-650px {
    width: 650px;
  }
  .md-width-700px {
    width: 700px;
  }
  .md-width-750px {
    width: 750px;
  }
  .md-width-800px {
    width: 800px;
  }
  .md-width-850px {
    width: 850px;
  }
  .md-width-900px {
    width: 900px;
  }
  .md-width-950px {
    width: 950px;
  }
  .md-width-1000px {
    width: 1000px;
  }

  /* height */
  .md-height-100 {
    height: 100%;
  }
  .md-height-auto {
    height: auto;
  }
  .md-height-80px {
    height: 80px;
  }
  .md-height-90px {
    height: 90px;
  }
  .md-height-100px {
    height: 100px;
  }
  .md-height-150px {
    height: 150px;
  }
  .md-height-200px {
    height: 200px;
  }
  .md-height-250px {
    height: 250px;
  }
  .md-height-300px {
    height: 300px;
  }
  .md-height-350px {
    height: 350px;
  }
  .md-height-400px {
    height: 400px;
  }
  .md-height-450px {
    height: 450px;
  }
  .md-height-500px {
    height: 500px;
  }
  .md-height-550px {
    height: 550px;
  }
  .md-height-600px {
    height: 600px;
  }
  .md-height-650px {
    height: 650px;
  }
  .md-height-700px {
    height: 700px;
  }

  /* line height */
  .md-line-height-auto {
    line-height: normal;
  }
  .md-line-height-10 {
    line-height: 10px;
  }
  .md-line-height-18 {
    line-height: 18px;
  }
  .md-line-height-20 {
    line-height: 20px;
  }
  .md-line-height-24 {
    line-height: 24px;
  }
  .md-line-height-22 {
    line-height: 22px;
  }
  .md-line-height-26 {
    line-height: 26px;
  }
  .md-line-height-28 {
    line-height: 28px;
  }
  .md-line-height-30 {
    line-height: 30px;
  }
  .md-line-height-35 {
    line-height: 35px;
  }
  .md-line-height-40 {
    line-height: 40px;
  }
  .md-line-height-45 {
    line-height: 45px;
  }
  .md-line-height-50 {
    line-height: 50px;
  }
  .md-line-height-55 {
    line-height: 55px;
  }
  .md-line-height-60 {
    line-height: 60px;
  }
  .md-line-height-65 {
    line-height: 65px;
  }
  .md-line-height-70 {
    line-height: 70px;
  }
  .md-line-height-75 {
    line-height: 75px;
  }
  .md-line-height-80 {
    line-height: 80px;
  }
  .md-line-height-85 {
    line-height: 85px;
  }
  .md-line-height-90 {
    line-height: 90px;
  }
  .md-line-height-95 {
    line-height: 95px;
  }
  .md-line-height-100 {
    line-height: 100px;
  }

  /* display */
  .md-display-block {
    display: block !important;
  }
  .md-overflow-hidden {
    overflow: hidden !important;
  }
  .md-overflow-auto {
    overflow: auto !important;
  }
  .md-display-inline-block {
    display: inline-block !important;
  }
  .md-display-inline {
    display: inline !important;
  }
  .md-display-none {
    display: none !important;
  }
  .md-display-inherit {
    display: inherit !important;
  }
  .md-display-table {
    display: table !important;
  }
  .md-display-table-cell {
    display: table-cell !important;
  }

  /* position */
  .md-position-inherit {
    position: inherit;
  }
  .md-position-relative {
    position: relative;
    z-index: 5;
  }
  .md-position-absolute {
    position: absolute;
  }
  .md-position-fixed {
    position: fixed;
  }

  /* border */
  .md-no-border-top {
    border-top: 0 !important;
  }
  .md-no-border-bottom {
    border-bottom: 0 !important;
  }
  .md-no-border-right {
    border-right: 0 !important;
  }
  .md-no-border-left {
    border-left: 0 !important;
  }

  /* box layout */
  .box-layout {
    padding: 0 0;
  }
  .md-no-background-img {
    background-image: none !important;
  }

  /* navigation */
  header nav .navbar-nav > li > a {
    margin: 0 18px;
  }

  /* nav box width */
  header nav.nav-box-width {
    padding: 0;
  }

  /* skillbar bar style */
  .box-layout .skillbar-bar-style2 .skillbar {
    width: 88%;
  }

  /* sidebar menu */
  nav.navbar.sidebar-nav ul.nav > li > a {
    padding: 10px 5px;
  }
  .nav.navbar-right-sidebar .dropdown.on .second-level {
    left: 230px;
  }
  .sidebar-wrapper {
    padding-left: 230px;
  }
  nav.navbar.sidebar-nav {
    width: 230px;
    padding-left: 0;
    padding-right: 0;
  }
  .nav.navbar-left-sidebar li {
    padding: 0 20px;
  }
  .sidebar-nav .dropdown-menu li > a {
    margin: 0 15px;
  }

  .sidebar-nav-style-1 .nav.navbar-left-sidebar .dropdown.on .second-level {
    left: 230px;
    width: 230px;
  }
  .sidebar-nav-style-1 .nav.navbar-left-sidebar .second-level .dropdown.on .third-level {
    left: 460px;
    width: 230px;
  }

  .sidebar-wrapper .portfolio-grid.work-4col li,
  .sidebar-wrapper .blog-grid.work-4col li {
    width: 33.33%;
  }

  /* feature-box-4 */
  .feature-box-4 figure figcaption {
    padding: 20px;
  }
  .feature-box-4 figure figcaption .btn {
    opacity: 1;
  }
  .feature-box-4 figure:hover figcaption {
    transform: translateY(0px);
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
  }

  /* feature-box-10 */
  .feature-box-10 .number-center::before {
    width: 180%;
  }
  .feature-box-10 .number-center::after {
    width: 180%;
  }

  /* feature-box-12 */
  .feature-box-12 .hover-content-box {
    width: 100%;
  }

  /* footer */
  .social-style-2 ul li {
    margin: 0 10px 5px 0;
  }

  /* hamburg menu */
  .hamburger-menu-links {
    font-size: 40px;
    line-height: 45px;
  }
  .menu-middle {
    width: 85%;
  }

  .side-left-menu-close.active {
    display: block;
  }

  /* sidebar nav style 2 */
  .left-nav-sidebar .container {
    width: 850px;
  }

  /* next prev button style 3 */
  .swiper-button-prev.swiper-prev-style3,
  .swiper-button-next.swiper-next-style3 {
    left: 57.8%;
  }

  /*tab style 4*/
  .tab-style4 .nav-tabs li a {
    padding: 0 15px;
  }

  /* homepage 14 */
  .box-separator-line {
    left: -60px;
    top: 86px;
  }
  .right-image {
    bottom: -250px;
    width: 70%;
    right: -130px;
  }
  .content-right-slider .swiper-button-next {
    right: 20px;
  }
  .content-right-slider .swiper-button-prev {
    left: 20px;
  }

  /* font size */
  .title-large {
    font-size: 90px;
    line-height: 90px;
  }
  .text-extra-large {
    font-size: 18px;
    line-height: 24px;
  }
  .percent {
    line-height: 125px;
  }

  /* portfolio grid */
  .portfolio-grid.work-6col li,
  .blog-grid.blog-6col li {
    width: 25%;
  }
  .portfolio-grid.work-6col li.grid-item-double,
  .blog-grid.blog-6col li.grid-item-double {
    width: 50%;
  }

  /* portfolio 5 column */
  .portfolio-grid.work-5col li,
  .blog-grid.blog-5col li {
    width: 33.333%;
  }
  .portfolio-grid.work-5col li.grid-item-double,
  .blog-grid.blog-5col li.grid-item-double {
    width: 50%;
  }

  .portfolio-grid.work-4col li,
  .blog-grid.blog-4col li {
    width: 33.333%;
  }
  .portfolio-grid.work-4col li.grid-item-double,
  .blog-grid.blog-4col li.grid-item-double {
    width: 66.667%;
  }
  .portfolio-grid.work-4col li.grid-item-double:last-child,
  .blog-grid.blog-4col li.grid-item-double:last-child {
    width: 100%;
  }

  /*mega menu*/
  nav.navbar.bootsnav li.dropdown .mega-menu-full {
    padding: 25px;
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li {
    border-right: none;
  }
  nav.navbar.bootsnav li.dropdown ul li ul li:last-child {
    border-bottom: none;
  }
  nav.navbar.bootsnav li.dropdown .menu-back-div > ul {
    width: 920px;
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li a,
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li.dropdown-header {
    padding: 2px 0;
  }

  /*counter-box*/
  .counter-box-3 .counter-box {
    display: inline-block;
    width: 100px;
  }

  /*banner style 01*/
  .banner-style1 figure:hover figcaption p {
    height: 80px;
  }
  .banner-style1 figure figcaption img {
    width: 130px;
  }
  .swiper-pagination-bottom.swiper-container-horizontal .swiper-wrapper {
    margin-bottom: 50px;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0px;
  }
  .swiper-full-screen .swiper-pagination,
  .hover-option3 .swiper-pagination {
    bottom: 30px;
  }

  /* hover option 3 */
  .hover-option3 .grid-item a:hover p {
    bottom: 0;
    opacity: 1;
  }
  .hover-option3 .grid-item figure p {
    bottom: 0;
    opacity: 1;
    margin-bottom: 30px;
  }
  .hover-option3 .grid-item figure h6 {
    bottom: 0;
  }
  .hover-option3 .grid-item figure figcaption {
    padding: 0 30px;
  }

  /*next prev button style 2*/
  .swiper-next-style2 {
    right: 5%;
  }
  .swiper-prev-style2 {
    left: 5%;
  }
  .swiper-auto-slide .swiper-scrollbar {
    left: 15%;
    width: 70%;
  }
  .swiper-auto-slide .swiper-slide.swiper-slide-active .absolute-middle-center h2 {
    transition: all 0.4s ease-in-out 0.4s;
    -webkit-transition: all 0.4s ease-in-out 0.4s;
    -moz-transition: all 0.4s ease-in-out 0.4s;
    -ms-transition: all 0.4s ease-in-out 0.4s;
    -o-transition: all 0.4s ease-in-out 0.4s;
  }

  /* swiper-bottom-scrollbar-full */
  .swiper-bottom-scrollbar-full .swiper-slide img {
    max-height: 450px;
    opacity: 1;
    filter: contrast(130%);
    -webkit-filter: contrast(130%);
    -moz-filter: contrast(130%);
    -ms-filter: contrast(130%);
    -o-filter: contrast(130%);
  }
  .swiper-bottom-scrollbar-full .swiper-slide:hover img {
    max-height: 450px;
  }
  .swiper-bottom-scrollbar-full .bottom-text {
    opacity: 1;
    letter-spacing: 3px;
  }
  .swiper-bottom-scrollbar-full .swiper-slide:hover .bottom-text {
    opacity: 1;
    letter-spacing: 3px;
    left: 0;
  }
  .swiper-bottom-scrollbar-full .swiper-slide:hover .hover-title-box {
    right: 0;
    opacity: 1;
  }
  .hover-title-box {
    opacity: 1;
    right: 0;
    text-align: center;
    top: inherit;
    bottom: 50%;
    transform: translateY(160px);
    -moz-transform: translateY(160px);
    -webkit-transform: translateY(160px);
    -o-transform: translateY(160px);
    -ms-transform: translateY(160px);
    z-index: 99;
    width: 100%;
  }
  .swiper-bottom-scrollbar-full .swiper-slide .hover-title-box {
    visibility: visible;
  }

  /* portfolio grid */
  .portfolio-grid.portfolio-metro-grid.work-4col li {
    width: 50%;
  }
  .portfolio-grid.portfolio-metro-grid.work-4col li.grid-item-double {
    width: 50%;
  }
  .portfolio-grid.portfolio-metro-grid.work-4col li.grid-item-double:last-child {
    width: 50%;
  }
  .portfolio-grid.portfolio-metro-grid.work-6col li {
    width: 50%;
  }
  .portfolio-grid.portfolio-metro-grid.work-6col li.grid-item-double {
    width: 50%;
  }
  .portfolio-grid.portfolio-metro-grid.work-6col li.grid-item-double:last-child {
    width: 50%;
  }
  .portfolio-grid.portfolio-metro-grid.work-3col li {
    width: 50%;
  }
  .portfolio-grid.portfolio-metro-grid.work-3col li.grid-item-double {
    width: 50%;
  }
  .portfolio-grid.portfolio-metro-grid.work-3col li.grid-item-double:last-child {
    width: 50%;
  }

  /* blog header style1 */
  .blog-header-style1 li figure figcaption {
    padding: 30px;
  }

  .demo-heading {
    font-size: 35px;
    line-height: 41px;
  }
}

@media (max-width: 1150px) {
  /* sidebar navigation */
  .sidebar-wrapper .container {
    width: 750px;
  }
}

@media (max-width: 1050px) {
  /* sidebar navigation */
  .sidebar-wrapper .container {
    width: 100%;
  }
  .parallax {
    background-attachment: inherit;
  }
  .feature-box.feature-box-17 .box .content {
    top: 50px;
  }

  .sidebar-nav .logo-holder {
    min-height: 60px;
  }
  nav.navbar.bootsnav.sidebar-nav li.dropdown ul.dropdown-menu {
    padding-top: 112px;
  }
  .nav.navbar-left-sidebar li a {
    padding: 17px 0;
  }
  .sidebar-part2 .sidebar-middle {
    padding: 90px 0 90px;
  }
  .sidebar-part2 .sidebar-middle {
    padding: 110px 0;
  }
}

@media (max-width: 1024px) and (min-width: 992px) {
  .sidebar-part2 .sidebar-middle,
  .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
    padding: 90px 0 220px;
  }
  header .sidebar-part2 nav.navbar.bootsnav ul > li > a {
    font-size: 30px;
    line-height: 37px;
  }
  .sidebar-part2 ul > li {
    padding: 7px 20px 7px 0;
  }
}

@media (max-width: 991px) {
  /* typography */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 18px;
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
  }
  h1 {
    font-size: 57px;
    line-height: 58px;
  }
  h2 {
    font-size: 43px;
    line-height: 48px;
  }
  h3 {
    font-size: 36px;
    line-height: 41px;
  }
  h4 {
    font-size: 30px;
    line-height: 35px;
  }
  h5 {
    font-size: 24px;
    line-height: 30px;
  }
  h6 {
    font-size: 22px;
    line-height: 26px;
  }
  p {
    margin: 0 0 20px;
  }

  .title-large {
    font-size: 70px;
    line-height: 65px;
  }
  .title-extra-large {
    font-size: 100px;
    line-height: 95px;
  }
  .percent {
    line-height: 125px;
  }

  /* custom */
  section {
    padding: 75px 0;
  }
  section.big-section {
    padding: 120px 0;
  }
  section.extra-big-section {
    padding: 150px 0;
  }
  section.half-section {
    padding: 40px 0;
  }
  .sm-col-2-nth .col-sm-6:nth-child(2n + 1) {
    clear: left;
  }
  .sm-col-3-nth .col-sm-4:nth-child(3n + 1) {
    clear: left;
  }
  .sm-col-4-nth .col-sm-3:nth-child(4n + 1) {
    clear: left;
  }
  .sm-center-col {
    float: none;
    margin: 0 auto;
  }

  /*float*/
  .sm-float-left {
    float: left !important;
  }
  .sm-float-right {
    float: right !important;
  }
  .sm-float-none {
    float: none !important;
  }

  /* header style */
  header * {
    transition-timing-function: none;
    -moz-transition-timing-function: none;
    -webkit-transition-timing-function: none;
    -o-transition-timing-function: none;
    -ms-transition-timing-function: none;
    transition-duration: 0s;
    -moz-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
  }
  .dropdown-menu {
    box-shadow: none;
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.06);
  }
  .navbar-collapse {
    left: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }
  nav.navbar.bootsnav ul.nav > li.dropdown > ul.dropdown-menu,
  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu.mega-menu {
    padding: 10px 15px 0 !important;
    margin: 0;
    float: left;
    top: 0 !important;
  }
  nav.navbar.bootsnav .navbar-nav {
    margin: 0;
    padding: 0;
    background-color: rgba(23, 23, 23, 0.95);
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li {
    border-bottom: none;
    margin-bottom: 10px;
  }
  nav.navbar.bootsnav li.dropdown.open .mega-menu-full {
    padding-top: 10px;
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li:last-child {
    margin-bottom: 0;
  }
  nav.navbar.bootsnav .navbar-nav li,
  nav.navbar.bootsnav li.dropdown ul.mega-menu-full li.dropdown-header {
    display: block;
    clear: both;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    border-top: 0;
  }
  .navbar-nav li > a,
  nav.navbar.navbar-default ul.nav > li > a,
  nav.navbar-brand-top.navbar.navbar-default ul.nav > li > a,
  header .navbar-nav li > a,
  header nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a,
  header nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a,
  nav.navbar.bootsnav li.dropdown ul.mega-menu-full li > a,
  header.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav > li > a,
  header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a,
  nav.navbar.bootsnav li.dropdown ul.mega-menu-full li.dropdown-header {
    margin: 0;
    padding: 9px 15px 8px;
    color: #939393;
    display: block;
    line-height: normal;
  }
  .navbar-nav li > a,
  nav.navbar.navbar-default ul.nav > li > a,
  header .navbar-nav li > a,
  header nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a,
  header.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav > li > a,
  header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a,
  header.header-appear nav.bootsnav.header-light .navbar-nav > li > a {
    color: #fff;
  }
  nav.navbar.bootsnav ul.nav > li.dropdown > ul.dropdown-menu li a,
  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu.mega-menu li a,
  nav.navbar.bootsnav li.dropdown ul.mega-menu-full li.dropdown-header {
    padding-left: 0;
    padding-right: 0;
  }
  .simple-dropdown.open > ul > li {
    border: 0 !important;
  }
  nav.navbar.bootsnav li.dropdown ul.mega-menu-full li.dropdown-header {
    color: #fff;
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li a,
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li.dropdown-header {
    padding: 0;
    margin: 0;
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li > ul {
    margin-top: 0;
  }
  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a {
    color: #939393;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  nav.navbar.bootsnav ul.nav li.dropdown.simple-dropdown ul.dropdown-menu > li.active > a {
    background-color: transparent;
    color: #fff;
  }
  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li:hover > a {
    color: #fff;
  }
  nav.navbar.bootsnav li.dropdown ul.mega-menu-full li ul {
    margin-top: 0;
  }
  .navbar-nav > li.simple-dropdown ul.dropdown-menu {
    top: 0;
    min-width: 0;
  }
  nav.navbar.bootsnav ul.nav li.dropdown.simple-dropdown > .dropdown-menu {
    background-color: #232323;
  }
  nav.navbar.bootsnav .navbar-toggle {
    top: 3px;
  }
  .navbar-nav > li.dropdown > .fas {
    display: block;
    position: absolute;
    right: 0px;
    top: 0;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding: 9px 15px 8px;
  }
  nav.navbar.bootsnav .navbar-nav > li.dropdown.open > ul,
  nav.navbar.bootsnav .navbar-nav > li.dropdown.on > ul {
    display: block !important;
    opacity: 1 !important;
  }
  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a {
    border: 0;
    padding: 10px 0;
  }
  nav.navbar.bootsnav ul.nav li.dropdown.simple-dropdown ul.dropdown-menu > li > a {
    padding: 7px 0;
    left: 0;
  }
  .dropdown.simple-dropdown.open .dropdown-menu .dropdown .dropdown-menu {
    display: block !important;
    opacity: 1 !important;
  }
  nav.navbar.bootsnav
    ul.nav
    li.dropdown.simple-dropdown.open
    ul.dropdown-menu
    li
    > a.dropdown-toggle {
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    color: #fff;
  }
  nav.navbar.bootsnav ul.nav .simple-dropdown ul.dropdown-menu li.dropdown ul.dropdown-menu {
    padding-left: 0 !important;
  }

  nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul,
  nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul > li.dropdown > ul {
    left: 0;
  }

  .simple-dropdown .dropdown-menu > li > a.dropdown-toggle .fas {
    display: none;
  }
  nav .accordion-menu {
    padding: 26px 15px 26px 15px;
  }
  .sticky nav .accordion-menu {
    padding: 26px 15px 26px 15px;
  }
  nav.navbar.bootsnav.menu-center ul.nav.navbar-center {
    width: 100%;
  }
  .center-logo {
    left: 0;
    position: relative;
    max-width: 100%;
    margin: 0;
    top: 0;
    transform: none;
    padding: 26px 15px;
  }
  .navbar-nav li {
    position: relative;
  }
  nav.navbar.bootsnav li.dropdown ul.mega-menu-full > li {
    padding: 0;
    border: 0;
  }
  nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li {
    padding-left: 0;
    padding-right: 0;
  }
  nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown {
    margin-bottom: 10px;
  }
  nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul:before {
    display: none;
  }
  nav.navbar.bootsnav ul.navbar-nav.navbar-left > li:last-child > ul.dropdown-menu {
    border: 0;
  }
  header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a:hover,
  header.header-appear nav.bootsnav.header-light .navbar-nav > li > a:hover,
  header.header-appear nav.header-light-transparent .header-social-icon a:hover,
  header.header-appear nav.header-light .header-social-icon a:hover,
  header.header-appear nav.header-light.white-link .header-social-icon a:hover,
  header.header-appear nav.header-light-transparent.white-link .header-social-icon a:hover,
  header.header-appear nav.header-light-transparent.white-link .header-menu-button a:hover,
  header.header-appear nav.header-light-transparent.white-link .header-searchbar a:hover,
  header nav.navbar .navbar-nav > li > a:hover,
  nav.navbar.bootsnav ul.nav > li > a:hover,
  header.sticky nav.navbar.white-link .navbar-nav > li.active > a,
  header.sticky nav.navbar.bootsnav.white-link ul.nav > li.active > a {
    color: rgba(255, 255, 255, 0.6);
  }
  header nav.navbar.white-link .navbar-nav > li > a:hover,
  nav.navbar.bootsnav.white-link ul.nav > li > a:hover,
  header nav.navbar.white-link .header-social-icon a:hover,
  nav.navbar.bootsnav.white-link .header-social-icon a:hover,
  header nav.navbar.white-link .header-searchbar a:hover,
  nav.navbar.bootsnav.white-link .header-searchbar a:hover,
  header nav.navbar.bootsnav ul.nav.white-link > li > a:hover,
  header.header-appear nav.header-dark-transparent .navbar-nav > li > a:hover,
  header.header-appear nav.header-dark .navbar-nav > li > a:hover,
  header.sticky nav.header-dark .navbar-nav > li > a:hover,
  header.sticky nav.header-dark-transparent .navbar-nav > li > a:hover,
  header.header-appear nav.header-dark-transparent .search-button:hover,
  header.header-appear nav.header-dark .search-button:hover,
  header.sticky nav.header-dark .search-button:hover,
  header.sticky nav.header-dark-transparent .search-button:hover {
    color: rgba(255, 255, 255, 0.6);
  }
  header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a:hover,
  header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-searchbar a:hover,
  header.sticky
    nav.navbar-fixed-top.header-light-transparent.white-link
    .header-social-icon
    a:hover,
  header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a:hover,
  header.sticky nav.navbar-fixed-top.header-light.white-link .header-searchbar a:hover,
  header.sticky nav.navbar-fixed-top.header-light.white-link .header-social-icon a:hover,
  header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover,
  header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover {
    color: rgba(255, 255, 255, 0.6);
  }
  header.sticky nav.navbar.white-link .navbar-nav > li > a:hover,
  header.sticky nav.navbar.bootsnav.white-link ul.nav > li > a:hover,
  header.sticky nav.navbar.white-link .navbar-nav > li > a.active,
  header.sticky nav.navbar.bootsnav.white-link ul.nav > li > a.active {
    color: rgba(255, 255, 255, 0.6);
  }
  header.sticky nav.navbar.header-dark.white-link .navbar-nav > li > a:hover,
  header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li > a:hover,
  header.sticky nav.header-dark .header-social-icon a:hover {
    color: rgba(255, 255, 255, 0.6);
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li.dropdown-header {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li:last-child img {
    display: none;
  }
  nav.navbar.navbar-default ul li.dropdown .dropdown-menu li > a:hover {
    left: 0;
  }
  .dropdown.open > div {
    display: block !important;
    opacity: 1 !important;
  }
  header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a {
    color: #fff;
  }
  header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a:hover,
  header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li.active > a {
    color: #939393;
  }
  nav.menu-logo-center .accordion-menu {
    padding: 26px 15px;
  }
  .sticky nav.menu-logo-center .accordion-menu {
    padding: 26px 15px;
  }
  nav.navbar.sidebar-nav.bootsnav .navbar-left-sidebar li a:hover,
  nav.navbar.sidebar-nav.bootsnav .navbar-left-sidebar li.active > a {
    color: #000;
  }
  header .sidebar-part2 nav.navbar.bootsnav ul > li > a:hover,
  header .sidebar-part2 nav.navbar.bootsnav ul > li.active > a {
    color: rgba(0, 0, 0, 0.6);
  }
  header .sidebar-part2 nav.navbar.bootsnav ul > li > a > .fas {
    top: -4px;
  }
  nav.navbar .container-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
  .header-with-topbar .navbar-top {
    top: 30px;
  }
  #search-header {
    width: 75%;
  }

  /* sidebar nav style 1 */
  .sidebar-wrapper {
    padding-left: 0;
  }
  nav.navbar.sidebar-nav {
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-property: display;
    -webkit-transition-property: display;
    -moz-transition-property: display;
    -ms-transition-property: display;
    -o-transition-property: display;
    left: -280px;
    width: 280px;
    z-index: 10005;
    padding: 60px 15px 15px;
    display: inline-block;
  }
  nav.navbar.sidebar-nav.sidebar-nav-style-1 .sidenav-header {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 1;
  }
  nav.navbar.sidebar-nav.sidemenu-open {
    left: 0;
  }
  nav.navbar.sidebar-nav .navbar-toggle .icon-bar {
    background: #232323;
  }
  nav.navbar.bootsnav.sidebar-nav .navbar-collapse.collapse,
  .sidebar-part2 nav.navbar.bootsnav .navbar-collapse.collapse {
    display: block !important;
    max-height: 100%;
    position: relative;
    top: 0;
  }
  nav.navbar.bootsnav.sidebar-nav .mobile-scroll {
    display: block;
    max-height: 80%;
    overflow-y: auto;
    position: absolute;
  }
  .sidebar-nav .logo-holder,
  .sidebar-nav .footer-holder {
    padding: 0;
    text-align: left;
    display: inline-block;
  }
  .sidebar-nav .logo-holder {
    min-height: 0;
    padding: 15px 0;
  }
  .sidebar-nav.sidemenu-open .footer-holder {
    width: 100%;
    padding: 0;
    margin-right: 0;
  }
  .sidebar-nav .footer-holder {
    margin-right: 15px;
  }
  .sidebar-nav .footer-holder .navbar-form {
    margin: 0 auto;
  }
  nav.navbar.bootsnav.sidebar-nav .navbar-toggle {
    margin-bottom: 0;
    position: absolute;
    top: auto;
    vertical-align: middle;
    height: 100%;
    right: 15px;
  }
  nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar .dropdown li:first-child {
    margin-top: 0;
  }
  nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown ul.dropdown-menu li {
    opacity: 1;
    visibility: visible;
  }
  nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown ul.dropdown-menu > li > a {
    color: #232323;
    margin: 0;
  }
  nav.navbar.bootsnav.sidebar-nav
    ul.nav
    li.dropdown
    ul.dropdown-menu
    > li.active
    > ul
    > li.active
    > a {
    color: #ff214f;
  }
  nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown ul.dropdown-menu > li > a .fas {
    display: none;
  }
  nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown.open ul.dropdown-menu > li > a {
    border-bottom: 1px solid #ededed !important;
  }
  nav.navbar.bootsnav.sidebar-nav
    ul.nav
    li.dropdown.open
    ul.dropdown-menu
    > li
    > ul.third-level
    > li
    > a {
    border-bottom: 0 !important;
    font-size: 11px;
    padding: 6px 0 0 0;
  }
  nav.navbar.bootsnav.sidebar-nav
    ul.nav
    li.dropdown.open
    ul.dropdown-menu
    > li
    > ul.third-level
    > li:first-child
    > a {
    padding-top: 10px;
  }
  nav.navbar.bootsnav.sidebar-nav
    ul.nav
    li.dropdown.open
    ul.dropdown-menu
    > li:last-child
    > ul.third-level
    > li:last-child
    > a {
    margin-bottom: 15px;
  }
  nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown.open ul.dropdown-menu li {
    margin-bottom: 5px;
  }
  .sidebar-nav-style-1 .nav.navbar-left-sidebar li {
    padding: 0;
  }
  .sidemenu-open .mobile-toggle span:first-child {
    transform: rotate(45deg) translate(7px);
    -webkit-transform: rotate(45deg) translate(7px);
    -moz-transform: rotate(45deg) translate(7px);
    -o-transform: rotate(45deg) translate(7px);
    -ms-transform: rotate(45deg) translate(7px);
  }
  .sidemenu-open .mobile-toggle span:nth-child(2) {
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
  }
  .sidemenu-open .mobile-toggle span:last-child {
    transform: rotate(-45deg) translate(7px);
    -webkit-transform: rotate(-45deg) translate(7px);
    -moz-transform: rotate(-45deg) translate(7px);
    -o-transform: rotate(-45deg) translate(7px);
    -ms-transform: rotate(-45deg) translate(7px);
  }
  .sidebar-part2 nav.navbar.bootsnav li.dropdown.open > a > i {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }
  nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li.dropdown.open > a > i {
    transform: translateY(-50%) rotate(90deg);
    -webkit-transform: translateY(-50%) rotate(90deg);
    -moz-transform: translateY(-50%) rotate(90deg);
    -o-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
  }

  /* mega menu */
  nav.navbar.bootsnav li.dropdown .menu-back-div > ul {
    width: 100%;
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full {
    padding: 0 15px;
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li a {
    padding: 8px 0;
    margin: 0;
  }
  header nav.navbar .navbar-nav > li.active > a,
  nav.navbar.bootsnav ul.nav > li.active > a,
  .dropdown-menu,
  header.header-appear nav.bootsnav.header-light .navbar-nav > li.active > a,
  header nav.navbar .navbar-nav > li.active > a,
  nav.navbar.bootsnav ul.nav > li.active > a {
    color: rgba(255, 255, 255, 0.6);
  }
  nav.navbar.bootsnav li.dropdown .mega-menu-full {
    position: relative;
  }

  /* sidebar nav style 2 */
  .left-nav-sidebar {
    padding-left: 0;
  }
  .left-nav-sidebar .fullscreenbanner {
    padding-left: 0 !important;
  }
  .sidebar-part2 .sidebar-middle {
    padding: 30px 0;
    display: block;
  }
  .sidebar-part2 .sidebar-middle-menu {
    display: block;
    overflow-y: auto;
    max-height: calc(100% - 10px);
  }
  .sidebar-part1 {
    width: 50px;
  }
  .sidebar-part2 nav.navbar.bootsnav .navbar-nav {
    background: transparent;
    padding: 0;
  }
  .sidebar-part2 ul > li {
    width: 100%;
    padding: 5px 30px 5px 0;
  }
  header .sidebar-part2 nav.navbar.bootsnav ul > li > a > .fas {
    right: -20px;
    top: 0px;
    font-size: 22px;
  }
  .bottom-menu-icon a,
  .nav-icon span {
    width: 20px;
  }
  .bottom-menu-icon a {
    margin-top: 2px;
  }
  .bottom-menu-icon {
    width: 50px;
    padding: 13px 10px 11px;
  }
  .sidebar-part2:before {
    bottom: 55px;
    display: none;
  }
  .sidebar-part2 {
    background-color: #fff;
    border-right: 0 solid #dfdfdf;
    height: calc(100% - 50px);
    left: -300px;
    padding: 0 20px;
    overflow: auto;
    position: fixed;
    top: 50px;
    text-align: center;
    width: 300px;
    z-index: -1;
    transition: ease-in-out 0.5s;
  }
  .sidebar-part2 .right-bg {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(0);
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    left: 0;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }
  .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu:before {
    display: none;
  }
  .sidebar-part2 nav.navbar.bootsnav li.dropdown.open ul.dropdown-menu {
    display: block !important;
    opacity: 1 !important;
  }
  .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu,
  .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu .third-level {
    top: 0;
    transform: translateY(0);
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
    left: 0;
    background-color: transparent !important;
    padding-left: 10px !important;
  }
  header .sidebar-part2 nav.navbar.bootsnav ul li ul li {
    padding: 0;
  }
  .sidebar-part2 .dropdown ul.second-level,
  .sidebar-part2 .dropdown ul.third-level {
    border-left: none !important;
  }
  .sidebar-part2 nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a {
    color: #232323;
    padding: 0;
    margin-bottom: 2px;
  }
  .sidebar-part2 nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a > .fas {
    display: none;
  }
  header .sidebar-part2 nav.navbar.bootsnav ul > li > a {
    font-size: 20px;
    line-height: 24px;
    border: 0;
  }
  .sidebar-part2 nav.navbar.bootsnav li.dropdown.open ul.dropdown-menu .third-level {
    display: inherit;
  }
  .left-nav-sidebar .container {
    width: 700px;
  }
  .sidebar-part2 nav.navbar {
    text-align: left;
  }
  header .sidebar-part2 nav.navbar.bootsnav ul li.dropdown .dropdown-menu.second-level > li > a {
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 0;
  }
  header
    .sidebar-part2
    nav.navbar.bootsnav
    ul
    li.dropdown
    .dropdown-menu.second-level
    > li:last-child {
    margin-bottom: 8px;
  }
  header
    .sidebar-part2
    nav.navbar.bootsnav
    ul
    li.dropdown
    .dropdown-menu.second-level
    > li
    > .dropdown-menu.third-level
    > li:first-child
    > a {
    margin-top: 8px;
  }

  /* sidebar nav style 1-2 */
  nav.navbar.bootsnav.sidebar-nav .navbar-nav,
  .sidebar-part2 nav.navbar.bootsnav .navbar-nav {
    background-color: transparent;
    padding: 0 0px 0 0;
  }
  nav.navbar.bootsnav.sidebar-nav .navbar-nav {
    padding: 0;
    margin-right: 50px;
  }
  nav.navbar.bootsnav.sidebar-nav.sidemenu-open .navbar-nav {
    margin-right: 0;
  }
  nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar .dropdown .second-level,
  .sidebar-part2 nav.navbar.bootsnav .nav.navbar-left-sidebar .dropdown .second-level {
    display: none !important;
  }
  nav.navbar.bootsnav.sidebar-nav .navbar-left-sidebar > li > a,
  .sidebar-part2 nav.navbar.bootsnav .navbar-left-sidebar > li > a {
    margin: 0;
    padding: 15px 15px 15px 0;
  }
  nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li a,
  .sidebar-part2 nav.navbar.bootsnav .nav.navbar-left-sidebar li a {
    border-bottom: 0 !important;
  }
  nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar .dropdown.open .second-level,
  nav.navbar.bootsnav.sidebar-nav
    .nav.navbar-left-sidebar
    .dropdown.open
    .second-level
    .dropdown
    .third-level,
  .sidebar-part2 nav.navbar.bootsnav .nav.navbar-left-sidebar .dropdown.open .second-level,
  .sidebar-part2
    nav.navbar.bootsnav
    .nav.navbar-left-sidebar
    .dropdown.open
    .second-level
    .dropdown
    .third-level {
    display: block !important;
    left: 0;
    width: 100%;
    height: auto;
    visibility: visible;
    opacity: 1 !important;
    background: transparent;
    padding: 0 0 0 8px !important;
  }
  header .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover {
    border: 0;
    color: #ff214f;
  }
  .sidebar-part1 {
    position: inherit;
    width: 50px;
    float: left;
  }
  .sidebar-part3 {
    position: absolute;
    right: 0;
    bottom: inherit;
    top: 0;
  }
  .left-nav {
    height: 50px;
    width: 100%;
  }

  /* top logo */
  .navbar.navbar-brand-top.bootsnav .navbar-toggle {
    float: left !important;
    top: 5px;
  }
  .navbar-brand-top .brand-top-menu-right {
    float: right;
    margin-left: 10px;
  }
  .navbar-brand-top .navbar-brand {
    text-align: left;
  }
  .navbar-brand-top .navbar-nav {
    text-align: left;
  }

  /*brand center*/
  .brand-center .accordion-menu {
    float: right;
  }
  .brand-center .center-logo {
    max-width: 100%;
  }

  /*text-alignment*/
  .sm-text-center {
    text-align: center;
  }
  .sm-text-left {
    text-align: left;
  }
  .sm-text-right {
    text-align: right;
  }

  /* verticle align */
  .sm-vertical-align-middle {
    vertical-align: middle;
  }
  .sm-vertical-align-top {
    vertical-align: top;
  }
  .sm-vertical-align-bottom {
    vertical-align: bottom;
  }

  /* image position*/
  .sm-background-image-left {
    background-position: left;
  }
  .sm-background-image-right {
    background-position: right;
  }
  .sm-background-image-center {
    background-position: center;
  }

  /* margin */
  .sm-margin-one-all {
    margin: 1%;
  }
  .sm-margin-one-half-all {
    margin: 1.5%;
  }
  .sm-margin-two-all {
    margin: 2%;
  }
  .sm-margin-two-half-all {
    margin: 2.5%;
  }
  .sm-margin-three-all {
    margin: 3%;
  }
  .sm-margin-three-half-all {
    margin: 3.5%;
  }
  .sm-margin-four-all {
    margin: 4%;
  }
  .sm-margin-four-half-all {
    margin: 4.5%;
  }
  .sm-margin-five-all {
    margin: 5%;
  }
  .sm-margin-five-half-all {
    margin: 5.5%;
  }
  .sm-margin-six-all {
    margin: 6%;
  }
  .sm-margin-six-half-all {
    margin: 6.5%;
  }
  .sm-margin-seven-all {
    margin: 7%;
  }
  .sm-margin-seven-half-all {
    margin: 7.5%;
  }
  .sm-margin-eight-all {
    margin: 8%;
  }
  .sm-margin-eight-half-all {
    margin: 8.5%;
  }
  .sm-margin-nine-all {
    margin: 9%;
  }
  .sm-margin-nine-half-all {
    margin: 9.5%;
  }
  .sm-margin-ten-all {
    margin: 10%;
  }
  .sm-margin-ten-half-all {
    margin: 10.5%;
  }
  .sm-margin-eleven-all {
    margin: 11%;
  }
  .sm-margin-eleven-half-all {
    margin: 11.5%;
  }
  .sm-margin-twelve-all {
    margin: 12%;
  }
  .sm-margin-twelve-half-all {
    margin: 12.5%;
  }
  .sm-margin-thirteen-all {
    margin: 13%;
  }
  .sm-margin-thirteen-half-all {
    margin: 13.5%;
  }
  .sm-margin-fourteen-all {
    margin: 14%;
  }
  .sm-margin-fourteen-half-all {
    margin: 14.5%;
  }
  .sm-margin-fifteen-all {
    margin: 15%;
  }
  .sm-margin-fifteen-half-all {
    margin: 15.5%;
  }
  .sm-margin-sixteen-all {
    margin: 16%;
  }
  .sm-margin-sixteen-half-all {
    margin: 16.5%;
  }
  .sm-margin-seventeen-all {
    margin: 17%;
  }
  .sm-margin-seventeen-half-all {
    margin: 17.5%;
  }
  .sm-margin-eighteen-all {
    margin: 18%;
  }
  .sm-margin-eighteen-half-all {
    margin: 18.5%;
  }
  .sm-margin-nineteen-all {
    margin: 19%;
  }
  .sm-margin-nineteen-half-all {
    margin: 19.5%;
  }
  .sm-margin-twenty-all {
    margin: 20%;
  }
  .sm-margin-twenty-half-all {
    margin: 20.5%;
  }
  .sm-margin-twenty-one-all {
    margin: 21%;
  }
  .sm-margin-twenty-one-half-all {
    margin: 21.5%;
  }
  .sm-margin-twenty-two-all {
    margin: 22%;
  }
  .sm-margin-twenty-two-half-all {
    margin: 22.5%;
  }
  .sm-margin-twenty-three-all {
    margin: 23%;
  }
  .sm-margin-twenty-three-half-all {
    margin: 23.5%;
  }
  .sm-margin-twenty-four-all {
    margin: 24%;
  }
  .sm-margin-twenty-four-half-all {
    margin: 24.5%;
  }
  .sm-margin-twenty-five-all {
    margin: 25%;
  }
  .sm-margin-5px-all {
    margin: 5px !important;
  }
  .sm-margin-10px-all {
    margin: 10px !important;
  }
  .sm-margin-15px-all {
    margin: 15px !important;
  }
  .sm-margin-20px-all {
    margin: 20px !important;
  }
  .sm-margin-25px-all {
    margin: 25px !important;
  }
  .sm-margin-30px-all {
    margin: 30px !important;
  }
  .sm-margin-35px-all {
    margin: 35px !important;
  }
  .sm-margin-40px-all {
    margin: 40px !important;
  }
  .sm-margin-45px-all {
    margin: 45px !important;
  }
  .sm-margin-50px-all {
    margin: 50px !important;
  }
  .sm-margin-55px-all {
    margin: 55px !important;
  }
  .sm-margin-60px-all {
    margin: 60px !important;
  }
  .sm-margin-65px-all {
    margin: 65px !important;
  }
  .sm-margin-70px-all {
    margin: 70px !important;
  }
  .sm-margin-75px-all {
    margin: 75px !important;
  }
  .sm-margin-80px-all {
    margin: 80px !important;
  }
  .sm-margin-85px-all {
    margin: 85px !important;
  }
  .sm-margin-90px-all {
    margin: 90px !important;
  }
  .sm-margin-95px-all {
    margin: 95px !important;
  }
  .sm-margin-100px-all {
    margin: 100px !important;
  }

  .sm-no-margin {
    margin: 0 !important;
  }
  .sm-no-margin-lr {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .sm-no-margin-tb {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .sm-no-margin-top {
    margin-top: 0 !important;
  }
  .sm-no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .sm-no-margin-left {
    margin-left: 0 !important;
  }
  .sm-no-margin-right {
    margin-right: 0 !important;
  }
  .sm-margin-lr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sm-margin-auto {
    margin: 0 auto !important;
  }

  /* margin top */
  .sm-margin-one-top {
    margin-top: 1%;
  }
  .sm-margin-one-half-top {
    margin-top: 1.5%;
  }
  .sm-margin-two-top {
    margin-top: 2%;
  }
  .sm-margin-two-half-top {
    margin-top: 2.5%;
  }
  .sm-margin-three-top {
    margin-top: 3%;
  }
  .sm-margin-three-half-top {
    margin-top: 3.5%;
  }
  .sm-margin-four-top {
    margin-top: 4%;
  }
  .sm-margin-four-half-top {
    margin-top: 4.5%;
  }
  .sm-margin-five-top {
    margin-top: 5%;
  }
  .sm-margin-five-half-top {
    margin-top: 5.5%;
  }
  .sm-margin-six-top {
    margin-top: 6%;
  }
  .sm-margin-six-half-top {
    margin-top: 6.5%;
  }
  .sm-margin-seven-top {
    margin-top: 7%;
  }
  .sm-margin-seven-half-top {
    margin-top: 7.5%;
  }
  .sm-margin-eight-top {
    margin-top: 8%;
  }
  .sm-margin-eight-half-top {
    margin-top: 8.5%;
  }
  .sm-margin-nine-top {
    margin-top: 9%;
  }
  .sm-margin-nine-half-top {
    margin-top: 9.5%;
  }
  .sm-margin-ten-top {
    margin-top: 10%;
  }
  .sm-margin-ten-half-top {
    margin-top: 10.5%;
  }
  .sm-margin-eleven-top {
    margin-top: 11%;
  }
  .sm-margin-eleven-half-top {
    margin-top: 11.5%;
  }
  .sm-margin-twelve-top {
    margin-top: 12%;
  }
  .sm-margin-twelve-half-top {
    margin-top: 12.5%;
  }
  .sm-margin-thirteen-top {
    margin-top: 13%;
  }
  .sm-margin-thirteen-half-top {
    margin-top: 13.5%;
  }
  .sm-margin-fourteen-top {
    margin-top: 14%;
  }
  .sm-margin-fourteen-half-top {
    margin-top: 14.5%;
  }
  .sm-margin-fifteen-top {
    margin-top: 15%;
  }
  .sm-margin-fifteen-half-top {
    margin-top: 15.5%;
  }
  .sm-margin-sixteen-top {
    margin-top: 16%;
  }
  .sm-margin-sixteen-half-top {
    margin-top: 16.5%;
  }
  .sm-margin-seventeen-top {
    margin-top: 17%;
  }
  .sm-margin-seventeen-half-top {
    margin-top: 17.5%;
  }
  .sm-margin-eighteen-top {
    margin-top: 18%;
  }
  .sm-margin-eighteen-half-top {
    margin-top: 18.5%;
  }
  .sm-margin-nineteen-top {
    margin-top: 19%;
  }
  .sm-margin-nineteen-half-top {
    margin-top: 19.5%;
  }
  .sm-margin-twenty-top {
    margin-top: 20%;
  }
  .sm-margin-twenty-half-top {
    margin-top: 20.5%;
  }
  .sm-margin-twenty-one-top {
    margin-top: 21%;
  }
  .sm-margin-twenty-one-half-top {
    margin-top: 21.5%;
  }
  .sm-margin-twenty-two-top {
    margin-top: 22%;
  }
  .sm-margin-twenty-two-half-top {
    margin-top: 22.5%;
  }
  .sm-margin-twenty-three-top {
    margin-top: 23%;
  }
  .sm-margin-twenty-three-half-top {
    margin-top: 23.5%;
  }
  .sm-margin-twenty-four-top {
    margin-top: 24%;
  }
  .sm-margin-twenty-four-half-top {
    margin-top: 24.5%;
  }
  .sm-margin-twenty-five-top {
    margin-top: 25%;
  }
  .sm-margin-5px-top {
    margin-top: 5px !important;
  }
  .sm-margin-10px-top {
    margin-top: 10px !important;
  }
  .sm-margin-15px-top {
    margin-top: 15px !important;
  }
  .sm-margin-20px-top {
    margin-top: 20px !important;
  }
  .sm-margin-25px-top {
    margin-top: 25px !important;
  }
  .sm-margin-30px-top {
    margin-top: 30px !important;
  }
  .sm-margin-35px-top {
    margin-top: 35px !important;
  }
  .sm-margin-40px-top {
    margin-top: 40px !important;
  }
  .sm-margin-45px-top {
    margin-top: 45px !important;
  }
  .sm-margin-50px-top {
    margin-top: 50px !important;
  }
  .sm-margin-55px-top {
    margin-top: 55px !important;
  }
  .sm-margin-60px-top {
    margin-top: 60px !important;
  }
  .sm-margin-65px-top {
    margin-top: 65px !important;
  }
  .sm-margin-70px-top {
    margin-top: 70px !important;
  }
  .sm-margin-75px-top {
    margin-top: 75px !important;
  }
  .sm-margin-80px-top {
    margin-top: 80px !important;
  }
  .sm-margin-85px-top {
    margin-top: 85px !important;
  }
  .sm-margin-90px-top {
    margin-top: 90px !important;
  }
  .sm-margin-95px-top {
    margin-top: 95px !important;
  }
  .sm-margin-100px-top {
    margin-top: 100px !important;
  }

  /* margin bottom */
  .sm-margin-one-bottom {
    margin-bottom: 1%;
  }
  .sm-margin-one-half-bottom {
    margin-bottom: 1.5%;
  }
  .sm-margin-two-bottom {
    margin-bottom: 2%;
  }
  .sm-margin-two-half-bottom {
    margin-bottom: 2.5%;
  }
  .sm-margin-three-bottom {
    margin-bottom: 3%;
  }
  .sm-margin-three-half-bottom {
    margin-bottom: 3.5%;
  }
  .sm-margin-four-bottom {
    margin-bottom: 4%;
  }
  .sm-margin-four-half-bottom {
    margin-bottom: 4.5%;
  }
  .sm-margin-five-bottom {
    margin-bottom: 5%;
  }
  .sm-margin-five-half-bottom {
    margin-bottom: 5.5%;
  }
  .sm-margin-six-bottom {
    margin-bottom: 6%;
  }
  .sm-margin-six-half-bottom {
    margin-bottom: 6.5%;
  }
  .sm-margin-seven-bottom {
    margin-bottom: 7%;
  }
  .sm-margin-seven-half-bottom {
    margin-bottom: 7.5%;
  }
  .sm-margin-eight-bottom {
    margin-bottom: 8%;
  }
  .sm-margin-eight-half-bottom {
    margin-bottom: 8.5%;
  }
  .sm-margin-nine-bottom {
    margin-bottom: 9%;
  }
  .sm-margin-nine-half-bottom {
    margin-bottom: 9.5%;
  }
  .sm-margin-ten-bottom {
    margin-bottom: 10%;
  }
  .sm-margin-ten-half-bottom {
    margin-bottom: 10.5%;
  }
  .sm-margin-eleven-bottom {
    margin-bottom: 11%;
  }
  .sm-margin-eleven-half-bottom {
    margin-bottom: 11.5%;
  }
  .sm-margin-twelve-bottom {
    margin-bottom: 12%;
  }
  .sm-margin-twelve-half-bottom {
    margin-bottom: 12.5%;
  }
  .sm-margin-thirteen-bottom {
    margin-bottom: 13%;
  }
  .sm-margin-thirteen-half-bottom {
    margin-bottom: 13.5%;
  }
  .sm-margin-fourteen-bottom {
    margin-bottom: 14%;
  }
  .sm-margin-fourteen-half-bottom {
    margin-bottom: 14.5%;
  }
  .sm-margin-fifteen-bottom {
    margin-bottom: 15%;
  }
  .sm-margin-fifteen-half-bottom {
    margin-bottom: 15.5%;
  }
  .sm-margin-sixteen-bottom {
    margin-bottom: 16%;
  }
  .sm-margin-sixteen-half-bottom {
    margin-bottom: 16.5%;
  }
  .sm-margin-seventeen-bottom {
    margin-bottom: 17%;
  }
  .sm-margin-seventeen-half-bottom {
    margin-bottom: 17.5%;
  }
  .sm-margin-eighteen-bottom {
    margin-bottom: 18%;
  }
  .sm-margin-eighteen-half-bottom {
    margin-bottom: 18.5%;
  }
  .sm-margin-nineteen-bottom {
    margin-bottom: 19%;
  }
  .sm-margin-nineteen-half-bottom {
    margin-bottom: 19.5%;
  }
  .sm-margin-twenty-bottom {
    margin-bottom: 20%;
  }
  .sm-margin-twenty-half-bottom {
    margin-bottom: 20.5%;
  }
  .sm-margin-twenty-one-bottom {
    margin-bottom: 21%;
  }
  .sm-margin-twenty-one-half-bottom {
    margin-bottom: 21.5%;
  }
  .sm-margin-twenty-two-bottom {
    margin-bottom: 22%;
  }
  .sm-margin-twenty-two-half-bottom {
    margin-bottom: 22.5%;
  }
  .sm-margin-twenty-three-bottom {
    margin-bottom: 23%;
  }
  .sm-margin-twenty-three-half-bottom {
    margin-bottom: 23.5%;
  }
  .sm-margin-twenty-four-bottom {
    margin-bottom: 24%;
  }
  .sm-margin-twenty-four-half-bottom {
    margin-bottom: 24.5%;
  }
  .sm-margin-twenty-five-bottom {
    margin-bottom: 25%;
  }
  .sm-margin-5px-bottom {
    margin-bottom: 5px !important;
  }
  .sm-margin-10px-bottom {
    margin-bottom: 10px !important;
  }
  .sm-margin-15px-bottom {
    margin-bottom: 15px !important;
  }
  .sm-margin-20px-bottom {
    margin-bottom: 20px !important;
  }
  .sm-margin-25px-bottom {
    margin-bottom: 25px !important;
  }
  .sm-margin-30px-bottom {
    margin-bottom: 30px !important;
  }
  .sm-margin-35px-bottom {
    margin-bottom: 35px !important;
  }
  .sm-margin-40px-bottom {
    margin-bottom: 40px !important;
  }
  .sm-margin-45px-bottom {
    margin-bottom: 45px !important;
  }
  .sm-margin-50px-bottom {
    margin-bottom: 50px !important;
  }
  .sm-margin-55px-bottom {
    margin-bottom: 55px !important;
  }
  .sm-margin-60px-bottom {
    margin-bottom: 60px !important;
  }
  .sm-margin-65px-bottom {
    margin-bottom: 65px !important;
  }
  .sm-margin-70px-bottom {
    margin-bottom: 70px !important;
  }
  .sm-margin-75px-bottom {
    margin-bottom: 75px !important;
  }
  .sm-margin-80px-bottom {
    margin-bottom: 80px !important;
  }
  .sm-margin-85px-bottom {
    margin-bottom: 85px !important;
  }
  .sm-margin-90px-bottom {
    margin-bottom: 90px !important;
  }
  .sm-margin-95px-bottom {
    margin-bottom: 95px !important;
  }
  .sm-margin-100px-bottom {
    margin-bottom: 100px !important;
  }

  /* margin right */
  .sm-margin-one-right {
    margin-right: 1%;
  }
  .sm-margin-one-half-right {
    margin-right: 1.5%;
  }
  .sm-margin-two-right {
    margin-right: 2%;
  }
  .sm-margin-two-half-right {
    margin-right: 2.5%;
  }
  .sm-margin-three-right {
    margin-right: 3%;
  }
  .sm-margin-three-half-right {
    margin-right: 3.5%;
  }
  .sm-margin-four-right {
    margin-right: 4%;
  }
  .sm-margin-four-half-right {
    margin-right: 4.5%;
  }
  .sm-margin-five-right {
    margin-right: 5%;
  }
  .sm-margin-five-half-right {
    margin-right: 5.5%;
  }
  .sm-margin-six-right {
    margin-right: 6%;
  }
  .sm-margin-six-half-right {
    margin-right: 6.5%;
  }
  .sm-margin-seven-right {
    margin-right: 7%;
  }
  .sm-margin-seven-half-right {
    margin-right: 7.5%;
  }
  .sm-margin-eight-right {
    margin-right: 8%;
  }
  .sm-margin-eight-half-right {
    margin-right: 8.5%;
  }
  .sm-margin-nine-right {
    margin-right: 9%;
  }
  .sm-margin-nine-half-right {
    margin-right: 9.5%;
  }
  .sm-margin-ten-right {
    margin-right: 10%;
  }
  .sm-margin-ten-half-right {
    margin-right: 10.5%;
  }
  .sm-margin-eleven-right {
    margin-right: 11%;
  }
  .sm-margin-eleven-half-right {
    margin-right: 11.5%;
  }
  .sm-margin-twelve-right {
    margin-right: 12%;
  }
  .sm-margin-twelve-half-right {
    margin-right: 12.5%;
  }
  .sm-margin-thirteen-right {
    margin-right: 13%;
  }
  .sm-margin-thirteen-half-right {
    margin-right: 13.5%;
  }
  .sm-margin-fourteen-right {
    margin-right: 14%;
  }
  .sm-margin-fourteen-half-right {
    margin-right: 14.5%;
  }
  .sm-margin-fifteen-right {
    margin-right: 15%;
  }
  .sm-margin-fifteen-half-right {
    margin-right: 15.5%;
  }
  .sm-margin-sixteen-right {
    margin-right: 16%;
  }
  .sm-margin-sixteen-half-right {
    margin-right: 16.5%;
  }
  .sm-margin-seventeen-right {
    margin-right: 17%;
  }
  .sm-margin-seventeen-half-right {
    margin-right: 17.5%;
  }
  .sm-margin-eighteen-right {
    margin-right: 18%;
  }
  .sm-margin-eighteen-half-right {
    margin-right: 18.5%;
  }
  .sm-margin-nineteen-right {
    margin-right: 19%;
  }
  .sm-margin-nineteen-half-right {
    margin-right: 19.5%;
  }
  .sm-margin-twenty-right {
    margin-right: 20%;
  }
  .sm-margin-twenty-half-right {
    margin-right: 20.5%;
  }
  .sm-margin-twenty-one-right {
    margin-right: 21%;
  }
  .sm-margin-twenty-one-half-right {
    margin-right: 21.5%;
  }
  .sm-margin-twenty-two-right {
    margin-right: 22%;
  }
  .sm-margin-twenty-two-half-right {
    margin-right: 22.5%;
  }
  .sm-margin-twenty-three-right {
    margin-right: 23%;
  }
  .sm-margin-twenty-three-half-right {
    margin-right: 23.5%;
  }
  .sm-margin-twenty-four-right {
    margin-right: 24%;
  }
  .sm-margin-twenty-four-half-right {
    margin-right: 24.5%;
  }
  .sm-margin-twenty-five-right {
    margin-right: 25%;
  }
  .sm-margin-10px-right {
    margin-right: 10px !important;
  }
  .sm-margin-15px-right {
    margin-right: 15px !important;
  }
  .sm-margin-20px-right {
    margin-right: 20px !important;
  }
  .sm-margin-25px-right {
    margin-right: 25px !important;
  }
  .sm-margin-30px-right {
    margin-right: 30px !important;
  }
  .sm-margin-35px-right {
    margin-right: 35px !important;
  }
  .sm-margin-40px-right {
    margin-right: 40px !important;
  }
  .sm-margin-45px-right {
    margin-right: 45px !important;
  }
  .sm-margin-50px-right {
    margin-right: 50px !important;
  }
  .sm-margin-55px-right {
    margin-right: 55px !important;
  }
  .sm-margin-60px-right {
    margin-right: 60px !important;
  }
  .sm-margin-65px-right {
    margin-right: 65px !important;
  }
  .sm-margin-70px-right {
    margin-right: 70px !important;
  }
  .sm-margin-75px-right {
    margin-right: 75px !important;
  }
  .sm-margin-80px-right {
    margin-right: 80px !important;
  }
  .sm-margin-85px-right {
    margin-right: 85px !important;
  }
  .sm-margin-90px-right {
    margin-right: 90px !important;
  }
  .sm-margin-95px-right {
    margin-right: 95px !important;
  }
  .sm-margin-100px-right {
    margin-right: 100px !important;
  }

  /* margin left */
  .sm-margin-one-left {
    margin-left: 1%;
  }
  .sm-margin-one-half-left {
    margin-left: 1.5%;
  }
  .sm-margin-two-left {
    margin-left: 2%;
  }
  .sm-margin-two-half-left {
    margin-left: 2.5%;
  }
  .sm-margin-three-left {
    margin-left: 3%;
  }
  .sm-margin-three-half-left {
    margin-left: 3.5%;
  }
  .sm-margin-four-left {
    margin-left: 4%;
  }
  .sm-margin-four-half-left {
    margin-left: 4.5%;
  }
  .sm-margin-five-left {
    margin-left: 5%;
  }
  .sm-margin-five-half-left {
    margin-left: 5.5%;
  }
  .sm-margin-six-left {
    margin-left: 6%;
  }
  .sm-margin-six-half-left {
    margin-left: 6.5%;
  }
  .sm-margin-seven-left {
    margin-left: 7%;
  }
  .sm-margin-seven-half-left {
    margin-left: 7.5%;
  }
  .sm-margin-eight-left {
    margin-left: 8%;
  }
  .sm-margin-eight-half-left {
    margin-left: 8.5%;
  }
  .sm-margin-nine-left {
    margin-left: 9%;
  }
  .sm-margin-nine-half-left {
    margin-left: 9.5%;
  }
  .sm-margin-ten-left {
    margin-left: 10%;
  }
  .sm-margin-ten-half-left {
    margin-left: 10.5%;
  }
  .sm-margin-eleven-left {
    margin-left: 11%;
  }
  .sm-margin-eleven-half-left {
    margin-left: 11.5%;
  }
  .sm-margin-twelve-left {
    margin-left: 12%;
  }
  .sm-margin-twelve-half-left {
    margin-left: 12.5%;
  }
  .sm-margin-thirteen-left {
    margin-left: 13%;
  }
  .sm-margin-thirteen-half-left {
    margin-left: 13.5%;
  }
  .sm-margin-fourteen-left {
    margin-left: 14%;
  }
  .sm-margin-fourteen-half-left {
    margin-left: 14.5%;
  }
  .sm-margin-fifteen-left {
    margin-left: 15%;
  }
  .sm-margin-fifteen-half-left {
    margin-left: 15.5%;
  }
  .sm-margin-sixteen-left {
    margin-left: 16%;
  }
  .sm-margin-sixteen-half-left {
    margin-left: 16.5%;
  }
  .sm-margin-seventeen-left {
    margin-left: 17%;
  }
  .sm-margin-seventeen-half-left {
    margin-left: 17.5%;
  }
  .sm-margin-eighteen-left {
    margin-left: 18%;
  }
  .sm-margin-eighteen-half-left {
    margin-left: 18.5%;
  }
  .sm-margin-nineteen-left {
    margin-left: 19%;
  }
  .sm-margin-nineteen-half-left {
    margin-left: 19.5%;
  }
  .sm-margin-twenty-left {
    margin-left: 20%;
  }
  .sm-margin-twenty-half-left {
    margin-left: 20.5%;
  }
  .sm-margin-twenty-one-left {
    margin-left: 21%;
  }
  .sm-margin-twenty-one-half-left {
    margin-left: 21.5%;
  }
  .sm-margin-twenty-two-left {
    margin-left: 22%;
  }
  .sm-margin-twenty-two-half-left {
    margin-left: 22.5%;
  }
  .sm-margin-twenty-three-left {
    margin-left: 23%;
  }
  .sm-margin-twenty-three-half-left {
    margin-left: 23.5%;
  }
  .sm-margin-twenty-four-left {
    margin-left: 24%;
  }
  .sm-margin-twenty-four-half-left {
    margin-left: 24.5%;
  }
  .sm-margin-twenty-five-left {
    margin-left: 25%;
  }
  .sm-margin-5px-left {
    margin-left: 5px !important;
  }
  .sm-margin-10px-left {
    margin-left: 10px !important;
  }
  .sm-margin-15px-left {
    margin-left: 15px !important;
  }
  .sm-margin-20px-left {
    margin-left: 20px !important;
  }
  .sm-margin-25px-left {
    margin-left: 25px !important;
  }
  .sm-margin-30px-left {
    margin-left: 30px !important;
  }
  .sm-margin-35px-left {
    margin-left: 35px !important;
  }
  .sm-margin-40px-left {
    margin-left: 40px !important;
  }
  .sm-margin-45px-left {
    margin-left: 45px !important;
  }
  .sm-margin-50px-left {
    margin-left: 50px !important;
  }
  .sm-margin-55px-left {
    margin-left: 55px !important;
  }
  .sm-margin-60px-left {
    margin-left: 60px !important;
  }
  .sm-margin-65px-left {
    margin-left: 65px !important;
  }
  .sm-margin-70px-left {
    margin-left: 70px !important;
  }
  .sm-margin-75px-left {
    margin-left: 75px !important;
  }
  .sm-margin-80px-left {
    margin-left: 80px !important;
  }
  .sm-margin-85px-left {
    margin-left: 85px !important;
  }
  .sm-margin-90px-left {
    margin-left: 90px !important;
  }
  .sm-margin-95px-left {
    margin-left: 95px !important;
  }
  .sm-margin-100px-left {
    margin-left: 100px !important;
  }

  /* margin left right */
  .sm-margin-one-lr {
    margin-left: 1%;
    margin-right: 1%;
  }
  .sm-margin-one-lr {
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
  .sm-margin-two-lr {
    margin-left: 2%;
    margin-right: 2%;
  }
  .sm-margin-two-lr {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  .sm-margin-three-lr {
    margin-left: 3%;
    margin-right: 3%;
  }
  .sm-margin-three-lr {
    margin-left: 3.5%;
    margin-right: 3.5%;
  }
  .sm-margin-four-lr {
    margin-left: 4%;
    margin-right: 4%;
  }
  .sm-margin-four-lr {
    margin-left: 4.5%;
    margin-right: 4.5%;
  }
  .sm-margin-five-lr {
    margin-left: 5%;
    margin-right: 5%;
  }
  .sm-margin-five-lr {
    margin-left: 5.5%;
    margin-right: 5.5%;
  }
  .sm-margin-six-lr {
    margin-left: 6%;
    margin-right: 6%;
  }
  .sm-margin-six-lr {
    margin-left: 6.5%;
    margin-right: 6.5%;
  }
  .sm-margin-seven-lr {
    margin-left: 7%;
    margin-right: 7%;
  }
  .sm-margin-seven-lr {
    margin-left: 7.5%;
    margin-right: 7.5%;
  }
  .sm-margin-eight-lr {
    margin-left: 8%;
    margin-right: 8%;
  }
  .sm-margin-eight-lr {
    margin-left: 8.5%;
    margin-right: 8.5%;
  }
  .sm-margin-nine-lr {
    margin-left: 9%;
    margin-right: 9%;
  }
  .sm-margin-nine-lr {
    margin-left: 9.5%;
    margin-right: 9.5%;
  }
  .sm-margin-ten-lr {
    margin-left: 10%;
    margin-right: 10%;
  }
  .sm-margin-ten-lr {
    margin-left: 10.5%;
    margin-right: 10.5%;
  }
  .sm-margin-eleven-lr {
    margin-left: 11%;
    margin-right: 11%;
  }
  .sm-margin-eleven-lr {
    margin-left: 11.5%;
    margin-right: 11.5%;
  }
  .sm-margin-twelve-lr {
    margin-left: 12%;
    margin-right: 12%;
  }
  .sm-margin-twelve-lr {
    margin-left: 12.5%;
    margin-right: 12.5%;
  }
  .sm-margin-thirteen-lr {
    margin-left: 13%;
    margin-right: 13%;
  }
  .sm-margin-thirteen-half-lr {
    margin-left: 13.5%;
    margin-right: 13.5%;
  }
  .sm-margin-fourteen-lr {
    margin-left: 14%;
    margin-right: 14%;
  }
  .sm-margin-fourteen-half-lr {
    margin-left: 14.5%;
    margin-right: 14.5%;
  }
  .sm-margin-fifteen-lr {
    margin-left: 15%;
    margin-right: 15%;
  }
  .sm-margin-fifteen-half-lr {
    margin-left: 15.5%;
    margin-right: 15.5%;
  }
  .sm-margin-sixteen-lr {
    margin-left: 16%;
    margin-right: 16%;
  }
  .sm-margin-sixteen-half-lr {
    margin-left: 16.5%;
    margin-right: 16.5%;
  }
  .sm-margin-seventeen-lr {
    margin-left: 17%;
    margin-right: 17%;
  }
  .sm-margin-seventeen-half-lr {
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  .sm-margin-eighteen-lr {
    margin-left: 18%;
    margin-right: 18%;
  }
  .sm-margin-eighteen-half-lr {
    margin-left: 18.5%;
    margin-right: 18.5%;
  }
  .sm-margin-nineteen-lr {
    margin-left: 19%;
    margin-right: 19%;
  }
  .sm-margin-nineteen-half-lr {
    margin-left: 19.5%;
    margin-right: 19.5%;
  }
  .sm-margin-twenty-lr {
    margin-left: 20%;
    margin-right: 20%;
  }
  .sm-margin-twenty-half-lr {
    margin-left: 20.5%;
    margin-right: 20.5%;
  }
  .sm-margin-twenty-one-lr {
    margin-left: 21%;
    margin-right: 21%;
  }
  .sm-margin-twenty-one-half-lr {
    margin-left: 21.5%;
    margin-right: 21.5%;
  }
  .sm-margin-twenty-two-lr {
    margin-left: 22%;
    margin-right: 22%;
  }
  .sm-margin-twenty-two-half-lr {
    margin-left: 22.5%;
    margin-right: 22.5%;
  }
  .sm-margin-twenty-three-lr {
    margin-left: 23%;
    margin-right: 23%;
  }
  .sm-margin-twenty-three-half-lr {
    margin-left: 23.5%;
    margin-right: 23.5%;
  }
  .sm-margin-twenty-four-lr {
    margin-left: 24%;
    margin-right: 24%;
  }
  .sm-margin-twenty-four-half-lr {
    margin-left: 24.5%;
    margin-right: 24.5%;
  }
  .sm-margin-twenty-five-lr {
    margin-left: 25%;
    margin-right: 25%;
  }
  .sm-margin-5px-lr {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .sm-margin-10px-lr {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .sm-margin-15px-lr {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .sm-margin-20px-lr {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .sm-margin-25px-lr {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .sm-margin-30px-lr {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .sm-margin-35px-lr {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .sm-margin-40px-lr {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .sm-margin-45px-lr {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .sm-margin-50px-lr {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .sm-margin-55px-lr {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .sm-margin-60px-lr {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .sm-margin-65px-lr {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .sm-margin-70px-lr {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .sm-margin-75px-lr {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .sm-margin-80px-lr {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .sm-margin-85px-lr {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .sm-margin-90px-lr {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .sm-margin-95px-lr {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .sm-margin-100px-lr {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  /* margin top bottom */
  .sm-margin-one-tb {
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .sm-margin-one-tb {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
  }
  .sm-margin-two-tb {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .sm-margin-two-tb {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
  }
  .sm-margin-three-tb {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .sm-margin-three-tb {
    margin-top: 3.5%;
    margin-bottom: 3.5%;
  }
  .sm-margin-four-tb {
    margin-top: 4%;
    margin-bottom: 4%;
  }
  .sm-margin-four-tb {
    margin-top: 4.5%;
    margin-bottom: 4.5%;
  }
  .sm-margin-five-tb {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .sm-margin-five-tb {
    margin-top: 5.5%;
    margin-bottom: 5.5%;
  }
  .sm-margin-six-tb {
    margin-top: 6%;
    margin-bottom: 6%;
  }
  .sm-margin-six-tb {
    margin-top: 6.5%;
    margin-bottom: 6.5%;
  }
  .sm-margin-seven-tb {
    margin-top: 7%;
    margin-bottom: 7%;
  }
  .sm-margin-seven-tb {
    margin-top: 7.5%;
    margin-bottom: 7.5%;
  }
  .sm-margin-eight-tb {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  .sm-margin-eight-tb {
    margin-top: 8.5%;
    margin-bottom: 8.5%;
  }
  .sm-margin-nine-tb {
    margin-top: 9%;
    margin-bottom: 9%;
  }
  .sm-margin-nine-tb {
    margin-top: 9.5%;
    margin-bottom: 9.5%;
  }
  .sm-margin-ten-tb {
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .sm-margin-ten-tb {
    margin-top: 10.5%;
    margin-bottom: 10.5%;
  }
  .sm-margin-eleven-tb {
    margin-top: 11%;
    margin-bottom: 11%;
  }
  .sm-margin-eleven-tb {
    margin-top: 11.5%;
    margin-bottom: 11.5%;
  }
  .sm-margin-twelve-tb {
    margin-top: 12%;
    margin-bottom: 12%;
  }
  .sm-margin-twelve-tb {
    margin-top: 12.5%;
    margin-bottom: 12.5%;
  }
  .sm-margin-thirteen-tb {
    margin-top: 13%;
    margin-bottom: 13%;
  }
  .sm-margin-thirteen-half-tb {
    margin-top: 13.5%;
    margin-bottom: 13.5%;
  }
  .sm-margin-fourteen-tb {
    margin-top: 14%;
    margin-bottom: 14%;
  }
  .sm-margin-fourteen-half-tb {
    margin-top: 14.5%;
    margin-bottom: 14.5%;
  }
  .sm-margin-fifteen-tb {
    margin-top: 15%;
    margin-bottom: 15%;
  }
  .sm-margin-fifteen-half-tb {
    margin-top: 15.5%;
    margin-bottom: 15.5%;
  }
  .sm-margin-sixteen-tb {
    margin-top: 16%;
    margin-bottom: 16%;
  }
  .sm-margin-sixteen-half-tb {
    margin-top: 16.5%;
    margin-bottom: 16.5%;
  }
  .sm-margin-seventeen-tb {
    margin-top: 17%;
    margin-bottom: 17%;
  }
  .sm-margin-seventeen-half-tb {
    margin-top: 17.5%;
    margin-bottom: 17.5%;
  }
  .sm-margin-eighteen-tb {
    margin-top: 18%;
    margin-bottom: 18%;
  }
  .sm-margin-eighteen-half-tb {
    margin-top: 18.5%;
    margin-bottom: 18.5%;
  }
  .sm-margin-nineteen-tb {
    margin-top: 19%;
    margin-bottom: 19%;
  }
  .sm-margin-nineteen-half-tb {
    margin-top: 19.5%;
    margin-bottom: 19.5%;
  }
  .sm-margin-twenty-tb {
    margin-top: 20%;
    margin-bottom: 20%;
  }
  .sm-margin-twenty-half-tb {
    margin-top: 20.5%;
    margin-bottom: 20.5%;
  }
  .sm-margin-twenty-one-tb {
    margin-top: 21%;
    margin-bottom: 21%;
  }
  .sm-margin-twenty-one-half-tb {
    margin-top: 21.5%;
    margin-bottom: 21.5%;
  }
  .sm-margin-twenty-two-tb {
    margin-top: 22%;
    margin-bottom: 22%;
  }
  .sm-margin-twenty-two-half-tb {
    margin-top: 22.5%;
    margin-bottom: 22.5%;
  }
  .sm-margin-twenty-three-tb {
    margin-top: 23%;
    margin-bottom: 23%;
  }
  .sm-margin-twenty-three-half-tb {
    margin-top: 23.5%;
    margin-bottom: 23.5%;
  }
  .sm-margin-twenty-four-tb {
    margin-top: 24%;
    margin-bottom: 24%;
  }
  .sm-margin-twenty-four-half-tb {
    margin-top: 24.5%;
    margin-bottom: 24.5%;
  }
  .sm-margin-twenty-five-tb {
    margin-top: 25%;
    margin-bottom: 25%;
  }
  .sm-margin-5px-tb {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .sm-margin-10px-tb {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .sm-margin-15px-tb {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .sm-margin-20px-tb {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .sm-margin-25px-tb {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .sm-margin-30px-tb {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .sm-margin-35px-tb {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .sm-margin-40px-tb {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .sm-margin-45px-tb {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .sm-margin-50px-tb {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .sm-margin-55px-tb {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .sm-margin-60px-tb {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .sm-margin-65px-tb {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .sm-margin-70px-tb {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .sm-margin-75px-tb {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .sm-margin-80px-tb {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .sm-margin-85px-tb {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .sm-margin-90px-tb {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .sm-margin-95px-tb {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .sm-margin-100px-tb {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  /* header padding top */
  .sm-header-padding-top {
    padding-top: 82px;
  }
  .sm-header-margin-top {
    margin-top: 80px;
  }

  /* padding */
  .sm-no-padding {
    padding: 0 !important;
  }
  .sm-no-padding-lr {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .sm-no-padding-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .sm-no-padding-top {
    padding-top: 0 !important;
  }
  .sm-no-padding-bottom {
    padding-bottom: 0 !important;
  }
  .sm-no-padding-left {
    padding-left: 0 !important;
  }
  .sm-no-padding-right {
    padding-right: 0 !important;
  }
  .sm-padding-one-all {
    padding: 1%;
  }
  .sm-padding-one-half-all {
    padding: 1.5%;
  }
  .sm-padding-two-all {
    padding: 2%;
  }
  .sm-padding-two-half-all {
    padding: 2.5%;
  }
  .sm-padding-three-all {
    padding: 3%;
  }
  .sm-padding-three-half-all {
    padding: 3.5%;
  }
  .sm-padding-four-all {
    padding: 4%;
  }
  .sm-padding-four-half-all {
    padding: 4.5%;
  }
  .sm-padding-five-all {
    padding: 5%;
  }
  .sm-padding-five-half-all {
    padding: 5.5%;
  }
  .sm-padding-six-all {
    padding: 6%;
  }
  .sm-padding-six-half-all {
    padding: 6.5%;
  }
  .sm-padding-seven-all {
    padding: 7%;
  }
  .sm-padding-seven-half-all {
    padding: 7.5%;
  }
  .sm-padding-eight-all {
    padding: 8%;
  }
  .sm-padding-eight-half-all {
    padding: 8.5%;
  }
  .sm-padding-nine-all {
    padding: 9%;
  }
  .sm-padding-nine-half-all {
    padding: 9.5%;
  }
  .sm-padding-ten-all {
    padding: 10%;
  }
  .sm-padding-ten-half-all {
    padding: 10.5%;
  }
  .sm-padding-eleven-all {
    padding: 11%;
  }
  .sm-padding-eleven-half-all {
    padding: 11.5%;
  }
  .sm-padding-twelve-all {
    padding: 12%;
  }
  .sm-padding-twelve-half-all {
    padding: 12.5%;
  }
  .sm-padding-thirteen-all {
    padding: 13%;
  }
  .sm-padding-thirteen-half-all {
    padding: 13.5%;
  }
  .sm-padding-fourteen-all {
    padding: 14%;
  }
  .sm-padding-fourteen-half-all {
    padding: 14.5%;
  }
  .sm-padding-fifteen-all {
    padding: 15%;
  }
  .sm-padding-fifteen-half-all {
    padding: 15.5%;
  }
  .sm-padding-sixteen-all {
    padding: 16%;
  }
  .sm-padding-sixteen-half-all {
    padding: 16.5%;
  }
  .sm-padding-seventeen-all {
    padding: 17%;
  }
  .sm-padding-seventeen-half-all {
    padding: 17.5%;
  }
  .sm-padding-eighteen-all {
    padding: 18%;
  }
  .sm-padding-eighteen-half-all {
    padding: 18.5%;
  }
  .sm-padding-nineteen-all {
    padding: 19%;
  }
  .sm-padding-nineteen-half-all {
    padding: 19.5%;
  }
  .sm-padding-twenty-all {
    padding: 20%;
  }
  .sm-padding-twenty-half-all {
    padding: 20.5%;
  }
  .sm-padding-twenty-one-all {
    padding: 21%;
  }
  .sm-padding-twenty-one-half-all {
    padding: 21.5%;
  }
  .sm-padding-twenty-two-all {
    padding: 22%;
  }
  .sm-padding-twenty-two-half-all {
    padding: 22.5%;
  }
  .sm-padding-twenty-three-all {
    padding: 23%;
  }
  .sm-padding-twenty-three-half-all {
    padding: 23.5%;
  }
  .sm-padding-twenty-four-all {
    padding: 24%;
  }
  .sm-padding-twenty-four-half-all {
    padding: 24.5%;
  }
  .sm-padding-twenty-five-all {
    padding: 25%;
  }
  .sm-padding-5px-all {
    padding: 5px !important;
  }
  .sm-padding-10px-all {
    padding: 10px !important;
  }
  .sm-padding-15px-all {
    padding: 15px !important;
  }
  .sm-padding-20px-all {
    padding: 20px !important;
  }
  .sm-padding-25px-all {
    padding: 25px !important;
  }
  .sm-padding-30px-all {
    padding: 30px !important;
  }
  .sm-padding-35px-all {
    padding: 35px !important;
  }
  .sm-padding-40px-all {
    padding: 40px !important;
  }
  .sm-padding-45px-all {
    padding: 45px !important;
  }
  .sm-padding-50px-all {
    padding: 50px !important;
  }
  .sm-padding-55px-all {
    padding: 55px !important;
  }
  .sm-padding-60px-all {
    padding: 60px !important;
  }
  .sm-padding-65px-all {
    padding: 65px !important;
  }
  .sm-padding-70px-all {
    padding: 70px !important;
  }
  .sm-padding-75px-all {
    padding: 75px !important;
  }
  .sm-padding-80px-all {
    padding: 80px !important;
  }
  .sm-padding-85px-all {
    padding: 85px !important;
  }
  .sm-padding-90px-all {
    padding: 90px !important;
  }
  .sm-padding-95px-all {
    padding: 95px !important;
  }
  .sm-padding-100px-all {
    padding: 100px !important;
  }

  /* padding top */
  .sm-padding-one-top {
    padding-top: 1%;
  }
  .sm-padding-one-half-top {
    padding-top: 1.5%;
  }
  .sm-padding-two-top {
    padding-top: 2%;
  }
  .sm-padding-two-half-top {
    padding-top: 2.5%;
  }
  .sm-padding-three-top {
    padding-top: 3%;
  }
  .sm-padding-three-half-top {
    padding-top: 3.5%;
  }
  .sm-padding-four-top {
    padding-top: 4%;
  }
  .sm-padding-four-half-top {
    padding-top: 4.5%;
  }
  .sm-padding-five-top {
    padding-top: 5%;
  }
  .sm-padding-five-half-top {
    padding-top: 5.5%;
  }
  .sm-padding-six-top {
    padding-top: 6%;
  }
  .sm-padding-six-half-top {
    padding-top: 6.5%;
  }
  .sm-padding-seven-top {
    padding-top: 7%;
  }
  .sm-padding-seven-half-top {
    padding-top: 7.5%;
  }
  .sm-padding-eight-top {
    padding-top: 8%;
  }
  .sm-padding-eight-half-top {
    padding-top: 8.5%;
  }
  .sm-padding-nine-top {
    padding-top: 9%;
  }
  .sm-padding-nine-half-top {
    padding-top: 9.5%;
  }
  .sm-padding-ten-top {
    padding-top: 10%;
  }
  .sm-padding-ten-half-top {
    padding-top: 10.5%;
  }
  .sm-padding-eleven-top {
    padding-top: 11%;
  }
  .sm-padding-eleven-half-top {
    padding-top: 11.5%;
  }
  .sm-padding-twelve-top {
    padding-top: 12%;
  }
  .sm-padding-twelve-half-top {
    padding-top: 12.5%;
  }
  .sm-padding-thirteen-top {
    padding-top: 13%;
  }
  .sm-padding-thirteen-half-top {
    padding-top: 13.5%;
  }
  .sm-padding-fourteen-top {
    padding-top: 14%;
  }
  .sm-padding-fourteen-half-top {
    padding-top: 14.5%;
  }
  .sm-padding-fifteen-top {
    padding-top: 15%;
  }
  .sm-padding-fifteen-half-top {
    padding-top: 15.5%;
  }
  .sm-padding-sixteen-top {
    padding-top: 16%;
  }
  .sm-padding-sixteen-half-top {
    padding-top: 16.5%;
  }
  .sm-padding-seventeen-top {
    padding-top: 17%;
  }
  .sm-padding-seventeen-half-top {
    padding-top: 17.5%;
  }
  .sm-padding-eighteen-top {
    padding-top: 18%;
  }
  .sm-padding-eighteen-half-top {
    padding-top: 18.5%;
  }
  .sm-padding-nineteen-top {
    padding-top: 19%;
  }
  .sm-padding-nineteen-half-top {
    padding-top: 19.5%;
  }
  .sm-padding-twenty-top {
    padding-top: 20%;
  }
  .sm-padding-twenty-half-top {
    padding-top: 20.5%;
  }
  .sm-padding-twenty-one-top {
    padding-top: 21%;
  }
  .sm-padding-twenty-one-half-top {
    padding-top: 21.5%;
  }
  .sm-padding-twenty-two-top {
    padding-top: 22%;
  }
  .sm-padding-twenty-two-half-top {
    padding-top: 22.5%;
  }
  .sm-padding-twenty-three-top {
    padding-top: 23%;
  }
  .sm-padding-twenty-three-half-top {
    padding-top: 23.5%;
  }
  .sm-padding-twenty-four-top {
    padding-top: 24%;
  }
  .sm-padding-twenty-four-half-top {
    padding-top: 24.5%;
  }
  .sm-padding-twenty-five-top {
    padding-top: 25%;
  }
  .sm-padding-5px-top {
    padding-top: 5px !important;
  }
  .sm-padding-10px-top {
    padding-top: 10px !important;
  }
  .sm-padding-15px-top {
    padding-top: 15px !important;
  }
  .sm-padding-20px-top {
    padding-top: 20px !important;
  }
  .sm-padding-25px-top {
    padding-top: 25px !important;
  }
  .sm-padding-30px-top {
    padding-top: 30px !important;
  }
  .sm-padding-35px-top {
    padding-top: 35px !important;
  }
  .sm-padding-40px-top {
    padding-top: 40px !important;
  }
  .sm-padding-45px-top {
    padding-top: 45px !important;
  }
  .sm-padding-50px-top {
    padding-top: 50px !important;
  }
  .sm-padding-55px-top {
    padding-top: 55px !important;
  }
  .sm-padding-60px-top {
    padding-top: 60px !important;
  }
  .sm-padding-65px-top {
    padding-top: 65px !important;
  }
  .sm-padding-70px-top {
    padding-top: 70px !important;
  }
  .sm-padding-75px-top {
    padding-top: 75px !important;
  }
  .sm-padding-80px-top {
    padding-top: 80px !important;
  }
  .sm-padding-85px-top {
    padding-top: 85px !important;
  }
  .sm-padding-90px-top {
    padding-top: 90px !important;
  }
  .sm-padding-95px-top {
    padding-top: 95px !important;
  }
  .sm-padding-100px-top {
    padding-top: 100px !important;
  }

  /* padding bottom */
  .sm-padding-one-bottom {
    padding-bottom: 1%;
  }
  .sm-padding-one-half-bottom {
    padding-bottom: 1.5%;
  }
  .sm-padding-two-bottom {
    padding-bottom: 2%;
  }
  .sm-padding-two-half-bottom {
    padding-bottom: 2.5%;
  }
  .sm-padding-three-bottom {
    padding-bottom: 3%;
  }
  .sm-padding-three-half-bottom {
    padding-bottom: 3.5%;
  }
  .sm-padding-four-bottom {
    padding-bottom: 4%;
  }
  .sm-padding-four-half-bottom {
    padding-bottom: 4.5%;
  }
  .sm-padding-five-bottom {
    padding-bottom: 5%;
  }
  .sm-padding-five-half-bottom {
    padding-bottom: 5.5%;
  }
  .sm-padding-six-bottom {
    padding-bottom: 6%;
  }
  .sm-padding-six-half-bottom {
    padding-bottom: 6.5%;
  }
  .sm-padding-seven-bottom {
    padding-bottom: 7%;
  }
  .sm-padding-seven-half-bottom {
    padding-bottom: 7.5%;
  }
  .sm-padding-eight-bottom {
    padding-bottom: 8%;
  }
  .sm-padding-eight-half-bottom {
    padding-bottom: 8.5%;
  }
  .sm-padding-nine-bottom {
    padding-bottom: 9%;
  }
  .sm-padding-nine-half-bottom {
    padding-bottom: 9.5%;
  }
  .sm-padding-ten-bottom {
    padding-bottom: 10%;
  }
  .sm-padding-ten-half-bottom {
    padding-bottom: 10.5%;
  }
  .sm-padding-eleven-bottom {
    padding-bottom: 11%;
  }
  .sm-padding-eleven-half-bottom {
    padding-bottom: 11.5%;
  }
  .sm-padding-twelve-bottom {
    padding-bottom: 12%;
  }
  .sm-padding-twelve-half-bottom {
    padding-bottom: 12.5%;
  }
  .sm-padding-thirteen-bottom {
    padding-bottom: 13%;
  }
  .sm-padding-thirteen-half-bottom {
    padding-bottom: 13.5%;
  }
  .sm-padding-fourteen-bottom {
    padding-bottom: 14%;
  }
  .sm-padding-fourteen-half-bottom {
    padding-bottom: 14.5%;
  }
  .sm-padding-fifteen-bottom {
    padding-bottom: 15%;
  }
  .sm-padding-fifteen-half-bottom {
    padding-bottom: 15.5%;
  }
  .sm-padding-sixteen-bottom {
    padding-bottom: 16%;
  }
  .sm-padding-sixteen-half-bottom {
    padding-bottom: 16.5%;
  }
  .sm-padding-seventeen-bottom {
    padding-bottom: 17%;
  }
  .sm-padding-seventeen-half-bottom {
    padding-bottom: 17.5%;
  }
  .sm-padding-eighteen-bottom {
    padding-bottom: 18%;
  }
  .sm-padding-eighteen-half-bottom {
    padding-bottom: 18.5%;
  }
  .sm-padding-nineteen-bottom {
    padding-bottom: 19%;
  }
  .sm-padding-nineteen-half-bottom {
    padding-bottom: 19.5%;
  }
  .sm-padding-twenty-bottom {
    padding-bottom: 20%;
  }
  .sm-padding-twenty-half-bottom {
    padding-bottom: 20.5%;
  }
  .sm-padding-twenty-one-bottom {
    padding-bottom: 21%;
  }
  .sm-padding-twenty-one-half-bottom {
    padding-bottom: 21.5%;
  }
  .sm-padding-twenty-two-bottom {
    padding-bottom: 22%;
  }
  .sm-padding-twenty-two-half-bottom {
    padding-bottom: 22.5%;
  }
  .sm-padding-twenty-three-bottom {
    padding-bottom: 23%;
  }
  .sm-padding-twenty-three-half-bottom {
    padding-bottom: 23.5%;
  }
  .sm-padding-twenty-four-bottom {
    padding-bottom: 24%;
  }
  .sm-padding-twenty-four-half-bottom {
    padding-bottom: 24.5%;
  }
  .sm-padding-twenty-five-bottom {
    padding-bottom: 25%;
  }
  .sm-padding-5px-bottom {
    padding-bottom: 5px !important;
  }
  .sm-padding-10px-bottom {
    padding-bottom: 10px !important;
  }
  .sm-padding-15px-bottom {
    padding-bottom: 15px !important;
  }
  .sm-padding-20px-bottom {
    padding-bottom: 20px !important;
  }
  .sm-padding-25px-bottom {
    padding-bottom: 25px !important;
  }
  .sm-padding-30px-bottom {
    padding-bottom: 30px !important;
  }
  .sm-padding-35px-bottom {
    padding-bottom: 35px !important;
  }
  .sm-padding-40px-bottom {
    padding-bottom: 40px !important;
  }
  .sm-padding-45px-bottom {
    padding-bottom: 45px !important;
  }
  .sm-padding-50px-bottom {
    padding-bottom: 50px !important;
  }
  .sm-padding-55px-bottom {
    padding-bottom: 55px !important;
  }
  .sm-padding-60px-bottom {
    padding-bottom: 60px !important;
  }
  .sm-padding-65px-bottom {
    padding-bottom: 65px !important;
  }
  .sm-padding-70px-bottom {
    padding-bottom: 70px !important;
  }
  .sm-padding-75px-bottom {
    padding-bottom: 75px !important;
  }
  .sm-padding-80px-bottom {
    padding-bottom: 80px !important;
  }
  .sm-padding-85px-bottom {
    padding-bottom: 85px !important;
  }
  .sm-padding-90px-bottom {
    padding-bottom: 90px !important;
  }
  .sm-padding-95px-bottom {
    padding-bottom: 95px !important;
  }
  .sm-padding-100px-bottom {
    padding-bottom: 100px !important;
  }

  /* padding right */
  .sm-padding-one-right {
    padding-right: 1%;
  }
  .sm-padding-one-half-right {
    padding-right: 1.5%;
  }
  .sm-padding-two-right {
    padding-right: 2%;
  }
  .sm-padding-two-half-right {
    padding-right: 2.5%;
  }
  .sm-padding-three-right {
    padding-right: 3%;
  }
  .sm-padding-three-half-right {
    padding-right: 3.5%;
  }
  .sm-padding-four-right {
    padding-right: 4%;
  }
  .sm-padding-four-half-right {
    padding-right: 4.5%;
  }
  .sm-padding-five-right {
    padding-right: 5%;
  }
  .sm-padding-five-half-right {
    padding-right: 5.5%;
  }
  .sm-padding-six-right {
    padding-right: 6%;
  }
  .sm-padding-six-half-right {
    padding-right: 6.5%;
  }
  .sm-padding-seven-right {
    padding-right: 7%;
  }
  .sm-padding-seven-half-right {
    padding-right: 7.5%;
  }
  .sm-padding-eight-right {
    padding-right: 8%;
  }
  .sm-padding-eight-half-right {
    padding-right: 8.5%;
  }
  .sm-padding-nine-right {
    padding-right: 9%;
  }
  .sm-padding-nine-half-right {
    padding-right: 9.5%;
  }
  .sm-padding-ten-right {
    padding-right: 10%;
  }
  .sm-padding-ten-half-right {
    padding-right: 10.5%;
  }
  .sm-padding-eleven-right {
    padding-right: 11%;
  }
  .sm-padding-eleven-half-right {
    padding-right: 11.5%;
  }
  .sm-padding-twelve-right {
    padding-right: 12%;
  }
  .sm-padding-twelve-half-right {
    padding-right: 12.5%;
  }
  .sm-padding-thirteen-right {
    padding-right: 13%;
  }
  .sm-padding-thirteen-half-right {
    padding-right: 13.5%;
  }
  .sm-padding-fourteen-right {
    padding-right: 14%;
  }
  .sm-padding-fourteen-half-right {
    padding-right: 14.5%;
  }
  .sm-padding-fifteen-right {
    padding-right: 15%;
  }
  .sm-padding-fifteen-half-right {
    padding-right: 15.5%;
  }
  .sm-padding-sixteen-right {
    padding-right: 16%;
  }
  .sm-padding-sixteen-half-right {
    padding-right: 16.5%;
  }
  .sm-padding-seventeen-right {
    padding-right: 17%;
  }
  .sm-padding-seventeen-half-right {
    padding-right: 17.5%;
  }
  .sm-padding-eighteen-right {
    padding-right: 18%;
  }
  .sm-padding-eighteen-half-right {
    padding-right: 18.5%;
  }
  .sm-padding-nineteen-right {
    padding-right: 19%;
  }
  .sm-padding-nineteen-half-right {
    padding-right: 19.5%;
  }
  .sm-padding-twenty-right {
    padding-right: 20%;
  }
  .sm-padding-twenty-half-right {
    padding-right: 20.5%;
  }
  .sm-padding-twenty-one-right {
    padding-right: 21%;
  }
  .sm-padding-twenty-one-half-right {
    padding-right: 21.5%;
  }
  .sm-padding-twenty-two-right {
    padding-right: 22%;
  }
  .sm-padding-twenty-two-half-right {
    padding-right: 22.5%;
  }
  .sm-padding-twenty-three-right {
    padding-right: 23%;
  }
  .sm-padding-twenty-three-half-right {
    padding-right: 23.5%;
  }
  .sm-padding-twenty-four-right {
    padding-right: 24%;
  }
  .sm-padding-twenty-four-half-right {
    padding-right: 24.5%;
  }
  .sm-padding-twenty-five-right {
    padding-right: 25%;
  }
  .sm-padding-5px-right {
    padding-right: 5px !important;
  }
  .sm-padding-10px-right {
    padding-right: 10px !important;
  }
  .sm-padding-15px-right {
    padding-right: 15px !important;
  }
  .sm-padding-20px-right {
    padding-right: 20px !important;
  }
  .sm-padding-25px-right {
    padding-right: 25px !important;
  }
  .sm-padding-30px-right {
    padding-right: 30px !important;
  }
  .sm-padding-35px-right {
    padding-right: 35px !important;
  }
  .sm-padding-40px-right {
    padding-right: 40px !important;
  }
  .sm-padding-45px-right {
    padding-right: 45px !important;
  }
  .sm-padding-50px-right {
    padding-right: 50px !important;
  }
  .sm-padding-55px-right {
    padding-right: 55px !important;
  }
  .sm-padding-60px-right {
    padding-right: 60px !important;
  }
  .sm-padding-65px-right {
    padding-right: 65px !important;
  }
  .sm-padding-70px-right {
    padding-right: 70px !important;
  }
  .sm-padding-75px-right {
    padding-right: 75px !important;
  }
  .sm-padding-80px-right {
    padding-right: 80px !important;
  }
  .sm-padding-85px-right {
    padding-right: 85px !important;
  }
  .sm-padding-90px-right {
    padding-right: 90px !important;
  }
  .sm-padding-95px-right {
    padding-right: 95px !important;
  }
  .sm-padding-100px-right {
    padding-right: 100px !important;
  }

  /* padding left */
  .sm-padding-one-left {
    padding-left: 1%;
  }
  .sm-padding-one-half-left {
    padding-left: 1.5%;
  }
  .sm-padding-two-left {
    padding-left: 2%;
  }
  .sm-padding-two-half-left {
    padding-left: 2.5%;
  }
  .sm-padding-three-left {
    padding-left: 3%;
  }
  .sm-padding-three-half-left {
    padding-left: 3.5%;
  }
  .sm-padding-four-left {
    padding-left: 4%;
  }
  .sm-padding-four-half-left {
    padding-left: 4.5%;
  }
  .sm-padding-five-left {
    padding-left: 5%;
  }
  .sm-padding-five-half-left {
    padding-left: 5.5%;
  }
  .sm-padding-six-left {
    padding-left: 6%;
  }
  .sm-padding-six-half-left {
    padding-left: 6.5%;
  }
  .sm-padding-seven-left {
    padding-left: 7%;
  }
  .sm-padding-seven-half-left {
    padding-left: 7.5%;
  }
  .sm-padding-eight-left {
    padding-left: 8%;
  }
  .sm-padding-eight-half-left {
    padding-left: 8.5%;
  }
  .sm-padding-nine-left {
    padding-left: 9%;
  }
  .sm-padding-nine-half-left {
    padding-left: 9.5%;
  }
  .sm-padding-ten-left {
    padding-left: 10%;
  }
  .sm-padding-ten-half-left {
    padding-left: 10.5%;
  }
  .sm-padding-eleven-left {
    padding-left: 11%;
  }
  .sm-padding-eleven-half-left {
    padding-left: 11.5%;
  }
  .sm-padding-twelve-left {
    padding-left: 12%;
  }
  .sm-padding-twelve-half-left {
    padding-left: 12.5%;
  }
  .sm-padding-thirteen-left {
    padding-left: 13%;
  }
  .sm-padding-thirteen-half-left {
    padding-left: 13.5%;
  }
  .sm-padding-fourteen-left {
    padding-left: 14%;
  }
  .sm-padding-fourteen-half-left {
    padding-left: 14.5%;
  }
  .sm-padding-fifteen-left {
    padding-left: 15%;
  }
  .sm-padding-fifteen-half-left {
    padding-left: 15.5%;
  }
  .sm-padding-sixteen-left {
    padding-left: 16%;
  }
  .sm-padding-sixteen-half-left {
    padding-left: 16.5%;
  }
  .sm-padding-seventeen-left {
    padding-left: 17%;
  }
  .sm-padding-seventeen-half-left {
    padding-left: 17.5%;
  }
  .sm-padding-eighteen-left {
    padding-left: 18%;
  }
  .sm-padding-eighteen-half-left {
    padding-left: 18.5%;
  }
  .sm-padding-nineteen-left {
    padding-left: 19%;
  }
  .sm-padding-nineteen-half-left {
    padding-left: 19.5%;
  }
  .sm-padding-twenty-left {
    padding-left: 20%;
  }
  .sm-padding-twenty-half-left {
    padding-left: 20.5%;
  }
  .sm-padding-twenty-one-left {
    padding-left: 21%;
  }
  .sm-padding-twenty-one-half-left {
    padding-left: 21.5%;
  }
  .sm-padding-twenty-two-left {
    padding-left: 22%;
  }
  .sm-padding-twenty-two-half-left {
    padding-left: 22.5%;
  }
  .sm-padding-twenty-three-left {
    padding-left: 23%;
  }
  .sm-padding-twenty-three-half-left {
    padding-left: 23.5%;
  }
  .sm-padding-twenty-four-left {
    padding-left: 24%;
  }
  .sm-padding-twenty-four-half-left {
    padding-left: 24.5%;
  }
  .sm-padding-twenty-five-left {
    padding-left: 25%;
  }
  .sm-padding-5px-left {
    padding-left: 5px !important;
  }
  .sm-padding-10px-left {
    padding-left: 10px !important;
  }
  .sm-padding-15px-left {
    padding-left: 15px !important;
  }
  .sm-padding-20px-left {
    padding-left: 20px !important;
  }
  .sm-padding-25px-left {
    padding-left: 25px !important;
  }
  .sm-padding-30px-left {
    padding-left: 30px !important;
  }
  .sm-padding-35px-left {
    padding-left: 35px !important;
  }
  .sm-padding-40px-left {
    padding-left: 40px !important;
  }
  .sm-padding-45px-left {
    padding-left: 45px !important;
  }
  .sm-padding-50px-left {
    padding-left: 50px !important;
  }
  .sm-padding-55px-left {
    padding-left: 55px !important;
  }
  .sm-padding-60px-left {
    padding-left: 60px !important;
  }
  .sm-padding-65px-left {
    padding-left: 65px !important;
  }
  .sm-padding-70px-left {
    padding-left: 70px !important;
  }
  .sm-padding-75px-left {
    padding-left: 75px !important;
  }
  .sm-padding-80px-left {
    padding-left: 80px !important;
  }
  .sm-padding-85px-left {
    padding-left: 85px !important;
  }
  .sm-padding-90px-left {
    padding-left: 90px !important;
  }
  .sm-padding-95px-left {
    padding-left: 95px !important;
  }
  .sm-padding-100px-left {
    padding-left: 100px !important;
  }

  /* padding top bottom */
  .sm-padding-one-tb {
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .sm-padding-one-half-tb {
    padding-top: 1.5%;
    padding-bottom: 1.5%;
  }
  .sm-padding-two-tb {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .sm-padding-two-half-tb {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
  }
  .sm-padding-three-tb {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .sm-padding-three-half-tb {
    padding-top: 3.5%;
    padding-bottom: 3.5%;
  }
  .sm-padding-four-tb {
    padding-top: 4%;
    padding-bottom: 4%;
  }
  .sm-padding-four-half-tb {
    padding-top: 4.5%;
    padding-bottom: 4.5%;
  }
  .sm-padding-five-tb {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .sm-padding-five-half-tb {
    padding-top: 5.5%;
    padding-bottom: 5.5%;
  }
  .sm-padding-six-tb {
    padding-top: 6%;
    padding-bottom: 6%;
  }
  .sm-padding-six-half-tb {
    padding-top: 6.5%;
    padding-bottom: 6.5%;
  }
  .sm-padding-seven-tb {
    padding-top: 7%;
    padding-bottom: 7%;
  }
  .sm-padding-seven-half-tb {
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }
  .sm-padding-eight-tb {
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .sm-padding-eight-half-tb {
    padding-top: 8.5%;
    padding-bottom: 8.5%;
  }
  .sm-padding-nine-tb {
    padding-top: 9%;
    padding-bottom: 9%;
  }
  .sm-padding-nine-half-tb {
    padding-top: 9.5%;
    padding-bottom: 9.5%;
  }
  .sm-padding-ten-tb {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .sm-padding-ten-half-tb {
    padding-top: 10.5%;
    padding-bottom: 10.5%;
  }
  .sm-padding-eleven-tb {
    padding-top: 11%;
    padding-bottom: 11%;
  }
  .sm-padding-eleven-half-tb {
    padding-top: 11.5%;
    padding-bottom: 11.5%;
  }
  .sm-padding-twelve-tb {
    padding-top: 12%;
    padding-bottom: 12%;
  }
  .sm-padding-twelve-half-tb {
    padding-top: 12.5%;
    padding-bottom: 12.5%;
  }
  .sm-padding-thirteen-tb {
    padding-top: 13%;
    padding-bottom: 13%;
  }
  .sm-padding-thirteen-half-tb {
    padding-top: 13.5%;
    padding-bottom: 13.5%;
  }
  .sm-padding-fourteen-tb {
    padding-top: 14%;
    padding-bottom: 14%;
  }
  .sm-padding-fourteen-half-tb {
    padding-top: 14.5%;
    padding-bottom: 14.5%;
  }
  .sm-padding-fifteen-tb {
    padding-top: 15%;
    padding-bottom: 15%;
  }
  .sm-padding-fifteen-half-tb {
    padding-top: 15.5%;
    padding-bottom: 15.5%;
  }
  .sm-padding-sixteen-tb {
    padding-top: 16%;
    padding-bottom: 16%;
  }
  .sm-padding-sixteen-half-tb {
    padding-top: 16.5%;
    padding-bottom: 16.5%;
  }
  .sm-padding-seventeen-tb {
    padding-top: 17%;
    padding-bottom: 17%;
  }
  .sm-padding-seventeen-half-tb {
    padding-top: 17.5%;
    padding-bottom: 17.5%;
  }
  .sm-padding-eighteen-tb {
    padding-top: 18%;
    padding-bottom: 18%;
  }
  .sm-padding-eighteen-half-tb {
    padding-top: 18.5%;
    padding-bottom: 18.5%;
  }
  .sm-padding-nineteen-tb {
    padding-top: 19%;
    padding-bottom: 19%;
  }
  .sm-padding-nineteen-half-tb {
    padding-top: 19.5%;
    padding-bottom: 19.5%;
  }
  .sm-padding-twenty-tb {
    padding-top: 20%;
    padding-bottom: 20%;
  }
  .sm-padding-twenty-half-tb {
    padding-top: 20.5%;
    padding-bottom: 20.5%;
  }
  .sm-padding-twenty-one-tb {
    padding-top: 21%;
    padding-bottom: 21%;
  }
  .sm-padding-twenty-one-half-tb {
    padding-top: 21.5%;
    padding-bottom: 21.5%;
  }
  .sm-padding-twenty-two-tb {
    padding-top: 22%;
    padding-bottom: 22%;
  }
  .sm-padding-twenty-two-half-tb {
    padding-top: 22.5%;
    padding-bottom: 22.5%;
  }
  .sm-padding-twenty-three-tb {
    padding-top: 23%;
    padding-bottom: 23%;
  }
  .sm-padding-twenty-three-half-tb {
    padding-top: 23.5%;
    padding-bottom: 23.5%;
  }
  .sm-padding-twenty-four-tb {
    padding-top: 24%;
    padding-bottom: 24%;
  }
  .sm-padding-twenty-four-half-tb {
    padding-top: 24.5%;
    padding-bottom: 24.5%;
  }
  .sm-padding-twenty-tb {
    padding-top: 25%;
    padding-bottom: 25%;
  }
  .sm-padding-5px-tb {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .sm-padding-10px-tb {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sm-padding-15px-tb {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .sm-padding-20px-tb {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sm-padding-25px-tb {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .sm-padding-30px-tb {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .sm-padding-35px-tb {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .sm-padding-40px-tb {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sm-padding-45px-tb {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .sm-padding-50px-tb {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .sm-padding-55px-tb {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .sm-padding-60px-tb {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .sm-padding-65px-tb {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .sm-padding-70px-tb {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .sm-padding-75px-tb {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .sm-padding-80px-tb {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .sm-padding-85px-tb {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .sm-padding-90px-tb {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .sm-padding-95px-tb {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .sm-padding-100px-tb {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  /* padding left right */
  .sm-padding-one-lr {
    padding-left: 1%;
    padding-right: 1%;
  }
  .sm-padding-one-half-lr {
    padding-left: 1.5%;
    padding-right: 1.5%;
  }
  .sm-padding-two-lr {
    padding-left: 2%;
    padding-right: 2%;
  }
  .sm-padding-two-half-lr {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  .sm-padding-three-lr {
    padding-left: 3%;
    padding-right: 3%;
  }
  .sm-padding-three-half-lr {
    padding-left: 3.5%;
    padding-right: 3.5%;
  }
  .sm-padding-four-lr {
    padding-left: 4%;
    padding-right: 4%;
  }
  .sm-padding-four-half-lr {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }
  .sm-padding-five-lr {
    padding-left: 5%;
    padding-right: 5%;
  }
  .sm-padding-five-half-lr {
    padding-left: 5.5%;
    padding-right: 5.5%;
  }
  .sm-padding-six-lr {
    padding-left: 6%;
    padding-right: 6%;
  }
  .sm-padding-six-half-lr {
    padding-left: 6.5%;
    padding-right: 6.5%;
  }
  .sm-padding-seven-lr {
    padding-left: 7%;
    padding-right: 7%;
  }
  .sm-padding-seven-half-lr {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
  .sm-padding-eight-lr {
    padding-left: 8%;
    padding-right: 8%;
  }
  .sm-padding-eight-half-lr {
    padding-left: 8.5%;
    padding-right: 8.5%;
  }
  .sm-padding-nine-lr {
    padding-left: 9%;
    padding-right: 9%;
  }
  .sm-padding-nine-half-lr {
    padding-left: 9.5%;
    padding-right: 9.5%;
  }
  .sm-padding-ten-lr {
    padding-left: 10%;
    padding-right: 10%;
  }
  .sm-padding-ten-half-lr {
    padding-left: 10.5%;
    padding-right: 10.5%;
  }
  .sm-padding-eleven-lr {
    padding-left: 11%;
    padding-right: 11%;
  }
  .sm-padding-eleven-half-lr {
    padding-left: 11.5%;
    padding-right: 11.5%;
  }
  .sm-padding-twelve-lr {
    padding-left: 12%;
    padding-right: 12%;
  }
  .sm-padding-twelve-half-lr {
    padding-left: 12.5%;
    padding-right: 12.5%;
  }
  .sm-padding-thirteen-lr {
    padding-left: 13%;
    padding-right: 13%;
  }
  .sm-padding-thirteen-half-lr {
    padding-left: 13.5%;
    padding-right: 13.5%;
  }
  .sm-padding-fourteen-lr {
    padding-left: 14%;
    padding-right: 14%;
  }
  .sm-padding-fourteen-half-lr {
    padding-left: 14.5%;
    padding-right: 14.5%;
  }
  .sm-padding-fifteen-lr {
    padding-left: 15%;
    padding-right: 15%;
  }
  .sm-padding-fifteen-half-lr {
    padding-left: 15.5%;
    padding-right: 15.5%;
  }
  .sm-padding-sixteen-lr {
    padding-left: 16%;
    padding-right: 16%;
  }
  .sm-padding-sixteen-half-lr {
    padding-left: 16.5%;
    padding-right: 16.5%;
  }
  .sm-padding-seventeen-lr {
    padding-left: 17%;
    padding-right: 17%;
  }
  .sm-padding-seventeen-half-lr {
    padding-left: 17.5%;
    padding-right: 17.5%;
  }
  .sm-padding-eighteen-lr {
    padding-left: 18%;
    padding-right: 18%;
  }
  .sm-padding-eighteen-half-lr {
    padding-left: 18.5%;
    padding-right: 18.5%;
  }
  .sm-padding-nineteen-lr {
    padding-left: 19%;
    padding-right: 19%;
  }
  .sm-padding-nineteen-half-lr {
    padding-left: 19.5%;
    padding-right: 19.5%;
  }
  .sm-padding-twenty-lr {
    padding-left: 20%;
    padding-right: 20%;
  }
  .sm-padding-twenty-half-lr {
    padding-left: 20.5%;
    padding-right: 20.5%;
  }
  .sm-padding-twenty-one-lr {
    padding-left: 21%;
    padding-right: 21%;
  }
  .sm-padding-twenty-one-half-lr {
    padding-left: 21.5%;
    padding-right: 21.5%;
  }
  .sm-padding-twenty-two-lr {
    padding-left: 22%;
    padding-right: 22%;
  }
  .sm-padding-twenty-two-half-lr {
    padding-left: 22.5%;
    padding-right: 22.5%;
  }
  .sm-padding-twenty-three-lr {
    padding-left: 23%;
    padding-right: 23%;
  }
  .sm-padding-twenty-three-half-lr {
    padding-left: 23.5%;
    padding-right: 23.5%;
  }
  .sm-padding-twenty-four-lr {
    padding-left: 24%;
    padding-right: 24%;
  }
  .sm-padding-twenty-four-half-lr {
    padding-left: 24.5%;
    padding-right: 24.5%;
  }
  .sm-padding-twenty-five-lr {
    padding-left: 25%;
    padding-right: 25%;
  }
  .sm-padding-5px-lr {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .sm-padding-10px-lr {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .sm-padding-15px-lr {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .sm-padding-20px-lr {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .sm-padding-25px-lr {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .sm-padding-30px-lr {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .sm-padding-35px-lr {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .sm-padding-40px-lr {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .sm-padding-45px-lr {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .sm-padding-50px-lr {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .sm-padding-55px-lr {
    padding-right: 55px !important;
    padding-left: 55px !important;
  }
  .sm-padding-60px-lr {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .sm-padding-65px-lr {
    padding-right: 65px !important;
    padding-left: 65px !important;
  }
  .sm-padding-70px-lr {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .sm-padding-75px-lr {
    padding-right: 75px !important;
    padding-left: 75px !important;
  }
  .sm-padding-80px-lr {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .sm-padding-85px-lr {
    padding-right: 85px !important;
    padding-left: 85px !important;
  }
  .sm-padding-90px-lr {
    padding-right: 90px !important;
    padding-left: 90px !important;
  }
  .sm-padding-95px-lr {
    padding-right: 95px !important;
    padding-left: 95px !important;
  }
  .sm-padding-100px-lr {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }

  /* top */
  .sm-top-auto {
    top: auto;
  }
  .sm-top-0 {
    top: 0 !important;
  }
  .sm-top-inherit {
    top: inherit !important;
  }
  .sm-top-1 {
    top: 1px;
  }
  .sm-top-2 {
    top: 2px;
  }
  .sm-top-3 {
    top: 3px;
  }
  .sm-top-4 {
    top: 4px;
  }
  .sm-top-5 {
    top: 5px;
  }
  .sm-top-6 {
    top: 6px;
  }
  .sm-top-7 {
    top: 7px;
  }
  .sm-top-8 {
    top: 8px;
  }
  .sm-top-9 {
    top: 9px;
  }
  .sm-top-10 {
    top: 10px;
  }
  .sm-top-12 {
    top: 12px;
  }
  .sm-top-15 {
    top: 15px;
  }
  .sm-top-17 {
    top: 17px;
  }
  .sm-top-20 {
    top: 20px;
  }
  .sm-top-25 {
    top: 25px;
  }
  .sm-top-30 {
    top: 30px;
  }
  .sm-top-35 {
    top: 35px;
  }
  .sm-top-40 {
    top: 40px;
  }
  .sm-top-45 {
    top: 45px;
  }
  .sm-top-50 {
    top: 50px;
  }

  .sm-top-minus1 {
    top: -1px;
  }
  .sm-top-minus2 {
    top: -2px;
  }
  .sm-top-minus3 {
    top: -3px;
  }
  .sm-top-minus4 {
    top: -4px;
  }
  .sm-top-minus5 {
    top: -5px;
  }
  .sm-top-minus6 {
    top: -6px;
  }
  .sm-top-minus7 {
    top: -7px;
  }
  .sm-top-minus8 {
    top: -8px;
  }
  .sm-top-minus9 {
    top: -9px;
  }
  .sm-top-minus10 {
    top: -10px;
  }
  .sm-top-minus15 {
    top: -15px;
  }
  .sm-top-minus20 {
    top: -20px;
  }
  .sm-top-minus25 {
    top: -25px;
  }
  .sm-top-minus30 {
    top: -30px;
  }
  .sm-top-minus35 {
    top: -35px;
  }
  .sm-top-minus40 {
    top: -40px;
  }
  .sm-top-minus45 {
    top: -45px;
  }
  .sm-top-minus50 {
    top: -50px;
  }

  /* bottom */
  .sm-bottom-auto {
    bottom: auto;
  }
  .sm-bottom-0 {
    bottom: 0;
  }
  .sm-bottom-1 {
    bottom: 1px;
  }
  .sm-bottom-2 {
    bottom: 2px;
  }
  .sm-bottom-3 {
    bottom: 3px;
  }
  .sm-bottom-4 {
    bottom: 4px;
  }
  .sm-bottom-5 {
    bottom: 5px;
  }
  .sm-bottom-6 {
    bottom: 6px;
  }
  .sm-bottom-7 {
    bottom: 7px;
  }
  .sm-bottom-8 {
    bottom: 8px;
  }
  .sm-bottom-9 {
    bottom: 9px;
  }
  .sm-bottom-10 {
    bottom: 10px;
  }
  .sm-bottom-20 {
    bottom: 20px;
  }
  .sm-bottom-25 {
    bottom: 25px;
  }
  .sm-bottom-30 {
    bottom: 30px;
  }
  .sm-bottom-35 {
    bottom: 35px;
  }
  .sm-bottom-40 {
    bottom: 40px;
  }
  .sm-bottom-45 {
    bottom: 45px;
  }
  .sm-bottom-50 {
    bottom: 50px;
  }

  /* left */
  .sm-left-auto {
    left: auto;
  }
  .sm-left-0 {
    left: 0;
  }
  .sm-left-1 {
    left: 1px;
  }
  .sm-left-2 {
    left: 2px;
  }
  .sm-left-3 {
    left: 3px;
  }
  .sm-left-4 {
    left: 4px;
  }
  .sm-left-5 {
    left: 5px;
  }
  .sm-left-6 {
    left: 6px;
  }
  .sm-left-7 {
    left: 7px;
  }
  .sm-left-8 {
    left: 8px;
  }
  .sm-left-9 {
    left: 9px;
  }
  .sm-left-10 {
    left: 10px;
  }

  /* right */
  .sm-right-auto {
    right: auto;
  }
  .sm-right-0 {
    right: 0;
  }
  .sm-right-1 {
    right: 1px;
  }
  .sm-right-2 {
    right: 2px;
  }
  .sm-right-3 {
    right: 3px;
  }
  .sm-right-4 {
    right: 4px;
  }
  .sm-right-5 {
    right: 5px;
  }
  .sm-right-6 {
    right: 6px;
  }
  .sm-right-7 {
    right: 7px;
  }
  .sm-right-8 {
    right: 8px;
  }
  .sm-right-9 {
    right: 9px;
  }
  .sm-right-10 {
    right: 10px;
  }

  /* width */
  .sm-width-10 {
    width: 10%;
  }
  .sm-width-15 {
    width: 15%;
  }
  .sm-width-20 {
    width: 20%;
  }
  .sm-width-25 {
    width: 25%;
  }
  .sm-width-30 {
    width: 30%;
  }
  .sm-width-35 {
    width: 35%;
  }
  .sm-width-40 {
    width: 40%;
  }
  .sm-width-45 {
    width: 45%;
  }
  .sm-width-50 {
    width: 50%;
  }
  .sm-width-55 {
    width: 55%;
  }
  .sm-width-60 {
    width: 60%;
  }
  .sm-width-65 {
    width: 65%;
  }
  .sm-width-70 {
    width: 70%;
  }
  .sm-width-75 {
    width: 75%;
  }
  .sm-width-80 {
    width: 80%;
  }
  .sm-width-85 {
    width: 85%;
  }
  .sm-width-90 {
    width: 90%;
  }
  .sm-width-95 {
    width: 95%;
  }
  .sm-width-100 {
    width: 100%;
  }
  .sm-width-auto {
    width: auto;
  }

  .sm-width-50px {
    width: 50px;
  }
  .sm-width-70px {
    width: 70px;
  }
  .sm-width-75px {
    width: 75px;
  }
  .sm-width-80px {
    width: 80px;
  }
  .sm-width-85px {
    width: 85px;
  }
  .sm-width-90px {
    width: 90px;
  }
  .sm-width-95px {
    width: 95px;
  }
  .sm-width-100px {
    width: 100px;
  }
  .sm-width-120px {
    width: 120px;
  }
  .sm-width-130px {
    width: 130px;
  }
  .sm-width-150px {
    width: 150px;
  }
  .sm-width-180px {
    width: 180px;
  }
  .sm-width-200px {
    width: 200px;
  }
  .sm-width-250px {
    width: 250px;
  }
  .sm-width-300px {
    width: 300px;
  }
  .sm-width-350px {
    width: 350px;
  }
  .sm-width-400px {
    width: 400px;
  }
  .sm-width-450px {
    width: 450px;
  }
  .sm-width-500px {
    width: 500px;
  }
  .sm-width-550px {
    width: 550px;
  }
  .sm-width-600px {
    width: 600px;
  }
  .sm-width-650px {
    width: 650px;
  }
  .sm-width-700px {
    width: 700px;
  }
  .sm-width-750px {
    width: 750px;
  }
  .sm-width-800px {
    width: 800px;
  }
  .sm-width-850px {
    width: 850px;
  }
  .sm-width-900px {
    width: 900px;
  }
  .sm-width-950px {
    width: 950px;
  }
  .sm-width-1000px {
    width: 1000px;
  }

  /* height */
  .sm-height-50 {
    height: 50%;
  }
  .sm-height-100 {
    height: 100%;
  }
  .sm-height-auto {
    height: auto !important;
  }
  .sm-height-80px {
    height: 80px;
  }
  .sm-height-90px {
    height: 90px;
  }
  .sm-height-100px {
    height: 100px;
  }
  .sm-height-150px {
    height: 150px;
  }
  .sm-height-200px {
    height: 200px;
  }
  .sm-height-250px {
    height: 250px;
  }
  .sm-height-300px {
    height: 300px;
  }
  .sm-height-350px {
    height: 350px;
  }
  .sm-height-400px {
    height: 400px;
  }
  .sm-height-450px {
    height: 450px;
  }
  .sm-height-500px {
    height: 500px;
  }
  .sm-height-550px {
    height: 550px;
  }
  .sm-height-600px {
    height: 600px;
  }
  .sm-height-650px {
    height: 650px;
  }
  .sm-height-700px {
    height: 700px;
  }

  /* display */
  .sm-display-block {
    display: block !important;
  }
  .sm-overflow-hidden {
    overflow: hidden !important;
  }
  .sm-overflow-auto {
    overflow: auto !important;
  }
  .sm-display-inline-block {
    display: inline-block !important;
  }
  .sm-display-inline {
    display: inline !important;
  }
  .sm-display-none {
    display: none !important;
  }
  .sm-display-inherit {
    display: inherit !important;
  }
  .sm-display-table {
    display: table !important;
  }
  .sm-display-table-cell {
    display: table-cell !important;
  }
  .sm-display-flex-inherit {
    -ms-box-orient: inherit;
    display: inherit;
    display: inherit;
    display: inherit;
    display: inherit;
    display: inherit;
    display: inherit;
    flex-flow: inherit;
    -webkit-flex-flow: inherit;
    -moz-flex-flow: inherit;
    -ms-flex-flow: inherit;
    -o-flex-flow: inherit;
  }

  /* min height */
  .sm-min-height-0 {
    min-height: 0;
  }

  /* clear-both */
  .sm-clear-both {
    clear: both;
  }

  /* position */
  .sm-position-inherit {
    position: inherit !important;
  }
  .sm-position-initial {
    position: initial !important;
  }
  .sm-position-relative {
    position: relative !important;
    z-index: 5;
  }
  .sm-position-absolute {
    position: absolute !important;
  }
  .sm-position-fixed {
    position: fixed !important;
  }

  /* line height */
  .sm-line-height-10 {
    line-height: 10px;
  }
  .sm-line-height-18 {
    line-height: 18px;
  }
  .sm-line-height-20 {
    line-height: 20px;
  }
  .sm-line-height-24 {
    line-height: 24px;
  }
  .sm-line-height-22 {
    line-height: 22px;
  }
  .sm-line-height-26 {
    line-height: 26px;
  }
  .sm-line-height-28 {
    line-height: 28px;
  }
  .sm-line-height-30 {
    line-height: 30px;
  }
  .sm-line-height-35 {
    line-height: 35px;
  }
  .sm-line-height-40 {
    line-height: 40px;
  }
  .sm-line-height-45 {
    line-height: 45px;
  }
  .sm-line-height-50 {
    line-height: 50px;
  }
  .sm-line-height-55 {
    line-height: 55px;
  }
  .sm-line-height-60 {
    line-height: 60px;
  }
  .sm-line-height-65 {
    line-height: 65px;
  }
  .sm-line-height-70 {
    line-height: 70px;
  }
  .sm-line-height-75 {
    line-height: 75px;
  }
  .sm-line-height-80 {
    line-height: 80px;
  }
  .sm-line-height-85 {
    line-height: 85px;
  }
  .sm-line-height-90 {
    line-height: 90px;
  }
  .sm-line-height-95 {
    line-height: 95px;
  }
  .sm-line-height-100 {
    line-height: 100px;
  }

  /* border */
  .sm-no-border-top {
    border-top: 0 !important;
  }
  .sm-no-border-bottom {
    border-bottom: 0 !important;
  }
  .sm-no-border-right {
    border-right: 0 !important;
  }
  .sm-no-border-left {
    border-left: 0 !important;
  }
  .sm-no-border {
    border: 0 !important;
  }

  /* parallax */
  .parallax {
    background-position: center;
  }

  .portfolio-filter-tab-1 li {
    margin: 10px 20px;
  }
  .portfolio-filter-tab-2 li a {
    padding: 7px 18px;
  }

  .portfolio-grid.work-6col li,
  .blog-grid.blog-6col li {
    width: 50%;
  }
  .portfolio-grid.work-4col li,
  .blog-grid.blog-4col li {
    width: 50%;
  }
  .sidebar-wrapper .portfolio-grid.work-4col li,
  .sidebar-wrapper .blog-grid.work-4col li {
    width: 50%;
  }
  .portfolio-grid.work-4col li.grid-item-double,
  .blog-grid.blog-4col li.grid-item-double {
    width: 50%;
  }
  .portfolio-grid.work-4col li.grid-item-double:last-child,
  .blog-grid.blog-4col li.grid-item-double:last-child {
    width: 50%;
  }

  .portfolio-grid.work-3col li,
  .blog-grid.blog-3col li {
    width: 50%;
  }
  .portfolio-grid.work-3col li.grid-item-double,
  .blog-grid.blog-3col li.grid-item-double {
    width: 50%;
  }

  /* feature-box-4 */
  .feature-box-4 figure figcaption {
    width: 100%;
    display: table-cell;
    margin: 0;
    padding: 15px;
    top: 0;
    height: 100%;
  }
  .grid-item.feature-box-4 figure figcaption .btn {
    opacity: 1;
  }
  .feature-box-4 figure:hover figcaption {
    transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
  }

  /* homepage option-1 */
  .bg-transparent-white {
    background: rgba(255, 255, 255, 0);
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 96%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0)),
      color-stop(96%, rgba(255, 255, 255, 1)),
      color-stop(100%, rgba(255, 255, 255, 1))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 96%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 96%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 96%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 96%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
  }

  /* homepage option-3 */
  .sm-swap-block {
    display: flex;
    flex-flow: column;
  }
  .sm-swap-block > div:first-child {
    order: 2;
  }
  .sm-swap-block .arrow-bottom::after {
    top: -29px;
    border: solid transparent;
    border-bottom-color: #f5f5f5;
    border-width: 15px;
    margin-left: -15px;
  }

  /* blog post style 3 */
  .sm-no-background-img {
    background-image: none !important;
  }

  /* blog post style 3 */
  .blog-post-style3 .grid-item .blog-post .blog-post-images .blog-hover-icon i {
    font-size: 36px;
  }

  /*swiper button 3*/
  .swiper-button-prev.swiper-prev-style3,
  .swiper-button-next.swiper-next-style3 {
    left: 64.5%;
  }

  /* Homepage 13 */
  .gridblock-main {
    padding: 60px 30px;
  }

  /* feature box style 8 */
  .parallax-feature-box-bottom {
    position: relative;
  }

  /* Homepage 14 */
  .right-image {
    position: relative;
    width: 100%;
    bottom: 0;
    padding: 0 15px 0;
    right: 0;
    clear: both;
  }
  .box-separator-line {
    left: -45px;
    top: 66px;
  }
  .content-right-slider .swiper-button-next,
  .content-right-slider .swiper-button-prev,
  .swiper-button-prev.slider-long-arrow-white,
  .swiper-container-rtl .swiper-button-next.slider-long-arrow-white,
  .swiper-button-next.slider-long-arrow-white,
  .swiper-container-rtl .swiper-button-prev.slider-long-arrow-white {
    background-size: 25px auto;
    width: 30px;
  }

  /* full width menu nav */
  .full-width-pull-menu .link-style-2 ul li a {
    font-size: 36px;
    line-height: 70px;
  }
  .full-width-pull-menu .dropdown .dropdown-toggle {
    top: 35px;
  }

  /* feature box 10 */
  .feature-box-10 .number-center::before {
    width: 100%;
  }
  .feature-box-10 .number-center::after {
    width: 100%;
  }

  /* feature box 4 */
  .grid-item.feature-box-4 figure:hover figcaption {
    transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
  }

  /* hamburger menu */
  .hamburger-menu-wrepper {
    padding: 35px 36px;
  }
  .hamburger-menu-links {
    font-size: 32px;
    line-height: 32px;
  }
  .menu-middle {
    width: 95%;
  }

  /* Homepage 10 */
  .swiper-auto-slide {
    padding: 0 15px;
  }
  .swiper-auto-slide .swiper-slide {
    width: 100%;
  }

  /* feature-box-11 */
  .feature-box-11:nth-child(2) .progress-line:after {
    visibility: hidden;
  }

  /* feature box style 12 */
  .feature-box-12 .hover-content-box {
    top: 0;
  }

  /* skillbar bar style 1  */
  .skillbar-bar-style1 .skillbar {
    width: 86%;
  }

  /* feature box style 14 */
  .feature-box-14 > div:nth-child(even) {
    border-right: 0;
  }
  .feature-box-14 > div {
    border-bottom-width: 1px;
  }
  .feature-box-14 > div:last-child {
    border-bottom-width: 0;
  }

  /* homepage 6 */
  .icon-box .icon-box-holder {
    height: 90px;
  }
  .icon-box .icon-box-holder i {
    padding-top: 5px;
  }

  /* swiper pagination */
  .swiper-vertical-pagination > .swiper-pagination-bullets {
    right: 30px;
  }
  .right-image img {
    max-width: 100%;
  }
  .banner-style2 figcaption {
    width: 80%;
  }

  /*banner style 01*/
  .banner-style1 figure:hover figcaption p {
    height: 100px;
  }

  .counter-box-5 .counter-box .number {
    font-size: 50px;
    line-height: 50px;
  }
  .left-sidebar,
  .right-sidebar {
    padding: 15px;
  }

  /*scroll to top*/
  .scroll-top-arrow {
    display: none !important;
    opacity: 0 !important;
  }

  /* text property */
  .sm-text-middle-line::before {
    display: none;
  }

  /* hover-option 3 */
  .hover-option3 .grid-item figure figcaption {
    padding: 0 30px;
  }

  .mfp-close,
  .mfp-close:active {
    top: 0;
    right: 0;
  }
  button.mfp-arrow {
    background-color: #000;
    height: 80px;
    width: 80px;
    opacity: 1;
  }
  .mfp-arrow:before {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 80px;
    border: 0;
  }
  .mfp-arrow:active,
  .mfp-arrow:focus {
    margin-top: -55px;
  }
  .mfp-arrow-left {
    left: 6px;
  }
  .mfp-arrow-right {
    right: 6px;
  }
  .banner-style2 figcaption {
    width: 50%;
  }
  .list-style-6 li {
    padding: 0 0 8px 0;
  }
  .search-form .search-input {
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .search-form .search-button {
    font-size: 15px;
  }

  /*next prev button style 2*/
  .swiper-next-style2 {
    right: 15px;
  }
  .swiper-prev-style2 {
    left: 15px;
  }
  .swiper-auto-slide .swiper-scrollbar {
    left: 12%;
    width: 76%;
  }

  /* portfolio 5 column */
  .portfolio-grid.work-5col li,
  .blog-grid.blog-5col li {
    width: 50%;
  }
  .portfolio-grid.work-5col li.grid-item-double,
  .blog-grid.blog-5col li.grid-item-double {
    width: 50%;
  }

  /* opacity */
  .sm-opacity1 {
    opacity: 0.1;
  }
  .sm-opacity2 {
    opacity: 0.2;
  }
  .sm-opacity3 {
    opacity: 0.3;
  }
  .sm-opacity4 {
    opacity: 0.4;
  }
  .sm-opacity5 {
    opacity: 0.5;
  }
  .sm-opacity6 {
    opacity: 0.6;
  }
  .sm-opacity7 {
    opacity: 0.7;
  }
  .sm-opacity8 {
    opacity: 0.8;
  }
  .sm-opacity9 {
    opacity: 0.9;
  }
  .sm-opacity10 {
    opacity: 1;
  }

  /* blog comments list */
  .blog-comment li {
    padding: 30px 0;
  }

  /* blog post style4 */
  .blog-post-style4 figure figcaption {
    padding: 30px;
  }

  .blog-header-style1 {
    display: block;
  }
  .blog-header-style1 li {
    width: 100%;
  }
  .blog-header-style1 li.blog-column-active {
    width: 100%;
  }

  /* feature box */
  .feature-box.feature-box-17 .box .content {
    top: 30px;
  }

  /* icon type */
  .elements-social .extra-small-icon li,
  .elements-social .small-icon li,
  .elements-social .medium-icon li,
  .elements-social .large-icon li,
  .elements-social .extra-large-icon li {
    margin-bottom: 10px;
  }

  /* pricing table */
  .highlight .pricing-box {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  .demo-heading {
    font-size: 30px;
    line-height: 35px;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 12px;
    line-height: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 17px;
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
  }
  h1 {
    font-size: 37px;
    line-height: 42px;
  }
  h2 {
    font-size: 30px;
    line-height: 36px;
  }
  h3 {
    font-size: 28px;
    line-height: 34px;
  }
  h4 {
    font-size: 24px;
    line-height: 30px;
  }
  h5 {
    font-size: 20px;
    line-height: 28px;
  }
  h6 {
    font-size: 18px;
    line-height: 23px;
  }

  /* opacity */
  .xs-opacity1 {
    opacity: 0.1;
  }
  .xs-opacity2 {
    opacity: 0.2;
  }
  .xs-opacity3 {
    opacity: 0.3;
  }
  .xs-opacity4 {
    opacity: 0.4;
  }
  .xs-opacity5 {
    opacity: 0.5;
  }
  .xs-opacity6 {
    opacity: 0.6;
  }
  .xs-opacity7 {
    opacity: 0.7;
  }
  .xs-opacity8 {
    opacity: 0.8;
  }
  .xs-opacity9 {
    opacity: 0.9;
  }
  .xs-opacity10 {
    opacity: 1;
  }

  /*text-alignment*/
  .xs-text-center {
    text-align: center;
  }
  .xs-text-left {
    text-align: left;
  }
  .xs-text-right {
    text-align: right;
  }

  /*float*/
  .xs-float-left {
    float: left !important;
  }
  .xs-float-right {
    float: right !important;
  }
  .xs-float-none {
    float: none !important;
  }

  /* verticle align */
  .xs-vertical-align-middle {
    vertical-align: middle;
  }
  .xs-vertical-align-top {
    vertical-align: top;
  }
  .xs-vertical-align-bottom {
    vertical-align: bottom;
  }

  /* image position*/
  .xs-background-image-left {
    background-position: left !important;
  }
  .xs-background-image-right {
    background-position: right !important;
  }
  .xs-background-image-center {
    background-position: center !important;
  }

  /* margin */
  .xs-margin-one-all {
    margin: 1%;
  }
  .xs-margin-one-half-all {
    margin: 1.5%;
  }
  .xs-margin-two-all {
    margin: 2%;
  }
  .xs-margin-two-half-all {
    margin: 2.5%;
  }
  .xs-margin-three-all {
    margin: 3%;
  }
  .xs-margin-three-half-all {
    margin: 3.5%;
  }
  .xs-margin-four-all {
    margin: 4%;
  }
  .xs-margin-four-half-all {
    margin: 4.5%;
  }
  .xs-margin-five-all {
    margin: 5%;
  }
  .xs-margin-five-half-all {
    margin: 5.5%;
  }
  .xs-margin-six-all {
    margin: 6%;
  }
  .xs-margin-six-half-all {
    margin: 6.5%;
  }
  .xs-margin-seven-all {
    margin: 7%;
  }
  .xs-margin-seven-half-all {
    margin: 7.5%;
  }
  .xs-margin-eight-all {
    margin: 8%;
  }
  .xs-margin-eight-half-all {
    margin: 8.5%;
  }
  .xs-margin-nine-all {
    margin: 9%;
  }
  .xs-margin-nine-half-all {
    margin: 9.5%;
  }
  .xs-margin-ten-all {
    margin: 10%;
  }
  .xs-margin-ten-half-all {
    margin: 10.5%;
  }
  .xs-margin-eleven-all {
    margin: 11%;
  }
  .xs-margin-eleven-half-all {
    margin: 11.5%;
  }
  .xs-margin-twelve-all {
    margin: 12%;
  }
  .xs-margin-twelve-half-all {
    margin: 12.5%;
  }
  .xs-margin-thirteen-all {
    margin: 13%;
  }
  .xs-margin-thirteen-half-all {
    margin: 13.5%;
  }
  .xs-margin-fourteen-all {
    margin: 14%;
  }
  .xs-margin-fourteen-half-all {
    margin: 14.5%;
  }
  .xs-margin-fifteen-all {
    margin: 15%;
  }
  .xs-margin-fifteen-half-all {
    margin: 15.5%;
  }
  .xs-margin-sixteen-all {
    margin: 16%;
  }
  .xs-margin-sixteen-half-all {
    margin: 16.5%;
  }
  .xs-margin-seventeen-all {
    margin: 17%;
  }
  .xs-margin-seventeen-half-all {
    margin: 17.5%;
  }
  .xs-margin-eighteen-all {
    margin: 18%;
  }
  .xs-margin-eighteen-half-all {
    margin: 18.5%;
  }
  .xs-margin-nineteen-all {
    margin: 19%;
  }
  .xs-margin-nineteen-half-all {
    margin: 19.5%;
  }
  .xs-margin-twenty-all {
    margin: 20%;
  }
  .xs-margin-twenty-half-all {
    margin: 20.5%;
  }
  .xs-margin-twenty-one-all {
    margin: 21%;
  }
  .xs-margin-twenty-one-half-all {
    margin: 21.5%;
  }
  .xs-margin-twenty-two-all {
    margin: 22%;
  }
  .xs-margin-twenty-two-half-all {
    margin: 22.5%;
  }
  .xs-margin-twenty-three-all {
    margin: 23%;
  }
  .xs-margin-twenty-three-half-all {
    margin: 23.5%;
  }
  .xs-margin-twenty-four-all {
    margin: 24%;
  }
  .xs-margin-twenty-four-half-all {
    margin: 24.5%;
  }
  .xs-margin-twenty-five-all {
    margin: 25%;
  }

  .xs-no-margin {
    margin: 0 !important;
  }
  .xs-no-margin-lr {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .xs-no-margin-tb {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .xs-no-margin-top {
    margin-top: 0 !important;
  }
  .xs-no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .xs-no-margin-left {
    margin-left: 0 !important;
  }
  .xs-no-margin-right {
    margin-right: 0 !important;
  }
  .xs-margin-lr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xs-margin-auto {
    margin: 0 auto !important;
  }

  /* margin top */
  .xs-margin-one-top {
    margin-top: 1%;
  }
  .xs-margin-one-half-top {
    margin-top: 1.5%;
  }
  .xs-margin-two-top {
    margin-top: 2%;
  }
  .xs-margin-two-half-top {
    margin-top: 2.5%;
  }
  .xs-margin-three-top {
    margin-top: 3%;
  }
  .xs-margin-three-half-top {
    margin-top: 3.5%;
  }
  .xs-margin-four-top {
    margin-top: 4%;
  }
  .xs-margin-four-half-top {
    margin-top: 4.5%;
  }
  .xs-margin-five-top {
    margin-top: 5%;
  }
  .xs-margin-five-half-top {
    margin-top: 5.5%;
  }
  .xs-margin-six-top {
    margin-top: 6%;
  }
  .xs-margin-six-half-top {
    margin-top: 6.5%;
  }
  .xs-margin-seven-top {
    margin-top: 7%;
  }
  .xs-margin-seven-half-top {
    margin-top: 7.5%;
  }
  .xs-margin-eight-top {
    margin-top: 8%;
  }
  .xs-margin-eight-half-top {
    margin-top: 8.5%;
  }
  .xs-margin-nine-top {
    margin-top: 9%;
  }
  .xs-margin-nine-half-top {
    margin-top: 9.5%;
  }
  .xs-margin-ten-top {
    margin-top: 10%;
  }
  .xs-margin-ten-half-top {
    margin-top: 10.5%;
  }
  .xs-margin-eleven-top {
    margin-top: 11%;
  }
  .xs-margin-eleven-half-top {
    margin-top: 11.5%;
  }
  .xs-margin-twelve-top {
    margin-top: 12%;
  }
  .xs-margin-twelve-half-top {
    margin-top: 12.5%;
  }
  .xs-margin-thirteen-top {
    margin-top: 13%;
  }
  .xs-margin-thirteen-half-top {
    margin-top: 13.5%;
  }
  .xs-margin-fourteen-top {
    margin-top: 14%;
  }
  .xs-margin-fourteen-half-top {
    margin-top: 14.5%;
  }
  .xs-margin-fifteen-top {
    margin-top: 15%;
  }
  .xs-margin-fifteen-half-top {
    margin-top: 15.5%;
  }
  .xs-margin-sixteen-top {
    margin-top: 16%;
  }
  .xs-margin-sixteen-half-top {
    margin-top: 16.5%;
  }
  .xs-margin-seventeen-top {
    margin-top: 17%;
  }
  .xs-margin-seventeen-half-top {
    margin-top: 17.5%;
  }
  .xs-margin-eighteen-top {
    margin-top: 18%;
  }
  .xs-margin-eighteen-half-top {
    margin-top: 18.5%;
  }
  .xs-margin-nineteen-top {
    margin-top: 19%;
  }
  .xs-margin-nineteen-half-top {
    margin-top: 19.5%;
  }
  .xs-margin-twenty-top {
    margin-top: 20%;
  }
  .xs-margin-twenty-half-top {
    margin-top: 20.5%;
  }
  .xs-margin-twenty-one-top {
    margin-top: 21%;
  }
  .xs-margin-twenty-one-half-top {
    margin-top: 21.5%;
  }
  .xs-margin-twenty-two-top {
    margin-top: 22%;
  }
  .xs-margin-twenty-two-half-top {
    margin-top: 22.5%;
  }
  .xs-margin-twenty-three-top {
    margin-top: 23%;
  }
  .xs-margin-twenty-three-half-top {
    margin-top: 23.5%;
  }
  .xs-margin-twenty-four-top {
    margin-top: 24%;
  }
  .xs-margin-twenty-four-half-top {
    margin-top: 24.5%;
  }
  .xs-margin-twenty-five-top {
    margin-top: 25%;
  }
  .xs-margin-5px-top {
    margin-top: 5px !important;
  }
  .xs-margin-10px-top {
    margin-top: 10px !important;
  }
  .xs-margin-15px-top {
    margin-top: 15px !important;
  }
  .xs-margin-20px-top {
    margin-top: 20px !important;
  }
  .xs-margin-25px-top {
    margin-top: 25px !important;
  }
  .xs-margin-30px-top {
    margin-top: 30px !important;
  }
  .xs-margin-35px-top {
    margin-top: 35px !important;
  }
  .xs-margin-40px-top {
    margin-top: 40px !important;
  }
  .xs-margin-45px-top {
    margin-top: 45px !important;
  }
  .xs-margin-50px-top {
    margin-top: 50px !important;
  }
  .xs-margin-55px-top {
    margin-top: 55px !important;
  }
  .xs-margin-60px-top {
    margin-top: 60px !important;
  }
  .xs-margin-65px-top {
    margin-top: 65px !important;
  }
  .xs-margin-70px-top {
    margin-top: 70px !important;
  }
  .xs-margin-75px-top {
    margin-top: 75px !important;
  }
  .xs-margin-80px-top {
    margin-top: 80px !important;
  }
  .xs-margin-85px-top {
    margin-top: 85px !important;
  }
  .xs-margin-90px-top {
    margin-top: 90px !important;
  }
  .xs-margin-95px-top {
    margin-top: 95px !important;
  }
  .xs-margin-100px-top {
    margin-top: 100px !important;
  }

  /* margin bottom */
  .xs-margin-one-bottom {
    margin-bottom: 1%;
  }
  .xs-margin-one-half-bottom {
    margin-bottom: 1.5%;
  }
  .xs-margin-two-bottom {
    margin-bottom: 2%;
  }
  .xs-margin-two-half-bottom {
    margin-bottom: 2.5%;
  }
  .xs-margin-three-bottom {
    margin-bottom: 3%;
  }
  .xs-margin-three-half-bottom {
    margin-bottom: 3.5%;
  }
  .xs-margin-four-bottom {
    margin-bottom: 4%;
  }
  .xs-margin-four-half-bottom {
    margin-bottom: 4.5%;
  }
  .xs-margin-five-bottom {
    margin-bottom: 5%;
  }
  .xs-margin-five-half-bottom {
    margin-bottom: 5.5%;
  }
  .xs-margin-six-bottom {
    margin-bottom: 6%;
  }
  .xs-margin-six-half-bottom {
    margin-bottom: 6.5%;
  }
  .xs-margin-seven-bottom {
    margin-bottom: 7%;
  }
  .xs-margin-seven-half-bottom {
    margin-bottom: 7.5%;
  }
  .xs-margin-eight-bottom {
    margin-bottom: 8%;
  }
  .xs-margin-eight-half-bottom {
    margin-bottom: 8.5%;
  }
  .xs-margin-nine-bottom {
    margin-bottom: 9%;
  }
  .xs-margin-nine-half-bottom {
    margin-bottom: 9.5%;
  }
  .xs-margin-ten-bottom {
    margin-bottom: 10%;
  }
  .xs-margin-ten-half-bottom {
    margin-bottom: 10.5%;
  }
  .xs-margin-eleven-bottom {
    margin-bottom: 11%;
  }
  .xs-margin-eleven-half-bottom {
    margin-bottom: 11.5%;
  }
  .xs-margin-twelve-bottom {
    margin-bottom: 12%;
  }
  .xs-margin-twelve-half-bottom {
    margin-bottom: 12.5%;
  }
  .xs-margin-thirteen-bottom {
    margin-bottom: 13%;
  }
  .xs-margin-thirteen-half-bottom {
    margin-bottom: 13.5%;
  }
  .xs-margin-fourteen-bottom {
    margin-bottom: 14%;
  }
  .xs-margin-fourteen-half-bottom {
    margin-bottom: 14.5%;
  }
  .xs-margin-fifteen-bottom {
    margin-bottom: 15%;
  }
  .xs-margin-fifteen-half-bottom {
    margin-bottom: 15.5%;
  }
  .xs-margin-sixteen-bottom {
    margin-bottom: 16%;
  }
  .xs-margin-sixteen-half-bottom {
    margin-bottom: 16.5%;
  }
  .xs-margin-seventeen-bottom {
    margin-bottom: 17%;
  }
  .xs-margin-seventeen-half-bottom {
    margin-bottom: 17.5%;
  }
  .xs-margin-eighteen-bottom {
    margin-bottom: 18%;
  }
  .xs-margin-eighteen-half-bottom {
    margin-bottom: 18.5%;
  }
  .xs-margin-nineteen-bottom {
    margin-bottom: 19%;
  }
  .xs-margin-nineteen-half-bottom {
    margin-bottom: 19.5%;
  }
  .xs-margin-twenty-bottom {
    margin-bottom: 20%;
  }
  .xs-margin-twenty-half-bottom {
    margin-bottom: 20.5%;
  }
  .xs-margin-twenty-one-bottom {
    margin-bottom: 21%;
  }
  .xs-margin-twenty-one-half-bottom {
    margin-bottom: 21.5%;
  }
  .xs-margin-twenty-two-bottom {
    margin-bottom: 22%;
  }
  .xs-margin-twenty-two-half-bottom {
    margin-bottom: 22.5%;
  }
  .xs-margin-twenty-three-bottom {
    margin-bottom: 23%;
  }
  .xs-margin-twenty-three-half-bottom {
    margin-bottom: 23.5%;
  }
  .xs-margin-twenty-four-bottom {
    margin-bottom: 24%;
  }
  .xs-margin-twenty-four-half-bottom {
    margin-bottom: 24.5%;
  }
  .xs-margin-twenty-five-bottom {
    margin-bottom: 25%;
  }
  .xs-margin-5px-bottom {
    margin-bottom: 5px !important;
  }
  .xs-margin-10px-bottom {
    margin-bottom: 10px !important;
  }
  .xs-margin-15px-bottom {
    margin-bottom: 15px !important;
  }
  .xs-margin-20px-bottom {
    margin-bottom: 20px !important;
  }
  .xs-margin-25px-bottom {
    margin-bottom: 25px !important;
  }
  .xs-margin-30px-bottom {
    margin-bottom: 30px !important;
  }
  .xs-margin-35px-bottom {
    margin-bottom: 35px !important;
  }
  .xs-margin-40px-bottom {
    margin-bottom: 40px !important;
  }
  .xs-margin-45px-bottom {
    margin-bottom: 45px !important;
  }
  .xs-margin-50px-bottom {
    margin-bottom: 50px !important;
  }
  .xs-margin-55px-bottom {
    margin-bottom: 55px !important;
  }
  .xs-margin-60px-bottom {
    margin-bottom: 60px !important;
  }
  .xs-margin-65px-bottom {
    margin-bottom: 65px !important;
  }
  .xs-margin-70px-bottom {
    margin-bottom: 70px !important;
  }
  .xs-margin-75px-bottom {
    margin-bottom: 75px !important;
  }
  .xs-margin-80px-bottom {
    margin-bottom: 80px !important;
  }
  .xs-margin-85px-bottom {
    margin-bottom: 85px !important;
  }
  .xs-margin-90px-bottom {
    margin-bottom: 90px !important;
  }
  .xs-margin-95px-bottom {
    margin-bottom: 95px !important;
  }
  .xs-margin-100px-bottom {
    margin-bottom: 100px !important;
  }

  /* margin right */
  .xs-margin-one-right {
    margin-right: 1%;
  }
  .xs-margin-one-half-right {
    margin-right: 1.5%;
  }
  .xs-margin-two-right {
    margin-right: 2%;
  }
  .xs-margin-two-half-right {
    margin-right: 2.5%;
  }
  .xs-margin-three-right {
    margin-right: 3%;
  }
  .xs-margin-three-half-right {
    margin-right: 3.5%;
  }
  .xs-margin-four-right {
    margin-right: 4%;
  }
  .xs-margin-four-half-right {
    margin-right: 4.5%;
  }
  .xs-margin-five-right {
    margin-right: 5%;
  }
  .xs-margin-five-half-right {
    margin-right: 5.5%;
  }
  .xs-margin-six-right {
    margin-right: 6%;
  }
  .xs-margin-six-half-right {
    margin-right: 6.5%;
  }
  .xs-margin-seven-right {
    margin-right: 7%;
  }
  .xs-margin-seven-half-right {
    margin-right: 7.5%;
  }
  .xs-margin-eight-right {
    margin-right: 8%;
  }
  .xs-margin-eight-half-right {
    margin-right: 8.5%;
  }
  .xs-margin-nine-right {
    margin-right: 9%;
  }
  .xs-margin-nine-half-right {
    margin-right: 9.5%;
  }
  .xs-margin-ten-right {
    margin-right: 10%;
  }
  .xs-margin-ten-half-right {
    margin-right: 10.5%;
  }
  .xs-margin-eleven-right {
    margin-right: 11%;
  }
  .xs-margin-eleven-half-right {
    margin-right: 11.5%;
  }
  .xs-margin-twelve-right {
    margin-right: 12%;
  }
  .xs-margin-twelve-half-right {
    margin-right: 12.5%;
  }
  .xs-margin-thirteen-right {
    margin-right: 13%;
  }
  .xs-margin-thirteen-half-right {
    margin-right: 13.5%;
  }
  .xs-margin-fourteen-right {
    margin-right: 14%;
  }
  .xs-margin-fourteen-half-right {
    margin-right: 14.5%;
  }
  .xs-margin-fifteen-right {
    margin-right: 15%;
  }
  .xs-margin-fifteen-half-right {
    margin-right: 15.5%;
  }
  .xs-margin-sixteen-right {
    margin-right: 16%;
  }
  .xs-margin-sixteen-half-right {
    margin-right: 16.5%;
  }
  .xs-margin-seventeen-right {
    margin-right: 17%;
  }
  .xs-margin-seventeen-half-right {
    margin-right: 17.5%;
  }
  .xs-margin-eighteen-right {
    margin-right: 18%;
  }
  .xs-margin-eighteen-half-right {
    margin-right: 18.5%;
  }
  .xs-margin-nineteen-right {
    margin-right: 19%;
  }
  .xs-margin-nineteen-half-right {
    margin-right: 19.5%;
  }
  .xs-margin-twenty-right {
    margin-right: 20%;
  }
  .xs-margin-twenty-half-right {
    margin-right: 20.5%;
  }
  .xs-margin-twenty-one-right {
    margin-right: 21%;
  }
  .xs-margin-twenty-one-half-right {
    margin-right: 21.5%;
  }
  .xs-margin-twenty-two-right {
    margin-right: 22%;
  }
  .xs-margin-twenty-two-half-right {
    margin-right: 22.5%;
  }
  .xs-margin-twenty-three-right {
    margin-right: 23%;
  }
  .xs-margin-twenty-three-half-right {
    margin-right: 23.5%;
  }
  .xs-margin-twenty-four-right {
    margin-right: 24%;
  }
  .xs-margin-twenty-four-half-right {
    margin-right: 24.5%;
  }
  .xs-margin-twenty-five-right {
    margin-right: 25%;
  }
  .xs-margin-10px-right {
    margin-right: 10px !important;
  }
  .xs-margin-15px-right {
    margin-right: 15px !important;
  }
  .xs-margin-20px-right {
    margin-right: 20px !important;
  }
  .xs-margin-25px-right {
    margin-right: 25px !important;
  }
  .xs-margin-30px-right {
    margin-right: 30px !important;
  }
  .xs-margin-35px-right {
    margin-right: 35px !important;
  }
  .xs-margin-40px-right {
    margin-right: 40px !important;
  }
  .xs-margin-45px-right {
    margin-right: 45px !important;
  }
  .xs-margin-50px-right {
    margin-right: 50px !important;
  }
  .xs-margin-55px-right {
    margin-right: 55px !important;
  }
  .xs-margin-60px-right {
    margin-right: 60px !important;
  }
  .xs-margin-65px-right {
    margin-right: 65px !important;
  }
  .xs-margin-70px-right {
    margin-right: 70px !important;
  }
  .xs-margin-75px-right {
    margin-right: 75px !important;
  }
  .xs-margin-80px-right {
    margin-right: 80px !important;
  }
  .xs-margin-85px-right {
    margin-right: 85px !important;
  }
  .xs-margin-90px-right {
    margin-right: 90px !important;
  }
  .xs-margin-95px-right {
    margin-right: 95px !important;
  }
  .xs-margin-100px-right {
    margin-right: 100px !important;
  }

  /* margin left */
  .xs-margin-one-left {
    margin-left: 1%;
  }
  .xs-margin-one-half-left {
    margin-left: 1.5%;
  }
  .xs-margin-two-left {
    margin-left: 2%;
  }
  .xs-margin-two-half-left {
    margin-left: 2.5%;
  }
  .xs-margin-three-left {
    margin-left: 3%;
  }
  .xs-margin-three-half-left {
    margin-left: 3.5%;
  }
  .xs-margin-four-left {
    margin-left: 4%;
  }
  .xs-margin-four-half-left {
    margin-left: 4.5%;
  }
  .xs-margin-five-left {
    margin-left: 5%;
  }
  .xs-margin-five-half-left {
    margin-left: 5.5%;
  }
  .xs-margin-six-left {
    margin-left: 6%;
  }
  .xs-margin-six-half-left {
    margin-left: 6.5%;
  }
  .xs-margin-seven-left {
    margin-left: 7%;
  }
  .xs-margin-seven-half-left {
    margin-left: 7.5%;
  }
  .xs-margin-eight-left {
    margin-left: 8%;
  }
  .xs-margin-eight-half-left {
    margin-left: 8.5%;
  }
  .xs-margin-nine-left {
    margin-left: 9%;
  }
  .xs-margin-nine-half-left {
    margin-left: 9.5%;
  }
  .xs-margin-ten-left {
    margin-left: 10%;
  }
  .xs-margin-ten-half-left {
    margin-left: 10.5%;
  }
  .xs-margin-eleven-left {
    margin-left: 11%;
  }
  .xs-margin-eleven-half-left {
    margin-left: 11.5%;
  }
  .xs-margin-twelve-left {
    margin-left: 12%;
  }
  .xs-margin-twelve-half-left {
    margin-left: 12.5%;
  }
  .xs-margin-thirteen-left {
    margin-left: 13%;
  }
  .xs-margin-thirteen-half-left {
    margin-left: 13.5%;
  }
  .xs-margin-fourteen-left {
    margin-left: 14%;
  }
  .xs-margin-fourteen-half-left {
    margin-left: 14.5%;
  }
  .xs-margin-fifteen-left {
    margin-left: 15%;
  }
  .xs-margin-fifteen-half-left {
    margin-left: 15.5%;
  }
  .xs-margin-sixteen-left {
    margin-left: 16%;
  }
  .xs-margin-sixteen-half-left {
    margin-left: 16.5%;
  }
  .xs-margin-seventeen-left {
    margin-left: 17%;
  }
  .xs-margin-seventeen-half-left {
    margin-left: 17.5%;
  }
  .xs-margin-eighteen-left {
    margin-left: 18%;
  }
  .xs-margin-eighteen-half-left {
    margin-left: 18.5%;
  }
  .xs-margin-nineteen-left {
    margin-left: 19%;
  }
  .xs-margin-nineteen-half-left {
    margin-left: 19.5%;
  }
  .xs-margin-twenty-left {
    margin-left: 20%;
  }
  .xs-margin-twenty-half-left {
    margin-left: 20.5%;
  }
  .xs-margin-twenty-one-left {
    margin-left: 21%;
  }
  .xs-margin-twenty-one-half-left {
    margin-left: 21.5%;
  }
  .xs-margin-twenty-two-left {
    margin-left: 22%;
  }
  .xs-margin-twenty-two-half-left {
    margin-left: 22.5%;
  }
  .xs-margin-twenty-three-left {
    margin-left: 23%;
  }
  .xs-margin-twenty-three-half-left {
    margin-left: 23.5%;
  }
  .xs-margin-twenty-four-left {
    margin-left: 24%;
  }
  .xs-margin-twenty-four-half-left {
    margin-left: 24.5%;
  }
  .xs-margin-twenty-five-left {
    margin-left: 25%;
  }
  .xs-margin-5px-left {
    margin-left: 5px !important;
  }
  .xs-margin-10px-left {
    margin-left: 10px !important;
  }
  .xs-margin-15px-left {
    margin-left: 15px !important;
  }
  .xs-margin-20px-left {
    margin-left: 20px !important;
  }
  .xs-margin-25px-left {
    margin-left: 25px !important;
  }
  .xs-margin-30px-left {
    margin-left: 30px !important;
  }
  .xs-margin-35px-left {
    margin-left: 35px !important;
  }
  .xs-margin-40px-left {
    margin-left: 40px !important;
  }
  .xs-margin-45px-left {
    margin-left: 45px !important;
  }
  .xs-margin-50px-left {
    margin-left: 50px !important;
  }
  .xs-margin-55px-left {
    margin-left: 55px !important;
  }
  .xs-margin-60px-left {
    margin-left: 60px !important;
  }
  .xs-margin-65px-left {
    margin-left: 65px !important;
  }
  .xs-margin-70px-left {
    margin-left: 70px !important;
  }
  .xs-margin-75px-left {
    margin-left: 75px !important;
  }
  .xs-margin-80px-left {
    margin-left: 80px !important;
  }
  .xs-margin-85px-left {
    margin-left: 85px !important;
  }
  .xs-margin-90px-left {
    margin-left: 90px !important;
  }
  .xs-margin-95px-left {
    margin-left: 95px !important;
  }
  .xs-margin-100px-left {
    margin-left: 100px !important;
  }

  /* margin left right */
  .xs-margin-one-lr {
    margin-left: 1%;
    margin-right: 1%;
  }
  .xs-margin-one-lr {
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
  .xs-margin-two-lr {
    margin-left: 2%;
    margin-right: 2%;
  }
  .xs-margin-two-lr {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  .xs-margin-three-lr {
    margin-left: 3%;
    margin-right: 3%;
  }
  .xs-margin-three-lr {
    margin-left: 3.5%;
    margin-right: 3.5%;
  }
  .xs-margin-four-lr {
    margin-left: 4%;
    margin-right: 4%;
  }
  .xs-margin-four-lr {
    margin-left: 4.5%;
    margin-right: 4.5%;
  }
  .xs-margin-five-lr {
    margin-left: 5%;
    margin-right: 5%;
  }
  .xs-margin-five-lr {
    margin-left: 5.5%;
    margin-right: 5.5%;
  }
  .xs-margin-six-lr {
    margin-left: 6%;
    margin-right: 6%;
  }
  .xs-margin-six-lr {
    margin-left: 6.5%;
    margin-right: 6.5%;
  }
  .xs-margin-seven-lr {
    margin-left: 7%;
    margin-right: 7%;
  }
  .xs-margin-seven-lr {
    margin-left: 7.5%;
    margin-right: 7.5%;
  }
  .xs-margin-eight-lr {
    margin-left: 8%;
    margin-right: 8%;
  }
  .xs-margin-eight-lr {
    margin-left: 8.5%;
    margin-right: 8.5%;
  }
  .xs-margin-nine-lr {
    margin-left: 9%;
    margin-right: 9%;
  }
  .xs-margin-nine-lr {
    margin-left: 9.5%;
    margin-right: 9.5%;
  }
  .xs-margin-ten-lr {
    margin-left: 10%;
    margin-right: 10%;
  }
  .xs-margin-ten-lr {
    margin-left: 10.5%;
    margin-right: 10.5%;
  }
  .xs-margin-eleven-lr {
    margin-left: 11%;
    margin-right: 11%;
  }
  .xs-margin-eleven-lr {
    margin-left: 11.5%;
    margin-right: 11.5%;
  }
  .xs-margin-twelve-lr {
    margin-left: 12%;
    margin-right: 12%;
  }
  .xs-margin-twelve-lr {
    margin-left: 12.5%;
    margin-right: 12.5%;
  }
  .xs-margin-thirteen-lr {
    margin-left: 13%;
    margin-right: 13%;
  }
  .xs-margin-thirteen-half-lr {
    margin-left: 13.5%;
    margin-right: 13.5%;
  }
  .xs-margin-fourteen-lr {
    margin-left: 14%;
    margin-right: 14%;
  }
  .xs-margin-fourteen-half-lr {
    margin-left: 14.5%;
    margin-right: 14.5%;
  }
  .xs-margin-fifteen-lr {
    margin-left: 15%;
    margin-right: 15%;
  }
  .xs-margin-fifteen-half-lr {
    margin-left: 15.5%;
    margin-right: 15.5%;
  }
  .xs-margin-sixteen-lr {
    margin-left: 16%;
    margin-right: 16%;
  }
  .xs-margin-sixteen-half-lr {
    margin-left: 16.5%;
    margin-right: 16.5%;
  }
  .xs-margin-seventeen-lr {
    margin-left: 17%;
    margin-right: 17%;
  }
  .xs-margin-seventeen-half-lr {
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  .xs-margin-eighteen-lr {
    margin-left: 18%;
    margin-right: 18%;
  }
  .xs-margin-eighteen-half-lr {
    margin-left: 18.5%;
    margin-right: 18.5%;
  }
  .xs-margin-nineteen-lr {
    margin-left: 19%;
    margin-right: 19%;
  }
  .xs-margin-nineteen-half-lr {
    margin-left: 19.5%;
    margin-right: 19.5%;
  }
  .xs-margin-twenty-lr {
    margin-left: 20%;
    margin-right: 20%;
  }
  .xs-margin-twenty-half-lr {
    margin-left: 20.5%;
    margin-right: 20.5%;
  }
  .xs-margin-twenty-one-lr {
    margin-left: 21%;
    margin-right: 21%;
  }
  .xs-margin-twenty-one-half-lr {
    margin-left: 21.5%;
    margin-right: 21.5%;
  }
  .xs-margin-twenty-two-lr {
    margin-left: 22%;
    margin-right: 22%;
  }
  .xs-margin-twenty-two-half-lr {
    margin-left: 22.5%;
    margin-right: 22.5%;
  }
  .xs-margin-twenty-three-lr {
    margin-left: 23%;
    margin-right: 23%;
  }
  .xs-margin-twenty-three-half-lr {
    margin-left: 23.5%;
    margin-right: 23.5%;
  }
  .xs-margin-twenty-four-lr {
    margin-left: 24%;
    margin-right: 24%;
  }
  .xs-margin-twenty-four-half-lr {
    margin-left: 24.5%;
    margin-right: 24.5%;
  }
  .xs-margin-twenty-five-lr {
    margin-left: 25%;
    margin-right: 25%;
  }
  .xs-margin-5px-lr {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .xs-margin-10px-lr {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .xs-margin-15px-lr {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .xs-margin-20px-lr {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .xs-margin-25px-lr {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .xs-margin-30px-lr {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .xs-margin-35px-lr {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .xs-margin-40px-lr {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .xs-margin-45px-lr {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .xs-margin-50px-lr {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .xs-margin-55px-lr {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .xs-margin-60px-lr {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .xs-margin-65px-lr {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .xs-margin-70px-lr {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .xs-margin-75px-lr {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .xs-margin-80px-lr {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .xs-margin-85px-lr {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .xs-margin-90px-lr {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .xs-margin-95px-lr {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .xs-margin-100px-lr {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  /* margin top bottom */
  .xs-margin-one-tb {
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .xs-margin-one-tb {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
  }
  .xs-margin-two-tb {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .xs-margin-two-tb {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
  }
  .xs-margin-three-tb {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .xs-margin-three-tb {
    margin-top: 3.5%;
    margin-bottom: 3.5%;
  }
  .xs-margin-four-tb {
    margin-top: 4%;
    margin-bottom: 4%;
  }
  .xs-margin-four-tb {
    margin-top: 4.5%;
    margin-bottom: 4.5%;
  }
  .xs-margin-five-tb {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .xs-margin-five-tb {
    margin-top: 5.5%;
    margin-bottom: 5.5%;
  }
  .xs-margin-six-tb {
    margin-top: 6%;
    margin-bottom: 6%;
  }
  .xs-margin-six-tb {
    margin-top: 6.5%;
    margin-bottom: 6.5%;
  }
  .xs-margin-seven-tb {
    margin-top: 7%;
    margin-bottom: 7%;
  }
  .xs-margin-seven-tb {
    margin-top: 7.5%;
    margin-bottom: 7.5%;
  }
  .xs-margin-eight-tb {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  .xs-margin-eight-tb {
    margin-top: 8.5%;
    margin-bottom: 8.5%;
  }
  .xs-margin-nine-tb {
    margin-top: 9%;
    margin-bottom: 9%;
  }
  .xs-margin-nine-tb {
    margin-top: 9.5%;
    margin-bottom: 9.5%;
  }
  .xs-margin-ten-tb {
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .xs-margin-ten-tb {
    margin-top: 10.5%;
    margin-bottom: 10.5%;
  }
  .xs-margin-eleven-tb {
    margin-top: 11%;
    margin-bottom: 11%;
  }
  .xs-margin-eleven-tb {
    margin-top: 11.5%;
    margin-bottom: 11.5%;
  }
  .xs-margin-twelve-tb {
    margin-top: 12%;
    margin-bottom: 12%;
  }
  .xs-margin-twelve-tb {
    margin-top: 12.5%;
    margin-bottom: 12.5%;
  }
  .xs-margin-thirteen-tb {
    margin-top: 13%;
    margin-bottom: 13%;
  }
  .xs-margin-thirteen-half-tb {
    margin-top: 13.5%;
    margin-bottom: 13.5%;
  }
  .xs-margin-fourteen-tb {
    margin-top: 14%;
    margin-bottom: 14%;
  }
  .xs-margin-fourteen-half-tb {
    margin-top: 14.5%;
    margin-bottom: 14.5%;
  }
  .xs-margin-fifteen-tb {
    margin-top: 15%;
    margin-bottom: 15%;
  }
  .xs-margin-fifteen-half-tb {
    margin-top: 15.5%;
    margin-bottom: 15.5%;
  }
  .xs-margin-sixteen-tb {
    margin-top: 16%;
    margin-bottom: 16%;
  }
  .xs-margin-sixteen-half-tb {
    margin-top: 16.5%;
    margin-bottom: 16.5%;
  }
  .xs-margin-seventeen-tb {
    margin-top: 17%;
    margin-bottom: 17%;
  }
  .xs-margin-seventeen-half-tb {
    margin-top: 17.5%;
    margin-bottom: 17.5%;
  }
  .xs-margin-eighteen-tb {
    margin-top: 18%;
    margin-bottom: 18%;
  }
  .xs-margin-eighteen-half-tb {
    margin-top: 18.5%;
    margin-bottom: 18.5%;
  }
  .xs-margin-nineteen-tb {
    margin-top: 19%;
    margin-bottom: 19%;
  }
  .xs-margin-nineteen-half-tb {
    margin-top: 19.5%;
    margin-bottom: 19.5%;
  }
  .xs-margin-twenty-tb {
    margin-top: 20%;
    margin-bottom: 20%;
  }
  .xs-margin-twenty-half-tb {
    margin-top: 20.5%;
    margin-bottom: 20.5%;
  }
  .xs-margin-twenty-one-tb {
    margin-top: 21%;
    margin-bottom: 21%;
  }
  .xs-margin-twenty-one-half-tb {
    margin-top: 21.5%;
    margin-bottom: 21.5%;
  }
  .xs-margin-twenty-two-tb {
    margin-top: 22%;
    margin-bottom: 22%;
  }
  .xs-margin-twenty-two-half-tb {
    margin-top: 22.5%;
    margin-bottom: 22.5%;
  }
  .xs-margin-twenty-three-tb {
    margin-top: 23%;
    margin-bottom: 23%;
  }
  .xs-margin-twenty-three-half-tb {
    margin-top: 23.5%;
    margin-bottom: 23.5%;
  }
  .xs-margin-twenty-four-tb {
    margin-top: 24%;
    margin-bottom: 24%;
  }
  .xs-margin-twenty-four-half-tb {
    margin-top: 24.5%;
    margin-bottom: 24.5%;
  }
  .xs-margin-twenty-five-tb {
    margin-top: 25%;
    margin-bottom: 25%;
  }
  .xs-margin-5px-tb {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .xs-margin-10px-tb {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .xs-margin-15px-tb {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .xs-margin-20px-tb {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .xs-margin-25px-tb {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .xs-margin-30px-tb {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .xs-margin-35px-tb {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .xs-margin-40px-tb {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .xs-margin-45px-tb {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .xs-margin-50px-tb {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .xs-margin-55px-tb {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .xs-margin-60px-tb {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .xs-margin-65px-tb {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .xs-margin-70px-tb {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .xs-margin-75px-tb {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .xs-margin-80px-tb {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .xs-margin-85px-tb {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .xs-margin-90px-tb {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .xs-margin-95px-tb {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .xs-margin-100px-tb {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  /* header padding top */
  .xs-header-padding-top {
    padding-top: 82px;
  }
  .xs-header-margin-top {
    margin-top: 80px;
  }

  /* padding */
  .xs-no-padding {
    padding: 0 !important;
  }
  .xs-no-padding-lr {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .xs-no-padding-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .xs-no-padding-top {
    padding-top: 0 !important;
  }
  .xs-no-padding-bottom {
    padding-bottom: 0 !important;
  }
  .xs-no-padding-left {
    padding-left: 0 !important;
  }
  .xs-no-padding-right {
    padding-right: 0 !important;
  }
  .xs-padding-one-all {
    padding: 1%;
  }
  .xs-padding-one-half-all {
    padding: 1.5%;
  }
  .xs-padding-two-all {
    padding: 2%;
  }
  .xs-padding-two-half-all {
    padding: 2.5%;
  }
  .xs-padding-three-all {
    padding: 3%;
  }
  .xs-padding-three-half-all {
    padding: 3.5%;
  }
  .xs-padding-four-all {
    padding: 4%;
  }
  .xs-padding-four-half-all {
    padding: 4.5%;
  }
  .xs-padding-five-all {
    padding: 5%;
  }
  .xs-padding-five-half-all {
    padding: 5.5%;
  }
  .xs-padding-six-all {
    padding: 6%;
  }
  .xs-padding-six-half-all {
    padding: 6.5%;
  }
  .xs-padding-seven-all {
    padding: 7%;
  }
  .xs-padding-seven-half-all {
    padding: 7.5%;
  }
  .xs-padding-eight-all {
    padding: 8%;
  }
  .xs-padding-eight-half-all {
    padding: 8.5%;
  }
  .xs-padding-nine-all {
    padding: 9%;
  }
  .xs-padding-nine-half-all {
    padding: 9.5%;
  }
  .xs-padding-ten-all {
    padding: 10%;
  }
  .xs-padding-ten-half-all {
    padding: 10.5%;
  }
  .xs-padding-eleven-all {
    padding: 11%;
  }
  .xs-padding-eleven-half-all {
    padding: 11.5%;
  }
  .xs-padding-twelve-all {
    padding: 12%;
  }
  .xs-padding-twelve-half-all {
    padding: 12.5%;
  }
  .xs-padding-thirteen-all {
    padding: 13%;
  }
  .xs-padding-thirteen-half-all {
    padding: 13.5%;
  }
  .xs-padding-fourteen-all {
    padding: 14%;
  }
  .xs-padding-fourteen-half-all {
    padding: 14.5%;
  }
  .xs-padding-fifteen-all {
    padding: 15%;
  }
  .xs-padding-fifteen-half-all {
    padding: 15.5%;
  }
  .xs-padding-sixteen-all {
    padding: 16%;
  }
  .xs-padding-sixteen-half-all {
    padding: 16.5%;
  }
  .xs-padding-seventeen-all {
    padding: 17%;
  }
  .xs-padding-seventeen-half-all {
    padding: 17.5%;
  }
  .xs-padding-eighteen-all {
    padding: 18%;
  }
  .xs-padding-eighteen-half-all {
    padding: 18.5%;
  }
  .xs-padding-nineteen-all {
    padding: 19%;
  }
  .xs-padding-nineteen-half-all {
    padding: 19.5%;
  }
  .xs-padding-twenty-all {
    padding: 20%;
  }
  .xs-padding-twenty-half-all {
    padding: 20.5%;
  }
  .xs-padding-twenty-one-all {
    padding: 21%;
  }
  .xs-padding-twenty-one-half-all {
    padding: 21.5%;
  }
  .xs-padding-twenty-two-all {
    padding: 22%;
  }
  .xs-padding-twenty-two-half-all {
    padding: 22.5%;
  }
  .xs-padding-twenty-three-all {
    padding: 23%;
  }
  .xs-padding-twenty-three-half-all {
    padding: 23.5%;
  }
  .xs-padding-twenty-four-all {
    padding: 24%;
  }
  .xs-padding-twenty-four-half-all {
    padding: 24.5%;
  }
  .xs-padding-twenty-five-all {
    padding: 25%;
  }
  .xs-padding-5px-all {
    padding: 5px !important;
  }
  .xs-padding-10px-all {
    padding: 10px !important;
  }
  .xs-padding-15px-all {
    padding: 15px !important;
  }
  .xs-padding-20px-all {
    padding: 20px !important;
  }
  .xs-padding-25px-all {
    padding: 25px !important;
  }
  .xs-padding-30px-all {
    padding: 30px !important;
  }
  .xs-padding-35px-all {
    padding: 35px !important;
  }
  .xs-padding-40px-all {
    padding: 40px !important;
  }
  .xs-padding-45px-all {
    padding: 45px !important;
  }
  .xs-padding-50px-all {
    padding: 50px !important;
  }
  .xs-padding-55px-all {
    padding: 55px !important;
  }
  .xs-padding-60px-all {
    padding: 60px !important;
  }
  .xs-padding-65px-all {
    padding: 65px !important;
  }
  .xs-padding-70px-all {
    padding: 70px !important;
  }
  .xs-padding-75px-all {
    padding: 75px !important;
  }
  .xs-padding-80px-all {
    padding: 80px !important;
  }
  .xs-padding-85px-all {
    padding: 85px !important;
  }
  .xs-padding-90px-all {
    padding: 90px !important;
  }
  .xs-padding-95px-all {
    padding: 95px !important;
  }
  .xs-padding-100px-all {
    padding: 100px !important;
  }

  /* padding top */
  .xs-padding-one-top {
    padding-top: 1%;
  }
  .xs-padding-one-half-top {
    padding-top: 1.5%;
  }
  .xs-padding-two-top {
    padding-top: 2%;
  }
  .xs-padding-two-half-top {
    padding-top: 2.5%;
  }
  .xs-padding-three-top {
    padding-top: 3%;
  }
  .xs-padding-three-half-top {
    padding-top: 3.5%;
  }
  .xs-padding-four-top {
    padding-top: 4%;
  }
  .xs-padding-four-half-top {
    padding-top: 4.5%;
  }
  .xs-padding-five-top {
    padding-top: 5%;
  }
  .xs-padding-five-half-top {
    padding-top: 5.5%;
  }
  .xs-padding-six-top {
    padding-top: 6%;
  }
  .xs-padding-six-half-top {
    padding-top: 6.5%;
  }
  .xs-padding-seven-top {
    padding-top: 7%;
  }
  .xs-padding-seven-half-top {
    padding-top: 7.5%;
  }
  .xs-padding-eight-top {
    padding-top: 8%;
  }
  .xs-padding-eight-half-top {
    padding-top: 8.5%;
  }
  .xs-padding-nine-top {
    padding-top: 9%;
  }
  .xs-padding-nine-half-top {
    padding-top: 9.5%;
  }
  .xs-padding-ten-top {
    padding-top: 10%;
  }
  .xs-padding-ten-half-top {
    padding-top: 10.5%;
  }
  .xs-padding-eleven-top {
    padding-top: 11%;
  }
  .xs-padding-eleven-half-top {
    padding-top: 11.5%;
  }
  .xs-padding-twelve-top {
    padding-top: 12%;
  }
  .xs-padding-twelve-half-top {
    padding-top: 12.5%;
  }
  .xs-padding-thirteen-top {
    padding-top: 13%;
  }
  .xs-padding-thirteen-half-top {
    padding-top: 13.5%;
  }
  .xs-padding-fourteen-top {
    padding-top: 14%;
  }
  .xs-padding-fourteen-half-top {
    padding-top: 14.5%;
  }
  .xs-padding-fifteen-top {
    padding-top: 15%;
  }
  .xs-padding-fifteen-half-top {
    padding-top: 15.5%;
  }
  .xs-padding-sixteen-top {
    padding-top: 16%;
  }
  .xs-padding-sixteen-half-top {
    padding-top: 16.5%;
  }
  .xs-padding-seventeen-top {
    padding-top: 17%;
  }
  .xs-padding-seventeen-half-top {
    padding-top: 17.5%;
  }
  .xs-padding-eighteen-top {
    padding-top: 18%;
  }
  .xs-padding-eighteen-half-top {
    padding-top: 18.5%;
  }
  .xs-padding-nineteen-top {
    padding-top: 19%;
  }
  .xs-padding-nineteen-half-top {
    padding-top: 19.5%;
  }
  .xs-padding-twenty-top {
    padding-top: 20%;
  }
  .xs-padding-twenty-half-top {
    padding-top: 20.5%;
  }
  .xs-padding-twenty-one-top {
    padding-top: 21%;
  }
  .xs-padding-twenty-one-half-top {
    padding-top: 21.5%;
  }
  .xs-padding-twenty-two-top {
    padding-top: 22%;
  }
  .xs-padding-twenty-two-half-top {
    padding-top: 22.5%;
  }
  .xs-padding-twenty-three-top {
    padding-top: 23%;
  }
  .xs-padding-twenty-three-half-top {
    padding-top: 23.5%;
  }
  .xs-padding-twenty-four-top {
    padding-top: 24%;
  }
  .xs-padding-twenty-four-half-top {
    padding-top: 24.5%;
  }
  .xs-padding-twenty-five-top {
    padding-top: 25%;
  }
  .xs-padding-5px-top {
    padding-top: 5px !important;
  }
  .xs-padding-10px-top {
    padding-top: 10px !important;
  }
  .xs-padding-15px-top {
    padding-top: 15px !important;
  }
  .xs-padding-20px-top {
    padding-top: 20px !important;
  }
  .xs-padding-25px-top {
    padding-top: 25px !important;
  }
  .xs-padding-30px-top {
    padding-top: 30px !important;
  }
  .xs-padding-35px-top {
    padding-top: 35px !important;
  }
  .xs-padding-40px-top {
    padding-top: 40px !important;
  }
  .xs-padding-45px-top {
    padding-top: 45px !important;
  }
  .xs-padding-50px-top {
    padding-top: 50px !important;
  }
  .xs-padding-55px-top {
    padding-top: 55px !important;
  }
  .xs-padding-60px-top {
    padding-top: 60px !important;
  }
  .xs-padding-65px-top {
    padding-top: 65px !important;
  }
  .xs-padding-70px-top {
    padding-top: 70px !important;
  }
  .xs-padding-75px-top {
    padding-top: 75px !important;
  }
  .xs-padding-80px-top {
    padding-top: 80px !important;
  }
  .xs-padding-85px-top {
    padding-top: 85px !important;
  }
  .xs-padding-90px-top {
    padding-top: 90px !important;
  }
  .xs-padding-95px-top {
    padding-top: 95px !important;
  }
  .xs-padding-100px-top {
    padding-top: 100px !important;
  }

  /* padding bottom */
  .xs-padding-one-bottom {
    padding-bottom: 1%;
  }
  .xs-padding-one-half-bottom {
    padding-bottom: 1.5%;
  }
  .xs-padding-two-bottom {
    padding-bottom: 2%;
  }
  .xs-padding-two-half-bottom {
    padding-bottom: 2.5%;
  }
  .xs-padding-three-bottom {
    padding-bottom: 3%;
  }
  .xs-padding-three-half-bottom {
    padding-bottom: 3.5%;
  }
  .xs-padding-four-bottom {
    padding-bottom: 4%;
  }
  .xs-padding-four-half-bottom {
    padding-bottom: 4.5%;
  }
  .xs-padding-five-bottom {
    padding-bottom: 5%;
  }
  .xs-padding-five-half-bottom {
    padding-bottom: 5.5%;
  }
  .xs-padding-six-bottom {
    padding-bottom: 6%;
  }
  .xs-padding-six-half-bottom {
    padding-bottom: 6.5%;
  }
  .xs-padding-seven-bottom {
    padding-bottom: 7%;
  }
  .xs-padding-seven-half-bottom {
    padding-bottom: 7.5%;
  }
  .xs-padding-eight-bottom {
    padding-bottom: 8%;
  }
  .xs-padding-eight-half-bottom {
    padding-bottom: 8.5%;
  }
  .xs-padding-nine-bottom {
    padding-bottom: 9%;
  }
  .xs-padding-nine-half-bottom {
    padding-bottom: 9.5%;
  }
  .xs-padding-ten-bottom {
    padding-bottom: 10%;
  }
  .xs-padding-ten-half-bottom {
    padding-bottom: 10.5%;
  }
  .xs-padding-eleven-bottom {
    padding-bottom: 11%;
  }
  .xs-padding-eleven-half-bottom {
    padding-bottom: 11.5%;
  }
  .xs-padding-twelve-bottom {
    padding-bottom: 12%;
  }
  .xs-padding-twelve-half-bottom {
    padding-bottom: 12.5%;
  }
  .xs-padding-thirteen-bottom {
    padding-bottom: 13%;
  }
  .xs-padding-thirteen-half-bottom {
    padding-bottom: 13.5%;
  }
  .xs-padding-fourteen-bottom {
    padding-bottom: 14%;
  }
  .xs-padding-fourteen-half-bottom {
    padding-bottom: 14.5%;
  }
  .xs-padding-fifteen-bottom {
    padding-bottom: 15%;
  }
  .xs-padding-fifteen-half-bottom {
    padding-bottom: 15.5%;
  }
  .xs-padding-sixteen-bottom {
    padding-bottom: 16%;
  }
  .xs-padding-sixteen-half-bottom {
    padding-bottom: 16.5%;
  }
  .xs-padding-seventeen-bottom {
    padding-bottom: 17%;
  }
  .xs-padding-seventeen-half-bottom {
    padding-bottom: 17.5%;
  }
  .xs-padding-eighteen-bottom {
    padding-bottom: 18%;
  }
  .xs-padding-eighteen-half-bottom {
    padding-bottom: 18.5%;
  }
  .xs-padding-nineteen-bottom {
    padding-bottom: 19%;
  }
  .xs-padding-nineteen-half-bottom {
    padding-bottom: 19.5%;
  }
  .xs-padding-twenty-bottom {
    padding-bottom: 20%;
  }
  .xs-padding-twenty-half-bottom {
    padding-bottom: 20.5%;
  }
  .xs-padding-twenty-one-bottom {
    padding-bottom: 21%;
  }
  .xs-padding-twenty-one-half-bottom {
    padding-bottom: 21.5%;
  }
  .xs-padding-twenty-two-bottom {
    padding-bottom: 22%;
  }
  .xs-padding-twenty-two-half-bottom {
    padding-bottom: 22.5%;
  }
  .xs-padding-twenty-three-bottom {
    padding-bottom: 23%;
  }
  .xs-padding-twenty-three-half-bottom {
    padding-bottom: 23.5%;
  }
  .xs-padding-twenty-four-bottom {
    padding-bottom: 24%;
  }
  .xs-padding-twenty-four-half-bottom {
    padding-bottom: 24.5%;
  }
  .xs-padding-twenty-five-bottom {
    padding-bottom: 25%;
  }
  .xs-padding-5px-bottom {
    padding-bottom: 5px !important;
  }
  .xs-padding-10px-bottom {
    padding-bottom: 10px !important;
  }
  .xs-padding-15px-bottom {
    padding-bottom: 15px !important;
  }
  .xs-padding-20px-bottom {
    padding-bottom: 20px !important;
  }
  .xs-padding-25px-bottom {
    padding-bottom: 25px !important;
  }
  .xs-padding-30px-bottom {
    padding-bottom: 30px !important;
  }
  .xs-padding-35px-bottom {
    padding-bottom: 35px !important;
  }
  .xs-padding-40px-bottom {
    padding-bottom: 40px !important;
  }
  .xs-padding-45px-bottom {
    padding-bottom: 45px !important;
  }
  .xs-padding-50px-bottom {
    padding-bottom: 50px !important;
  }
  .xs-padding-55px-bottom {
    padding-bottom: 55px !important;
  }
  .xs-padding-60px-bottom {
    padding-bottom: 60px !important;
  }
  .xs-padding-65px-bottom {
    padding-bottom: 65px !important;
  }
  .xs-padding-70px-bottom {
    padding-bottom: 70px !important;
  }
  .xs-padding-75px-bottom {
    padding-bottom: 75px !important;
  }
  .xs-padding-80px-bottom {
    padding-bottom: 80px !important;
  }
  .xs-padding-85px-bottom {
    padding-bottom: 85px !important;
  }
  .xs-padding-90px-bottom {
    padding-bottom: 90px !important;
  }
  .xs-padding-95px-bottom {
    padding-bottom: 95px !important;
  }
  .xs-padding-100px-bottom {
    padding-bottom: 100px !important;
  }

  /* padding right */
  .xs-padding-one-right {
    padding-right: 1%;
  }
  .xs-padding-one-half-right {
    padding-right: 1.5%;
  }
  .xs-padding-two-right {
    padding-right: 2%;
  }
  .xs-padding-two-half-right {
    padding-right: 2.5%;
  }
  .xs-padding-three-right {
    padding-right: 3%;
  }
  .xs-padding-three-half-right {
    padding-right: 3.5%;
  }
  .xs-padding-four-right {
    padding-right: 4%;
  }
  .xs-padding-four-half-right {
    padding-right: 4.5%;
  }
  .xs-padding-five-right {
    padding-right: 5%;
  }
  .xs-padding-five-half-right {
    padding-right: 5.5%;
  }
  .xs-padding-six-right {
    padding-right: 6%;
  }
  .xs-padding-six-half-right {
    padding-right: 6.5%;
  }
  .xs-padding-seven-right {
    padding-right: 7%;
  }
  .xs-padding-seven-half-right {
    padding-right: 7.5%;
  }
  .xs-padding-eight-right {
    padding-right: 8%;
  }
  .xs-padding-eight-half-right {
    padding-right: 8.5%;
  }
  .xs-padding-nine-right {
    padding-right: 9%;
  }
  .xs-padding-nine-half-right {
    padding-right: 9.5%;
  }
  .xs-padding-ten-right {
    padding-right: 10%;
  }
  .xs-padding-ten-half-right {
    padding-right: 10.5%;
  }
  .xs-padding-eleven-right {
    padding-right: 11%;
  }
  .xs-padding-eleven-half-right {
    padding-right: 11.5%;
  }
  .xs-padding-twelve-right {
    padding-right: 12%;
  }
  .xs-padding-twelve-half-right {
    padding-right: 12.5%;
  }
  .xs-padding-thirteen-right {
    padding-right: 13%;
  }
  .xs-padding-thirteen-half-right {
    padding-right: 13.5%;
  }
  .xs-padding-fourteen-right {
    padding-right: 14%;
  }
  .xs-padding-fourteen-half-right {
    padding-right: 14.5%;
  }
  .xs-padding-fifteen-right {
    padding-right: 15%;
  }
  .xs-padding-fifteen-half-right {
    padding-right: 15.5%;
  }
  .xs-padding-sixteen-right {
    padding-right: 16%;
  }
  .xs-padding-sixteen-half-right {
    padding-right: 16.5%;
  }
  .xs-padding-seventeen-right {
    padding-right: 17%;
  }
  .xs-padding-seventeen-half-right {
    padding-right: 17.5%;
  }
  .xs-padding-eighteen-right {
    padding-right: 18%;
  }
  .xs-padding-eighteen-half-right {
    padding-right: 18.5%;
  }
  .xs-padding-nineteen-right {
    padding-right: 19%;
  }
  .xs-padding-nineteen-half-right {
    padding-right: 19.5%;
  }
  .xs-padding-twenty-right {
    padding-right: 20%;
  }
  .xs-padding-twenty-hlaf-right {
    padding-right: 20.5%;
  }
  .xs-padding-twenty-one-right {
    padding-right: 21%;
  }
  .xs-padding-twenty-one-hlaf-right {
    padding-right: 21.5%;
  }
  .xs-padding-twenty-two-right {
    padding-right: 22%;
  }
  .xs-padding-twenty-two-hlaf-right {
    padding-right: 22.5%;
  }
  .xs-padding-twenty-three-right {
    padding-right: 23%;
  }
  .xs-padding-twenty-three-hlaf-right {
    padding-right: 23.5%;
  }
  .xs-padding-twenty-four-right {
    padding-right: 24%;
  }
  .xs-padding-twenty-four-hlaf-right {
    padding-right: 24.5%;
  }
  .xs-padding-twenty-five-right {
    padding-right: 25%;
  }
  .xs-padding-5px-right {
    padding-right: 5px !important;
  }
  .xs-padding-10px-right {
    padding-right: 10px !important;
  }
  .xs-padding-15px-right {
    padding-right: 15px !important;
  }
  .xs-padding-20px-right {
    padding-right: 20px !important;
  }
  .xs-padding-25px-right {
    padding-right: 25px !important;
  }
  .xs-padding-30px-right {
    padding-right: 30px !important;
  }
  .xs-padding-35px-right {
    padding-right: 35px !important;
  }
  .xs-padding-40px-right {
    padding-right: 40px !important;
  }
  .xs-padding-45px-right {
    padding-right: 45px !important;
  }
  .xs-padding-50px-right {
    padding-right: 50px !important;
  }
  .xs-padding-55px-right {
    padding-right: 55px !important;
  }
  .xs-padding-60px-right {
    padding-right: 60px !important;
  }
  .xs-padding-65px-right {
    padding-right: 65px !important;
  }
  .xs-padding-70px-right {
    padding-right: 70px !important;
  }
  .xs-padding-75px-right {
    padding-right: 75px !important;
  }
  .xs-padding-80px-right {
    padding-right: 80px !important;
  }
  .xs-padding-85px-right {
    padding-right: 85px !important;
  }
  .xs-padding-90px-right {
    padding-right: 90px !important;
  }
  .xs-padding-95px-right {
    padding-right: 95px !important;
  }
  .xs-padding-100px-right {
    padding-right: 100px !important;
  }

  /* padding left */
  .xs-padding-one-left {
    padding-left: 1%;
  }
  .xs-padding-one-half-left {
    padding-left: 1.5%;
  }
  .xs-padding-two-left {
    padding-left: 2%;
  }
  .xs-padding-two-half-left {
    padding-left: 2.5%;
  }
  .xs-padding-three-left {
    padding-left: 3%;
  }
  .xs-padding-three-half-left {
    padding-left: 3.5%;
  }
  .xs-padding-four-left {
    padding-left: 4%;
  }
  .xs-padding-four-half-left {
    padding-left: 4.5%;
  }
  .xs-padding-five-left {
    padding-left: 5%;
  }
  .xs-padding-five-half-left {
    padding-left: 5.5%;
  }
  .xs-padding-six-left {
    padding-left: 6%;
  }
  .xs-padding-six-half-left {
    padding-left: 6.5%;
  }
  .xs-padding-seven-left {
    padding-left: 7%;
  }
  .xs-padding-seven-half-left {
    padding-left: 7.5%;
  }
  .xs-padding-eight-left {
    padding-left: 8%;
  }
  .xs-padding-eight-half-left {
    padding-left: 8.5%;
  }
  .xs-padding-nine-left {
    padding-left: 9%;
  }
  .xs-padding-nine-half-left {
    padding-left: 9.5%;
  }
  .xs-padding-ten-left {
    padding-left: 10%;
  }
  .xs-padding-ten-half-left {
    padding-left: 10.5%;
  }
  .xs-padding-eleven-left {
    padding-left: 11%;
  }
  .xs-padding-eleven-half-left {
    padding-left: 11.5%;
  }
  .xs-padding-twelve-left {
    padding-left: 12%;
  }
  .xs-padding-twelve-half-left {
    padding-left: 12.5%;
  }
  .xs-padding-thirteen-left {
    padding-left: 13%;
  }
  .xs-padding-thirteen-half-left {
    padding-left: 13.5%;
  }
  .xs-padding-fourteen-left {
    padding-left: 14%;
  }
  .xs-padding-fourteen-half-left {
    padding-left: 14.5%;
  }
  .xs-padding-fifteen-left {
    padding-left: 15%;
  }
  .xs-padding-fifteen-half-left {
    padding-left: 15.5%;
  }
  .xs-padding-sixteen-left {
    padding-left: 16%;
  }
  .xs-padding-sixteen-half-left {
    padding-left: 16.5%;
  }
  .xs-padding-seventeen-left {
    padding-left: 17%;
  }
  .xs-padding-seventeen-half-left {
    padding-left: 17.5%;
  }
  .xs-padding-eighteen-left {
    padding-left: 18%;
  }
  .xs-padding-eighteen-half-left {
    padding-left: 18.5%;
  }
  .xs-padding-nineteen-left {
    padding-left: 19%;
  }
  .xs-padding-nineteen-half-left {
    padding-left: 19.5%;
  }
  .xs-padding-twenty-left {
    padding-left: 20%;
  }
  .xs-padding-twenty-half-left {
    padding-left: 20.5%;
  }
  .xs-padding-twenty-one-left {
    padding-left: 21%;
  }
  .xs-padding-twenty-one-half-left {
    padding-left: 21.5%;
  }
  .xs-padding-twenty-two-left {
    padding-left: 22%;
  }
  .xs-padding-twenty-two-half-left {
    padding-left: 22.5%;
  }
  .xs-padding-twenty-three-left {
    padding-left: 23%;
  }
  .xs-padding-twenty-three-half-left {
    padding-left: 23.5%;
  }
  .xs-padding-twenty-four-left {
    padding-left: 24%;
  }
  .xs-padding-twenty-four-half-left {
    padding-left: 24.5%;
  }
  .xs-padding-twenty-five-left {
    padding-left: 25%;
  }
  .xs-padding-5px-left {
    padding-left: 5px !important;
  }
  .xs-padding-10px-left {
    padding-left: 10px !important;
  }
  .xs-padding-15px-left {
    padding-left: 15px !important;
  }
  .xs-padding-20px-left {
    padding-left: 20px !important;
  }
  .xs-padding-25px-left {
    padding-left: 25px !important;
  }
  .xs-padding-30px-left {
    padding-left: 30px !important;
  }
  .xs-padding-35px-left {
    padding-left: 35px !important;
  }
  .xs-padding-40px-left {
    padding-left: 40px !important;
  }
  .xs-padding-45px-left {
    padding-left: 45px !important;
  }
  .xs-padding-50px-left {
    padding-left: 50px !important;
  }
  .xs-padding-55px-left {
    padding-left: 55px !important;
  }
  .xs-padding-60px-left {
    padding-left: 60px !important;
  }
  .xs-padding-65px-left {
    padding-left: 65px !important;
  }
  .xs-padding-70px-left {
    padding-left: 70px !important;
  }
  .xs-padding-75px-left {
    padding-left: 75px !important;
  }
  .xs-padding-80px-left {
    padding-left: 80px !important;
  }
  .xs-padding-85px-left {
    padding-left: 85px !important;
  }
  .xs-padding-90px-left {
    padding-left: 90px !important;
  }
  .xs-padding-95px-left {
    padding-left: 95px !important;
  }
  .xs-padding-100px-left {
    padding-left: 100px !important;
  }

  /* padding top bottom */
  .xs-padding-one-tb {
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .xs-padding-one-half-tb {
    padding-top: 1.5%;
    padding-bottom: 1.5%;
  }
  .xs-padding-two-tb {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .xs-padding-two-half-tb {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
  }
  .xs-padding-three-tb {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .xs-padding-three-half-tb {
    padding-top: 3.5%;
    padding-bottom: 3.5%;
  }
  .xs-padding-four-tb {
    padding-top: 4%;
    padding-bottom: 4%;
  }
  .xs-padding-four-half-tb {
    padding-top: 4.5%;
    padding-bottom: 4.5%;
  }
  .xs-padding-five-tb {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .xs-padding-five-half-tb {
    padding-top: 5.5%;
    padding-bottom: 5.5%;
  }
  .xs-padding-six-tb {
    padding-top: 6%;
    padding-bottom: 6%;
  }
  .xs-padding-six-half-tb {
    padding-top: 6.5%;
    padding-bottom: 6.5%;
  }
  .xs-padding-seven-tb {
    padding-top: 7%;
    padding-bottom: 7%;
  }
  .xs-padding-seven-half-tb {
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }
  .xs-padding-eight-tb {
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .xs-padding-eight-half-tb {
    padding-top: 8.5%;
    padding-bottom: 8.5%;
  }
  .xs-padding-nine-tb {
    padding-top: 9%;
    padding-bottom: 9%;
  }
  .xs-padding-nine-half-tb {
    padding-top: 9.5%;
    padding-bottom: 9.5%;
  }
  .xs-padding-ten-tb {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .xs-padding-ten-half-tb {
    padding-top: 10.5%;
    padding-bottom: 10.5%;
  }
  .xs-padding-eleven-tb {
    padding-top: 11%;
    padding-bottom: 11%;
  }
  .xs-padding-eleven-half-tb {
    padding-top: 11.5%;
    padding-bottom: 11.5%;
  }
  .xs-padding-twelve-tb {
    padding-top: 12%;
    padding-bottom: 12%;
  }
  .xs-padding-twelve-half-tb {
    padding-top: 12.5%;
    padding-bottom: 12.5%;
  }
  .xs-padding-thirteen-tb {
    padding-top: 13%;
    padding-bottom: 13%;
  }
  .xs-padding-thirteen-half-tb {
    padding-top: 13.5%;
    padding-bottom: 13.5%;
  }
  .xs-padding-fourteen-tb {
    padding-top: 14%;
    padding-bottom: 14%;
  }
  .xs-padding-fourteen-half-tb {
    padding-top: 14.5%;
    padding-bottom: 14.5%;
  }
  .xs-padding-fifteen-tb {
    padding-top: 15%;
    padding-bottom: 15%;
  }
  .xs-padding-fifteen-half-tb {
    padding-top: 15.5%;
    padding-bottom: 15.5%;
  }
  .xs-padding-sixteen-tb {
    padding-top: 16%;
    padding-bottom: 16%;
  }
  .xs-padding-sixteen-half-tb {
    padding-top: 16.5%;
    padding-bottom: 16.5%;
  }
  .xs-padding-seventeen-tb {
    padding-top: 17%;
    padding-bottom: 17%;
  }
  .xs-padding-seventeen-half-tb {
    padding-top: 17.5%;
    padding-bottom: 17.5%;
  }
  .xs-padding-eighteen-tb {
    padding-top: 18%;
    padding-bottom: 18%;
  }
  .xs-padding-eighteen-half-tb {
    padding-top: 18.5%;
    padding-bottom: 18.5%;
  }
  .xs-padding-nineteen-tb {
    padding-top: 19%;
    padding-bottom: 19%;
  }
  .xs-padding-nineteen-half-tb {
    padding-top: 19.5%;
    padding-bottom: 19.5%;
  }
  .xs-padding-twenty-tb {
    padding-top: 20%;
    padding-bottom: 20%;
  }
  .xs-padding-twenty-one-half-tb {
    padding-top: 20.5%;
    padding-bottom: 20.5%;
  }
  .xs-padding-twenty-one-tb {
    padding-top: 21%;
    padding-bottom: 21%;
  }
  .xs-padding-twenty-half-tb {
    padding-top: 21.5%;
    padding-bottom: 21.5%;
  }
  .xs-padding-twenty-two-tb {
    padding-top: 22%;
    padding-bottom: 22%;
  }
  .xs-padding-twenty-two-half-tb {
    padding-top: 22.5%;
    padding-bottom: 22.5%;
  }
  .xs-padding-twenty-three-tb {
    padding-top: 23%;
    padding-bottom: 23%;
  }
  .xs-padding-twenty-three-half-tb {
    padding-top: 23.5%;
    padding-bottom: 23.5%;
  }
  .xs-padding-twenty-four-tb {
    padding-top: 24%;
    padding-bottom: 24%;
  }
  .xs-padding-twenty-four-half-tb {
    padding-top: 24.5%;
    padding-bottom: 24.5%;
  }
  .xs-padding-twenty-five-tb {
    padding-top: 25%;
    padding-bottom: 25%;
  }
  .xs-padding-5px-tb {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xs-padding-10px-tb {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xs-padding-15px-tb {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xs-padding-20px-tb {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xs-padding-25px-tb {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .xs-padding-30px-tb {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xs-padding-35px-tb {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xs-padding-40px-tb {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xs-padding-45px-tb {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .xs-padding-50px-tb {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .xs-padding-55px-tb {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .xs-padding-60px-tb {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .xs-padding-65px-tb {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .xs-padding-70px-tb {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .xs-padding-75px-tb {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .xs-padding-80px-tb {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .xs-padding-85px-tb {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .xs-padding-90px-tb {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .xs-padding-95px-tb {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .xs-padding-100px-tb {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  /* padding left right */
  .xs-padding-one-lr {
    padding-left: 1%;
    padding-right: 1%;
  }
  .xs-padding-one-half-lr {
    padding-left: 1.5%;
    padding-right: 1.5%;
  }
  .xs-padding-two-lr {
    padding-left: 2%;
    padding-right: 2%;
  }
  .xs-padding-two-half-lr {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  .xs-padding-three-lr {
    padding-left: 3%;
    padding-right: 3%;
  }
  .xs-padding-three-half-lr {
    padding-left: 3.5%;
    padding-right: 3.5%;
  }
  .xs-padding-four-lr {
    padding-left: 4%;
    padding-right: 4%;
  }
  .xs-padding-four-half-lr {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }
  .xs-padding-five-lr {
    padding-left: 5%;
    padding-right: 5%;
  }
  .xs-padding-five-half-lr {
    padding-left: 5.5%;
    padding-right: 5.5%;
  }
  .xs-padding-six-lr {
    padding-left: 6%;
    padding-right: 6%;
  }
  .xs-padding-six-half-lr {
    padding-left: 6.5%;
    padding-right: 6.5%;
  }
  .xs-padding-seven-lr {
    padding-left: 7%;
    padding-right: 7%;
  }
  .xs-padding-seven-half-lr {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
  .xs-padding-eight-lr {
    padding-left: 8%;
    padding-right: 8%;
  }
  .xs-padding-eight-half-lr {
    padding-left: 8.5%;
    padding-right: 8.5%;
  }
  .xs-padding-nine-lr {
    padding-left: 9%;
    padding-right: 9%;
  }
  .xs-padding-nine-half-lr {
    padding-left: 9.5%;
    padding-right: 9.5%;
  }
  .xs-padding-ten-lr {
    padding-left: 10%;
    padding-right: 10%;
  }
  .xs-padding-ten-half-lr {
    padding-left: 10.5%;
    padding-right: 10.5%;
  }
  .xs-padding-eleven-lr {
    padding-left: 11%;
    padding-right: 11%;
  }
  .xs-padding-eleven-half-lr {
    padding-left: 11.5%;
    padding-right: 11.5%;
  }
  .xs-padding-twelve-lr {
    padding-left: 12%;
    padding-right: 12%;
  }
  .xs-padding-twelve-half-lr {
    padding-left: 12.5%;
    padding-right: 12.5%;
  }
  .xs-padding-thirteen-lr {
    padding-left: 13%;
    padding-right: 13%;
  }
  .xs-padding-thirteen-half-lr {
    padding-left: 13.5%;
    padding-right: 13.5%;
  }
  .xs-padding-fourteen-lr {
    padding-left: 14%;
    padding-right: 14%;
  }
  .xs-padding-fourteen-half-lr {
    padding-left: 14.5%;
    padding-right: 14.5%;
  }
  .xs-padding-fifteen-lr {
    padding-left: 15%;
    padding-right: 15%;
  }
  .xs-padding-fifteen-half-lr {
    padding-left: 15.5%;
    padding-right: 15.5%;
  }
  .xs-padding-sixteen-lr {
    padding-left: 16%;
    padding-right: 16%;
  }
  .xs-padding-sixteen-half-lr {
    padding-left: 16.5%;
    padding-right: 16.5%;
  }
  .xs-padding-seventeen-lr {
    padding-left: 17%;
    padding-right: 17%;
  }
  .xs-padding-seventeen-half-lr {
    padding-left: 17.5%;
    padding-right: 17.5%;
  }
  .xs-padding-eighteen-lr {
    padding-left: 18%;
    padding-right: 18%;
  }
  .xs-padding-eighteen-half-lr {
    padding-left: 18.5%;
    padding-right: 18.5%;
  }
  .xs-padding-nineteen-lr {
    padding-left: 19%;
    padding-right: 19%;
  }
  .xs-padding-nineteen-half-lr {
    padding-left: 19.5%;
    padding-right: 19.5%;
  }
  .xs-padding-twenty-lr {
    padding-left: 20%;
    padding-right: 20%;
  }
  .xs-padding-twenty-half-lr {
    padding-left: 20.5%;
    padding-right: 20.5%;
  }
  .xs-padding-twenty-one-lr {
    padding-left: 21%;
    padding-right: 21%;
  }
  .xs-padding-twenty-one-half-lr {
    padding-left: 21.5%;
    padding-right: 21.5%;
  }
  .xs-padding-twenty-two-lr {
    padding-left: 22%;
    padding-right: 22%;
  }
  .xs-padding-twenty-two-half-lr {
    padding-left: 22.5%;
    padding-right: 22.5%;
  }
  .xs-padding-twenty-three-lr {
    padding-left: 23%;
    padding-right: 23%;
  }
  .xs-padding-twenty-three-half-lr {
    padding-left: 23.5%;
    padding-right: 23.5%;
  }
  .xs-padding-twenty-four-lr {
    padding-left: 24%;
    padding-right: 24%;
  }
  .xs-padding-twenty-four-half-lr {
    padding-left: 24.5%;
    padding-right: 24.5%;
  }
  .xs-padding-twenty-lr {
    padding-left: 25%;
    padding-right: 25%;
  }
  .xs-padding-5px-lr {
    padding-left: 5px !important;
    padding-right: 5px;
  }
  .xs-padding-10px-lr {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xs-padding-15px-lr {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xs-padding-20px-lr {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xs-padding-25px-lr {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .xs-padding-30px-lr {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xs-padding-35px-lr {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xs-padding-40px-lr {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xs-padding-45px-lr {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xs-padding-50px-lr {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .xs-padding-55px-lr {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .xs-padding-60px-lr {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .xs-padding-65px-lr {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .xs-padding-70px-lr {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .xs-padding-75px-lr {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .xs-padding-80px-lr {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .xs-padding-85px-lr {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .xs-padding-90px-lr {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .xs-padding-95px-lr {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .xs-padding-100px-lr {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  /* col height  */
  .one-fifth-screen {
    height: 600px;
  }
  .one-fourth-screen {
    height: 500px;
  }
  .one-third-screen {
    height: 350px;
  }
  .one-second-screen {
    height: 300px;
  }
  .extra-small-screen {
    height: 250px;
  }

  /* top */
  .xs-top-auto {
    top: auto;
  }
  .xs-top-inherit {
    top: inherit !important;
  }
  .xs-top-0 {
    top: 0 !important;
  }
  .xs-top-1 {
    top: 1px;
  }
  .xs-top-2 {
    top: 2px;
  }
  .xs-top-3 {
    top: 3px;
  }
  .xs-top-4 {
    top: 4px;
  }
  .xs-top-5 {
    top: 5px;
  }
  .xs-top-6 {
    top: 6px;
  }
  .xs-top-7 {
    top: 7px;
  }
  .xs-top-8 {
    top: 8px;
  }
  .xs-top-9 {
    top: 9px;
  }
  .xs-top-10 {
    top: 10px;
  }
  .xs-top-12 {
    top: 12px;
  }
  .xs-top-15 {
    top: 15px;
  }
  .xs-top-17 {
    top: 17px;
  }
  .xs-top-20 {
    top: 20px;
  }
  .xs-top-25 {
    top: 25px;
  }
  .xs-top-30 {
    top: 30px;
  }
  .xs-top-35 {
    top: 35px;
  }
  .xs-top-40 {
    top: 40px;
  }
  .xs-top-45 {
    top: 45px;
  }
  .xs-top-50 {
    top: 50px;
  }

  .xs-top-minus1 {
    top: -1px;
  }
  .xs-top-minus2 {
    top: -2px;
  }
  .xs-top-minus3 {
    top: -3px;
  }
  .xs-top-minus4 {
    top: -4px;
  }
  .xs-top-minus5 {
    top: -5px;
  }
  .xs-top-minus6 {
    top: -6px;
  }
  .xs-top-minus7 {
    top: -7px;
  }
  .xs-top-minus8 {
    top: -8px;
  }
  .xs-top-minus9 {
    top: -9px;
  }
  .xs-top-minus10 {
    top: -10px;
  }
  .xs-top-minus15 {
    top: -15px;
  }
  .xs-top-minus20 {
    top: -20px;
  }
  .xs-top-minus25 {
    top: -25px;
  }
  .xs-top-minus30 {
    top: -30px;
  }
  .xs-top-minus35 {
    top: -35px;
  }
  .xs-top-minus40 {
    top: -40px;
  }
  .xs-top-minus45 {
    top: -45px;
  }
  .xs-top-minus50 {
    top: -50px;
  }

  /* bottom */
  .xs-bottom-auto {
    bottom: auto;
  }
  .xs-bottom-0 {
    bottom: 0;
  }
  .xs-bottom-1 {
    bottom: 1px;
  }
  .xs-bottom-2 {
    bottom: 2px;
  }
  .xs-bottom-3 {
    bottom: 3px;
  }
  .xs-bottom-4 {
    bottom: 4px;
  }
  .xs-bottom-5 {
    bottom: 5px;
  }
  .xs-bottom-6 {
    bottom: 6px;
  }
  .xs-bottom-7 {
    bottom: 7px;
  }
  .xs-bottom-8 {
    bottom: 8px;
  }
  .xs-bottom-9 {
    bottom: 9px;
  }
  .xs-bottom-10 {
    bottom: 10px;
  }
  .xs-bottom-20 {
    bottom: 20px;
  }
  .xs-bottom-25 {
    bottom: 25px;
  }
  .xs-bottom-30 {
    bottom: 30px;
  }
  .xs-bottom-35 {
    bottom: 35px;
  }
  .xs-bottom-40 {
    bottom: 40px;
  }
  .xs-bottom-45 {
    bottom: 45px;
  }
  .xs-bottom-50 {
    bottom: 50px;
  }

  .xs-bottom--minus30 {
    bottom: -30px;
  }
  .xs-bottom--minus50 {
    bottom: -50px;
  }
  .xs-bottom--minus55 {
    bottom: -55px;
  }

  /* left */
  .xs-left-auto {
    left: auto;
  }
  .xs-left-0 {
    left: 0;
  }
  .xs-left-1 {
    left: 1px;
  }
  .xs-left-2 {
    left: 2px;
  }
  .xs-left-3 {
    left: 3px;
  }
  .xs-left-4 {
    left: 4px;
  }
  .xs-left-5 {
    left: 5px;
  }
  .xs-left-6 {
    left: 6px;
  }
  .xs-left-7 {
    left: 7px;
  }
  .xs-left-8 {
    left: 8px;
  }
  .xs-left-9 {
    left: 9px;
  }
  .xs-left-10 {
    left: 10px;
  }

  /* right */
  .xs-right-auto {
    right: auto;
  }
  .xs-right-0 {
    right: 0;
  }
  .xs-right-1 {
    right: 1px;
  }
  .xs-right-2 {
    right: 2px;
  }
  .xs-right-3 {
    right: 3px;
  }
  .xs-right-4 {
    right: 4px;
  }
  .xs-right-5 {
    right: 5px;
  }
  .xs-right-6 {
    right: 6px;
  }
  .xs-right-7 {
    right: 7px;
  }
  .xs-right-8 {
    right: 8px;
  }
  .xs-right-9 {
    right: 9px;
  }
  .xs-right-10 {
    right: 10px;
  }

  /* width */
  .xs-width-10 {
    width: 10%;
  }
  .xs-width-15 {
    width: 15%;
  }
  .xs-width-20 {
    width: 20%;
  }
  .xs-width-25 {
    width: 25%;
  }
  .xs-width-30 {
    width: 30%;
  }
  .xs-width-35 {
    width: 35%;
  }
  .xs-width-40 {
    width: 40%;
  }
  .xs-width-45 {
    width: 45%;
  }
  .xs-width-50 {
    width: 50%;
  }
  .xs-width-55 {
    width: 55%;
  }
  .xs-width-60 {
    width: 60%;
  }
  .xs-width-65 {
    width: 65%;
  }
  .xs-width-70 {
    width: 70%;
  }
  .xs-width-75 {
    width: 75%;
  }
  .xs-width-80 {
    width: 80%;
  }
  .xs-width-85 {
    width: 85%;
  }
  .xs-width-90 {
    width: 90%;
  }
  .xs-width-95 {
    width: 95%;
  }
  .xs-width-100 {
    width: 100%;
  }
  .xs-width-auto {
    width: auto;
  }

  .xs-width-50px {
    width: 50px;
  }
  .xs-width-70px {
    width: 70px;
  }
  .xs-width-75px {
    width: 75px;
  }
  .xs-width-80px {
    width: 80px;
  }
  .xs-width-85px {
    width: 85px;
  }
  .xs-width-90px {
    width: 90px;
  }
  .xs-width-95px {
    width: 95px;
  }
  .xs-width-100px {
    width: 100px;
  }
  .xs-width-120px {
    width: 120px;
  }
  .xs-width-130px {
    width: 130px;
  }
  .xs-width-150px {
    width: 150px;
  }
  .xs-width-180px {
    width: 180px;
  }
  .xs-width-200px {
    width: 200px;
  }
  .xs-width-250px {
    width: 250px;
  }
  .xs-width-300px {
    width: 300px;
  }
  .xs-width-350px {
    width: 350px;
  }
  .xs-width-400px {
    width: 400px;
  }
  .xs-width-450px {
    width: 450px;
  }
  .xs-width-500px {
    width: 500px;
  }
  .xs-width-550px {
    width: 550px;
  }
  .xs-width-600px {
    width: 600px;
  }
  .xs-width-650px {
    width: 650px;
  }
  .xs-width-700px {
    width: 700px;
  }
  .xs-width-750px {
    width: 750px;
  }
  .xs-width-800px {
    width: 800px;
  }
  .xs-width-850px {
    width: 850px;
  }
  .xs-width-900px {
    width: 900px;
  }
  .xs-width-950px {
    width: 950px;
  }
  .xs-width-1000px {
    width: 1000px;
  }

  /* height */
  .xs-height-50 {
    height: 50% !important;
  }
  .xs-height-100 {
    height: 100% !important;
  }
  .xs-height-auto {
    height: auto !important;
  }
  .xs-height-50px {
    height: 50px !important;
  }
  .xs-height-60px {
    height: 60px !important;
  }
  .xs-height-70px {
    height: 70px !important;
  }
  .xs-height-80px {
    height: 80px !important;
  }
  .xs-height-90px {
    height: 90px !important;
  }
  .xs-height-100px {
    height: 100px !important;
  }
  .xs-height-150px {
    height: 150px !important;
  }
  .xs-height-200px {
    height: 200px !important;
  }
  .xs-height-250px {
    height: 250px !important;
  }
  .xs-height-300px {
    height: 300px !important;
  }
  .xs-height-350px {
    height: 350px !important;
  }
  .xs-height-400px {
    height: 400px !important;
  }
  .xs-height-450px {
    height: 450px !important;
  }
  .xs-height-500px {
    height: 500px !important;
  }
  .xs-height-550px {
    height: 550px !important;
  }
  .xs-height-600px {
    height: 600px !important;
  }
  .xs-height-650px {
    height: 650px !important;
  }
  .xs-height-700px {
    height: 700px !important;
  }

  /* line height */
  .xs-line-height-10 {
    line-height: 10px;
  }
  .xs-line-height-18 {
    line-height: 18px;
  }
  .xs-line-height-20 {
    line-height: 20px;
  }
  .xs-line-height-24 {
    line-height: 24px;
  }
  .xs-line-height-22 {
    line-height: 22px;
  }
  .xs-line-height-26 {
    line-height: 26px;
  }
  .xs-line-height-28 {
    line-height: 28px;
  }
  .xs-line-height-30 {
    line-height: 30px;
  }
  .xs-line-height-35 {
    line-height: 35px;
  }
  .xs-line-height-40 {
    line-height: 40px;
  }
  .xs-line-height-45 {
    line-height: 45px;
  }
  .xs-line-height-50 {
    line-height: 50px;
  }
  .xs-line-height-55 {
    line-height: 55px;
  }
  .xs-line-height-60 {
    line-height: 60px;
  }
  .xs-line-height-65 {
    line-height: 65px;
  }
  .xs-line-height-70 {
    line-height: 70px;
  }
  .xs-line-height-75 {
    line-height: 75px;
  }
  .xs-line-height-80 {
    line-height: 80px;
  }
  .xs-line-height-85 {
    line-height: 85px;
  }
  .xs-line-height-90 {
    line-height: 90px;
  }
  .xs-line-height-95 {
    line-height: 95px;
  }
  .xs-line-height-100 {
    line-height: 100px;
  }

  .page-title-large h1 {
    font-size: 35px;
    line-height: 35px;
  }
  .page-title-medium h1 {
    font-size: 25px;
    line-height: 30px;
  }
  .title-large {
    font-size: 38px;
    line-height: 44px;
  }
  .title-extra-large {
    font-size: 35px;
    line-height: 40px;
  }

  /* feature box style 14 */
  .feature-box-14 > div:nth-child(even) {
    border-right: 0;
  }
  .feature-box-14 > div:nth-child(odd) {
    border-right: 0;
  }

  /* display */
  .xs-display-block {
    display: block !important;
  }
  .xs-overflow-hidden {
    overflow: hidden !important;
  }
  .xs-overflow-auto {
    overflow: auto !important;
  }
  .xs-display-inline-block {
    display: inline-block !important;
  }
  .xs-display-inline {
    display: inline !important;
  }
  .xs-display-none {
    display: none !important;
  }
  .xs-display-inherit {
    display: inherit !important;
  }
  .xs-display-table {
    display: table !important;
  }
  .xs-display-table-cell {
    display: table-cell !important;
  }

  /* position */
  .xs-position-inherit {
    position: inherit;
  }
  .xs-position-relative {
    position: relative;
    z-index: 5;
  }
  .xs-position-absolute {
    position: absolute;
  }
  .xs-position-fixed {
    position: fixed;
  }

  /* position */
  .xs-letter-spacing-0 {
    letter-spacing: 0;
  }

  /* border */
  .xs-no-border-top {
    border-top: 0 !important;
  }
  .xs-no-border-bottom {
    border-bottom: 0 !important;
  }
  .xs-no-border-right {
    border-right: 0 !important;
  }
  .xs-no-border-left {
    border-left: 0 !important;
  }
  .xs-no-border {
    border: 0 !important;
  }

  /* custom */
  section {
    padding: 50px 0;
  }
  section.big-section {
    padding: 80px 0;
  }
  section.extra-big-section {
    padding: 100px 0;
  }
  section.half-section {
    padding: 25px 0;
  }
  .xs-col-2-nth .col-sm-6:nth-child(2n + 1) {
    clear: left;
  }
  .xs-col-3-nth .col-sm-4:nth-child(3n + 1) {
    clear: left;
  }
  .xs-col-4-nth .col-sm-3:nth-child(4n + 1) {
    clear: left;
  }
  .xs-center-col {
    float: none;
    margin: 0 auto;
  }
  .mobile-height,
  .swiper-full-screen {
    height: auto !important;
  }
  .mobile-height .full-screen,
  .swiper-full-screen .full-screen {
    height: 480px !important;
    min-height: 480px !important;
  }
  .overlap-section {
    margin-top: 0;
  }
  .gridblock-main {
    padding: 15px;
  }
  .gridblock-list li {
    width: 100%;
  }

  /*blockquote*/
  .blog-image blockquote {
    padding: 20px;
  }

  /* slider */
  .slider-half-small-screen {
    min-height: 330px;
  }
  .slider-half-screen {
    min-height: 420px;
  }

  /* portfolio grid */
  .portfolio-grid.work-6col li,
  .blog-grid.blog-6col li {
    width: 100%;
  }
  .portfolio-grid.work-6col li.grid-item-double,
  .blog-grid.blog-6col li.grid-item-double {
    width: 100%;
  }

  .portfolio-grid.work-4col li,
  .blog-grid.work-4col li {
    width: 100%;
  }
  .portfolio-grid.work-4col li.grid-item-double,
  .blog-grid.blog-4col li.grid-item-double {
    width: 100%;
  }
  .portfolio-grid.work-4col li.grid-item-double:last-child,
  .blog-grid.blog-4col li.grid-item-double:last-child {
    width: 100%;
  }

  .portfolio-grid.work-3col li,
  .blog-grid.work-3col li {
    width: 100%;
  }
  .portfolio-grid.work-3col li.grid-item-double,
  .blog-grid.work-3col li.grid-item-double {
    width: 100%;
  }

  .portfolio-grid.gutter-small,
  .blog-grid.gutter-small {
    margin: 0 7px;
  }
  .portfolio-grid.gutter-small li,
  .blog-grid.gutter-small li {
    padding: 7px 7px;
  }
  .portfolio-grid.gutter-extra-large li,
  .blog-grid.gutter-extra-large li {
    padding: 15px;
  }
  .portfolio-grid.gutter-extra-large,
  .blog-grid.gutter-extra-large {
    margin: 0 -15px;
  }

  .hover-option8 .grid-item .portfolio-icon {
    right: 39px;
    top: 34px;
  }
  .portfolio-grid.gutter-medium,
  .blog-grid.gutter-medium,
  .portfolio-grid.gutter-large,
  .portfolio-grid.gutter-large,
  .blog-grid.gutter-large {
    margin: 0;
  }
  .portfolio-grid.gutter-medium li,
  .blog-grid.gutter-medium li,
  .portfolio-grid.gutter-large li,
  .blog-grid.gutter-large li {
    padding: 7px 0;
  }

  .portfolio-grid.work-5col li,
  .blog-grid.blog-5col li {
    width: 100%;
  }
  .portfolio-grid.work-5col li.grid-item-double,
  .blog-grid.blog-5col li.grid-item-double {
    width: 100%;
  }

  /* portfolio 2 column */
  .portfolio-grid.work-2col li,
  .blog-grid.work-2col li {
    width: 100%;
  }
  .portfolio-grid.work-2col li.grid-item-double,
  .blog-grid.work-2col li.grid-item-double {
    width: 100%;
  }

  /* portfolio grid */
  .portfolio-grid.portfolio-metro-grid.work-4col li {
    width: 100%;
  }
  .portfolio-grid.portfolio-metro-grid.work-4col li.grid-item-double {
    width: 100%;
  }
  .portfolio-grid.portfolio-metro-grid.work-4col li.grid-item-double:last-child {
    width: 100%;
  }
  .portfolio-grid.portfolio-metro-grid.work-6col li {
    width: 100%;
  }
  .portfolio-grid.portfolio-metro-grid.work-6col li.grid-item-double {
    width: 100%;
  }
  .portfolio-grid.portfolio-metro-grid.work-6col li.grid-item-double:last-child {
    width: 100%;
  }
  .portfolio-grid.portfolio-metro-grid.work-3col li {
    width: 100%;
  }
  .portfolio-grid.portfolio-metro-grid.work-3col li.grid-item-double {
    width: 100%;
  }
  .portfolio-grid.portfolio-metro-grid.work-3col li.grid-item-double:last-child {
    width: 100%;
  }

  /* swiper-bottom-scrollbar-full */
  .swiper-bottom-scrollbar-full .swiper-slide img {
    max-height: 100%;
  }
  .swiper-bottom-scrollbar-full .swiper-slide:hover img {
    max-height: 100%;
  }

  /* verticle align */
  .xs-clear-both {
    clear: both;
  }

  /* feature box style 4 */
  .grid-item.feature-box-4 figure figcaption {
    padding-bottom: 30px;
  }

  /* swipe carousel  */
  .swiper-full-screen .swiper-pagination {
    bottom: 20px;
  }

  /* counter box style 3 */
  .counter-box-3 .counter-box {
    width: 25%;
  }
  .counter-box-3 .counter-box .number {
    font-size: 16px;
    line-height: 16px;
  }
  .counter-box-3 .counter-box span {
    font-size: 12px;
    line-height: 24px;
  }
  .counter-box-3 .counter-box:after {
    top: -1px;
  }

  /* counter box style 4 */
  .counter-box-4 .timer::after {
    display: none;
  }
  .icon-box .icon-box-holder i {
    padding-top: 0;
  }

  /*feature box 4*/
  .feature-box-4 .grid-item figcaption {
    padding: 0 20px;
  }
  .feature-box-4 figure figcaption {
    padding: 10% 5%;
    text-align: center;
  }

  /*feature box 5*/
  .feature-box-5 {
    padding-left: 0;
    text-align: center;
  }
  .feature-box-5 i {
    display: block;
    width: 100%;
    position: inherit;
    margin-bottom: 15px;
  }

  /*feature box 7*/
  .feature-box.feature-box-7 .box,
  .feature-box.feature-box-7 div:first-child .box {
    border: 1px solid #ededed !important;
  }

  /*feature box 9 */
  .feature-box-9:nth-child(3) {
    clear: left;
  }

  /*feature box 10 */
  .feature-box-10 .number-center::before,
  .feature-box-10 .number-center::after {
    height: 0;
  }

  /* feature box style 12 */
  .feature-box-12 .hover-content-box {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }

  /* feature box style 17 */
  .feature-box.feature-box-17 .box .content {
    top: 25px;
  }

  /* hover option3 */
  .hover-option3 .grid-item figure {
    bottom: 80px;
  }
  .hover-option3 .grid-item figure figcaption {
    padding: 0 30px;
  }

  /* header social icon */
  .header-searchbar {
    padding-left: 5px;
  }
  .header-searchbar a i {
    margin-right: 0;
  }
  .header-right {
    margin-left: 0;
  }

  /* header with sticky topbar */
  .header-with-topbar.fixed-topbar nav.navbar.bootsnav.navbar-fixed-top,
  .sticky.header-with-topbar.fixed-topbar nav.navbar.bootsnav.navbar-fixed-top,
  .sticky.header-with-topbar.header-appear.fixed-topbar nav.navbar.bootsnav.navbar-top {
    top: 30px;
  }

  /* home-page-7 */
  .head-text {
    font-size: 30px;
    line-height: 36px;
  }
  .sidebar-wrapper .portfolio-grid.work-4col li,
  .blog-grid.blog-4col li,
  .blog-grid.blog-3col li {
    width: 100%;
  }
  .special-char-extra-large {
    font-size: 130px;
    line-height: 110px;
  }

  /* home-page-7 */
  .hover-option11.gutter-small .grid-item {
    padding: 0;
    margin-bottom: 25px;
  }

  /* swiper pagination */
  .swiper-pagination-square-bottom.swiper-pagination-bullets {
    bottom: -35px;
  }
  .swiper-number-pagination .swiper-pagination-bullets {
    bottom: auto;
    top: 25% !important;
  }
  .swiper-vertical-pagination > .swiper-pagination-bullets {
    right: 15px;
  }

  /*verticle slder homepage 12*/
  .swiper-container-autoheight .swiper-slide {
    height: auto;
  }
  .swiper-container-autoheight .swiper-slide:last-child {
    margin-bottom: 0;
  }
  .swiper-bottom-scrollbar-full .swiper-slide img {
    opacity: 1;
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    -o-filter: grayscale(0);
    filter: contrast(130%);
    -webkit-filter: contrast(130%);
    -moz-filter: contrast(130%);
    -ms-filter: contrast(130%);
    -o-filter: contrast(130%);
    width: 100%;
    max-height: 100%;
  }
  .hover-title-box .separator {
    position: relative;
    left: 0;
    top: 70px;
  }
  .swiper-bottom-scrollbar-full .bottom-text {
    opacity: 1;
  }
  .swiper-auto-slide.swiper-container {
    height: calc(100% - 93px);
    top: 78px;
  }
  .swiper-bottom-scrollbar-full .bottom-text {
    bottom: 25px;
  }
  .hover-title-box {
    opacity: 1;
    text-align: center;
    top: inherit;
    transform: translate(-50%, -30px);
    -moz-transform: translate(-50%, -30px);
    -webkit-transform: translate(-50%, -30px);
    -o-transform: translate(-50%, -30px);
    -ms-transform: translate(-50%, -30px);
    bottom: 0;
    left: 50%;
    right: 0;
    display: block;
  }

  /* swiper button style3 */
  .swiper-button-prev.swiper-prev-style3,
  .swiper-button-next.swiper-next-style3 {
    position: relative;
    bottom: 24px;
    display: inline-block;
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    left: 50%;
    margin: 0;
  }
  .swiper-button-prev.swiper-prev-style3 {
    left: 50%;
    margin: 0 0 0 -85px;
  }

  /* left nav */
  .sidebar-part1,
  .bottom-menu-icon {
    width: 50px;
  }
  .bottom-menu-icon {
    padding: 8px 15px;
  }

  /* testimonial-style2 */
  .testimonial-style2 .profile-box {
    margin-top: -25px;
  }

  /* left-sidebar style 2 */
  .sidebar-part2 {
    width: 280px;
    left: -280px;
  }
  .sidebar-part2 .right-bg {
    right: 15px;
  }

  /* link style 2 */
  .full-width-pull-menu .link-style-2 ul li a {
    font-size: 26px;
    line-height: 60px;
  }
  .full-width-pull-menu .link-style-2 ul li a {
    line-height: 42px;
    font-size: 22px;
  }
  .full-width-pull-menu .link-style-2 .icon-social-medium a:first-child .fab {
    margin-left: 0;
  }
  .full-width-pull-menu .link-style-2 .dropdown ul li a {
    line-height: 16px;
    font-size: 12px;
    padding: 3px 0;
  }
  .link-style-2 .dropdown-backdrop {
    display: none;
  }

  /* close button */
  .close-button-menu {
    top: 5px;
    right: 5px;
  }

  /* full width pull menu */
  .full-width-pull-menu .dropdown span.dropdown-toggle {
    width: 30px;
    height: 30px;
    top: 21px;
  }
  .full-width-pull-menu .dropdown .dropdown-toggle:before {
    right: 15px;
    top: 10px;
  }
  .full-width-pull-menu .dropdown .dropdown-toggle:after {
    right: 10px;
    top: 15px;
  }

  /* btn */
  .btn-dual .btn {
    margin: 10px auto !important;
    display: table;
    min-width: 160px;
  }

  /* search form style */
  .search-form {
    width: 100%;
  }

  /*hamburger menu*/
  .animation-box {
    padding: 90px 0 30px;
  }
  .animation-box .menu-middle {
    overflow-y: auto;
    display: inherit;
  }
  .hamburger-menu-links {
    margin-bottom: 15px;
  }
  .hamburger-menu-links li {
    width: 100%;
    margin-bottom: 15px;
  }
  .hamburger-menu-links {
    font-size: 30px;
  }
  .hamburger-menu .hamburger-menu-wrepper .btn-hamburger {
    right: 15px;
  }
  .hamburger-menu-wrepper {
    padding: 20px 15px;
  }
  .menu-middle {
    width: 100%;
  }
  .hamburger-menu-links {
    font-size: 20px;
    line-height: 24px;
  }

  /* footer */
  .social-style-2 ul li {
    margin: 2px 5px;
  }
  .latest-post li div {
    float: left;
    width: 70%;
  }
  .latest-post li {
    width: 100%;
  }
  .footer-widget-area .list-unstyled {
    margin-bottom: 0;
  }
  .instagram-follow-api li {
    padding: 0 5px 10px;
  }

  .list-style-7 li {
    padding-bottom: 8px;
    margin-bottom: 5px;
  }

  /* feature-box-11 */
  .feature-box-11 .progress-line:after {
    visibility: hidden;
  }

  /* sidebar nav style 2 */
  .left-nav-sidebar .container {
    width: 100%;
  }
  .sidebar-part2 .sidebar-middle {
    padding: 15px 0 100px;
  }
  .sidebar-part2 .widget ul {
    bottom: -12px;
  }

  /* homepage 14 */
  .content-right-slider .swiper-button-next,
  .swiper-button-next.slider-long-arrow-white,
  .swiper-container-rtl .swiper-button-prev.slider-long-arrow-white {
    right: 5px;
  }
  .content-right-slider .swiper-button-prev,
  .swiper-button-prev.slider-long-arrow-white,
  .swiper-container-rtl .swiper-button-next.slider-long-arrow-white {
    left: 5px;
  }
  .content-right-slider .swiper-button-next,
  .content-right-slider .swiper-button-prev,
  .swiper-button-prev.slider-long-arrow-white,
  .swiper-container-rtl .swiper-button-next.slider-long-arrow-white,
  .swiper-button-next.slider-long-arrow-white,
  .swiper-container-rtl .swiper-button-prev.slider-long-arrow-white {
    background-size: 20px auto;
    width: 25px;
  }

  /*swiper bootom scroll bar for portfoio personal*/
  .swiper-bottom-scrollbar-full.swiper-container {
    height: calc(100% - 80px);
    top: 80px;
    overflow: hidden;
    float: left;
    display: block;
  }
  .swiper-bottom-scrollbar-full .swiper-wrapper {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  /* homepage 19 */
  .swiper-vertical-pagination .box-arrow {
    height: 50px;
    position: absolute;
    right: -35px;
    bottom: 0;
    width: 50px;
  }

  .down-section {
    bottom: 40px;
  }
  .after-before-separator::before,
  .after-before-separator::after {
    margin: 0 10px;
    width: 25px;
  }

  /* social media icon style 3*/
  .social-style-3 ul li {
    margin: 0 8px;
  }

  /* tab */
  .nav-tabs {
    text-align: center;
  }
  .portfolio-filter-tab-1 li {
    margin: 5px 0;
    width: 100%;
  }
  .portfolio-filter-tab-2 li {
    width: 100%;
    margin-bottom: 5px;
  }

  /* tab style 2 */
  .tab-style2 .nav-tabs > li.active > a {
    top: 0;
  }

  /*tab style 1*/
  .tab-style1 .nav-tabs li {
    width: 100%;
    margin-bottom: 30px;
  }

  /* team style 2 */
  .team-style-2 figure:hover figcaption .overlay-content {
    top: 20px;
  }

  /*tab style 2*/
  .tab-style2 .tab-content {
    padding: 30px 0 0;
  }
  .tab-style2 .nav-tabs li {
    display: block;
    margin-right: 0;
  }

  /*tab style 3*/
  .tab-style3 .tab-content {
    padding: 30px 0 0;
  }
  .tab-style3 .nav-tabs li {
    display: block;
    text-align: left;
    margin: 0;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  /* skillbar bar style 3  */
  .skillbar-bar-style3 .skillbar {
    width: 100%;
  }

  .team-image img {
    width: 100%;
  }
  .banner-style2 figure:hover figcaption {
    right: 10%;
  }
  .banner-style2 figcaption {
    width: 80%;
  }
  .text-decoration-line-through-black::before,
  .text-decoration-line-through-deep-pink:before {
    display: none;
  }
  .counter-box-5 .counter-box .number {
    font-size: 30px;
    line-height: 30px;
  }
  .counter-box-5 .counter-box {
    width: 20%;
  }
  .counter-box-5 .counter-box span {
    letter-spacing: 1px;
    font-size: 11px;
  }

  .navigation-area {
    right: 0;
  }
  .swiper-button-prev.swiper-prev-style4,
  .swiper-button-next.swiper-next-style4 {
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -o-transform: scale(0.8);
    top: 5px;
    right: -4px;
  }
  .swiper-button-next.swiper-next-style4 {
    top: 14px;
  }
  .blog-nav-link {
    padding: 10px 0;
    font-size: 12px;
  }
  .blog-nav-link-next {
    padding-right: 30px;
  }
  .blog-nav-link-prev {
    padding-left: 30px;
  }
  .blog-listing-comment {
    width: 100%;
    text-align: center;
  }
  .blog-listing-comment li {
    display: inline-block;
    float: none;
  }
  .blog-text .name {
    width: 100%;
    text-align: center;
  }
  .text-outside-line:after {
    display: none;
  }
  .text-outside-line:before {
    display: none;
  }
  .input-border-bottom {
    margin-bottom: 10px;
  }
  .sidebar-part3 {
    top: 6px;
  }
  .blog-text .name:nth-child(2) {
    padding-top: 0;
    padding-bottom: 0;
  }

  /* top logo */
  .navbar.navbar-brand-top.bootsnav .navbar-toggle {
    top: 4px;
  }
  .navbar-brand-top .brand-top-menu-right {
    margin-top: 1px;
    margin-left: 0;
  }

  /* slider image */
  .parallax-category-text {
    background: #000 none repeat scroll 0 0;
    color: #fff;
    opacity: 1;
    padding: 5px 18px;
  }

  /* hover option 6 */
  .hover-option6 .grid-item .portfolio-icon i.fa-search {
    top: 12px;
  }

  /* header style */
  nav.navbar .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mfp-close,
  .mfp-close:active {
    top: 0;
    right: 0;
  }
  button.mfp-arrow {
    background-color: #000;
    height: 50px;
    width: 50px;
    opacity: 1;
  }
  .mfp-arrow:before {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 50px;
    border: 0;
  }
  .mfp-arrow,
  .mfp-arrow:active,
  .mfp-arrow:focus {
    margin-top: -25px;
  }
  .mfp-arrow-left {
    left: 6px;
  }
  .mfp-arrow-right {
    right: 6px;
  }
  #search-header {
    width: 90%;
    margin: 0 auto;
  }
  .search-form .search-input {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 34px;
  }
  .search-form .search-button {
    font-size: 12px;
    top: 20px;
  }

  /* social style */
  .social-style-5 ul li {
    margin: 5px;
  }
  .social-style-1 ul li {
    margin: 0 5px;
  }

  /* blog comment */
  .child-comment {
    padding-left: 20px;
  }
  .blog-comment li ul.child-comment {
    margin-left: 0;
  }
  .blog-comment li ul.child-comment li {
    margin-top: 30px;
  }
  img.alignleft,
  img.alignright {
    margin: 0 auto 15px;
    float: none;
    width: 100%;
  }
  blockquote {
    margin: 30px 0;
    padding: 15px 25px;
  }
  blockquote p {
    font-size: 14px;
    line-height: 24px;
  }

  /* blog caption */
  .wp-caption {
    max-width: 100%;
  }
  .wp-caption.alignleft,
  .wp-caption.alignright {
    float: none;
    margin: 5px 0 30px;
  }

  /* icon type */
  .icon-medium {
    font-size: 30px;
  }
  .icon-extra-medium {
    font-size: 30px;
  }
  .icon-large {
    font-size: 40px;
  }
  .extra-small-icon li,
  .small-icon li,
  .medium-icon li,
  .large-icon li,
  .extra-large-icon li {
    margin-bottom: 10px;
  }

  .uranus.tparrows:before {
    font-size: 20px;
  }

  .demo-heading {
    font-size: 24px;
    line-height: 30px;
  }
  .tab-style4 .tab-content {
    padding-left: 15px;
  }
  .tab-style4 .nav-tabs {
    display: block !important;
    padding-right: 15px;
    margin-bottom: 30px;
  }
  .tab-style4 .nav-tabs li {
    border-right: 1px solid #e5e5e5;
  }
}

@media (max-width: 640px) {
  /* special character */
  .special-char-medium {
    font-size: 60px;
  }

  /* feature box 7 */
  .feature-box {
    padding: 0 15px;
  }
  .feature-box.feature-box-7 .box,
  .feature-box.feature-box-7 div:first-child .box {
    border: 1px solid #ededed;
  }

  /* accordion-style2 */
  .accordion-style2 .panel-body {
    padding: 20px 10px;
  }
  .accordion-style2 .panel .panel-heading {
    padding: 15px 10px 16px 10px;
  }
  .accordion-style2 .tab-tag {
    margin-right: 3px;
  }

  /* accordion-style3 */
  .accordion-style3 .panel-body {
    padding: 20px 10px;
  }
  .accordion-style3 .panel .panel-heading {
    padding: 15px 10px 16px 10px;
  }
  .accordion-style3 .tab-tag {
    margin-right: 3px;
  }

  /* hover style 7 */
  .hover-option7 .grid-item figcaption {
    padding: 20px;
  }
  .hover-option7 .grid-item figure:hover img {
    top: -83px;
  }

  /* breadcrumb */
  .breadcrumb ul > li:after {
    padding: 0 3px 0 5px;
  }

  .skillbar-bar-style1 .skill-bar-percent {
    right: -30px;
  }
  .skillbar-bar-style2 .skill-bar-percent {
    right: -30px;
  }
}

/* ipad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  /* slider image */
  .parallax {
    background-attachment: inherit;
    background-position: center;
  }
}

/* ipad Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  /* slider image */
  .parallax {
    background-attachment: inherit;
    background-position: center;
  }
}

@media screen and (max-width: 55.1875em) {
  .cbp-spmenu-horizontal {
    font-size: 75%;
    height: 110px;
  }
  .cbp-spmenu-top {
    top: -110px;
  }
  .cbp-spmenu-bottom {
    bottom: -110px;
  }
}

@media screen and (max-height: 26.375em) {
  .cbp-spmenu-vertical {
    font-size: 90%;
    width: 190px;
  }
  .cbp-spmenu-left,
  .cbp-spmenu-push-toleft {
    left: -190px;
  }
  .cbp-spmenu-right {
    right: -190px;
  }
  .cbp-spmenu-push-toright {
    left: 190px;
  }
}

@media screen and (max-height: 320px) {
  nav.navbar.bootsnav.no-full .navbar-collapse {
    max-height: 240px;
  }
}
