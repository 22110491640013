.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  background-color: #00000042;
  transition: background-color 0.3s ease-in-out;
  height: 100px;
  z-index: 999;
}

.navbar.sticky {
  background-image: linear-gradient(to right, #d84449, #660099);
  transition: background-color 0.3s ease-in-out;
}

.navbar__left {
  display: flex;
}

.navbar__logo {
  height: 40px;
  margin-right: 20px;
}

.navbar__options {
  display: flex;
  list-style: none;
  margin: 0;
}

.navbar__option {
  font-weight: 600;
  letter-spacing: 0.015em;
  font-size: 12px;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  text-transform: uppercase;
  margin: 0 20px;
  padding: 26px 0;
  background: transparent;
  border-color: transparent;
}

.navbar__option:hover,
.navbar__option.active {
  color: #d84449;
}

.navbar.sticky .navbar__option:hover,
.navbar.sticky .navbar__option.active {
  color: #ffffff99;
}

.navbar__pipe {
  margin-right: 20px;
  font-size: 16px;
  color: white;
}

.navbar__right {
  display: flex;
  align-items: center;
}

.navbar__icon {
  font-size: 24px;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  font-weight: 300;
}

.item-menu-servicos.active {
  background-color: #d84449;
  color: white;
  padding-left: 2px;
}

.item-menu-servicos:hover {
  background-color: rgba(35, 35, 35, 1);
  color: white;
  padding-left: 2px;
}

.menu-servicos {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.div-menu-back-servicos {
  opacity: 1;
  margin: 0 auto;
  max-width: 1170px;
  display: table;
  background: rgba(35, 35, 35, 1);
  padding: 45px 50px;
  min-width: 160px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1170px;
  z-index: 999;
  margin-top: 100px;

  /* transition: opacity 0.3s ease-in-out;
  opacity: 0; */
}

/* .navbar__option.hoverDiv + .div-menu-back-servicos{
  opacity: 1;
} */

.header-menu-servicos {
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 8px;
  white-space: inherit;
  padding: 0 40px;
  border-bottom: none;
  text-transform: uppercase;
  padding: 0;
}

.item-menu-servicos {
  color: #6f6f6f;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 8px;
  white-space: inherit;
  padding: 0 40px;
  border-bottom: none;
  padding: 0;
}

header a.logo {
  display: inline-block;
  vertical-align: middle;
}

.logo-light {
  opacity: 1;
  width: auto;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

.progress-bar {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  height: 2px;
  background: linear-gradient(to right, #d84449, #660099);
  overflow: hidden;
}

header nav .nav-header-container {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.swiper-pagination {
  bottom: unset !important;
  margin-top: -30px !important;
}

.swiper-pagination-bullet {
  background: #fff;
}

.header {
  height: 55px;
}

.header-container {
  align-items: center;
  display: flex;
  flex: 1;
}

@media screen and (max-width: 960px) {
  .header {
    height: auto;
  }

  .header-container {
    flex-direction: column;
    height: auto;
    background-color: #fff;
    border: 1px solid #2c304d;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .header-container > div {
    padding: 5px;
  }
}
